import React from 'react'
import { Icon, notification, Form, Switch, Button } from 'antd'
import { connect } from 'react-redux'
import { Helmet } from "react-helmet"
import { injectIntl, FormattedMessage } from 'react-intl'

import configService from 'services/config'
import reduxActions from 'actions'
import PageTitle from 'components/Global/PageTitle'

export class ModulesPage extends React.Component {
  state = {
    settings: {},
    processing: false
  }

  componentDidMount () {
    this.fetchSettings()
  }

  fetchSettings = async () => {
    try {
      const settings = await configService.get()

      this.setState({
        settings
      })
      this.props.dispatch(reduxActions.setSettings(settings))
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({ id: 'settings', defaultMessage: 'Settings' })} - download error`
      })
    }
  }

  saveSettings = async () => {
    if (this.state.processing) {
      return
    }

    this.setState({
      processing: true
    })

    try {
      const settings = await configService.save({
        ...this.state.settings
      })

      notification.success({
        message: this.props.intl.formatMessage({ id: 'saved', defaultMessage: 'Saved' })
      })

      this.setState({
        settings
      })
      this.props.dispatch(reduxActions.setSettings(settings))
    } catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({ id: 'saving error', defaultMessage: 'Saving Error' }),
      })
    }

    this.setState({
      processing: false
    })
  }

  updateModule = (field, value) => {
    this.setState(
      state => ({
        settings: {
          ...state.settings,
          [field]: {
            ...(state.settings[field] || {}),
            enabled: !!value
          }
        }
      })
    )
  }

  render () {
    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      className: 'formItem'
    }

    return (
      <div>
        <FormattedMessage id="head.title.modules" defaultMessage="Modules">
          {title => <Helmet><title>{title}</title></Helmet>}
        </FormattedMessage>

        <PageTitle
          title={this.props.intl.formatMessage({ id: 'modules', defaultMessage: 'Modules' })}
        />

        <Form.Item label="BaseX" {...formItemLayout}>
          <Switch
            disabled
            checkedChildren={<Icon type="check" />}
            unCheckedChildren={<Icon type="close" />}
            defaultChecked
          />
        </Form.Item>

        <Form.Item label="BuildX" {...formItemLayout}>
          <Switch
            checkedChildren={<Icon type="check" />}
            unCheckedChildren={<Icon type="close" />}
            checked={(this.state.settings.buildx || {}).enabled}
            onChange={checked => this.updateModule('buildx', checked)}
          />
        </Form.Item>

        <Form.Item label="DataX" {...formItemLayout}>
          <Switch
            checkedChildren={<Icon type="check" />}
            unCheckedChildren={<Icon type="close" />}
            checked={(this.state.settings.datax || {}).enabled}
            onChange={checked => this.updateModule('datax', checked)}
          />
        </Form.Item>

        <Form.Item label="DOClink" {...formItemLayout}>
          <Switch
            checkedChildren={<Icon type="check" />}
            unCheckedChildren={<Icon type="close" />}
            checked={(this.state.settings.doclink || {}).enabled}
            onChange={checked => this.updateModule('doclink', checked)}
          />
        </Form.Item>

        <Form.Item label="MainX" {...formItemLayout}>
          <Switch
            checkedChildren={<Icon type="check" />}
            unCheckedChildren={<Icon type="close" />}
            checked={(this.state.settings.mainx || {}).enabled}
            onChange={checked => this.updateModule('mainx', checked)}
          />
        </Form.Item>

        <Button
          htmlType="button"
          type="primary"
          onClick={this.saveSettings}
        >
          <FormattedMessage
            id="save"
            defaultMessage="Save"
          />
        </Button>
      </div>
    )
  }
}

export default injectIntl(connect()(ModulesPage))
