import React from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Form, Input, Select, Spin } from 'antd'

import './styles.scss'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
  className: 'formItem'
}

class CategoryForm extends React.Component {
  render () {
    const { props, props: { formOptions } } = this
    const { getFieldDecorator } = props.form
    let custom_form
    if (this.props.category.custom_form) {
      if (typeof this.props.category.custom_form === 'object') {
        custom_form = this.props.category.custom_form.name
      } else {
        custom_form = null
      }
    }

    return (
      <React.Fragment>
        <h3 style={{ fontSize: '16px', marginBottom: '30px' }}>
          {
            props.option === 'edit' ? (<FormattedMessage
              id="edit category"
              defaultMessage="Edit category"
            />)
              : (<FormattedMessage
                id="add category"
                defaultMessage="Add category"
              />)
          }
        </h3>
        <Form className="workOrdersForm">
          <Form.Item {...formItemLayout} label="Name">
            {getFieldDecorator('name', {
              initialValue: props.category.name,
              rules: [ {
                required: true,
                message: this.props.intl.formatMessage({ id: 'name is required', defaultMessage: 'Name is required' })
              } ]
            })(
              <Input
                onChange={event => props.updateCategory('name', event.target.value)}
              />
            )}
          </Form.Item>

          <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: 'project', defaultMessage: 'Project' })}>
            <Select
              showSearch
              allowClear
              style={{ width: '100%' }}
              optionFilterProp="children"
              notFoundContent={props.formLoading ? <div className="objects-spinner-container"><Spin size="small" /></div> : null}
              onFocus={() => props.fetchProjects('')}
              onSearch={value => props.fetchProjects(value)}
              onChange={projectID => props.updateCategory('project', projectID)}
              defaultValue={props.category.project ? props.category.project.projectName : ''}
              disabled={props.readOnlyNewProject}
            >
              {props.projects.map(project => (
                <Select.Option key={project._id} value={project._id}>{project.projectName}</Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            label={this.props.intl.formatMessage({ id: 'category form', defaultMessage: 'Form' })}
          >
            <Select
              showSearch
              allowClear
              // disabled={this.props.project.archived}
              style={{ width: '100%' }}
              placeholder={this.props.intl.formatMessage({ id: 'select category form', defaultMessage: 'Select Form' })}
              notFoundContent={this.props.formLoading ? <div className="categorie-spinner-container"><Spin size="small" /></div> : null}
              optionFilterProp="children"
              onFocus={() => this.props.fetchForms('')}
              // filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onSearch={ value => this.props.fetchForms(value)}
              onChange={(value, e) => this.props.updateCategory('custom_form', value, e)}
              defaultValue={ custom_form }
            >
              {formOptions.map(form => (
                <Select.Option key={form._id} data={form} value={form._id}>{form.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </React.Fragment>
    )
  }
}

export default injectIntl(connect()(CategoryForm))
