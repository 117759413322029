import React, { Component } from 'react'
import { Input, Button, Card, Form, Icon, notification, Dropdown, Menu } from 'antd';
import { Link } from 'react-router-dom'
import { injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import passwordService from 'services/password'

import 'pages/BaseX/Login/style.scss'
import actions from 'actions'

class ForgotPasswordPage extends Component {
  state = {
    email: '',
    processing: false
  }

  send = async () => {
    if (this.state.processing) {
      return
    }

    if (!this.state.email) {
      return notification.error({
        message: this.props.intl.formatMessage({ id: 'email cannot be empty', defaultMessage: 'Email cannot be empty' })
      })
    }

    this.setState({
      processing: true
    })

    try {
      await passwordService.sendToken(this.state.email)

      notification.success({
        message: this.props.intl.formatMessage({ id: 'success', defaultMessage: 'Email cannot be empty' }),
        description: this.props.intl.formatMessage({ id: 'email with further instructions has been sent', defaultMessage: 'Email with further instructions has been sent' })
      })

      this.setState({
        email: ''
      })
    } catch (error) {
      notification.error({
        message: 'Error'
      })
    }

    this.setState({
      processing: false
    })
  }

  render () {
    return (
      <div className="centerBoxWrapper">
        <p className="lang-selector">
          <Dropdown
            overlay={<Menu>
              <Menu.Item onClick={() => this.props.dispatch(actions.setLang('en'))}>
                <span className="nav-text" style={{ fontWeight: this.props.settings.lang === 'en' ? 'bold' : 'normal' }}>EN</span>
              </Menu.Item>

              <Menu.Item onClick={() => this.props.dispatch(actions.setLang('de'))}>
                <span className="nav-text" style={{ fontWeight: this.props.settings.lang === 'en' ? 'normal' : 'bold' }}>DE</span>
              </Menu.Item>
            </Menu>}
            placement="bottomCenter"
            trigger={['click']}
          >
            <Button
              shape="circle"
              icon="global"
            />
          </Dropdown>
        </p>

        <Card className="card">
          <img src="/images/logo.png" alt="logo" />

          <h1 style={{ fontSize: '2.5rem' }}>
            <FormattedMessage
              id="forgot password"
              defaultMessage="Forgot password"
            />
          </h1>

          <Form.Item>
            <p className="card-label">{this.props.intl.formatMessage({ id: 'email', defaultMessage: 'Email' })}</p>

            <Input
              onChange={e => this.setState({ email: e.target.value })}
              placeholder={this.props.intl.formatMessage({ id: 'email', defaultMessage: 'Email' })}
              value={this.state.email}
              prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
            />
          </Form.Item>

          <Form.Item style={{ textAlign: 'right' }}>
            <Button
              htmlType="button"
              onClick={this.send}
              type="primary"
              loading={this.state.processing}
            >
              <FormattedMessage
                id="send"
                defaultMessage="Send"
              />
            </Button>
          </Form.Item>

          <Link to="/login">
            <FormattedMessage
              id="login"
              defaultMessage="Login"
            />?
          </Link>
        </Card>
      </div >
    )
  }
}

const mapStateToProps = state => ({
  settings: state.settingsReducer
})

export default injectIntl(connect(mapStateToProps)(ForgotPasswordPage))
