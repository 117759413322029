import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import moment from 'moment'
import { Tabs, Form, Select, AutoComplete, Icon, Switch, Tag, Spin } from 'antd'

import InformationsSection from './Sections/Informations'
import IdentificationSection from './Sections/Identification'
import GeneralSection from './Sections/General'
import ImagesSection from './Sections/Images'
import ExtraFieldsSection from './Sections/ExtraFields'
import ProductsTab from './Sections/ProductsTab/'

import './styles.scss'

const { TabPane } = Tabs
const Option = AutoComplete.Option

class RecordingForm extends React.Component {
  changeStatus = status => {
    this.props.updateRecording('isInvalid', false)
    this.props.updateRecording('isVerified', false)
    this.props.updateRecording('isRejected', false)

    this.props.updateRecording('status', status)
  }

  render() {
    const {
      recording,
      readOnly,
      searchedProjects,
      updateRecording,
      fetchProjectsOnSearch,
      assignObjectHandler,
      handleSearchObjectId,
      objectIdSearchResult,
      objects,
      objectName,
      auth,
      extraFieldsForm,
      dataXEnabled,
      formLoading,
      acceptance,
      projects,
      defectsPage
    } = this.props

    let customForm = recording.project && recording.project.form ? recording.project.form : extraFieldsForm

    if (acceptance && recording.project) {
      const projectId = recording.project && recording.project._id ? recording.project._id : null;
      if (projectId) {
        const project = projects.find(project => project._id === projectId)
        customForm = (project.custom_forms && project.custom_forms.acceptance) || extraFieldsForm
      }
    }
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      className: 'formItem'
    }

    let status = recording.status
    if (!status) {
      if (recording.isVerified) {
        status = 'approved'
      } else if (recording.isInvalid) {
        status = 'invalid'
      } else if (recording.isRejected) {
        status = 'rejected'
      }
    }

    let contractSectionName = recording.contractSectionName
    if (contractSectionName === undefined) {
      contractSectionName = (recording.contractSection && recording.contractSection.name) || ''
    }

    const contractSections = (this.props.contractSections || []).map(contractSection => (
      <Option key={contractSection._id} value={contractSection.name}>
        {contractSection.name}
      </Option>
    ))

    return (
      <Tabs defaultActiveKey="form">
        <TabPane
          tab={this.props.intl.formatMessage({
            id: 'informations',
            defaultMessage: 'Informations'
          })}
          key="form"
          className="recordings-popup-wrap"
        >
          <Form onSubmit={() => {
          }} className="recordingForm">
            <InformationsSection
              formItemLayout={formItemLayout}
              recording={recording}
              formLoading={formLoading}
              projects={searchedProjects}
              fetchProjectsOnSearch={fetchProjectsOnSearch}
              changeData={updateRecording}
              readOnly={readOnly}
            />
            <IdentificationSection
              formItemLayout={formItemLayout}
              recording={recording}
              formLoading={formLoading}
              assignObjectHandler={assignObjectHandler}
              handleSearchObjectId={handleSearchObjectId}
              objectIdSearchResult={objectIdSearchResult}
              objects={objects}
              changeData={updateRecording}
              objectName={objectName}
              readOnly={readOnly}
              form={this.props.form}
            />
            <GeneralSection
              formItemLayout={formItemLayout}
              formLoading={formLoading}
              recording={recording}
              changeData={updateRecording}
              readOnly={readOnly}
            />

            {!this.props.recording.acceptanceId &&
            ['admin', 'superadmin'].includes(auth.auth_user.scope[0]) && (
              <React.Fragment>
                <Form.Item {...formItemLayout} label="Status">
                  <Select
                    style={{
                      width: '100%'
                    }}
                    allowClear
                    value={status}
                    onChange={value => this.changeStatus(value)}
                    disabled={defectsPage}
                  >
                    <Select.Option value="opened">
                      <FormattedMessage
                        id="opened"
                        defaultMessage="Opened"
                      />
                    </Select.Option>
                    <Select.Option value="rejected">
                      <FormattedMessage
                        id="rejected"
                        defaultMessage="Rejected"
                      />
                    </Select.Option>
                    <Select.Option value="approved">
                      <FormattedMessage
                        id="approved"
                        defaultMessage="Approved"
                      />
                    </Select.Option>
                    <Select.Option value="invalid">
                      <FormattedMessage
                        id="invalid"
                        defaultMessage="Invalid"
                      />
                    </Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({
                  id: 'archived',
                  defaultMessage: 'Archived'
                })}>
                  <Switch
                    disabled={readOnly}
                    checkedChildren={<Icon type="check"/>}
                    unCheckedChildren={<Icon type="close"/>}
                    checked={recording.archived}
                    onChange={checked => updateRecording('archived', checked)}
                  />
                </Form.Item>

                {recording.submitted &&
                <React.Fragment>
                  <h3>
                    <FormattedMessage
                      id="upload"
                      defaultMessage="Upload"
                    />
                  </h3>

                  <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({
                    id: 'submitted at',
                    defaultMessage: 'Submitted at'
                  })}>
                    <span className="ant-form-text">
                      {!!recording.submittedAt && moment(recording.submittedAt).format(
                        'DD.MM.YYYY, HH:mm')}
                    </span>
                  </Form.Item>

                  <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({
                    id: 'reference number',
                    defaultMessage: 'Reference number'
                  })}>
                    <span className="ant-form-text">
                      {!!recording.submittedRefNr && recording.submittedRefNr}
                    </span>
                  </Form.Item>

                  <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({
                    id: 'status',
                    defaultMessage: 'Status'
                  })}>
                    <span className="ant-form-text">
                      {!!recording.submittedRefNr &&
                      <Tag color="#87d068"><Icon type="check"/> erfolgreich</Tag>}
                    </span>
                  </Form.Item>
                </React.Fragment>
                }
              </React.Fragment>
            )}

            {recording.acceptanceId && (
              <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({
                id: 'contract section',
                defaultMessage: 'Contract section'
              })}>
                <div className="certain-category-search-wrapper">
                  <AutoComplete
                    allowClear
                    className="certain-category-search"
                    dropdownClassName="certain-category-search-dropdown"
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ width: 300 }}
                    style={{ width: '100%' }}
                    dataSource={contractSections}
                    optionLabelProp="value"
                    notFoundContent={this.props.formLoading ?
                      <div className="recordings-spinner-container"><Spin size="small"/>
                      </div> : null}
                    filterOption={(inputValue, option) => option.props.children.toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1}
                    defaultValue={contractSectionName}
                    onFocus={() => this.props.fetchContractSections('')}
                    onSearch={(value) => this.props.fetchContractSections(value)}
                    onChange={value => updateRecording('contractSectionName', value)}
                    disabled={readOnly}
                  />
                </div>
              </Form.Item>
            )}
          </Form>
        </TabPane>

        <TabPane tab={this.props.intl.formatMessage({ id: 'photos', defaultMessage: 'Photos' })}
                 key="photos">
          <Form onSubmit={() => {
          }} className="recordingForm">
            <ImagesSection
              formItemLayout={formItemLayout}
              recording={recording}
              changeData={updateRecording}
              readOnly={readOnly}
            />
          </Form>
        </TabPane>

        {!!customForm && (customForm.fields || []).length > 0 && (
          <TabPane tab={customForm.name} key="extraFields" className="recordings-popup-wrap">
            <Form onSubmit={() => {
            }} className="recordingForm">
              <ExtraFieldsSection
                formItemLayout={formItemLayout}
                recording={recording}
                form={customForm}
                changeData={updateRecording}
                readOnly={readOnly}
              />
            </Form>
          </TabPane>
        )}
        {
          dataXEnabled && (
            <TabPane
              className="recordings-popup-wrap"
              tab={this.props.intl.formatMessage({ id: 'products', defaultMessage: 'Products' })}
              key="products"
            >
              <Form onSubmit={() => {
              }} className="productsForm">
                <ProductsTab
                  recording={recording}
                  auth={auth}
                  updateRecording={updateRecording}
                  readOnly={readOnly}
                />
              </Form>
            </TabPane>
          )
        }
      </Tabs>
    )
  }
}

RecordingForm.propTypes = {
  recording: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  contractSections: PropTypes.array,
  submittedAt: PropTypes.instanceOf(Date)
}

const mapStateToProps = state => {
  return {
    auth: state.authReducer,
    projects: state.projectReducer.projects
  }
}

export default injectIntl(connect(mapStateToProps)(RecordingForm))
