import React from 'react'
import PropTypes from 'prop-types'
import { Button as AntButton, Icon } from 'antd'

const Button = (props) => {
  return (
    <AntButton
      type={props.type}
      onClick={props.onClick}
      disabled={props.disabled}
      style={props.style}
      size={props.size}
      loading={props.loading}
    >
      {props.icon && !props.iconRight && <React.Fragment><Icon type={props.icon} /> </React.Fragment>}
      {props.value} {props.children}
      {props.icon && props.iconRight && <Icon type={props.icon} />}
    </AntButton>)
}

Button.propTypes = {
  value: PropTypes.string.isRequired,
  icon: PropTypes.string,
  type: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  iconRight: PropTypes.bool,
  size: PropTypes.string
}

Button.defaultProps = {
  iconRight: false
}

export default Button
