import React from 'react'
import { Route } from 'react-router-dom'

const PublicRoute = ({ component: Component, layout: Layout }) => (
  <Route
    render={props => {
      if (!Layout) {
        return <Component {...props} />
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)

export default PublicRoute
