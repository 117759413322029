import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import actions from 'actions'

import {
  Table,
  Icon,
  notification,
  Input,
  Form,
  Menu,
  Dropdown,
  Tag
} from 'antd'

import RecordingForm from 'components/Recording/Form'
import recordingService from 'services/recording'
import formService from 'services/form'
import projectService from 'services/project'
import config from 'config'
import objectService from 'services/object'
import PageTitle from 'components/Global/PageTitle'
import Button from 'components/Global/Button'
import PopconfirmDelete from 'components/Global/PopconfirmDelete'

import DrawerComponent from 'components/Drawer'
import ModalDefectCreation from 'components/ModalDefectCreation'
import RecordingStatusIcon from 'components/Global/RecordingStatusIcon'

export class RecordingsPage extends React.Component {
  _isMounted = false
  state = {
    recordings: [],
    recording: {},
    objects: [],
    searchedProjects: [],
    sapEnabled: false,
    objectName: '',
    visible: false,
    loading: false,
    formLoading: false,
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50', '100']
    },
    search: {
      name: '',
      aksNumber: '',
      submittedRefNr: ''
    },
    selectedRowKeys: [],
    statusFilters: [],
    archivedFilters: [],
    createdByFilters: [],
    recordingCustomFormDefault: {},
    defect: {},
    originalData: {},
    recordingCustomForm: {},
    recordingsName: '',
    readOnly: false,
    objectIdSearchResult: [],
    modalDefectCreationVisible: false
  }

  componentDidMount () {
    this._isMounted = true

    this.fetchRecordings()
    this.fetchSettings()
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { objectName, recordingsName } = nextProps.settings.data.buildx
    if (objectName !== prevState.objectName ||
      recordingsName !== prevState.recordingsName ||
      nextProps.settings.data.datax.enabled !== prevState.dataXEnabled ||
      nextProps.settings.data.apps.sap !== prevState.sapEnabled) {
      return {
        objectName: nextProps.settings.data.buildx.objectName,
        recordingsName: nextProps.settings.data.buildx.recordingsName,
        dataXEnabled: nextProps.settings.data.datax.enabled,
        sapEnabled: nextProps.settings.data.apps.sap
      }
    }
    return null
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  fetchProjects = async () => {
    try {
      const projects = await projectService.list()

      if (this._isMounted) {
        this.setState({
          projects
        })
      }
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({
          id: 'projects',
          defaultMessage: 'Projects'
        })} - download error`
      })

      this.setState({
        projects: []
      })
    }
  }

  fetchProjectsOnSearch = async (value) => {
    this.setState({
      formLoading: true
    })
    const timer = setTimeout(async () => {
      try {
        let projects = await projectService.listV2({
          projectName: [value],
          limit: 100
        })

        this.setState({
          searchedProjects: projects.docs,
          formLoading: false
        })

        clearTimeout(timer)
      } catch (error) {
        this.setState({
          searchedProjects: [],
          formLoading: false
        })
        clearTimeout(timer)
      }
    })
  }

  componentDidUpdate (prevProps) {
    if (this.props.project !== prevProps.project) {
      this.fetchRecordings()
    }
  }

  fetchRecordings = async (params) => {
    this.setState({
      loading: true
    })

    try {
      const recordings = await recordingService.listV2({
        limit: this.state.defaultPageSize,
        ...params
      })

      const pagination = {
        ...this.state.pagination
      }

      pagination.total = recordings.totalDocs

      this.setState({
        recordings: recordings.docs,
        createdByFilters: recordings.filterOptions.createdBy,
        loading: false,
        pagination
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({
          id: 'recordings',
          defaultMessage: 'Recordings'
        })} - download error`
      })

      this.setState({
        recordings: [],
        visible: false,
        loading: false
      })
    }
  }

  fetchSettings = async () => {
    try {
      const recordingCustomFormID = (this.props.settings.data.buildx.custom_forms || {}).recording
      let recordingCustomFormDefault = {}
      if (recordingCustomFormID) {
        recordingCustomFormDefault = await formService.get(recordingCustomFormID)
      }
      if (this._isMounted) {
        this.setState({
          recordingCustomFormDefault
        })
      }
    }
    catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({
          id: 'settings',
          defaultMessage: 'Settings'
        })} - download error`
      })
    }
  };

  showDrawer = async recording => {
    let status = recording.status
    if (!status) {
      if (recording.isVerified) {
        status = 'approved'
      } else if (recording.isInvalid) {
        status = 'invalid'
      } else if (recording.isRejected) {
        status = 'rejected'
      }
    }

    let recordingCustomForm = {}
    if (recording.project) {
      try {
        const project = this.state.projects.filter(item => item._id === recording.project)[0]
        if (project && project.form) {
          recordingCustomForm = await formService.get(project.form)
        }
      } catch (error) {
      }
    }

    this.setState({
      readOnly: status === 'approved' || status === 'invalid',
      visible: true,
      recording,
      recordingCustomForm
    })
  }

  hideDrawer = () => {
    this.props.form.resetFields()

    this.setState({
      visible: false,
      recording: {},
      recordingCustomForm: {}
    })
  }

  saveRecording = async () => {
    this.props.form.validateFields(async err => {
      if (!err) {
        try {
          const savedRecording = await recordingService.save(this.state.recording)

          this.fetchRecordings()
          if (this.state.recording._id) {
            notification.success({
              message: `${this.props.intl.formatMessage({ id: 'updated successfully', defaultMessage: 'Updated successfully' })}`
            })
          }

          this.props.form.resetFields()

          this.setState({
            visible: false,
            recording: {},
            recordings: [
              ...this.state.recordings.filter(recording => recording._id !== savedRecording._id),
              savedRecording
            ]
          })
        } catch (error) {
          notification.error({
            message: this.props.intl.formatMessage({
              id: 'saving error',
              defaultMessage: 'Saving Error'
            }),
            description: error.message
          })
        }
      }
    })
  }

  deleteRecording = async () => {
    try {
      await recordingService.delete(this.state.recording)

      this.props.form.resetFields()

      this.setState({
        visible: false,
        recording: {},
        recordings: [
          ...this.state.recordings.filter(recording => recording._id !== this.state.recording._id)
        ]
      })
    } catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({
          id: 'deleting error',
          defaultMessage: 'Deleting Error'
        }),
        description: error.message
      })
    }
  }

  archive = async (recording) => {
    try {
      await recordingService.archive(recording._id)
      this.fetchRecordings()

      notification.success({
        message: `${this.props.intl.formatMessage({ id: 'archived successfully', defaultMessage: 'Archived successfully' })}`
      })
    } catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({
          id: 'archive error',
          defaultMessage: 'Recording could not be archived.'
        }),
        description: error.message
      })
    }
  }

  unarchive = async (recording) => {
    try {
      await recordingService.unarchive(recording._id)
      this.fetchRecordings()

      notification.success({
        message: `${this.props.intl.formatMessage({ id: 'unarchived successfully', defaultMessage: 'Unarchived successfully' })}`
      })
    } catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({
          id: 'unarchived error',
          defaultMessage: 'Recording could not be unarchived.'
        }),
        description: error.message
      })
    }
  }

  updateRecording = (field, value) => {
    this.setState({
      recording: {
        ...this.state.recording,
        [field]: value
      }
    }
    )
  }

  changeName = name => {
    this.updateRecording('objectId', {
      _id: null,
      name
    })
  }

  assignObjectHandler = (value, e) => {
    this.changeName(e.props.children)
  }

  showPDF = recording => {
    axios({
      method: 'get',
      url: `${config.server.url}/recording/${recording._id}/pdf`,
      responseType: 'arraybuffer',
      headers: {
        authorization: localStorage.getItem('auth_token')
      }
    }).then(function (response) {
      let blob = new Blob([response.data], { type: 'application/pdf' })
      const fileName = `${recording._id}.pdf`

      if (navigator && navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, fileName)
      } else {
        let blob = new Blob([response.data], { type: 'application/pdf' })

        let link = document.createElement('a')
        link.setAttribute('href', window.URL.createObjectURL(blob))
        link.setAttribute('download', `${recording.name}.pdf`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    })
  }

  exportCSV = () => {
    const { params } = this.state
    const { project } = this.props;
    const formedParams = { ...params }

    if (project && project.project && project.project._id) {
      formedParams.project = project.project._id
    }
    axios({
      method: 'get',
      url: `${config.server.url}/recording-csv`,
      responseType: 'text/csv',
      headers: {
        authorization: localStorage.getItem('auth_token')
      },
      params: formedParams
    }).then(function (response) {
      const blob = new Blob([response.data], { type: 'text/csv' })
      const fileName = `recordingsData.csv`

      if (navigator && navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, fileName)
      } else {
        const dataURI = `data:text/csv;charset=utf-8,${response.data}`

        const URL = window.URL || window.webkitURL
        const downloadURI = typeof URL.createObjectURL === 'undefined' ? dataURI : URL.createObjectURL(
          blob)

        let link = document.createElement('a')
        link.setAttribute('href', downloadURI)
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    })
  }

  exportProductsCSV = async () => {
    const { params } = this.state
    const { project } = this.props;
    const formedParams = { ...params }

    if (project._id) {
      formedParams.project = project._id
    }
    axios({
      method: 'get',
      url: `${config.server.url}/recording-products-csv`,
      responseType: 'text/csv',
      headers: {
        authorization: localStorage.getItem('auth_token')
      },
      params: formedParams
    }).then(function (response) {
      const blob = new Blob([response.data], { type: 'text/csv' })
      const fileName = `products.csv`

      if (navigator && navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, fileName)
      } else {
        const dataURI = `data:text/csv;charset=utf-8,${response.data}`

        const URL = window.URL || window.webkitURL
        const downloadURI = typeof URL.createObjectURL === 'undefined' ? dataURI : URL.createObjectURL(
          blob)

        let link = document.createElement('a')
        link.setAttribute('href', downloadURI)
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    })
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this[`searchInput${dataIndex}`] = node
          }}
          placeholder={this.props.intl.formatMessage({ id: 'search', defaultMessage: 'Suchen' })}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          onClick={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          icon="search"
          size="small"
          value="Suchen"
          style={{ width: 90, marginRight: 8 }}
        />

        <Button
          onClick={() => this.handleReset(dataIndex, clearFilters)}
          size="small"
          type="secondary"
          value="Reset"
          style={{ width: 90 }}
        />
      </div>
    ),
    filterIcon: filtered => (<Icon type="search"
      style={{ color: filtered ? '#1890ff' : undefined }} />),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this[`searchInput${dataIndex}`].select())
      }
    }
  })

  handleSearch = (selectedKeys, field, confirm) => {
    confirm()
    this.setState({
      search: {
        ...this.state.search,
        [field]: selectedKeys[0]
      }
    })
  }

  handleReset = (field, clearFilters) => {
    clearFilters()

    this.setState({
      search: {
        ...this.state.search,
        [field]: ''
      }
    })
  }

  onSelectChange = selectedRowKeys => {
    this.setState({
      selectedRowKeys
    })
  }

  onTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    pager.offset = pagination.current

    this.setState({
      pagination: pager,
      params: {
        offset: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters
      }
    })

    this.fetchRecordings({
      limit: pagination.pageSize,
      offset: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    })
  }

  handleSearchObjectId = async value => {
    this.setState({
      formLoading: true
    })
    const timer = setTimeout(async () => {
      try {
        const response = await objectService.listV2({
          name: [value],
          limit: 100
        })

        this.setState({
          objectIdSearchResult: response.docs,
          formLoading: false
        })
        clearTimeout(timer)
      } catch (error) {
        this.setState({
          formLoading: false
        })
        clearTimeout(timer)
      }
    }, 1000)
  }

  showModalDefectCreation = (record) => {
    this.setState({
      modalDefectCreationVisible: true,
    })
  }

  hideModalDefectCreation = (record) => {
    this.setState({
      modalDefectCreationVisible: false,
    })
  }

  createTask = async (record) => {
    try {
      const savedWorkorder = await recordingService.createTask(record._id)
      if (savedWorkorder._id) {
        notification.success({
          message: `${this.props.intl.formatMessage({ id: 'task created successfully', defaultMessage: 'Task created successfully' })}`
        })
        this.fetchRecordings()
      }
    } catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({
          id: 'saving error',
          defaultMessage: 'Saving Error'
        }),
        description: error.message
      })
    }
  }

  updateStateDefect = (field, value) => {
    this.setState({
      defect: {
        ...this.state.defect,
        [field]: value
      }
    })
  }

  createDefect = (record) => {
    this.props.dispatch(actions.setDefectData({ income: record, value: {} }));
    this.showModalDefectCreation();
  }

  render () {
    const {
      dataXEnabled,
      recordings,
      loading,
      sapEnabled,
      readOnly,
      modalDefectCreationVisible
    } = this.state

    const { intl } = this.props

    const columns = [
      {
        title: intl.formatMessage({ id: 'status', defaultMessage: 'Status' }),
        key: 'status',
        align: 'center',
        render: (text, record) => {
          let status = record.status || ''
          if (!status) {
            if (record.isVerified) {
              status = 'approved'
            } else if (record.isInvalid) {
              status = 'invalid'
            } else if (record.isRejected) {
              status = 'rejected'
            }
          }

          return (
            <RecordingStatusIcon status={status} />
          )
        },
        filters: [
          {
            text: intl.formatMessage({ id: 'approved', defaultMessage: 'Approved' }),
            value: 'approved'
          }, {
            text: intl.formatMessage({ id: 'opened', defaultMessage: 'Opened' }),
            value: 'opened'
          }, {
            text: intl.formatMessage({ id: 'rejected', defaultMessage: 'Rejected' }),
            value: 'rejected'
          }, {
            text: intl.formatMessage({ id: 'invalid', defaultMessage: 'Invalid' }),
            value: 'invalid'
          }
        ]
      },
      {
        title: intl.formatMessage({ id: 'number', defaultMessage: 'Number' }),
        key: 'docNumber',
        dataIndex: 'docNumber',
        ...this.getColumnSearchProps('docNumber')
      },
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        sorter: true,
        ...this.getColumnSearchProps('name')
      },
      {
        title: this.state.objectName,
        key: 'objectId',
        dataIndex: 'objectId',
        sorter: true,
        render: (text, record) => {
          if (record.objectId && record.objectId.name) {
            return record.objectId.name
          }

          return ''
        },
        ...this.getColumnSearchProps('aksNumber')
      },
      {
        title: intl.formatMessage({ id: 'archived', defaultMessage: 'Archived' }),
        key: 'archived',
        dataIndex: 'archived',
        align: 'center',
        render: active => active && <Icon type="check" style={{ color: '#005591' }} />,
        filters: [
          {
            text: intl.formatMessage({
              id: 'show all',
              defaultMessage: 'show all'
            }),
            value: 'showAll'
          },
          {
            text: intl.formatMessage({
              id: 'show unarchived',
              defaultMessage: 'show unarchived'
            }),
            value: 'showUnarchived'
          },
          {
            text: intl.formatMessage({
              id: 'show archived',
              defaultMessage: 'show archived'
            }),
            value: 'showArchived'
          }
        ],
        filterMultiple: false,
        filteredValue: this.state.archivedFilters
      },
      {
        title: intl.formatMessage({ id: 'created at', defaultMessage: 'Created At' }),
        key: 'createdAt',
        dataIndex: 'createdAt',
        sorter: true,
        render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
        defaultSortOrder: 'descend'
      },
      {
        title: intl.formatMessage({ id: 'created by', defaultMessage: 'Created By' }),
        key: 'createdBy',
        dataIndex: 'createdBy',
        render: createdBy => createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : '',
        filters: this.state.createdByFilters.map(user => ({
          text: `${user.firstName} ${user.lastName}`,
          value: user._id
        }))
      },
      {
        title: intl.formatMessage({ id: 'reference number', defaultMessage: 'Reference Number' }),
        key: 'submittedRefNr',
        dataIndex: 'submittedRefNr',
        ...this.getColumnSearchProps('submittedRefNr'),
        render: (text, record) => {
          let data = []

          if (record.submittedWorkorder && record.submittedWorkorderId) {
            data.push(
              <span><Tag color="blue"><Icon type="check" /> Workorder ({record.submittedWorkorderId.docNumber})</Tag>&nbsp;&nbsp;</span>)
          }

          if (record.submittedTask && record.submittedTaskId) {
            data.push(
              <span><Tag color="blue"><Icon type="check" /> Task ({record.submittedTaskId.docNumber})</Tag>&nbsp;&nbsp;</span>)
          }

          if (record.submitted) {
            data.push(
              <span><Tag color="blue"><Icon type="check" /> SAP ({record.submittedRefNr})</Tag></span>)
          }

          if (record.submissions && record.submissions.some(e => e.name === 'defect')) {
            data.push(
              <span>
                <Tag color="blue">
                  <Icon type="check" />&nbsp;
                <FormattedMessage
                    id="defect"
                    defaultMessage="Defect" />&nbsp;
                  ({record.submissions.map(a => <span>{a.reference}</span>)})
            </Tag>
              </span>)
          }

          return data
        }
      },
      {
        key: 'actions',
        render: (text, record) => (
          <div style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-end'
          }}>
            <Dropdown overlay={
              <Menu>
                <Menu.Item
                  key="0"
                  onClick={() => this.showDrawer(record)}
                >
                  <Icon
                    type={text.status === "approved" ? 'eye' : 'edit'}
                  />
                  {text.status === "approved"
                    ? intl.formatMessage({
                      id: 'view',
                      defaultMessage: 'View'
                    })
                    : intl.formatMessage({
                      id: 'edit',
                      defaultMessage: 'edit'
                    })}
                </Menu.Item>
                <Menu.Item
                  key="1"
                  onClick={() => this.showPDF(record)}
                >
                  <Icon type="file-pdf" />
                  <FormattedMessage
                    id='generate pdf'
                    defaultMessage='Generate PDF'
                  />
                </Menu.Item>

                {!record.archived &&
                  <Menu.Item
                    key="2"
                    onClick={() => this.archive(record)}
                  >
                    <Icon type="inbox" />
                    <FormattedMessage
                      id='archive'
                      defaultMessage='Archive'
                    />
                  </Menu.Item>
                }

                {record.archived &&
                  <Menu.Item
                    key="2"
                    onClick={() => this.unarchive(record)}
                  >
                    <Icon type="inbox" />
                    <FormattedMessage
                      id='unarchive'
                      defaultMessage='Unarchive'
                    />
                  </Menu.Item>
                }

                <Menu.Divider />

                {sapEnabled && record.submitted &&
                  < Menu.Item key="3"
                    disabled={record.submitted}>
                    <Icon type="check" />&nbsp;
                    <FormattedMessage
                      id='submitted to sap'
                      defaultMessage='Submitted to SAP'
                    />
                  </Menu.Item>
                }

                {record.submissions && !record.submissions.some(e => e.name === 'defect') && !record.archived &&
                  <Menu.Item
                    key="5"
                    onClick={() => this.createDefect(record)}
                  >
                    <Icon type="plus" />&nbsp;
                    <FormattedMessage
                      id='create defect mainx'
                      defaultMessage='Create defect (MainX)'
                    />
                  </Menu.Item>
                }

                {record.submissions && record.submissions.some(e => e.name === 'defect') &&
                  <Menu.Item
                    disabled={true}
                    key="5"
                  >
                    <Icon type="check" />&nbsp;
                    <FormattedMessage
                      id='defect created mainx'
                      defaultMessage='Defect created (MainX)'
                    />
                  </Menu.Item>
                }

                {record.submittedWorkorder &&
                  <Menu.Item
                    disabled={true}
                    key="5"
                  >
                    <Icon type="check" />&nbsp;
                    <FormattedMessage
                      id='mainx workorder created'
                      defaultMessage='MainX Workorder created'
                    />
                  </Menu.Item>
                }

              </Menu >
            } trigger={['click']} >
              <a className="ant-dropdown-link" href="#">
                <Icon type="more" style={{ fontSize: '2rem', color: '#444' }} />
              </a>
            </Dropdown>
          </div >
        )
      }
    ]

    if (!sapEnabled) {
      const columnIndex = columns.findIndex(x => x.key === 'submittedRefNr')
      if (columnIndex) columns.splice(columnIndex, 1)
    }

    const csvProductExportButton = [
      <Button
        onClick={this.exportProductsCSV}
        icon="download"
        disabled={loading}
        value={<FormattedMessage
          id="csv product export"
          defaultMessage="CSV Product Export"
        />}
      />
    ]

    const headerButtons = [
      <Button
        onClick={this.exportCSV}
        icon="download"
        value={<FormattedMessage
          id="csv export"
          defaultMessage="CSV Export"
        />}
        disabled={loading}
      />
    ]

    if (dataXEnabled) {
      headerButtons.push(csvProductExportButton)
    }

    const footerDrawerButtons = [
      <React.Fragment>
        <Button
          onClick={this.hideDrawer}
          style={{ marginRight: 8 }}
          value={<FormattedMessage
            id="cancel"
            defaultMessage="Cancel"
          />}
        />

        <Button
          onClick={this.saveRecording}
          type="primary"
          value={<FormattedMessage
            id='update recording'
            defaultMessage='Update recording'
          />}
        />
      </React.Fragment>
    ]

    if (['admin', 'superadmin'].includes(this.props.auth.auth_user.scope[0]) && this.state.recording) {
      footerDrawerButtons.unshift(
        <PopconfirmDelete
          onConfirmAction={this.deleteRecording}
          readOnly={readOnly}
        />
      )
    }

    return (
      <div>
        <FormattedMessage id="head.title.recordings" defaultMessage="Recordings">
          {title => <Helmet><title>{title}</title></Helmet>}
        </FormattedMessage>

        <PageTitle
          title={intl.formatMessage({ id: 'recordings', defaultMessage: 'Recordings' })}
          buttons={headerButtons}
        />

        <Table
          ref={ref => this._table = ref}
          rowKey={record => record._id}
          loading={loading}
          columns={columns}
          dataSource={recordings}
          onChange={this.onTableChange}
          onRow={(record) => ({
            onDoubleClick: () => { this.showDrawer(record) }
          })}
          defaultPageSize={this.state.pagination.defaultPageSize}
          pagination={this.state.pagination}
        />

        <DrawerComponent
          title={readOnly === true ? intl.formatMessage({ id: 'recording details', defaultMessage: 'Recording details' }) : this.props.intl.formatMessage({ id: 'edit recording', defaultMessage: 'Edit recording' })}
          onClose={this.hideDrawer}
          visible={this.state.visible}
          footerButtons={footerDrawerButtons}
        >
          <RecordingForm
            recording={this.state.recording}
            searchedProjects={this.state.searchedProjects}
            formLoading={this.state.formLoading}
            fetchProjectsOnSearch={this.fetchProjectsOnSearch}
            updateRecording={this.updateRecording}
            assignObjectHandler={this.assignObjectHandler}
            handleSearchObjectId={this.handleSearchObjectId}
            objectIdSearchResult={this.state.objectIdSearchResult}
            objectName={this.state.objectName}
            objects={this.props.objects.objects || []}
            dataXEnabled={this.state.dataXEnabled}
            extraFieldsForm={this.state.recordingCustomForm._id ? this.state.recordingCustomForm : this.state.recordingCustomFormDefault}
            form={this.props.form}
            auth={this.props.auth}
            readOnly={this.state.readOnly}
          />
        </DrawerComponent>

        <ModalDefectCreation
          visible={modalDefectCreationVisible}
          updateStateDefect={this.updateStateDefect}
          onClose={this.hideModalDefectCreation}
          onCancel={this.hideModalDefectCreation}
          refreshData={this.fetchRecordings}
        />
      </div>
    )
  }
}

RecordingsPage.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.authReducer,
  settings: state.settingsReducer,
  project: state.projectReducer,
  objects: state.objectsReducer
})

export default injectIntl(Form.create({ name: 'recording_form' })(
  connect(mapStateToProps)(RecordingsPage)))
