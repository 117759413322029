import React from 'react'
import moment from 'moment'
import _ from 'lodash'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import formService from 'services/form'
import axios from 'axios'
import NumberFormat from 'react-number-format'

import {
  Table,
  notification,
  Icon,
  Form,
  Input,
  Menu,
  Dropdown,
  Tag,
  Modal,
  Spin,
  Button
} from 'antd'

import defectsService from 'services/defects'
import imageService from 'services/image'

import projectService from 'services/project'
import contractSectionService from 'services/contractSection'
import config from 'config'

import DefectModalTable from 'components/Defect/ModalTable'
import PageTitle from 'components/Global/PageTitle'
import DrawerComponent from 'components/Drawer'
import RecordingForm from 'components/Recording/Form'
import PopconfirmDelete from 'components/Global/PopconfirmDelete'
import DefectsDrawerTabs from './DefectsDrawerTabs';


export class DefectsPage extends React.Component {
  state = {
    items: [],
    item: {},
    new: false,
    projects: [],
    contractSections: [],
    selectedRowKeys: [],
    drawerVisible: false,
    activeFilters: ['active', 'inactive'],
    loading: false,
    formLoading: false,
    sapModalShown: false,
    sapSending: false,
    sapUploadOBject: {},
    sapMessage: '',
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50', '100']
    },
    filterOptions: {},
    defectRecording: {},
    defectDrawerVisible: false,
    recordingCustomForm: {},
    selectedRowObjects: [],
    assignToModalVisible: false,
    multipleUpdateField: '',
    fileUpload: false

  };

  componentDidMount () {
    this.fetchDefects()
  }

  componentDidUpdate (prevProps) {
    if (this.props.project !== prevProps.project) {
      this.fetchDefects()
    }
  }

  fetchDefects = async (params) => {
    this.setState({
      loading: true
    });
    try {
      const pagination = {
        ...this.state.pagination
      }

      const defect = await defectsService.listV2({
        limit: pagination.defaultPageSize,
        ...params,
      })

      pagination.total = defect.totalDocs

      this.setState({
        items: defect.docs,
        loading: false,
        pagination,
        filterOptions: defect.filterOptions
      })
    }
    catch (error) {
      console.log(error);
      notification.error({
        message: `${this.props.intl.formatMessage({
          id: 'defect',
          defaultMessage: 'Defect'
        })} - download error`
      });

      this.setState({
        drawerVisible: false,
        loading: false
      })
    }
  };

  fetchProjects = async (value) => {
    this.setState({
      formLoading: true
    });
    const timer = setTimeout(async () => {
      try {
        let projects = await projectService.listV2({
          projectName: [value],
          limit: 100
        });

        this.setState({
          projects: projects.docs,
          formLoading: false
        });

        clearTimeout(timer)
      }
      catch (error) {
        this.setState({
          projects: [],
          formLoading: false
        });
        clearTimeout(timer)
      }
    })
  };

  fetchContractSections = async (value) => {
    this.setState({
      formLoading: true
    });
    const timer = setTimeout(async () => {
      try {
        let contractSections = await contractSectionService.listV2({
          limit: 100
        });

        this.setState({
          contractSections: contractSections.docs,
          formLoading: false
        });

        clearTimeout(timer)
      }
      catch (error) {
        this.setState({
          contractSections: [],
          formLoading: false
        });
        clearTimeout(timer)
      }
    })
  };

  onTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.offset = pagination.current;

    this.setState({
      pagination: {
        ...pager,
        current: pagination.current
      },
      params: {
        offset: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters
      }
    });

    this.fetchDefects({
      limit: pagination.pageSize,
      offset: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    })
  };

  handleSearch = (selectedKeys, field, confirm) => {
    confirm();
    this.setState({
      search: {
        ...this.state.search,
        [field]: selectedKeys[0]
      }
    })
  };

  handleReset = (field, clearFilters) => {
    clearFilters();

    this.setState({
      search: {
        ...this.state.search,
        [field]: ''
      }
    })
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this[`searchInput${dataIndex}`] = node
          }}
          placeholder={this.props.intl.formatMessage({ id: 'search', defaultMessage: 'Suchen' })}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Suchen
        </Button>
        <Button
          onClick={() => this.handleReset(dataIndex, clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type="search"
      style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this[`searchInput${dataIndex}`].select())
      }
    }
  });

  uploadSAP = () => {
    const { sapUploadObject } = this.state;
    this.setState({
      sapSending: true
    });
    defectsService.submit(sapUploadObject._id)
      .then(response => {
        if (typeof response.response === 'object' && response.response.submissions[0] && response.response.submissions[0].reference) {
          this.setState({
            sapSending: 'success',
            message: response.response.submissions[0].reference
          })
        } else if (typeof response.response === 'string' && response.response.indexOf('Error' > -1)) {
          this.setState({
            sapSending: 'error'
          })
        }
      })
      .catch(error => {
        this.setState({
          sapSending: 'error'
        })
      })
  };

  requestUploadSAP = (defect) => {
    this.setState({
      sapModalShown: true,
      sapSending: false,
      sapUploadObject: defect
    })
  };

  onSelectChange = selectedRowKeys => {
    const selectedRowObjects = this.state.items.filter(item => selectedRowKeys.indexOf(item._id) > -1)
    this.setState({
      selectedRowKeys,
      selectedRowObjects
    });
  };

  updateItem = (field, value) => {
    if (field === "project") {
      this.setState(
        state => ({
          item: {
            ...state.item,
            project: value,
            objectId: ''
          }
        })
      )
    } else {
      this.setState(
        state => ({
          item: {
            ...state.item,
            [field]: value
          }
        })
      )
    }
  };


  showDrawer = item => {
    const { project } = this.props
    if (_.isEmpty(item, true)) {
      this.setState({
        drawerVisible: true,
        item: {
          ...item,
          project
        },
        readOnlyNewProject: !!project
      })
    } else {
      this.setState({
        drawerVisible: true,
        item: {
          ...item
        },
        readOnlyNewProject: false
      })
    }
  }

  hideDrawer = (key) => {
    this.setState({
      [key]: false
    })
  }

  saveItem = async () => {
    const { item } = this.state
    this.props.form.validateFields(async err => {
      if (!err) {
        try {
          (item.images || []).forEach(async (image) => {
            if (image.updated) {
              const formData = new FormData();
              formData.append('comment', image.comment);
              await imageService.update(image._id, formData)
            }
          })

          const savedItem = await defectsService.save(this.state.item);
          this.fetchDefects();
          if (this.state.item._id) {
            notification.success({
              message: `${this.props.intl.formatMessage({
                id: 'updated successfully',
                defaultMessage: 'Updated successfully'
              })}`
            })
          } else {
            notification.success({
              message: `${this.props.intl.formatMessage({
                id: 'created successfully',
                defaultMessage: 'Created successfully'
              })}`
            })
          }

          this.setState({
            drawerVisible: false,
            item: {},
            items: [
              ...this.state.items.filter(item => item._id !== savedItem._id),
              savedItem
            ]
          })
        }
        catch (error) {
          console.log(error);
          notification.error({
            message: this.props.intl.formatMessage({
              id: 'saving error',
              defaultMessage: 'Saving Error'
            }),
            description: error.error
          })
        }
      }
    })
  }

  deleteItem = async () => {
    try {
      const savedItem = await defectsService.deleteTask(this.state.item._id)
      this.fetchDefects()

      if (this.state.item._id) {
        // notification.success({
        //   message: `${this.props.intl.formatMessage({
        //     id: 'updated successfully',
        //     defaultMessage: 'Updated successfully'
        //   })}`
        // })
      } else {
        // notification.success({
        //   message: `${this.props.intl.formatMessage({
        //     id: 'created successfully',
        //     defaultMessage: 'Created successfully'
        //   })}`
        // })
      }

      this.setState({
        drawerVisible: false,
        item: {},
        items: [
          ...this.state.items.filter(item => item._id !== savedItem._id),
          savedItem
        ]
      })
    }
    catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({
          id: 'saving error',
          defaultMessage: 'Saving Error'
        }),
        description: error.error
      })
    }
  };

  handleRefClick = async (defect) => {
    const { globalCustomForms } = this.props;

    let recordingCustomForm = {}
    if (defect.recording.project) {
      const project = defect.recording.project
      if (project && project.form) {
        if (defect.recording.acceptanceId) {
          recordingCustomForm = project.custom_form && project.custom_form.acceptance
        } else {
          recordingCustomForm = project.form
        }
      }
    } else {
      try {
        recordingCustomForm = await formService.get(defect.recording.acceptanceId ? globalCustomForms.acceptance : globalCustomForms.recording)
      }
      catch (error) {
        console.log(error);
      }
    }

    this.setState({
      defectRecording: defect.recording,
      defectDrawerVisible: true,
      recordingCustomForm
    })
  }

  exportCSV = () => {
    const { params } = this.state
    const { project } = this.props;
    const formedParams = { ...params }
    if (project && project._id) {
      formedParams.project = project._id
    }
    axios({
      method: 'get',
      url: `${config.server.url}/defect-csv`,
      responseType: 'text/csv',
      headers: {
        authorization: localStorage.getItem('auth_token')
      },
      params: formedParams
    }).then(function (response) {
      const blob = new Blob([response.data], { type: 'text/csv' })
      const fileName = `defects.csv`

      if (navigator && navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, fileName)
      } else {
        const dataURI = `data:text/csv;charset=utf-8,${response.data}`

        const URL = window.URL || window.webkitURL
        const downloadURI = typeof URL.createObjectURL === 'undefined' ? dataURI : URL.createObjectURL(
          blob)

        let link = document.createElement('a')
        link.setAttribute('href', downloadURI)
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    })
  }

  finishSubmitting = () => {
    this.fetchDefects()
    this.setState({
      assignToModalVisible: false,
      selectedRowObjects: [],
      selectedRowKeys: []
    })
  }

  handleFileUpdate = async (filesArray) => {
    const { item } = this.state;
    this.setState({
      item: { ...item, files: filesArray }
    })
    // try {
    //   await defectsService.save({ ...item, files: filesArray.map(item => item._id) });
    // }
    // catch (error) {
    //   notification.error({
    //     message: this.props.intl.formatMessage({
    //       id: 'saving error',
    //       defaultMessage: 'Saving Error'
    //     }),
    //     description: error.error
    //   })
    // }
  }

  handleImageUpdate = async (imagesArray) => {
    const { item } = this.state;
    this.setState({
      item: { ...item, images: imagesArray }
    })

    // try {
    //   await defectsService.save({ ...item, images: imagesArray });
    //   // this.fetchDefects();
    // }
    // catch (error) {
    //   notification.error({
    //     message: this.props.intl.formatMessage({
    //       id: 'saving error',
    //       defaultMessage: 'Saving Error'
    //     }),
    //     description: error.error
    //   })
    // }
  }

  render () {
    const {
      sapSending,
      sapModalShown,
      sapUploadObject,
      sapMessage,
      drawerVisible,
      formLoading,
      contractSections,
      item,
      items,
      loading,
      selectedRowKeys,
      readOnlyNewProject,
      defectRecording,
      defectDrawerVisible,
      recordingCustomForm,
      selectedRowObjects,
      assignToModalVisible,
      multipleUpdateField,
    } = this.state

    const {
      projects,
      intl,
      objectName
    } = this.props
    const columns = [
      {
        title: this.props.intl.formatMessage({ id: 'number', defaultMessage: 'Number' }),
        key: 'docNumber',
        dataIndex: 'docNumber',
        sorter: true,
        fixed: 'left',
        ...this.getColumnSearchProps('docNumber')
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 200,
        sorter: true,
        ...this.getColumnSearchProps('name')
      },
      {
        title: objectName,
        dataIndex: 'objectId',
        key: 'objectId',
        width: 220,
        render: (text, record) => {
          let data = []

          if (record.objectId && record.objectId.name) {
            data.push(record.objectId.name)
          }

          return data
        },
        ...this.getColumnSearchProps('aksNumber')
      },
      {
        title: this.props.intl.formatMessage({ id: 'protocolReference' }),
        dataIndex: 'protocolReference',
        key: 'protocolReference',
        width: 180,
        ...this.getColumnSearchProps('protocolReference')
      },
      {
        title: this.props.intl.formatMessage({ id: 'protocolResponsible' }),
        dataIndex: 'protocolResponsible',
        key: 'protocolResponsible',
        filters: (this.state.filterOptions.protocolResponsible || []).map(item => ({
          text: item.company,
          value: item._id
        })),
        render: (text, record) => {
          if (record.protocolResponsible && record.protocolResponsible.company) {
            return record.protocolResponsible.company
          }
          return ''
        },
      },
      {
        title: this.props.intl.formatMessage({ id: 'estimated_cost' }),
        dataIndex: 'estimated_cost',
        key: 'estimated_cost',
        sorter: (a, b) => a.estimated_cost - b.estimated_cost,
        align: 'right',
        render: (text, record) => {
          return (
            <NumberFormat
              displayType={'text'}
              value={record.estimated_cost}
              thousandSeparator={'\''}
              prefix={'CHF '} />
          )
        }
      },
      {
        title: this.props.intl.formatMessage({
          id: 'contract section',
          defaultMessage: 'Contract section'
        }),
        dataIndex: 'contractSection',
        key: 'contractSection',
        sorter: true,
        width: 220,
        render: (text, record) => {
          if (record.contractSection && record.contractSection.name) {
            return record.contractSection.name
          }
          return ''
        },
        ...this.getColumnSearchProps('contractSection')

      },
      {
        title: this.props.intl.formatMessage({ id: 'created at', defaultMessage: 'CreatedAt' }),
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 200,
        render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
        sorter: true
      },
      {
        title: this.props.intl.formatMessage({ id: 'created by', defaultMessage: 'CreatedBy' }),
        dataIndex: 'createdBy',
        key: 'createdBy',
        width: 200,
        sorter: true,
        render: createdBy => createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : '',
        filters: (this.state.filterOptions.createdBy || []).map(user => ({
          text: `${user.firstName} ${user.lastName}`,
          value: user._id
        }))
      },
      {
        title: this.props.intl.formatMessage({
          id: 'reference number',
          defaultMessage: 'Reference Number'
        }),
        key: 'submittedRefNr',
        dataIndex: 'submittedRefNr',
        ...this.getColumnSearchProps('submittedRefNr'),
        render: (text, record) => {
          if (record.recording && record.recording.docNumber) {
            return (
              <Tag
                onClick={() => this.handleRefClick(record)}
                color="blue">
                <Icon type="check" />&nbsp;
                {record.recording.docNumber}
              </Tag>
            )
          }

          return ''
        }
      },
      {
        title: 'Zugewiesen an',
        dataIndex: 'assignedTo',
        key: 'assignedTo',
        width: 150,
        render: (text, record) => {
          if (record.assignedTo) {
            return record.assignedTo.company
          }

          return ''
        },
        filters: (this.state.filterOptions.assignedTo || []).map(user => ({
          text: user.company,
          value: user._id
        }))
      },
      {
        title: 'SAP',
        key: 'sap',
        sorter: true,
        width: 150,
        ...this.getColumnSearchProps('submittedRefNr'),
        render: (text, record) => {
          let data = []

          if (record.submissions && record.submissions.some(e => e.name === 'sap')) {
            data.push(
              <span>
                <Icon type="check" />&nbsp;{record.submissions.map(a => <span>{a.reference}</span>)}
              </span>)
          }

          return data
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        filters: [
          {
            text: intl.formatMessage({ id: 'open', defaultMessage: 'open' }),
            value: 'open'
          },
          {
            text: intl.formatMessage({ id: 'in progress', defaultMessage: 'in progress' }),
            value: 'in progress'
          },
          {
            text: intl.formatMessage({ id: 'completed', defaultMessage: 'completed' }),
            value: 'completed'
          },
        ],
        filteredValue: ['open', 'in progress'],
        render: (text, record) => {
          switch (record.status) {
            case 'open':
              return intl.formatMessage({ id: 'open', defaultMessage: 'open' })
            case 'in progress':
              return intl.formatMessage({ id: 'in progress', defaultMessage: 'in progress' })
            case 'completed':
              return intl.formatMessage({ id: 'completed', defaultMessage: 'completed' })
            default:
              return ''
          }
        }
      }
    ]

    if (!this.props.project) {
      columns.push({
        title: this.props.intl.formatMessage({ id: 'project', defaultMessage: 'Project' }),
        key: 'project',
        width: 220,
        dataIndex: 'project.projectName'
      })
    }

    columns.push({
      key: 'actions',
      fixed: 'right',
      render: (text, record) => (
        <div style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'flex-end'
        }}>
          <Dropdown overlay={
            <Menu>
              <Menu.Item
                key="0"
                onClick={() => this.showDrawer(record)}
              >
                <Icon type="edit" />&nbsp;
                {this.props.intl.formatMessage({
                  id: 'edit',
                  defaultMessage: 'edit'
                })}
              </Menu.Item>

              <Menu.Divider />

              {record.submissions && !record.submissions.some(e => e.name === 'sap') &&
                <Menu.Item key="3"
                  onClick={() => this.requestUploadSAP(record)}
                >
                  <Icon type="upload" />&nbsp;
                {this.props.intl.formatMessage({
                    id: 'send to sap',
                    defaultMessage: 'Send to SAP'
                  })}

                </Menu.Item>
              }

              {record.submissions && record.submissions.some(e => e.name === 'sap') &&
                < Menu.Item key="3"
                  disabled={true}>
                  <Icon type="check" />&nbsp;
                {this.props.intl.formatMessage({
                    id: 'submitted to sap',
                    defaultMessage: 'Submitted to SAP'
                  })}
                </Menu.Item>
              }

            </Menu>
          } trigger={['click']}>
            <a className="ant-dropdown-link" href="#">
              <Icon type="more" style={{ fontSize: '2rem', color: '#444' }} />
            </a>
          </Dropdown>
        </div>
      )
    })

    const drawerButtons = [
      <Button key={65493152}
        onClick={defectDrawerVisible
          ? () => this.hideDrawer('defectDrawerVisible')
          : () => {
            this.hideDrawer('drawerVisible')
            this.fetchDefects()
          }
        }
        style={{ marginRight: 8 }}>
        <FormattedMessage
          id="cancel"
          defaultMessage="Cancel"
        />
      </Button>,
      <Button
        key={237323536}
        onClick={this.saveItem} type="primary"
        disabled={defectDrawerVisible}
      >
        {this.props.intl.formatMessage({ id: 'save', defaultMessage: 'Save' })}
      </Button>
    ]


    if (['admin', 'superadmin'].includes(this.props.auth.auth_user.scope[0])) {
      drawerButtons.unshift(
        <PopconfirmDelete onConfirmAction={this.deleteItem} />
      )
    }

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    }

    const menu = (
      <Menu
      // onClick={() => this.setState({ assignToModalVisible: true })}
      >
        <Menu.Item
          onClick={() => this.setState({
            assignToModalVisible: true,
          })}
        >
          <Icon type="user" />
          <FormattedMessage
            id="bulk change button"
            defaultMessage="Bulk change"
          />
        </Menu.Item>
        <Menu.Item
          disabled
          onClick={() => this.setState({
            assignToModalVisible: true,
            multipleUpdateField: 'status'
          })}
        >
          <Icon type="user" />
          Developing
        </Menu.Item>
      </Menu>
    )

    const headerButtons = [
      <Button
        onClick={this.exportCSV}
        icon="download"
        disabled={loading}
      >
        <FormattedMessage
          id="csv export"
          defaultMessage="CSV Export"
        />
      </Button>,
      <Dropdown
        overlay={menu}
        disabled={selectedRowObjects.length === 0}
      >
        <Button>
          Actions <Icon type="down" />
        </Button>
      </Dropdown>,
      <Button
        key={238397323536}
        type="primary"
        onClick={() => this.showDrawer({})}
      >
        <FormattedMessage
          id="create defect"
          defaultMessage="Create defect"
        />
      </Button>
    ]

    return (
      <div>
        <FormattedMessage id="head.title.defects" defaultMessage="Defects">
          {title => <Helmet><title>{title}</title></Helmet>}
        </FormattedMessage>

        <PageTitle
          title={this.props.intl.formatMessage({ id: 'defects', defaultMessage: 'Defects' })}
          buttons={headerButtons}
        />
        <Table
          // eslint-disable-next-line no-return-assign
          ref={ref => this._table = ref}
          columns={columns}
          rowSelection={rowSelection}
          size="small"
          dataSource={items}
          loading={loading}
          onRow={(record) => ({
            onDoubleClick: () => {
              this.showDrawer(record)
            }
          })}
          scroll={{ x: 2500 }}
          rowKey={record => record._id}
          onChange={this.onTableChange}
          pagination={this.state.pagination}
        />

        <DrawerComponent
          title={item.docNumber ? item.docNumber : this.props.intl.formatMessage({
            id: 'create defect',
            defaultMessage: 'Create defect'
          })}
          onClose={() => this.hideDrawer('drawerVisible')}
          visible={!!drawerVisible}
          footerButtons={drawerButtons}
        >
          <DefectsDrawerTabs
            workorder={this.props.workOrder}
            updateFiles={this.handleFileUpdate}
            updateImages={this.handleImageUpdate}
            defect={item}
            projects={projects}
            contractSections={contractSections}
            readOnlyNewProject={readOnlyNewProject}
            formLoading={formLoading}
            fetchProjects={this.fetchProjects}
            fetchContractSections={this.fetchContractSections}
            updateItem={this.updateItem}
            form={this.props.form}
            handleRefClick={this.handleRefClick}
            defectRecording={defectRecording}
            defectDrawerVisible={defectDrawerVisible}
            recordingCustomForm={recordingCustomForm}
            drawerButtons={drawerButtons}
            hideDrawer={this.hideDrawer}
          />
        </DrawerComponent>
        {
          !drawerVisible &&
          <DrawerComponent
            title={this.props.intl.formatMessage({
              id: 'recording details',
              defaultMessage: 'Recording details'
            }) + ` (${defectRecording.name})`}
            onClose={() => this.hideDrawer('defectDrawerVisible')}
            visible={defectDrawerVisible}
            footerButtons={drawerButtons}
            coverStyle={{ zIndex: '1001' }}
          >
            <RecordingForm
              recording={defectRecording}
              extraFieldsForm={recordingCustomForm}
              form={this.props.form}
              readOnly={true}
              acceptance={defectRecording.acceptanceId}
              defectsPage
            />
          </DrawerComponent>
        }


        <Modal
          title=""
          visible={sapModalShown}
          width={640}
          onCancel={() => this.setState({ sapModalShown: false, sapSending: false })}
          onOk={this.uploadSAP}
          destroyOnClose
          confirmLoading={typeof sapSending === 'string' ? false : sapSending}
          footer={[
            sapSending !== 'success' &&
            <Button
              key="back"
              onClick={() => this.setState({ sapModalShown: false, sapSending: false })}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={typeof sapSending === 'string' ? false : sapSending}
              onClick={sapSending !== 'success'
                ? this.uploadSAP
                : () => this.setState({
                  sapModalShown: false,
                  sapSending: false
                })}
            >
              {sapSending === false && 'Submit'}
              {sapSending === true && 'Processing'}
              {sapSending === 'error' && 'Restart'}
              {sapSending === 'success' && 'Close'}
            </Button>
          ]}
        >
          <div
            style={{
              minHeight: 350,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {
              sapSending
                ? <div>
                  <h4 style={{ textAlign: 'center', lineHeight: 1.3 }}>
                    {
                      sapSending === true &&
                      <>
                        <Spin style={{ marginRight: 8 }}
                          size="large" /> {this.props.intl.formatMessage({
                            id: 'uploading to SAP',
                            defaultMessage: 'Uploading to SAP'
                          })} <br />
                        <b>{sapUploadObject.name}</b>
                      </>
                    }
                    {
                      sapSending === 'success' &&
                      <>
                        <Icon type="check-circle"
                          style={{ fontSize: 30, color: '#52c41a', marginBottom: 20 }} /> <br />
                        <b>{sapUploadObject.name}</b> <br />
                        {this.props.intl.formatMessage({
                          id: 'Uploaded successfully to SAP',
                          defaultMessage: 'Uploaded successfully to SAP'
                        })} <br />
                        {sapMessage}
                      </>
                    }
                    {
                      sapSending === 'error' &&
                      <>
                        <Icon type="close-circle"
                          style={{ fontSize: 30, color: 'red', marginBottom: 20 }} /> <br />
                        Error uploading <b>{sapUploadObject.name}</b> <br />
                        {sapMessage} <br />
                        <span style={{ fontStyle: 'italic' }}>Please try again later or contact DDC Schweiz AG.</span>
                      </>
                    }
                  </h4>
                </div>
                : <div>
                  <h4 style={{ textAlign: 'center', lineHeight: 1.3 }}>{this.props.intl.formatMessage(
                    {
                      id: 'You are about to start sending',
                      defaultMessage: 'You are about to start sending'
                    })} <br /> <b></b></h4>
                  <p style={{
                    fontSize: 16,
                    fontStyle: 'italic',
                    marginTop: 25,
                    marginBottom: 0,
                    textAlign: 'center'
                  }}>
                    {this.props.intl.formatMessage({
                      id: 'interface-process-description',
                      defaultMessage: 'The process is not revertible, you will not be able to edit the recording after the process is finished'
                    })}

                  </p>
                </div>
            }
          </div>
        </Modal>

        <DefectModalTable
          assignToModalVisible={assignToModalVisible}
          selectedRowObjects={selectedRowObjects}
          finishSubmitting={this.finishSubmitting}
          multipleUpdateField={multipleUpdateField}
        />
      </div>
    )
  }
}

DefectsPage.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  return {
    auth: state.authReducer,
    projects: state.projectReducer.projects,
    project: state.projectReducer.project,
    objectName: state.settingsReducer.data.buildx.objectName,
    globalCustomForms: state.settingsReducer.data.buildx.custom_forms
  }
}

export default injectIntl(Form.create({ name: 'defects_page' })(
  connect(mapStateToProps)(DefectsPage)))
