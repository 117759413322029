import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Form, PageHeader, Icon, message, Upload } from 'antd'

const { Dragger } = Upload;

const props = {
    name: 'file',
    multiple: true,
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    onChange (info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
};

class FileUpload extends React.Component {
    render () {
        return <React.Fragment>
            <Form className="workorder-file-upload-mode">

                <PageHeader onBack={this.props.action} title={this.props.intl.formatMessage({ id: 'Upload files', defaultMessage: 'Upload files' })} />

                <Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                        <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                        Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                        band files
    </p>
                </Dragger>,

            </Form>
        </React.Fragment>;
    }
}

export default injectIntl(connect()(FileUpload))

