import types from 'actions/constants'

export const initialState = {
  auth_user: {},
  auth_token: localStorage.getItem('auth_token') || ''
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.AUTH_TOKEN:
      let token = ''
      if (action.token) {
        token = action.token
        localStorage.setItem('auth_token', token)
      }

      return {
        ...state,
        auth_token: token
      }
    case types.AUTH_USER:
      return {
        ...state,
        auth_user: action.user
      }
    case types.AUTH_LOGOUT:
      localStorage.clear()

      return {
        auth_user: {},
        auth_token: ''
      }
    default:
      return state
  }
}

export default reducer
