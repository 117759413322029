import React from 'react'
import PropTypes from 'prop-types'

import { Form, Select, AutoComplete, Spin } from 'antd'

const { Option } = Select

class Identification extends React.Component {
  render () {
    const { getFieldDecorator } = this.props.form

    const { objectIdSearchResult } = this.props

    const searchResults = objectIdSearchResult || []

    const children = searchResults.map((object, i) => (
      <Option key={i} value={object.name || ''}>
        {object.name}
      </Option>
    ))
    return (
        <Form.Item
          label={(<span>{this.props.objectName}<span style={{ color: '#005591' }}>*</span></span>)}
          required={false}
          {...this.props.formItemLayout}
        >
          {getFieldDecorator('objectName', {
            initialValue: this.props.recording.object ? this.props.recording.object ? this.props.recording.object.name : '' : this.props.recording.objectId ? this.props.recording.objectId.name : '',
            rules: [ {
              required: true,
              message: `${this.props.objectName} is required`
            } ]
          })(
            <AutoComplete
              allowClear={true}
              className="certain-category-search"
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ width: 300 }}
              style={{ width: '100%' }}
              // placeholder="Select Object"
              // initialValue="Select Object"
              // optionFilterProp="children"
              notFoundContent={this.props.formLoading ? <div className="recordings-spinner-container"><Spin size="small" /></div> : null}
              onFocus={() => this.props.handleSearchObjectId('')}
              onSearch={this.props.handleSearchObjectId}
              onSelect={this.props.assignObjectHandler}
              disabled={this.props.readOnly}
            >
              {children}
            </AutoComplete>
          )}
        </Form.Item>
    )
  }
}

Identification.propTypes = {
  formItemLayout: PropTypes.object.isRequired,
  recording: PropTypes.object.isRequired,
  // changeData: PropTypes.func.isRequired,
  // objectName: PropTypes.string.isRequired,
  // readOnly: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired
}

export default Identification
