import React from "react";
import PDFViewer from "components/PDFViewer/PDFViewer";
import PDFJSBackend from "components/PDFViewer/pdfjs";
import BodyClassName from "react-body-classname";
import config from 'config'

export class DOCLinkPage extends React.Component {
  render () {
    return (
      <BodyClassName className="module--doclink">
        <PDFViewer
          backend={PDFJSBackend}
          src={`${config.server.url}/doc/start.pdf`}
        />
      </BodyClassName>
    );
  }
}

export default DOCLinkPage;
