import types from 'actions/constants'

export const initialState = {
  project: undefined,
  projects: [],
  lastUpdated: ''
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PROJECT:
      return {
        ...state,
        project: action.project
      }
    case types.PROJECTS:
      return {
        ...state,
        projects: action.projects
      }
    case types.PROJECT_LAST_UPDATED:
      return {
        ...state,
        lastUpdated: action.lastUpdated
      }
    default:
      return state
  }
}

export default reducer
