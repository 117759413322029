import React from 'react'
import PropTypes from 'prop-types'
import { Drawer } from 'antd'

export class DrawerComponent extends React.Component {
  render() {
    const { title, visible, children, footerButtons, onClose, coverStyle,closable } = this.props
    return (
      <Drawer
        title={title}
        placement="right"
        width={700}
        destroyOnClose={true}
        closable={true}
        maskClosable={!!closable}
        keyboard={false}
        onClose={onClose}
        visible={visible}
        style={coverStyle}
      >
        {
          children
        }
        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 999
          }}
        >
          {
            footerButtons
          }
        </div>
      </Drawer>
    )
  }
}

DrawerComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired
}

export default DrawerComponent
