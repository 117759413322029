import React from 'react'
import { Switch, BrowserRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import DefaultLayout from 'layout/Default'
import MainLayout from 'layout/Main'

import PublicRoute from './PublicRoute'
import ProtectedRoute from './ProtectedRoute'

import DOCLinkPage from 'pages/DOCLink'

import ForgotPasswordPage from 'pages/BaseX/ForgotPassword'
import ResetPasswordPage from 'pages/BaseX/ResetPassword'
import NotFoundPage from 'pages/BaseX/NotFound'
import NoPermissions from 'pages/BaseX/NoPermissions'
import HomePage from 'pages/BaseX/Home'
import LoginPage from 'pages/BaseX/Login'

import WorkordersPage from 'pages/MainX/Workorders'
import TasksPage from 'pages/MainX/Tasks'
import DefectsPage from 'pages/MainX/Defects'
import ScheduledWorkordersPage from 'pages/MainX/ScheduledWorkorders'
import RequestsPage from 'pages/MainX/Requests'
import SpecialWorkPage from 'pages/MainX/SpecialWork'
import ProtocolPage from 'pages/MainX/Protocol'

import RecordingsPage from 'pages/BuildX/Recordings'
import AcceptancesPage from 'pages/BuildX/Acceptances'

import ProductsPage from 'pages/DataX/Products'
import ManufacturersPage from 'pages/DataX/Manufacturers'
import CategoriesPage from 'pages/DataX/Categories'

import ContactsPage from 'pages/SettingsPages/Contact'
import RightsPage from 'pages/SettingsPages/Rights'
import ProjectsPage from 'pages/SettingsPages/Projects'
import SettingsPage from 'pages/SettingsPages/Settings'
import UsersPage from 'pages/SettingsPages/Users'
import ObjectsPage from 'pages/SettingsPages/Objects'
import ModulesPage from 'pages/SettingsPages/Modules'
import LocationsPage from 'pages/SettingsPages/Locations'
import ContractSectionPage from 'pages/SettingsPages/ContractSection'
import FormsPage from 'pages/SettingsPages/Forms'
import GroupsPage from 'pages/SettingsPages/Groups'

const Routes = props => (
  <BrowserRouter>
    <Switch>
      <PublicRoute exact path="/login" component={LoginPage} />

      <PublicRoute
        exact
        path="/forgot-password"
        component={ForgotPasswordPage}
      />

      <PublicRoute exact path="/password" component={ResetPasswordPage} />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path="/acceptances"
        component={AcceptancesPage}
        user={props.auth.auth_user}
        project={props.project}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path="/contract-sections"
        component={ContractSectionPage}
        user={props.auth.auth_user}
        project={props.project}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path="/products"
        component={ProductsPage}
        user={props.auth.auth_user}
        project={props.project}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path="/categories"
        component={CategoriesPage}
        user={props.auth.auth_user}
        project={props.project}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path="/manufactures"
        component={ManufacturersPage}
        user={props.auth.auth_user}
        project={props.project}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path="/doclink"
        component={DOCLinkPage}
        user={props.auth.auth_user}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path="/forms"
        component={FormsPage}
        user={props.auth.auth_user}
        project={props.project}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path="/groups"
        component={GroupsPage}
        roles={['admin', 'superadmin']}
        user={props.auth.auth_user}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path="/"
        component={HomePage}
        user={props.auth.auth_user}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path="/locations"
        component={LocationsPage}
        roles={['admin', 'superadmin']}
        user={props.auth.auth_user}
        project={props.project}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path="/no-permissions"
        component={NoPermissions}
        user={props.auth.auth_user}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path="/modules"
        component={ModulesPage}
        roles={['admin', 'superadmin']}
        user={props.auth.auth_user}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path="/objects"
        component={ObjectsPage}
        roles={['admin', 'superadmin']}
        user={props.auth.auth_user}
        project={props.project}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path="/tasks"
        component={TasksPage}
        user={props.auth.auth_user}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path="/defects"
        component={DefectsPage}
        user={props.auth.auth_user}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path="/workorders"
        component={WorkordersPage}
        user={props.auth.auth_user}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path="/scheduled-workorders"
        component={ScheduledWorkordersPage}
        user={props.auth.auth_user}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path="/projects"
        component={ProjectsPage}
        roles={['admin', 'superadmin']}
        user={props.auth.auth_user}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path="/recordings"
        component={RecordingsPage}
        user={props.auth.auth_user}
        project={props.project}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path="/requests"
        component={RequestsPage}
        user={props.auth.auth_user}
        project={props.project}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path="/specialwork"
        component={SpecialWorkPage}
        user={props.auth.auth_user}
        project={props.project}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path="/protocol"
        component={ProtocolPage}
        roles={['admin', 'superadmin']}
        user={props.auth.auth_user}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path="/rights"
        component={RightsPage}
        roles={['admin', 'superadmin']}
        user={props.auth.auth_user}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path="/settings"
        component={SettingsPage}
        roles={['admin', 'superadmin']}
        user={props.auth.auth_user}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path="/users"
        component={UsersPage}
        roles={['admin', 'superadmin']}
        user={props.auth.auth_user}
      />

      <ProtectedRoute
        layout={MainLayout}
        exact
        path="/contacts"
        component={ContactsPage}
        roles={['admin', 'superadmin']}
        user={props.auth.auth_user}
      />

      <PublicRoute layout={DefaultLayout} exact component={NotFoundPage} />
    </Switch>
  </BrowserRouter>
)

Routes.propTypes = {
  auth: PropTypes.object.isRequired,
  project: PropTypes.string
}

export default Routes
