import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'

import { Form, Input } from 'antd'

const General = props => {
  const attributes = props.recording.attributes || {}

  const changeAttribute = (field, value) => {
    attributes[field] = value

    props.changeData('attributes', attributes)
  }

  return (
    <React.Fragment>
      <h3>
        <FormattedMessage
          id="general"
          defaultMessage="General"
        />
      </h3>

      <Form.Item {...props.formItemLayout} label={props.intl.formatMessage({ id: 'actual status', defaultMessage: 'Actual Status' })}>
        <Input.TextArea
          disabled={props.readOnly}
          value={attributes.actualStatus}
          autosize={{ minRows: 2, maxRows: 6 }}
          onChange={event =>
            changeAttribute('actualStatus', event.target.value)
          }
        />
      </Form.Item>

      <Form.Item {...props.formItemLayout} label={props.intl.formatMessage({ id: 'target status', defaultMessage: 'Target Status' })}>
        <Input.TextArea
          disabled={props.readOnly}
          value={attributes.targetStatus}
          autosize={{ minRows: 2, maxRows: 6 }}
          onChange={event =>
            changeAttribute('targetStatus', event.target.value)
          }
        />
      </Form.Item>

      <Form.Item {...props.formItemLayout} label={props.intl.formatMessage({ id: 'comment', defaultMessage: 'Comment' })}>
        <Input.TextArea
          disabled={props.readOnly}
          value={props.recording.comment}
          autosize={{ minRows: 2, maxRows: 6 }}
          onChange={event => props.changeData('comment', event.target.value)}
        />
      </Form.Item>
    </React.Fragment>
  )
}

General.propTypes = {
  formItemLayout: PropTypes.object.isRequired,
  recording: PropTypes.object.isRequired
  // changeData: PropTypes.func.isRequired,
  // readOnly: PropTypes.bool.isRequired
}

export default injectIntl(General)
