import axios from '../helpers/axios'

class FileService {
  create (data) {
    return axios.post('/file', data)
  }

  delete (id) {
    return axios.delete(`/file/${id}`)
  }

  update (id, data) {
    return axios.put(`/file/${id}`, data)
  }
}

const fileService = new FileService()
export default fileService
