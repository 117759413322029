import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { PageHeader, Icon, Upload, Modal } from 'antd'
import axios from '../../helpers/axios'
import { saveAs } from 'file-saver'
import { Button } from 'antd';
import config from 'config'
import fileService from 'services/file'
import defectService from 'services/defects'

import './styles.scss'
import moment from 'moment';

const { Dragger } = Upload;


class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      fileList: [],
      deleteFile: {},
      deleteDrawerVisible: false
    }
    this.uploadCover = React.createRef();
  }

  handleDelete = (file) => {
    const { updateFiles, defect: { files } } = this.props;
    const { deleteFile } = this.state;
    fileService.delete(deleteFile._id)
    .then(response => {
      updateFiles(files.filter(item => item._id !== deleteFile._id))
      this.setState({
        deleteFile: {},
        deleteDrawerVisible: false
      })
    })
    .catch(error => {
      console.log(error)
    })
  }

  onChange = (info) => {
    this.setState({
      image: { ...info.file, fileName: info.file.name }
    })
  };

  customRequest = async (option) => {
    const { updateFiles, defect } = this.props;
    await new Promise(resolve => this.waitUntilImageLoaded(resolve)); //in the next section
    const { image } = this.state;
    const formData = new FormData();
    formData.append('file', image);
    defectService.addFile(defect._id, formData)
    .then(response => {
      updateFiles([response, ...defect.files])
      this.setState({
        image: undefined
      })
    })
    .catch(error => {
      console.log(error)
    })
  };

  waitUntilImageLoaded = resolve => {
    setTimeout(() => {
      this.state.image
        ? resolve() // from onChange method
        : this.waitUntilImageLoaded(resolve);
    }, 10);
  };

  handleDownload = (file) => {
    saveAs(file.url, file.filename)
  }

  render() {
    const { deleteDrawerVisible, deleteFile } = this.state
    const { defect, intl, action } = this.props;

    const draggerProps = {
      onDownload: (file) => {
        this.handleDownload(file)
      },
      listType: 'picture',
      onRemove: file => {
        this.setState({
          deleteFile: file,
          deleteDrawerVisible: true
        })
      },
      beforeUpload: (file) => this.setState(state => ({
        fileList: [...state.fileList, file],
        image: file
      })),
      onPreview: (file) => {
        this.handleDownload(file)

      },
      fileList: defect.files.map(item => {
        const fileType = (item.imageUrl || item.fileUrl).split('.').pop()
        let iconSrc = ""
        if ((item.type && item.type.indexOf('image') > -1)||fileType === 'png') {
          iconSrc = config.server.url + (item.imageUrl || item.fileUrl)
        } else if (fileType === 'csv') {
          iconSrc = 'images/file.svg'
        } else if (fileType === 'pdf') {
          iconSrc = 'images/pdf.svg'
        }

        item.uid = "rc-" + item._id
        item.status = 'done'
        item.name =
          <div style={{
            display: 'flex',
            flex: 1,
            flexWrap: 'wrap',
            height: '100%',
            lineHeight: 1.2,
            alignItems: 'center'
          }}>
            <p style={{ width: '100%', marginBottom: 0 }}><b>{item.filename}</b></p>
            <p style={{  width: '100%',marginBottom: 0 }}>{moment(item.createdAt).format('DD.MM.YYYY, HH:mm')}</p>
            <p style={{ marginBottom: 0 }}>{item.createdBy.fullName}</p>
          </div>

        item.thumbUrl = iconSrc
        item.url = config.server.url + item.fileUrl
        return item
      })
    };
    return <React.Fragment>
      <PageHeader
        onBack={action}
        title={intl.formatMessage({
          id: 'Upload files',
          defaultMessage: 'Upload files'
        })}
      />
      <div className="dragger-cover" ref={this.uploadCover}>
        <Dragger
          {...draggerProps}
          customRequest={this.customRequest}
        >
          <p className="ant-upload-drag-icon">
            <Icon type="inbox"/>
          </p>
          <p className="ant-upload-text">
            {intl.formatMessage({ id: 'clickToUpload', defaultMessage: 'clickToUpload' })}
          </p>
          <p className="ant-upload-hint">
            {intl.formatMessage({ id: 'fileSupportInfo', defaultMessage: 'fileSupportInfo' })}
          </p>
        </Dragger>
        <Modal
          visible={deleteDrawerVisible}
          title="Acceptance required"
          onOk={this.handleOk}
          getContainer={this.uploadCover.current}
          footer={[
            <Button
              key="back"
              onClick={() => this.setState({ deleteDrawerVisible: false, deleteFile: {} })}
            >
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={this.handleDelete}>
              Proceed
            </Button>,
          ]}
        >
          <p>Image {deleteFile.name} will be deleted</p>
        </Modal>
      </div>
    </React.Fragment>
  }
}

export default injectIntl(connect()(FileUpload))

