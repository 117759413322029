import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import { Table, notification, Icon, Button, Form, Input, Menu, Dropdown, Tag } from 'antd'
import taskService from 'services/tasks'

import PageTitle from 'components/Global/PageTitle'

export class TaskPage extends React.Component {
  state = {
    items: [],
    item: {},
    new: false,
    selectedRowKeys: [],
    visibleDrawer: false,
    loading: false,
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50', '100']
    }
  }

  componentDidMount () {
    this.fetchTasks()
  }

  componentDidUpdate (prevProps) {
    if (this.props.project !== prevProps.project) {
      this.fetchTasks()
    }
  }

  fetchTasks = async (params) => {
    this.setState({
      loading: true
    })
    try {
      const task = await taskService.listV2({
        ...params
      })

      const pagination = {
        ...this.state.pagination
      }

      pagination.total = task.totalDocs

      this.setState({
        items: task.docs,
        loading: false,
        pagination
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({ id: 'task', defaultMessage: 'Task' })} - download error`
      })

      this.setState({
        visibleDrawer: false,
        loading: false
      })
    }
  }

  onTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    pager.offset = pagination.current

    this.setState({
      pagination: {
        ...pager,
        current: pagination.current
      }
    })

    this.fetchTasks({
      limit: pagination.pageSize,
      offset: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    })
  }

  handleSearch = (selectedKeys, field, confirm) => {
    confirm()
    this.setState({
      search: {
        ...this.state.search,
        [field]: selectedKeys[0]
      }
    })
  }

  handleReset = (field, clearFilters) => {
    clearFilters()

    this.setState({
      search: {
        ...this.state.search,
        [field]: ''
      }
    })
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => { this[`searchInput${dataIndex}`] = node }}
          placeholder={this.props.intl.formatMessage({ id: 'search', defaultMessage: 'Suchen' })}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Suchen
        </Button>
        <Button
          onClick={() => this.handleReset(dataIndex, clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => String(record[dataIndex]).toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this[`searchInput${dataIndex}`].select())
      }
    }
  })

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  }

  render () {
    const {
      loading,
      selectedRowKeys
    } = this.state

    const columns = [
      {
        title: this.props.intl.formatMessage({ id: 'number', defaultMessage: 'Number' }),
        key: 'docNumber',
        dataIndex: 'docNumber',
        sorter: true,
        ...this.getColumnSearchProps('docNumber')
      },
      // {
      //   title: 'Name',
      //   dataIndex: 'name',
      //   key: 'name',
      //   sorter: true,
      //   ...this.getColumnSearchProps('name')
      // },
      {
        title: 'Objectnumber',
        dataIndex: 'objectId.name',
        key: 'objectId.name',
        sorter: true,
        ...this.getColumnSearchProps('name')
      },
      {
        title: this.props.intl.formatMessage({ id: 'created at', defaultMessage: 'CreatedAt' }),
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
        sorter: true
      },
      {
        title: this.props.intl.formatMessage({ id: 'created by', defaultMessage: 'CreatedBy' }),
        dataIndex: 'createdBy',
        key: 'createdBy',
        sorter: true,
        render: (text, record) => {
          if (record.createdBy) {
            return record.createdBy.fullName
          }

          return ''
        }
      },
      {
        title: this.props.intl.formatMessage({ id: 'reference number', defaultMessage: 'Reference Number' }),
        key: 'submittedRefNr',
        dataIndex: 'submittedRefNr',
        ...this.getColumnSearchProps('submittedRefNr'),
        render: (text, record) => {
          let data = []

          if (record.submittedWorkorder && record.submittedWorkorderId) {
            data.push(<span><Tag color="blue"><Icon type="check" /> Workorder ({record.submittedWorkorderId.docNumber})</Tag>&nbsp;&nbsp;</span>)
          }

          if (record.submittedTask && record.submittedTaskId) {
            data.push(<span><Tag color="blue"><Icon type="check" /> Task ({record.submittedTaskId.docNumber})</Tag>&nbsp;&nbsp;</span>)
          }

          if (record.submitted) {
            data.push(<span><Tag color="blue"><Icon type="check" /> SAP ({record.submittedRefNr})</Tag></span>)
          }

          return data
        }
      },
      {
        title: 'Zugewiesen an',
        dataIndex: 'assignee',
        key: 'assignee',
        sorter: true,
        ...this.getColumnSearchProps('name')
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        ...this.getColumnSearchProps('status')
      },
    ]

    if (!this.props.project) {
      columns.push({
        title: this.props.intl.formatMessage({ id: 'project', defaultMessage: 'Project' }),
        key: 'project',
        dataIndex: 'project.projectName'
      })
    }

    columns.push({
      key: 'actions',
      render: (text, record) => (
        <div style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'flex-end'
        }}>
          <Dropdown overlay={
            <Menu>
              <Menu.Item
                key="0"
              // onClick={() => this.showDrawer(record)}
              >
                <Icon type='edit' />&nbsp;
                {this.props.intl.formatMessage({
                  id: 'edit',
                  defaultMessage: 'edit'
                })}
              </Menu.Item>
            </Menu >
          } trigger={['click']} >
            <a className="ant-dropdown-link" href="#">
              <Icon type="more" style={{ fontSize: '2rem', color: '#444' }} />
            </a>
          </Dropdown >
        </div >
      )
    })

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    }
    const hasSelected = selectedRowKeys.length > 0

    const headerButtons = [
      <Button
        key={59934324}
        type="primary"
        disabled={!hasSelected}
        loading={loading}
        onClick={this.showDrawerAdd}
      >
        <FormattedMessage
          id="assign to workorder"
          defaultMessage="Assign to workorder"
        />
      </Button>
    ]

    return (
      <div>
        <FormattedMessage id="head.title.tasks" defaultMessage="Tasks">
          {title => <Helmet><title>{title}</title></Helmet>}
        </FormattedMessage>

        <PageTitle
          title={this.props.intl.formatMessage({ id: 'tasks', defaultMessage: 'Tasks' })}
          buttons={headerButtons}
        />

        <Table
          // eslint-disable-next-line no-return-assign
          ref={ref => this._table = ref}
          columns={columns}
          rowSelection={rowSelection}
          size='small'
          dataSource={this.state.items}
          loading={this.state.loading}
          onRow={(record) => ({
            onDoubleClick: () => { this.showDrawerEdit(record) }
          })}
          rowKey={record => record._id}
          onChange={this.onTableChange}
          pagination={this.state.pagination}
        />
      </div>
    )
  }
}

TaskPage.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.authReducer,
  projects: state.projectReducer.projects,
  project: state.projectReducer.project
})

export default injectIntl(Form.create({ name: 'task_page' })(connect(mapStateToProps)(TaskPage)))
