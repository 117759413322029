import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
// import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
// import draftToHtml from 'draftjs-to-html'
// import htmlToDraft from 'html-to-draftjs'
// import { Editor } from 'react-draft-wysiwyg'

import { Form, Input, Switch } from 'antd'

import './styles.scss'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
  className: 'formItem'
}

// const toolBarOptions = {
//   options: [
//     'inline',
//     'blockType',
//     'list',
//     'link',
//     'history'
//   ],
//   inline: {
//     options: [ 'bold', 'italic', 'underline', 'strikethrough' ]
//   },
//   list: {
//     options: [ 'unordered', 'ordered' ]
//   }
// }

class SpecialWorkForm extends React.Component {
  render () {
    const { form, item, updateStateSpecialWork } = this.props

    return (
      <React.Fragment>
        <Form className="requestForm">
          <Form.Item {...formItemLayout} label="Name">
            {form.getFieldDecorator('name', {
              initialValue: item.name,
              rules: [ {
                required: true,
                message: this.props.intl.formatMessage({ id: 'name is required', defaultMessage: 'Name is required' })
              } ]
            })(
              <Input
                onChange={event => updateStateSpecialWork('name', event.target.value)}
              />
            )}
          </Form.Item>
          <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: 'enabled', defaultMessage: 'Enabled' })}>
            <Switch
              checked={item.enabled}
              onChange={(value) => this.props.updateStateSpecialWork('enabled', value)}
            />
          </Form.Item>
          <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: 'title', defaultMessage: 'Title' })}>
            {form.getFieldDecorator('title', {
              initialValue: item.title,
              rules: [ {
                required: true,
                message: this.props.intl.formatMessage({ id: 'title is required', defaultMessage: 'Title is required' })
              } ]
            })(
              <Input
                onChange={event => updateStateSpecialWork('title', event.target.value)}
              />
            )}
            {/* <Input.TextArea
              value={item.description}
              onChange={event =>
                updateStateSpecialWork('description', event.target.value)
              }
            /> */}
          </Form.Item>
          <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: 'description', defaultMessage: 'Description' })}>
            {form.getFieldDecorator('description', {
              initialValue: item.description,
              rules: [ {
                required: true,
                message: this.props.intl.formatMessage({ id: 'description is required', defaultMessage: 'Description is required' })
              } ]
            })(
              <Input.TextArea
                onChange={event => updateStateSpecialWork('description', event.target.value)}
              />
            )}
            {/* <Input.TextArea
              value={item.description}
              onChange={event =>
                updateStateSpecialWork('description', event.target.value)
              }
            /> */}
          </Form.Item>
          {/* <Form.Item {...formItemLayout} label={props.intl.formatMessage({ id: 'description', defaultMessage: 'Description' })}>
            <Editor
              toolbar={toolBarOptions}
              editorState={this.props.editorState ? this.props.editorState : EditorState.createEmpty()}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
              editorStyle={{ border: '1px solid #d9d9d9' }}
              // wrapperStyle={{ border: '1px solid #d9d9d9' }}
              // toolbarStyle={{ border: '1px solid #d9d9d9' }}
              onEditorStateChange={this.props.onEditorStateChange}
              // onContentStateChange={this.onContentStateChange}
            />
          </Form.Item> */}
        </Form>
      </React.Fragment>
    )
  }
}

SpecialWorkForm.propTypes = {
  updateStateSpecialWork: PropTypes.func.isRequired
}

export default injectIntl(connect()(SpecialWorkForm))
