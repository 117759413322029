import { combineReducers, createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import authReducer from './reducers/auth'
import settingsReducer from './reducers/settings'
import projectReducer from './reducers/project'
import objectsReducer from './reducers/objects'
import tempData from './reducers/temp'

const reducers = combineReducers({
  authReducer,
  settingsReducer,
  projectReducer,
  objectsReducer,
  tempData
})

const persistConfig = {
  key: 'root',
  blacklist: [ 'objectsReducer' ],
  storage
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = createStore(persistedReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
persistStore(store)

export default store
