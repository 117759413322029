import React from 'react'
import PropTypes from 'prop-types'

import FormBuilder2 from 'react-form-builder2'
import './assets/css/font-awesome.min.css'
import './assets/css/bootstrap.min.css'
import 'react-form-builder2/dist/app.css'
import './styles.scss'

export class FormBuilder extends React.Component {
  render () {
    return (
      <React.Fragment>
        <FormBuilder2.ReactFormBuilder
          onPost={this.props.onChange}
          data={this.props.data}
          toolbarItems={[
            {
              key: 'TextInput',
              canHaveAnswer: true,
              name: 'Text Input',
              label: 'Placeholder Label',
              icon: 'fa fa-font',
              field_name: 'text_input_',
            },
            {
              key: 'TextArea',
              canHaveAnswer: true,
              name: 'Multi-line Input',
              label: 'Placeholder Label',
              icon: 'fa fa-text-height',
              field_name: 'text_area_',
            },
            {
              key: 'Dropdown',
              canHaveAnswer: true,
              name: 'Dropdown',
              label: 'Placeholder Label',
              icon: 'fa fa-caret-square-o-down',
              field_name: 'dropdown_',
              options: []
            },
            {
              key: 'Checkboxes',
              canHaveAnswer: true,
              name: 'Checkboxes',
              label: 'Placeholder Label',
              icon: 'fa fa-check-square-o',
              field_name: 'checkboxes_',
              options: []
            }
          ]}
        />
      </React.Fragment>
    )
  }
}

FormBuilder.propTypes = {
  data: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,

}

export default FormBuilder
