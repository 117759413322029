export default {
  'acceptances': 'Acceptances',
  'activated': 'Activated',
  'active': 'Active',
  'archive': 'Archive',
  'actual status': 'Actual Status',
  'add product': 'Add product',
  'add workorder': 'Add workorder',
  'add category': 'Add category',
  'after': 'after',
  'approve': 'Approve',
  'approved': 'Approved',
  'archived': 'Archived',
  'before': 'before',
  'category': 'Category',
  'categories': 'Categories',
  'cancel': 'Cancel',
  'click to upload': 'Click to upload',
  'comment': 'Comment',
  'company address': 'Company address',
  'company name': 'Company name',
  'company location': 'Company location',
  'confirm deleting': 'Confirm deleting',
  'confirm overwriting': 'Confirm overwriting',
  'contract section': 'Contract section',
  'contract sections': 'Contract sections',
  'created at': 'Created at',
  'created by': 'Created by',
  'create workorder': 'Create work order',
  'create scheduled workorder': 'Create scheduled workorder',
  'create a new scheduled Workorder': 'Create a new scheduled Workorder',
  'create manufacturer': 'Create manufacturer',
  'create category': 'Create category',
  'create product': 'Create product',
  'create special work': 'Create special work',
  'edit special work': 'Edit special work',
  'date': 'Date',
  'delete': 'Delete',
  'deleted': 'Deleted',
  'deleting error': 'Deleting error',
  'description': 'Description',
  'description is required': 'Description is required',
  'depth': 'Depth',
  'disabled': 'Disabled',
  'do you want to archive this project?': 'Do you want to archive this project?',
  'do you want to delete this acceptance permanently?': 'Do you want to delete this acceptance permanently?',
  'do you want to delete this recording permanently?': 'Do you want to delete this recording permanently?',
  'do you want to unarchive this project?': 'Do you want to unarchive this project?',
  'do you really want to remove this product?': 'Do you really want to remove this product?',
  'done': 'Done',
  'task removing': 'Task removing fail',
  'task save': 'Task saving fail',
  'edit product': 'Edit product',
  'edit manufacturer': 'Edit manufacturer',
  'edit workorder': 'Edit workorder',
  'edit request': 'Edit request',
  'edit category': 'Edit category',
  'email': 'Email',
  'email and password are required': 'E-mail and Password are required',
  'email cannot be empty': 'Email cannot be empty',
  'email is not valid': 'E-mail is not valid',
  'email with further instructions has been sent': 'Email with further instructions has been sent',
  'employee number': 'Employee number',
  'enabled': 'Enabled',
  'error': 'Error',
  'file has been imported successfully': 'File has been imported successfully',
  'first name': 'First name',
  'forgot password': 'Forgot Password',
  'form': 'Form',
  'forms': 'Forms',
  'function': 'Function',
  'general': 'General',
  'global settings': 'Global Settings',
  'group': 'Group',
  'groups': 'Groups',
  'hide archived': 'hide archived',
  'height': 'Height',
  'high': 'Hoch',
  'identification': 'Identification',
  'image must smaller than': 'Image must smaller than',
  'inactive': 'Inactive',
  'incorrect email or password': 'Incorrect email or password',
  'informations': 'Informations',
  'invalid': 'Invalid',
  'is required': 'is required',
  'in progress': 'in progress',
  'last login': 'Last login',
  'last name': 'Last name',
  'location': 'Location',
  'locations': 'Locations',
  'log in to your ddSuite instance': 'Log in to your ddSuite instance',
  'login': 'Login',
  'login error': 'Login Error',
  'logout': 'Logout',
  'low': 'Low',
  'length': 'Length',
  'medium': 'Medium',
  'manufacturer': 'Manufacturer',
  'manufacturers': 'Manufacturers',
  'modules': 'Modules',
  'Mark as undone': 'Mark as undone',
  'name is required': 'Name is required',
  'new': 'New',
  'no': 'No',
  'number': 'Number',
  'number of': 'Number of',
  'numeration': 'Numeration',
  'objects': 'Objects',
  'object name': 'Object name',
  'open': 'Open',
  'opened': 'Opened',
  'orders': 'Orders',
  'ordernumber': 'ordernumber',
  'ordernumber is required': 'Ordernumber is required',
  'overwrite': 'Overwrite',
  'participant': 'Participant',
  'password': 'Password',
  'password cannot be empty': 'Password cannot be empty',
  'passwords arent identical': `Passwords aren't identical`,
  'phone': 'Phone',
  'photo': 'Photo',
  'photo comment': 'Comment',
  'photos': 'Photos',
  'project': 'Project',
  'projects': 'Projects',
  'products': 'Products',
  'price': 'Price',
  'quantity': 'Quantity',
  'recording form': 'Recording Form',
  'recording images': 'Images',
  'recordings': 'Recordings',
  'recurring': 'Recurring',
  'reject': 'Reject',
  'rejected': 'Rejected',
  'requested': 'Requested',
  'requests': 'Requests',
  'reset password': 'Reset password',
  'remove': 'Remove',
  'retype password': 'Retype password',
  'rights': 'Rights',
  'role': 'Role',
  'save': 'Save',
  'saved': 'Saved',
  'save special work': 'Save special work',
  'saving error': 'Saving error',
  'search': 'Search',
  'select a project': 'Select a project',
  'select form': 'Select form',
  'select manager': 'Select manager',
  'send': 'Send',
  'settings': 'Settings',
  'stats': 'Stats',
  'status': 'status',
  'success': 'Success',
  'supplier number': 'Supplier number',
  'target status': 'Target Status',
  'title': 'Title',
  'unarchived': 'Unarchived',
  'unverified': 'Unverified',
  'update special work': 'Update special work',
  'update account': 'Update account',
  'uploading error': 'Uploading Error',
  'users': 'Users',
  'verified': 'Verified',
  'verify selected': 'Verify Selected',
  'workorder created': 'Workorder successfully created',
  'work orders': 'Work orders',
  'width': 'Width',
  'yes': 'Yes',
  'you can only upload PNG file': 'You can only upload PNG file',
  'your password has been changed': 'Your password has been changed',
  'zip': 'Zip',
  'completeInfoBelow': 'Please complete the information below.',
  'name': 'Name',
  'objectNumber': 'Objectnumber',
  'assignedTo': 'Assigned to',
  'actualSituation' : 'Actual Situation',
  'targetSituation' : 'Target Situation',
  'skip': 'skip',
  'estimated_cost': 'Estimation cost',
  'bulk change button': 'Bulk change',
  'change assignment': 'Change assignment',
  'change status': 'Change status',
  'protocolReference': 'Protocol Reference',
  'protocolResponsible': 'Protocol Responsible',
  'images': 'Images',
  'clickToUpload': 'Click or drag file to this area to upload',
  'fileSupportInfo': 'Support for a single. Strictly prohibit from uploading company data or other band files',
  'images upload': 'Images upload',
  'add comment': 'Add Comment',
  'edit comment': 'Edit Comment'
}
