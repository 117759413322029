import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import _ from 'lodash'

import { Form, Input, AutoComplete, Icon, Checkbox, Spin, Select } from 'antd'

import './styles.scss'

const Option = AutoComplete.Option

class ObjectForm extends React.Component {
  // changeField = (field, value) => {
  //   const fields = {
  //     ...(this.props.object.fields || {}),
  //     [field]: value
  //   }
  //
  //   this.props.updateObject('fields', fields)
  // }

  changeName = name => {
    this.props.updateObject('fields', {})
    this.props.updateObject('name', name)
  }

  changeLocation = name => {
    const location = {
      name
    }

    this.props.updateObject('location', location)
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const { object, objectName, projects, readOnlyNewProject } = this.props

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      className: 'formItem'
    }

    // const fields = object.fields || {}
    const uniqueObjects = _.uniqBy(this.props.locations, (x) => {
      return x.name
    })

    const locations = uniqueObjects.map(location => (
      <Option key={location._id} value={location.name}>
        {location.name}
      </Option>
    ))

    return (
      <Form onSubmit={() => { }} className="objectForm">
        <Form.Item
          {...formItemLayout}
          label={(<span>{objectName}<span style={{ color: '#005591' }}>*</span></span>)}
          required={false}
        >
          {getFieldDecorator('name', {
            initialValue: object.name,
            rules: [ {
              required: true,
              message: this.props.intl.formatMessage({ id: 'name is required', defaultMessage: 'Name is required' })
            } ]
          })(
            <Input
              disabled={!!object._id}
              onChange={event => this.changeName(event.target.value)}
            />
          )}
        </Form.Item>

        <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: 'project', defaultMessage: 'Project' })}>
          <Select
            showSearch
            allowClear
            style={{ width: '100%' }}
            optionFilterProp="children"
            notFoundContent={this.props.formLoading ? <div className="objects-spinner-container"><Spin size="small" /></div> : null}
            onFocus={() => this.props.fetchProjects('')}
            onSearch={value => this.props.fetchProjects(value)}
            onChange={projectID => this.props.updateObject('project', projectID)}
            defaultValue={object.project ? object.project.projectName : ''}
            disabled={readOnlyNewProject}
          >
            {projects.map(project => (
              <Select.Option key={project._id} value={project._id}>{project.projectName}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: 'location', defaultMessage: 'Location' })}>
          <div className="certain-category-search-wrapper">
            <AutoComplete
              className="certain-category-search"
              allowClear={true}
              dropdownClassName="certain-category-search-dropdown"
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ width: 300 }}
              style={{ width: '100%' }}
              dataSource={locations}
              optionLabelProp="value"
              notFoundContent={this.props.formLoading ? <div className="objects-spinner-container"><Spin size="small" /></div> : null}
              filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
              value={object.location ? object.location.name : ''}
              onFocus={() => this.props.fetchLocations('')}
              onSearch={(value) => this.props.fetchLocations(value)}
              onChange={value => this.changeLocation(value)}
            >
              <Input
                suffix={<Icon type="search" className="certain-category-icon" />}
              />
            </AutoComplete>
          </div>
        </Form.Item>

        <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: 'description', defaultMessage: 'Description' })}>
          <Input.TextArea
            autosize={{ minRows: 2, maxRows: 6 }}
            onChange={event => this.props.updateObject('description', event.target.value)}
            value={object.description}
          />
        </Form.Item>

        <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: 'comment', defaultMessage: 'Comment' })}>
          <Input.TextArea
            autosize={{ minRows: 2, maxRows: 6 }}
            onChange={event => this.props.updateObject('comment', event.target.value)}
            value={object.comment}
          />
        </Form.Item>

        {object._id && (
          <React.Fragment>
            <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: 'disabled', defaultMessage: 'Disabled' })}>
              <Checkbox
                checked={!!object.disabled}
                onChange={event => this.props.updateObject('disabled', event.target.checked)}
              />
            </Form.Item>
          </React.Fragment>
        )}
      </Form>
    )
  }
}

ObjectForm.propTypes = {
  object: PropTypes.object.isRequired,
  updateObject: PropTypes.func.isRequired,
  locations: PropTypes.array.isRequired,
  objectName: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired
}

export default injectIntl(ObjectForm)
