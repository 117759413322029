import React, { Component } from 'react'
import { Input, Button, Card, Form, Icon, notification, Dropdown, Menu } from 'antd';
import queryString from 'query-string'
import { PasswordInput } from 'antd-password-input-strength'
import { injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import actions from 'actions'

import passwordService from 'services/password'

import 'pages/BaseX/Login/style.scss'

class ResetPasswordPage extends Component {
  state = {
    password: '',
    passwordRetype: '',
    processing: false
  }

  send = async () => {
    if (this.state.processing) {
      return
    }

    if (!this.state.password) {
      return notification.error({
        message: this.props.intl.formatMessage({ id: 'password cannot be empty', defaultMessage: 'Password cannot be empty' })
      })
    }

    if (this.state.password !== this.state.passwordRetype) {
      return notification.error({
        message: this.props.intl.formatMessage({ id: 'passwords arent identical', defaultMessage: `Passwords aren't identical` })
      })
    }

    this.setState({
      processing: true
    })

    try {
      const query = queryString.parse(this.props.location.search)

      await passwordService.reset(this.state.password, query.token)

      notification.success({
        message: this.props.intl.formatMessage({ id: 'success', defaultMessage: 'Success' }),
        description: this.props.intl.formatMessage({ id: 'your password has been changed', defaultMessage: 'Your password has been changed' })
      })

      this.setState({
        password: '',
        passwordRetype: ''
      })
    } catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({ id: 'error', defaultMessage: 'Error' })
      })
    }

    this.setState({
      processing: false
    })
  }

  render () {
    return (
      <div className="centerBoxWrapper">
        <p className="lang-selector">
          <Dropdown
            overlay={<Menu>
              <Menu.Item onClick={() => this.props.dispatch(actions.setLang('en'))}>
                <span className="nav-text" style={{ fontWeight: this.props.settings.lang === 'en' ? 'bold' : 'normal' }}>EN</span>
              </Menu.Item>

              <Menu.Item onClick={() => this.props.dispatch(actions.setLang('de'))}>
                <span className="nav-text" style={{ fontWeight: this.props.settings.lang === 'en' ? 'normal' : 'bold' }}>DE</span>
              </Menu.Item>
            </Menu>}
            placement="bottomCenter"
            trigger={['click']}
          >
            <Button
              shape="circle"
              icon="global"
            />
          </Dropdown>
        </p>

        <Card className="card">
          <img src="/images/logo.png" alt="logo" />

          <h1>
            <FormattedMessage
              id="reset password"
              defaultMessage="Reset Password"
            />
          </h1>

          <Form.Item>
            <p className="card-label">{this.props.intl.formatMessage({ id: 'password', defaultMessage: 'Password' })}</p>

            <PasswordInput
              onChange={e => this.setState({ password: e.target.value })}
              value={this.state.password}
              placeholder={this.props.intl.formatMessage({ id: 'password', defaultMessage: 'Password' })}
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            />
          </Form.Item>

          <Form.Item>
            <p className="card-label">{this.props.intl.formatMessage({ id: 'retype password', defaultMessage: 'Retype password' })}</p>

            <Input.Password
              onChange={e => this.setState({ passwordRetype: e.target.value })}
              value={this.state.passwordRetype}
              placeholder={this.props.intl.formatMessage({ id: 'retype password', defaultMessage: 'Retype password' })}
              type="password"
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            />
          </Form.Item>

          <Form.Item style={{ textAlign: 'right' }}>
            <Button
              htmlType="button"
              onClick={this.send}
              type="primary"
              loading={this.state.processing}
            >
              <FormattedMessage
                id="send"
                defaultMessage="Send"
              />
            </Button>
          </Form.Item>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  settings: state.settingsReducer
})

export default injectIntl(connect(mapStateToProps)(ResetPasswordPage))
