import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import SectionTable from './SectionTable'
import { Typography, Button, notification } from 'antd'
import AddProduct from './AddProduct'
import productService from 'services/products'
import categoriesService from 'services/categories'

const { Title } = Typography

class ProductsTab extends React.Component {
  state = {
    renderAddProduct: false,
    products: [],
    categories: []
  }

  componentDidMount () {
    this.fetchProducts()
    this.fetchCategories()
  }

  fetchProducts = async () => {
    try {
      const products = await productService.getList()
      this.setState({
        products
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({ id: 'products', defaultMessage: 'Products' })} - download error`
      })
    }
  }

  fetchCategories = async () => {
    try {
      const categories = await categoriesService.getList()

      let categoriesArray = []

      categories.map((category) => {
        return categoriesArray.push(category)
      })

      this.setState({
        categories: categoriesArray
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({ id: 'categories', defaultMessage: 'Categories' })} - download error`
      })
    }
  }

  addProductSwitcher = () => {
    this.setState({
      renderAddProduct: !this.state.renderAddProduct
    })
  }

  renderSectionTables = () => {
    let sections = {}

    if (this.props.recording && this.props.recording.products) {
      this.props.recording.products.forEach((item) => {
        if (item.extra_fields) {
          item.extra_fields.forEach(extraField => {
            this.state.categories.forEach(category => {
              category.custom_form && category.custom_form.fields && category.custom_form.fields.forEach(field => {
                if (field.id === extraField.id) {
                  extraField.label = field.label
                  extraField.element = field.element
                  extraField.product_id = item._id
                  extraField.options = field.options
                }
              })
            })
          })
        }

        if (sections.hasOwnProperty(item.product.category._id)) {
          sections = {
            ...sections,
            [item.product.category._id]: [ ...sections[item.product.category._id],
              item
            ]
          }
        } else {
          sections = {
            ...sections,
            [item.product.category._id]: []
          }
          sections[item.product.category._id].push(item)
        }
      })
    }

    const tables = Object.keys(sections).map(key => {
      return (
        <div key={sections[key][0].product._id}>
          <Title style={{ marginTop: '20px' }} level={4}>{sections[key][0].product.category.name}</Title>
          <SectionTable
            updateRecording={this.props.updateRecording}
            translation={this.props.intl}
            products={sections[key]}
            category={this.state.categories.filter(item => item._id === key)[0]}
            allRecordingProducts={this.props.recording.products}
            auth={this.props.auth}
            readOnly={this.props.readOnly}
          />
        </div >
      )
    })
    return (
      <div>
        {
          tables
        }
      </div >
    )
  }

  addProductToSection = (product) => {
    const oldData = this.props.recording.products || []
    const productPayload = {
      product: {
        _id: product._id,
        category: product.category,
        name: product.name,
        ordernumber: product.ordernumber
      },
      quantity: 1
    }

    if (this.props.recording.products && this.props.recording.products.filter(value => value.product._id === productPayload.product._id).length > 0) {
      this.addProductSwitcher()
      return false
    }

    this.props.updateRecording('products', [ ...oldData, productPayload ])

    this.addProductSwitcher()
  }

  renderAddProduct = () => {
    return (
      <AddProduct
        products={this.state.products}
        addProductSwitcher={this.addProductSwitcher}
        categories={this.state.categories}
        addProductToSection={this.addProductToSection}
        readOnly={this.props.readOnly}
      />
    )
  }

  renderTables = () => {
    return (
      <div className="recording-form-tables-wrap">
        <Button
          onClick={this.addProductSwitcher}
          type="primary"
          disabled={this.props.readOnly}
        >
          {this.props.intl.formatMessage({ id: 'add product', defaultMessage: 'Add product' })}
        </Button>
        {
          this.renderSectionTables()
        }
      </div>
    )
  }

  render () {
    return (
      <React.Fragment>
        {
          this.state.renderAddProduct ? this.renderAddProduct() : this.renderTables()
        }
      </React.Fragment>
    )
  }
}

ProductsTab.propTypes = {
  recording: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
}

export default injectIntl(ProductsTab)
