import React from 'react'
import moment from 'moment'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Table, Icon, Button, notification, Form, Input } from 'antd'

import UserForm from 'components/User/Form'
import PageTitle from 'components/Global/PageTitle'
import userService from 'services/user'
import DrawerComponent from 'components/Drawer'
import PopconfirmDelete from 'components/Global/PopconfirmDelete/index';

export class UsersPage extends React.Component {
  state = {
    users: [],
    visible: false,
    loading: false,
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50', '100']
    },
    user: {},
    new: false
  };

  componentDidMount () {
    this.fetchUsers()
  }

  fetchUsers = async (params) => {
    this.setState({
      loading: true
    })
    try {
      const users = await userService.listV2({
        ...params
      })

      const pagination = {
        ...this.state.pagination
      }

      pagination.total = users.totalDocs

      this.setState({
        users: users.docs,
        loading: false,
        pagination
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({ id: 'users', defaultMessage: 'Users' })} - download error`
      })

      this.setState({
        users: [],
        visible: false,
        loading: false,
        user: {}
      })
    }
  }

  showDrawer = (user, newUser) => {
    delete user.password

    if (newUser) {
      this.setState({
        visible: true,
        new: newUser,
        user: {}
      })
    } else {
      this.setState({
        visible: true,
        new: newUser,
        user: {
          ...user,
          scope: user.scope ? user.scope[0] : null
        }
      })
    }
  }

  hideDrawer = () => {
    this.props.form.resetFields()
    this.setState({
      visible: false,
      user: {}
    })
  }

  saveUser = async () => {
    this.props.form.validateFields(async err => {
      if (!err) {
        try {
          if (this.state.user.password) {
            if (this.state.user.password !== this.state.user.passwordRetype) {
              return notification.error({
                message: this.props.intl.formatMessage({ id: 'passwords arent identical', defaultMessage: `Passwords aren't identical` })
              })
            }
          }

          if (!this.state.user._id) {
            if (!this.state.user.password) {
              return notification.error({
                message: `${this.props.intl.formatMessage({ id: 'password', defaultMessage: 'Password' })} ${this.props.intl.formatMessage({ id: 'is required', defaultMessage: 'is required' })}`
              })
            }
          }

          const savedUser = await userService.save(this.state.user)

          this.fetchUsers()
          if (this.state.user._id) {
            notification.success({
              message: `${this.props.intl.formatMessage({ id: 'updated successfully', defaultMessage: 'Updated successfully' })}`
            })
          } else {
            notification.success({
              message: `${this.props.intl.formatMessage({ id: 'created successfully', defaultMessage: 'Created successfully' })}`
            })
          }

          this.setState({
            visible: false,
            user: {},
            users: [
              ...this.state.users.filter(user => user._id !== savedUser._id),
              savedUser
            ]
          })

          this.props.form.resetFields()
        } catch (error) {
          notification.error({
            message: this.props.intl.formatMessage({ id: 'saving error', defaultMessage: 'Saving Error' }),
            description: error.error
          })
        }
      }
    })
  }

  removeUser = async () => {
    try {
      await userService.remove(this.state.user)

      this.setState({
        visible: false,
        user: {},
        users: [
          ...this.state.users.filter(item => item._id !== this.state.user._id)
        ]
      })
    } catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({ id: 'deleting error', defaultMessage: 'Deleting error' }),
        description: error.error
      })
    }
  }

  updateUser = (field, value) => {
    this.setState(
      state => ({
        user: {
          ...state.user,
          [field]: value
        }
      })
    )
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this[`searchInput${dataIndex}`] = node
          }}
          placeholder={this.props.intl.formatMessage({ id: 'search', defaultMessage: 'Suchen' })}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Suchen
        </Button>
        <Button
          onClick={() => this.handleReset(dataIndex, clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (<Icon type="search"
      style={{ color: filtered ? '#1890ff' : undefined }} />),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this[`searchInput${dataIndex}`].select())
      }
    }
  })

  handleSearch = (selectedKeys, field, confirm) => {
    confirm()
    this.setState({
      search: {
        ...this.state.search,
        [field]: selectedKeys[0]
      }
    })
  }

  handleReset = (field, clearFilters) => {
    clearFilters()

    this.setState({
      search: {
        ...this.state.search,
        [field]: ''
      }
    })
  }

  onTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    pager.offset = pagination.current

    this.setState({
      pagination: {
        ...pager,
        current: pagination.current
      }
    })

    this.fetchUsers({
      limit: pagination.pageSize,
      offset: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    })
  }

  render () {
    const roles = {
      user: 'Standard User',
      admin: 'Administrator',
      superadmin: 'Superadmin'
    }

    const columns = [
      {
        title: 'Name',
        key: 'fullName',
        dataIndex: 'fullName',
        defaultSortOrder: 'ascend',
        sorter: true
      },
      {
        title: 'E-Mail',
        key: 'email',
        dataIndex: 'email',
        ...this.getColumnSearchProps('email')
      },
      {
        title: this.props.intl.formatMessage({ id: 'function', defaultMessage: 'Function' }),
        key: 'function',
        dataIndex: 'function'
      },
      {
        title: this.props.intl.formatMessage({ id: 'role', defaultMessage: 'Role' }),
        key: 'scope',
        render: (text, record) => record.scope.map(item => roles[item] || item).join(', ')
      },
      {
        title: this.props.intl.formatMessage({ id: 'created at', defaultMessage: 'Created At' }),
        key: 'createdAt',
        dataIndex: 'createdAt',
        sorter: true,
        render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm')
      },
      {
        title: this.props.intl.formatMessage({ id: 'last login', defaultMessage: 'Last login' }),
        key: 'lastLogin',
        dataIndex: 'lastLogin',
        sorter: true,
        render: lastLogin => lastLogin ? moment(lastLogin).format('DD.MM.YYYY, HH:mm') : '-'
      },
      {
        title: this.props.intl.formatMessage({ id: 'activated', defaultMessage: 'Activated' }),
        key: 'activated',
        dataIndex: 'activated',
        align: 'center',
        sorter: true,
        render: (text, record) => record.activated ? <Icon type="check" style={{ color: '#005591' }} /> : <Icon type="close" style={{ color: 'red' }} />
      },
      {
        key: 'actions',
        render: (text, record) => (
          <div style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-end'
          }}>
            <React.Fragment>
              <Button onClick={() => this.showDrawer(record, false)}>
                <Icon type="edit" />
              </Button>
            </React.Fragment>
          </div>
        )
      }
    ]

    const headerButtons = [
      <Button
        key={89456547848}
        type="primary"
        onClick={() => this.showDrawer({}, true)}
      >
        <FormattedMessage
          id="create user"
          defaultMessage="Create User"
        />
      </Button>
    ]

    const footerDrawerButtons = [
      <Button onClick={this.hideDrawer} style={{ marginRight: 8 }}>
        <FormattedMessage
          id="cancel"
          defaultMessage="Cancel"
        />
      </Button>
    ]

    if (this.state.new) {
      footerDrawerButtons.push(
        <Button
          onClick={this.saveUser} type="primary">
          <FormattedMessage
            id="create account"
            defaultMessage="Create account"
          />
        </Button>
      )
    } else {
      footerDrawerButtons.push(
        <Button
          onClick={this.saveUser} type="primary">
          <FormattedMessage
            id="update account"
            defaultMessage="Update account"
          />
        </Button>
      )
    }

    if (['superadmin'].includes(this.props.auth.auth_user.scope[0]) &&
      this.state.user && !this.state.new) {
      footerDrawerButtons.unshift(
        <PopconfirmDelete onConfirmAction={() => this.removeUser()} />
      )
    }

    return (
      <div>
        <FormattedMessage id="head.title.users" defaultMessage="Users">
          {title => <Helmet><title>{title}</title></Helmet>}
        </FormattedMessage>

        <PageTitle
          title={this.props.intl.formatMessage({ id: 'users', defaultMessage: 'Users' })}
          buttons={headerButtons}
        />

        <Table
          columns={columns}
          dataSource={this.state.users}
          rowKey={record => record._id}
          loading={this.state.loading}
          onRow={(record) => ({
            onDoubleClick: () => { this.showDrawer(record) }
          })}
          onChange={this.onTableChange}
          pagination={this.state.pagination}
        />

        <DrawerComponent
          title={this.state.new === true ? this.props.intl.formatMessage({ id: 'create a new account', defaultMessage: 'Create a new account' }) : this.props.intl.formatMessage({ id: 'update account', defaultMessage: 'Update account' })}
          onClose={this.hideDrawer}
          visible={this.state.visible}
          footerButtons={footerDrawerButtons}
        >
          <UserForm
            user={this.state.user}
            updateUser={this.updateUser}
            roles={roles}
            form={this.props.form}
          />
        </DrawerComponent>
      </div>
    )
  }
}

UsersPage.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.authReducer
})

export default injectIntl(Form.create({ name: 'users_form' })(
  connect(mapStateToProps)(UsersPage)))
