const types = {
  AUTH_USER: 'AUTH_USER',
  AUTH_TOKEN: 'AUTH_TOKEN',
  AUTH_LOGOUT: 'AUTH_LOGOUT',
  SETTINGS: 'SETTINGS',
  PROJECT: 'PROJECT',
  PROJECTS: 'PROJECTS',
  LANG: 'LANG',
  PROJECT_LAST_UPDATED: 'PROJECT_LAST_UPDATED',
  OBJECTS: 'OBJECTS',
  DEFECT_DATA: 'DEFECT_DATA'
}

export default types
