import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import _ from 'lodash'

import { Table, Icon, Button, notification, Form } from 'antd'

import ContractSectionForm from 'components/ContractSection/Form'
import PageTitle from 'components/Global/PageTitle'
import PopconfirmDelete from 'components/Global/PopconfirmDelete'
import contractSectionService from 'services/contractSection'
import projectService from 'services/project'
import DrawerComponent from 'components/Drawer'

export class ContractSectionsPage extends React.Component {
  state = {
    contractSections: [],
    drawer: false,
    contractSection: {},
    projects: [],
    loading: false,
    formLoading: false,
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50', '100']
    },
    createdByFilters: [],
    activeFilters: [],
    readOnlyNewProject: false
  };

  componentDidMount () {
    this.fetchContractSections()
  }

  componentDidUpdate (prevProps) {
    if (this.props.project !== prevProps.project) {
      this.fetchContractSections()
    }
  }

  fetchContractSections = async (params) => {
    this.setState({
      loading: true
    })
    try {
      const contractSections = await contractSectionService.listV2({
        ...params
      })

      const pagination = {
        ...this.state.pagination
      }

      pagination.total = contractSections.totalDocs

      this.setState({
        contractSections: contractSections.docs,
        createdByFilters: contractSections.filterOptions.createdBy,
        loading: false,
        pagination
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({ id: 'contract sections', defaultMessage: 'Contract Sections' })} - download error`
      })

      this.setState({
        contractSections: [],
        drawer: false,
        loading: false,
        contractSection: {}
      })
    }
  }

  fetchProjects = async (value) => {
    this.setState({
      formLoading: true
    })
    const timer = setTimeout(async () => {
      try {
        let projects = await projectService.listV2({
          projectName: [value],
          limit: 100
        })

        this.setState({
          projects: projects.docs,
          formLoading: false
        })

        clearTimeout(timer)
      } catch (error) {
        this.setState({
          projects: [],
          formLoading: false
        })
        clearTimeout(timer)
      }
    })
  }

  showDrawer = contractSection => {
    if (_.isEmpty(contractSection, true)) {
      this.setState({
        drawer: true,
        contractSection: {
          ...contractSection,
          project: this.props.selectedProject ? this.props.selectedProject : null
        },
        readOnlyNewProject: !!this.props.selectedProject
      })
    } else {
      this.setState({
        drawer: true,
        contractSection: {
          ...contractSection
        },
        readOnlyNewProject: false
      })
    }
  }

  hideDrawer = () => {
    this.setState({
      drawer: false,
      contractSection: {}
    })
  }

  saveContractSection = async () => {
    this.props.form.validateFields(async err => {
      if (!err) {
        try {
          const savedContractSection = await contractSectionService.save(this.state.contractSection)

          this.fetchContractSections()
          if (this.state.contractSection._id) {
            notification.success({
              message: `${this.props.intl.formatMessage({ id: 'updated successfully', defaultMessage: 'Updated successfully' })}`
            })
          } else {
            notification.success({
              message: `${this.props.intl.formatMessage({ id: 'created successfully', defaultMessage: 'Created successfully' })}`
            })
          }

          this.setState({
            drawer: false,
            contractSection: {},
            contractSections: [
              ...this.state.contractSections.filter(contractSection => contractSection._id !== savedContractSection._id),
              savedContractSection
            ]
          })
        } catch (error) {
          notification.error({
            message: this.props.intl.formatMessage({ id: 'saving error', defaultMessage: 'Saving Error' }),
            description: error.error
          })
        }
      }
    })
  }

  removeContractSection = async contractSection => {
    try {
      await contractSectionService.remove(contractSection)

      this.setState({
        drawer: false,
        contractSection: {},
        contractSections: [
          ...this.state.contractSections.filter(item => item._id !== contractSection._id)
        ]
      })
    } catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({ id: 'deleting error', defaultMessage: 'Deleting Error' }),
        description: error.error
      })
    }
  }

  updateContractSection = (field, value) => {
    this.setState(
      state => ({
        contractSection: {
          ...state.contractSection,
          [field]: value
        }
      })
    )
  }

  onTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    pager.offset = pagination.current

    this.setState({
      pagination: {
        ...pager,
        current: pagination.current
      }
    })

    this.fetchContractSections({
      limit: pagination.pageSize,
      offset: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    })
  }

  render () {
    const columns = [
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        defaultSortOrder: 'ascend',
        sorter: true
      },
      {
        title: this.props.intl.formatMessage({ id: 'created at', defaultMessage: 'Created At' }),
        key: 'createdAt',
        dataIndex: 'createdAt',
        sorter: true,
        render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm')
      },
      {
        title: this.props.intl.formatMessage({ id: 'created by', defaultMessage: 'Created By' }),
        key: 'createdBy',
        dataIndex: 'createdBy',
        render: createdBy => createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : '',
        filters: this.state.createdByFilters.map(user => ({
          text: `${user.firstName} ${user.lastName}`,
          value: user._id
        }))
      },
      {
        title: this.props.intl.formatMessage({ id: 'active', defaultMessage: 'Active' }),
        key: 'disabled',
        dataIndex: 'disabled',
        align: 'center',
        render: disabled => (
          <React.Fragment>
            {!disabled && (
              <Icon type="check" style={{ color: '#005591' }} />
            )}
          </React.Fragment>
        ),
        filters: [
          {
            text: this.props.intl.formatMessage({ id: 'active', defaultMessage: 'active' }),
            value: false
          },
          {
            text: this.props.intl.formatMessage({ id: 'inactive', defaultMessage: 'Inactive' }),
            value: true
          }
        ],
        onFilter: (value, record) => {
          if (value === 'active') {
            return !record.disabled
          } else if (value === 'inactive') {
            return !!record.disabled
          }

          return false
        },
        filteredValue: this.state.activeFilters
      }
    ]

    if (!this.props.project) {
      columns.push({
        title: this.props.intl.formatMessage({ id: 'project', defaultMessage: 'Project' }),
        key: 'project',
        dataIndex: 'project.projectName'
      })
    }

    columns.push({
      key: 'actions',
      render: (text, record) => (
        <div style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'flex-end'
        }}>
          <React.Fragment>
            <Button onClick={() => this.showDrawer(record)}>
              <Icon type="edit" />
            </Button>
          </React.Fragment>
        </div>
      )
    })

    const drawerButtons = [
      <PopconfirmDelete onConfirmAction={() => this.removeContractSection(this.state.contractSection)} />
      ,
      <Button key={65493152} onClick={this.hideDrawer} style={{ marginRight: 8 }}>
        <FormattedMessage
          id="cancel"
          defaultMessage="Cancel"
        />
      </Button>,
      <Button
        key={237323536}
        onClick={this.saveContractSection} type="primary">
        {this.props.intl.formatMessage({ id: 'save', defaultMessage: 'Save' })}
      </Button>
    ]

    const { contractSections, contractSection, drawer, pagination, projects, loading, formLoading, readOnlyNewProject } = this.state

    const headerButtons = [
      <Button
        key={238397323536}
        type="primary"
        onClick={() => this.showDrawer({})}
      >
        <FormattedMessage
          id="create contract section"
          defaultMessage="Create Contract Section"
        />
      </Button>
    ]

    return (
      <div>
        <FormattedMessage id="head.title.contractSections" defaultMessage="Contract Sections">
          {title => <Helmet><title>{title}</title></Helmet>}
        </FormattedMessage>

        <PageTitle
          title={this.props.intl.formatMessage({ id: 'contract sections', defaultMessage: 'Contract sections' })}
          buttons={headerButtons}
        />

        <Table
          rowKey={record => record._id}
          columns={columns}
          dataSource={contractSections}
          onRow={(record) => ({
            onDoubleClick: () => { this.showDrawer(record) }
          })}
          loading={loading}
          onChange={this.onTableChange}
          pagination={pagination}
        />

        <DrawerComponent
          title={contractSection._id ? contractSection.name : this.props.intl.formatMessage({ id: 'create contract section', defaultMessage: 'Create contract section' })}
          onClose={this.hideDrawer}
          visible={!!drawer}
          footerButtons={drawerButtons}
        >
          <ContractSectionForm
            contractSection={contractSection}
            projects={projects}
            readOnlyNewProject={readOnlyNewProject}
            formLoading={formLoading}
            fetchProjects={this.fetchProjects}
            updateContractSection={this.updateContractSection}
            form={this.props.form}
          />
        </DrawerComponent>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  projects: state.projectReducer.projects,
  selectedProject: state.projectReducer.project
})

export default injectIntl(Form.create({ name: 'contract_section_form' })(connect(mapStateToProps)(ContractSectionsPage)))
