import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Form, Input, Select, Spin, InputNumber } from 'antd'
import objectService from 'services/object'
import contractSectionService from 'services/contractSection'
import contactService from 'services/contact'
import './styles.scss'
import isEqual from 'lodash/isEqual'

const { Option } = Select;
const formItemLayout = {
  className: 'form-item-cover',
};
const groupData = [
  "Kabel",
  "Leittechnik",
  "Materialschaden / falsches Material",
  "falsche Montage",
  "Beschriftung",
  "Brandschottung",
  "Doppelboden",
  "Reinigung",
  "Dokumentation",
  "Schema",
  "Schäden",
  "No-Break",
  "Andere",
  "Zu klären / fertigstellen",
  "Altlasten",
  "Baufehler"
];

class DefectForm extends React.Component {
  state = {
    objects: [],
    projects: [],
    contractSections: [],
    contacts: []
  }

  fetchData = async (value, key) => {
    this.setState({
      formLoading: true
    });
    const { projects, data, formValue } = this.props;
    const timer = setTimeout(async () => {
      try {
        let list;
        if (key === 'projects') {
          list = projects.filter(item => item.projectName.toLowerCase()
            .indexOf((value.toString()).toLowerCase()) > -1)
        } else if (key === 'objects') {
          // data.project&& data.project._id
          list = await objectService.listV2({
            name: [value],
            limit: 100
          }, formValue.project || data.project && data.project._id)
        } else if (key === 'contractSections') {
          list = await contractSectionService.listV2({
            name: [value],
            limit: 100
          })
        } else if (key === 'contacts') {
          list = await contactService.listV2({
            name: [value],
            limit: 100
          })
        }
        this.setState({
          [key]: list.docs || list,
          formLoading: false
        });

        clearTimeout(timer)
      }
      catch (error) {
        this.setState({
          [key]: [],
          formLoading: false
        });
        clearTimeout(timer)
      }
    })
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { data, form } = this.props;

    if (!isEqual(data, prevProps.data)) {
      form.resetFields()
    }
  }
  handleProjectChange = (projectID) => {
    const { updateStateDefect, form } = this.props;

    form.setFields({ objectId: { value: null } });
    this.setState({
      objects: []
    });
    updateStateDefect('project', projectID)
  };

  render () {
    const { updateStateDefect, data, intl, errors, objectName } = this.props;
    const { objects, projects, formLoading, contractSections, contacts } = this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <Form>
        <div className="form-cover">
          <Form.Item
            {...formItemLayout}
            label={intl.formatMessage({ id: 'name' })}
            validateStatus={errors.name ? "error" : null}
            help={errors.name ? intl.formatMessage({ id: 'is required' }) : null}
          >
            {getFieldDecorator('name', {})(
              <Input
                onChange={event =>
                  updateStateDefect('name', event.target.value)
                }
              />)}
          </Form.Item>
          <Form.Item {...formItemLayout} label={intl.formatMessage({ id: 'project' })}>
            {getFieldDecorator('project', {
              initialValue: data.project ? data.project.projectName : ''
            })(
              <Select
                showSearch
                allowClear
                style={{ width: '100%' }}
                optionFilterProp="children"
                notFoundContent={formLoading ?
                  <div className="objects-spinner-container"><Spin size="small" /></div> : null}
                onFocus={() => this.fetchData('', 'projects')}
                onSearch={value => this.fetchData(value, 'projects')}
                onChange={this.handleProjectChange}
              >
                {projects.map(project => (
                  <Option key={project._id} value={project._id}>{project.projectName}</Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            label={objectName}
            validateStatus={errors.objectId ? "error" : null}
            help={errors.objectId ? intl.formatMessage({ id: 'is required' }) : null}
          >
            {getFieldDecorator('objectId', {
              initialValue: data.objectId ? (data.objectId.projectName || data.objectId.name) : ''
            })(
              <Select
                showSearch
                allowClear
                style={{ width: '100%' }}
                optionFilterProp="children"
                notFoundContent={formLoading ?
                  <div className="objects-spinner-container"><Spin size="small" /></div> : null}
                onFocus={() => this.fetchData('', 'objects')}
                onSearch={value => this.fetchData(value, 'objects')}
                onChange={objectId => updateStateDefect('objectId', objectId)}
              >
                {objects.map(object => (
                  <Option key={object._id} value={object._id}>{object.name}</Option>
                ))}
              </Select>)}
          </Form.Item>

          <Form.Item {...formItemLayout} label={intl.formatMessage({
            id: 'contract section',
          })}>
            {getFieldDecorator('contractSection', {
              initialValue: data.contractSection ? data.contractSection.name : ''

            })(
              <Select
                showSearch
                allowClear
                style={{ width: '100%' }}
                optionFilterProp="children"
                notFoundContent={formLoading ?
                  <div className="objects-spinner-container"><Spin size="small" /></div> : null}
                onFocus={() => this.fetchData('', 'contractSections')}
                onSearch={value => this.fetchData(value, 'contractSections')}
                onChange={projectID => updateStateDefect('contractSection', projectID)}
              >
                {contractSections.map(element => (
                  <Option key={element._id} value={element._id}>{element.name}</Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item {...formItemLayout} label={intl.formatMessage({
            id: 'assignedTo',
          })}>
            {getFieldDecorator('assignedTo', {
              initialValue: data.assignedTo ? data.assignedTo.company : ''
            })(
              <Select
                showSearch
                allowClear
                style={{ width: '100%' }}
                optionFilterProp="children"
                notFoundContent={formLoading ?
                  <div className="objects-spinner-container"><Spin size="small" /></div> : null}
                onFocus={() => this.fetchData('', 'contacts')}
                onSearch={value => this.fetchData(value, 'contacts')}
                onChange={projectID => updateStateDefect('assignedTo', projectID)}
              >
                {contacts.map(element => (
                  <Option key={element._id} value={element._id}>{element.company}</Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item {...formItemLayout} label={intl.formatMessage({
            id: 'group',
          })}>
            {getFieldDecorator('group', {})(
              <Select
                onChange={group => updateStateDefect('group', group)}
              >
                {groupData.map((item, index) => (
                  <Option key={index} value={item}>{item}</Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item {...formItemLayout} label={intl.formatMessage({
            id: 'actualSituation',
          })}>
            {getFieldDecorator('actualSituation', {
              initialValue: (data.attributes && data.attributes.actualStatus) || ''
            })(
              <Input.TextArea
                onChange={event =>
                  updateStateDefect('actualSituation', event.target.value)
                }
              />
            )}
          </Form.Item>
          <Form.Item {...formItemLayout} label={intl.formatMessage({
            id: 'targetSituation',
          })}>
            {getFieldDecorator('targetSituation', {
              initialValue: (data.attributes && data.attributes.targetStatus) || ''
            })(
              <Input.TextArea
                onChange={event =>
                  updateStateDefect('targetSituation', event.target.value)
                }
              />
            )}
          </Form.Item>
          <Form.Item {...formItemLayout} label={intl.formatMessage({
            id: 'estimated_cost',
          })}>
            {getFieldDecorator('estimated_cost', {
              initialValue: data.estimated_cost || ''
            })(
              <InputNumber
                onChange={value =>
                  updateStateDefect('estimated_cost', value)
                }
                formatter={value => `CHF ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '')}
                parser={value => value.replace(" ", '').match(/[0-9 , \.]+/g)}
              />
            )}
          </Form.Item>
          <Form.Item {...formItemLayout} label={intl.formatMessage({
            id: 'protocolResponsible',
          })}>
            {getFieldDecorator('protocolResponsible', {
              initialValue: data.protocolResponsible ? data.protocolResponsible.company : ''
            })(
              <Select
                showSearch
                allowClear
                style={{ width: '100%' }}
                optionFilterProp="children"
                notFoundContent={formLoading ?
                  <div className="objects-spinner-container"><Spin size="small" /></div> : null}
                onFocus={() => this.fetchData('', 'contacts')}
                onSearch={value => this.fetchData(value, 'contacts')}
                onChange={projectID => updateStateDefect('protocolResponsible', projectID)}
              >
                {contacts.map(element => (
                  <Option key={element._id} value={element._id}>{element.company}</Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label={intl.formatMessage({ id: 'protocolRef' })}
          >
            {getFieldDecorator('protocolReference', {
              initialValue: data.protocolReference || ''
            })(
              <Input
                onChange={event =>
                  updateStateDefect('protocolReference', event.target.value)
                }
              />)}
          </Form.Item>
        </div>
      </Form>
    )
  }
}

const mapStateToProps = state => {
  return {
    defectData: state.tempData.defectData,
    projects: state.projectReducer.projects,
    objectName: state.settingsReducer.data.buildx.objectName,
  }
}
export default Form.create()(injectIntl(connect(mapStateToProps)(DefectForm)));

