import axios from '../helpers/axios'

class ImageService {
  create(data) {
    return axios.post('/recording-image', data)
  }

  delete(id) {
    return axios.delete(`/recording-image/${id}`)
  }

  update(id, data) {
    return axios.put(`/recording-image/${id}`, data)
  }
}

const imageService = new ImageService()
export default imageService
