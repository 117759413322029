import React from 'react'
import PropTypes from 'prop-types'
import { Form, Checkbox, Select, Input, Spin, AutoComplete } from 'antd'
import { injectIntl } from 'react-intl'

import './styles.scss'

const { Option } = Select

class AcceptanceForm extends React.Component {
  render () {
    let locationName = this.props.acceptance.location ? this.props.acceptance.location.name : ''

    const children = this.props.locations.map((location, i) => (
      <Option key={i} data={location} value={location.name || ''}>
        {location.name}
      </Option>
    ))

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      className: 'formItem'
    }

    // const locations = this.props.locations.map(location => (
    //   <Option key={location._id} value={location.name}>
    //     {location.name}
    //   </Option>
    // ))

    return (
      <React.Fragment>
        <Form.Item {...formItemLayout} label="Name">
          <Input
            value={this.props.acceptance.name}
            onChange={event => this.props.updateAcceptance('name', event.target.value)}
          />
        </Form.Item>

        <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: 'project', defaultMessage: 'Project' })}>
          <Select
            showSearch
            allowClear
            style={{ width: '100%' }}
            optionFilterProp="children"
            notFoundContent={this.props.formLoading ? <div className="objects-spinner-container"><Spin size="small" /></div> : null}
            onFocus={() => this.props.fetchProjects('')}
            onSearch={value => this.props.fetchProjects(value)}
            onChange={projectID => this.props.updateAcceptance('project', projectID)}
            defaultValue={this.props.acceptance.project ? this.props.acceptance.project.projectName : ''}
          >
            {this.props.projects.map(project => (
              <Select.Option key={project._id} value={project._id}>{project.projectName}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({
          id: 'participant',
          defaultMessage: 'Participant'
        })}>
          <Input.TextArea
            autosize={{ minRows: 2, maxRows: 6 }}
            value={this.props.acceptance.participant}
            onChange={event => this.props.updateAcceptance('participant', event.target.value)}
          />
        </Form.Item>

        <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({
          id: 'location',
          defaultMessage: 'Location'
        })}>
          <div className="certain-category-search-wrapper">
            <AutoComplete
              showSearch
              allowClear
              className="certain-category-search"
              dropdownClassName="certain-category-search-dropdown"
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ width: 300 }}
              style={{ width: '100%' }}
              // dataSource={locations}
              optionLabelProp="value"
              // filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
              defaultValue={locationName}
              notFoundContent={this.props.formLoading ? <div className="acceptance-spinner-container"><Spin size="small" /></div> : null}
              onFocus={() => this.props.fetchLocations('')}
              onSearch={(value) => this.props.fetchLocations(value)}
              onChange={(value, e) => this.props.updateLocationInAcceptance(value, e)}
            >
              {/* <Input
                suffix={<Icon type="search" className="certain-category-icon"/>}
              /> */}
              {
                children
              }
            </AutoComplete>
          </div>
        </Form.Item>

        <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({
          id: 'archived',
          defaultMessage: 'Archived'
        })}>
          <Checkbox
            checked={this.props.acceptance.archived}
            onChange={event => this.props.updateAcceptance('archived', event.target.checked)}
          />
        </Form.Item>
      </React.Fragment>
    )
  }
}

AcceptanceForm.propTypes = {
  acceptance: PropTypes.object.isRequired,
  updateAcceptance: PropTypes.func.isRequired,
  locations: PropTypes.array.isRequired
}

export default injectIntl(AcceptanceForm)
