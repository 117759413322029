import React from 'react'
import { Button, Icon, Input, Form } from 'antd'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'

import FormBuilder from 'components/FormBuilder'
import PopconfirmDelete from 'components/Global/PopconfirmDelete'

export class FormBuilderPage extends React.Component {
  saveForm = () => {
    this.props.form.validateFields(err => {
      if (!err) {
        this.props.saveForm()
      }
    })
  }

  render() {
    const { form: { getFieldDecorator }, authUser } = this.props

    return (
      <div
        style={{
          overflowX: 'auto'
        }}
      >
        <h1>
          <FormattedMessage
            id="form"
            defaultMessage="Form"
          />
        </h1>

        <div
          style={{
            width: 650
          }}
        >
          <div style={{
            textAlign: 'right',
            padding: 10
          }}>
            <Button
              shape="circle"
              onClick={() => this.props.setForm({ form: {} })}
              style={{
                marginRight: 10
              }}
            >
              <Icon type="arrow-left"/>
            </Button>
          </div>

          <Form.Item
            label={(<span>Name<span style={{ color: '#005591' }}>*</span></span>)}
            required={false}
          >
            {getFieldDecorator('name', {
              initialValue: this.props.formData.name,
              rules: [{
                required: true,
                message: this.props.intl.formatMessage({
                  id: 'name is required',
                  defaultMessage: 'Name is required'
                })
              }]
            })(
              <Input
                size="large"
                placeholder="Name"
                onChange={event => this.props.updateForm('name', event.target.value)}
              />
            )}
          </Form.Item>

          <FormBuilder
            data={this.props.formData.fields || []}
            onChange={data => this.props.updateForm('fields', data.task_data)}
          />

          <div style={{
            textAlign: 'left',
            padding: 10
          }}>
            {authUser.scope.some(item => item === 'superadmin') && (
              <PopconfirmDelete onConfirmAction={() => this.deleteForm()}/>
            )}

            <Button
              type="primary"
              onClick={() => this.saveForm()}
            >
              <FormattedMessage
                id="save"
                defaultMessage="Save"
              />
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

FormBuilderPage.propTypes = {
  formData: PropTypes.object.isRequired,
  setForm: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired
}

export default injectIntl(Form.create({ name: 'formbuilder_form' })(FormBuilderPage))
