import types from 'actions/constants'

export const initialState = {
  objects: undefined
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.OBJECTS:
      return {
        ...state,
        objects: action.objects
      }
    default:
      return state
  }
}

export default reducer
