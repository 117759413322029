import React from 'react'
import PropTypes from 'prop-types'
import ImageZoom from 'react-medium-image-zoom'
import { FormattedMessage, injectIntl } from 'react-intl'

import { Form, Input } from 'antd'

import config from 'config'

class Images extends React.Component {
  render () {
    const images = this.props.request.images || []

    return (
      <React.Fragment>
        {images.map(image => (
          <React.Fragment key={image._id}>
            <h3>
              <FormattedMessage
                id="photo"
                defaultMessage="Photo"
              />
            </h3>

            <Form.Item {...this.props.formItemLayout} label={this.props.intl.formatMessage({ id: 'photo', defaultMessage: 'Photo' })}>
              <ImageZoom
                image={{
                  src: config.server.url + image.imageUrl,
                  alt: '',
                  style: { width: '100%' }
                }}
                zoomImage={{
                  src: config.server.url + image.imageUrl,
                  alt: ''
                }}
                defaultStyles={{
                  zoomContainer: {
                    zIndex: 9999
                  }
                }}
              />
            </Form.Item>
            <Form.Item {...this.props.formItemLayout} label={this.props.intl.formatMessage({ id: 'comment', defaultMessage: 'Comment' })}>
              <Input.TextArea
                disabled={true}
                value={ image.hasOwnProperty('comment') ? image.comment : ''}
              />
            </Form.Item>
          </React.Fragment>
        ))}
      </React.Fragment>
    )
  }
}

Images.propTypes = {
  formItemLayout: PropTypes.object.isRequired,
  request: PropTypes.object.isRequired
  // changeData: PropTypes.func.isRequired,
  // readOnly: PropTypes.bool.isRequired
}

export default injectIntl(Images)
