import types from 'actions/constants'

export const initialState = {
  defectData: {}
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.DEFECT_DATA:
      return {
        ...state,
        defectData: action.defectData
      }
    default:
      return state
  }
}

export default reducer
