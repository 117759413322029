import store from '../store'
import config from 'config'
import axios from '../helpers/axios'

export default {
  async getList () {
    const response = await axios.get('/manufacturer')
    return response
  },
  async listV2 (params = {}) {
    const projectID = store.getState().projectReducer.project
    if (projectID) {
      params.project = projectID._id
    }
    return axios.get(config.server.host + '/1.1/manufacturer', params)
  },
  async addManufacturer (data) {
    const response = await axios.post('/manufacturer', data)
    return response
  },
  async deleteManufacturer (id) {
    const response = await axios.delete(`/manufacturer/${id}`)
    return response
  },
  async updateManufacturer (id, data) {
    const response = await axios.put(`/manufacturer/${id}`, data)
    return response
  }
}
