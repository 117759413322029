import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Form, Button, PageHeader } from 'antd'
import SortableList from '../../SortableHOC/SortableHOC'

class TaskEdit extends React.Component {
    handleAddTask = () => {
      this.props.addShareholder()
    };

    render () {
      return (<React.Fragment>
        <Form className="workorder-task-edit-mode">

          <PageHeader onBack={this.props.action} title={this.props.intl.formatMessage({ id: 'Edit tasks', defaultMessage: 'Edit tasks' })} />

          <SortableList
            items={this.props.shareholders}
            objects={this.props.objects}
            recording={this.props.recording}
            formLoading={this.props.formLoading}
            form={this.props.form}
            auth={this.props.auth}
            objectName={this.props.objectName}
            dataXEnabled={this.props.dataXEnabled}
            extraFieldsForm={this.props.extraFieldsForm}
            recordingIdSearchResult={this.props.recordingIdSearchResult}
            handleRecordingSelect={this.props.handleRecordingSelect}
            objectIdSearchResult={this.props.objectIdSearchResult}
            removeTagHandler={this.props.removeTagHandler}
            clearTagHandler={this.props.clearTagHandler}
            taskIdChange={this.props.taskIdChange}
            handleTaskIdSelectChange={this.props.handleTaskIdSelectChange}
            handleTaskIdChangeAutocomplete={this.props.handleTaskIdChangeAutocomplete}
            handleSearchObjectId={this.props.handleSearchObjectId}
            handleSearchRecordingId={this.props.handleSearchRecordingId}
            removeTaskholder={this.props.removeTaskholder}
            onSortEnd={this.props.onSortTasksEnd}
            markAsDoneTask={this.props.markAsDoneTask}
            showModalComment={this.props.showModalComment}
            hideModalComment={this.props.hideModalComment}
            recordingDrawerVisible={this.props.recordingDrawerVisible}
            recordingDrawerShow={this.props.recordingDrawerShow}
            recordingDrawerHide={this.props.recordingDrawerHide}
            modalCommentActive={this.props.modalCommentActive}
            helperClass="task-ghost-helper"
            transitionDuration={5}
            useDragHandle
          />

          <div className="add-worker-order-task-wrap">
            <Button type="primary" onClick={this.handleAddTask}><FormattedMessage id="Add task" defaultMessage="Add task" /></Button>
          </div>

        </Form>
      </React.Fragment>)
    }
}

export default injectIntl(connect()(TaskEdit))
