import React from 'react'
import PropTypes from 'prop-types'

import { Checkbox, Form, Input, Select } from 'antd'

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  className: 'formItem'
}

const ExtraFields = props => {
  const attributes = props.recording.attributes || {}
  const extra_fields = attributes.extra_fields || []

  const changeFields = (fieldID, value, choices) => {
    const field = extra_fields.filter(item => item.id === fieldID)[0] || {
      id: fieldID
    }

    if (field) {
      field.value = value
      field.choices = choices

      props.changeData('attributes', {
        ...attributes,
        extra_fields: [
          ...extra_fields.filter(item => item.id !== field.id),
          {
            ...field
          }
        ]
      })
    }
  }

  const changeChoices = (fieldID, value, checked) => {
    const field = extra_fields.filter(item => item.id === fieldID)[0]

    let choices = []
    if (field && field.choices) {
      choices = field.choices
    }

    choices = choices.filter(item => item !== value)
    if (checked) {
      choices = [
        ...choices,
        value
      ]
    }

    changeFields(fieldID, null, choices)
  }

  const items = props.form.fields.map(item => {
    const field = extra_fields.filter(extra_field => extra_field.id === item.id)[0] || {
      id: item.id
    }

    if (item.element === 'TextArea') {
      return (
        <Form.Item key={item.id} {...formItemLayout} label={item.label}>
          <Input.TextArea
            disabled={props.readOnly}
            value={field.value}
            onChange={event => changeFields(item.id, event.target.value)}
          />
        </Form.Item>
      )
    } else if (item.element === 'Checkboxes') {
      return (
        <Form.Item key={item.id} {...formItemLayout} label={item.label}>
          {(item.options || []).map(option => (
            <p key={option.key} style={{ margin: 0 }}>
              <Checkbox
                disabled={props.readOnly}
                checked={(field.choices || []).includes(option.value)}
                onChange={event => changeChoices(item.id, option.value, event.target.checked)}
              /> {option.text}
            </p>
          ))}
        </Form.Item>
      )
    } else if (item.element === 'Dropdown') {
      return (
        <Form.Item key={item.id} {...formItemLayout} label={item.label}>
          <Select
            style={{
              width: '100%'
            }}
            disabled={props.readOnly}
            allowClear
            value={field.value}
            onChange={value => changeFields(item.id, value)}
          >
            {(item.options || []).map(option => (
              <Select.Option key={option.key} value={option.value}>{option.text}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      )
    } else {
      return (
        <Form.Item key={item.id} {...formItemLayout} label={item.label}>
          <Input
            disabled={props.readOnly}
            value={field.value}
            autosize={{ minRows: 2, maxRows: 6 }}
            onChange={event => changeFields(item.id, event.target.value)}
          />
        </Form.Item>
      )
    }
  })

  return (
    <React.Fragment>
      <h3>{props.form.name}</h3>

      {items}
    </React.Fragment>
  )
}

ExtraFields.propTypes = {
  formItemLayout: PropTypes.object.isRequired,
  recording: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
  // changeData: PropTypes.func.isRequired,
  // readOnly: PropTypes.bool.isRequired
}

export default ExtraFields
