import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { ModalComponent } from 'components/Modal'
import DefectForm from 'components/ModalDefectCreation/Form'
import RecordingPreview from 'components/ModalDefectCreation/RecordingPreview'
import { connect } from 'react-redux';
import recordingService from 'services/recording'
import { Button, notification } from 'antd';

import './Form/styles.scss'

export class ModalDefectCreation extends React.PureComponent {
  state = {
    formValue: {},
    errors: {},
    confirmLoading: false
  }

  saveDefect = () => {
    const { defectData, defectData: { income }, onClose, onOk, refreshData } = this.props;
    const { formValue } = this.state;
    const payload = { ...formValue };
    this.setState({
      confirmLoading: true
    })
    if (!("actualSituation" in formValue) && income.attributes && income.attributes.actualStatus) {
      payload.actualSituation = income.attributes.actualStatus
    }
    if (!("targetSituation" in formValue) && income.attributes && income.attributes.targetStatus) {
      payload.targetSituation = income.attributes.targetStatus
    }
    if (!("contractSection" in formValue) && income.contractSection) {
      payload.contractSection = income.contractSection._id
    }
    if (!("project" in formValue) && income.project) {
      payload.project = income.project._id
    }
    if (!("objectId" in formValue)) {
      payload.objectId = income.objectId._id
    }

    if (payload.name && payload.name.length > 0 && payload.objectId && payload.objectId.length > 0) {
      recordingService.createDefect(income._id, payload)
      .then(result => {
        notification.success({
          message: `${result.docNumber} ${this.props.intl.formatMessage({
            id: 'created successfully',
            defaultMessage: 'Created successfully'
          })}`
        });
        if (refreshData){
          refreshData()
        }
        if (defectData.type === 'acceptance') {
          onOk()
        } else {
          onClose()
        }
        this.setState({
          formValue: {},
          confirmLoading: false
        })
      })
      .catch(error => {
        console.log(error);

        notification.error({
          message: `${this.props.intl.formatMessage({
            id: 'uploading error',
            description: error.data,
            defaultMessage: 'uploading error'
          })}`
        });
        this.setState({
          confirmLoading: false
        })
      });
    } else {
      this.setState({
        errors: {
          name: !payload.name || payload.name.length === 0,
          objectId: !payload.objectId || payload.objectId.length === 0
        },
        confirmLoading: false
      })
    }
  }

  updateStateDefect = (key, value) => {
    if (key==="project"){
      this.setState({
        formValue: {
          ...this.state.formValue,
          project: value,
          objectId: ""
        },
        errors: {}
      })
    }else{
      this.setState({
        formValue: {
          ...this.state.formValue,
          [key]: value,
        },
        errors: {}
      })
    }
  }

  render() {
    const { defectData, visible, onClose, onOk, onCancel, intl } = this.props;
    const { errors, confirmLoading,formValue } = this.state;

    return (
      <ModalComponent
        title={`${intl.formatMessage({
          id: 'defect creation',
          defaultMessage: 'Defect creation'
        })} ${defectData.step ? defectData.step.index + 1 + '/' + defectData.step.total : ''}`}
        onClose={onClose}
        onOk={this.saveDefect}
        okText={intl.formatMessage({
          id: 'create defect',
          defaultMessage: 'Create defect'
        })}
        onCancel={onCancel}
        visible={visible}
        confirmLoading={confirmLoading}
        hideFooter={defectData.type === 'acceptance'}
      >
        <p>{intl.formatMessage({ id: 'completeInfoBelow' })}</p>
        <RecordingPreview
          recording={defectData.income}
        />

        <DefectForm
          data={defectData.income}
          updateStateDefect={this.updateStateDefect}
          errors={errors}
          formValue={formValue}
        />
        {
          defectData.type === 'acceptance' &&
          <div className="buttons-block">
            <Button
              onClick={onClose}
            >
              <FormattedMessage
                id="cancel"
                defaultMessage="Cancel"
              />
            </Button>
            <Button
              onClick={onOk}
            >
              <FormattedMessage
                id="skip"
                defaultMessage="Skip"
              />
            </Button>
            <Button
              key="ok"
              type="primary"
              onClick={this.saveDefect}
              loading={confirmLoading}
            >
              {
                intl.formatMessage({
                  id: 'create defect',
                  defaultMessage: 'Create defect'
                })
              }
            </Button>
          </div>
        }

      </ModalComponent>
    )
  }
}

ModalDefectCreation.propTypes = {};

const mapStateToProps = state => {
  return {
    defectData: state.tempData.defectData,
  }
}

export default injectIntl(connect(mapStateToProps)(ModalDefectCreation))
