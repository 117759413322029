import React from 'react'
import PropTypes from 'prop-types'
import { Badge } from 'antd'

const RecordingStatusIcon = (props) => {
  const { status } = props

  let backgroundColor = 'orange'

  if (status === 'approved') {
    backgroundColor = 'green'
  }

  if (status === 'rejected') {
    backgroundColor = 'red'
  }

  if (status === 'invalid') {
    backgroundColor = 'grey'
  }

  return (
    <Badge
      style={{ backgroundColor, color: backgroundColor }}
      count={1}
    />)
}

RecordingStatusIcon.propTypes = {
  status: PropTypes.string.isRequired
}

RecordingStatusIcon.defaultProps = {
  status: ''
}

export default RecordingStatusIcon
