import React from 'react'
import { Layout } from 'antd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import { injectIntl } from 'react-intl'
import { Helmet } from 'react-helmet'

import Header from './Header'
import Sidebar from './Sidebar'

import configService from 'services/config'
import projectService from 'services/project'
import reduxActions from 'actions'

class MainLayout extends React.Component {
  state = {
    projects: []
  }

  async componentDidMount () {
    const projects = this.props.projects || []
    try {
      const settings = await configService.get()

      this.props.dispatch(reduxActions.setSettings(settings))
    } catch (error) { }

    if (projects.length <= 1) {
      this.fetchProjects()
    }
  }

  componentDidUpdate (prevProps) {
    if (!this.props.projectLastUpdated) {
      this.props.dispatch(reduxActions.setProjectLastUpdated(moment().format()))
    } else if (prevProps.projectLastUpdated && prevProps.projectLastUpdated !== this.props.projectLastUpdated) {
      this.fetchProjects()
    }
  }

  fetchProjects = async (value) => {
    const timer = setTimeout(async () => {
      try {
        let projects = await projectService.list()
        projects = projects.filter(item => !item.archived)

        this.props.dispatch(reduxActions.setProjects(projects))

        this.setState({
          projects
        })

        this.props.dispatch(reduxActions.setProject(undefined))
        clearTimeout(timer)

        // if (this.props.project &&
        //   projects.filter(item => item._id === this.props.project).length === 0
        // ) {
        //   this.props.dispatch(reduxActions.setProject(undefined))
        // }
      } catch (error) {
        this.setState({
          projects: []
        })
        clearTimeout(timer)
      }
    })
  }
  render () {
    return (
      <Layout style={{ height: '100vh' }}>
        <Helmet titleTemplate="%s | ddSuite">
          <title>App</title>
        </Helmet>
        <Layout.Sider
          collapsible
          breakpoint="lg"
          width={256}
          style={{
            overflow: 'auto'
          }}
        >
          <Sidebar
            auth={this.props.auth}
            settings={this.props.settings}
          />
        </Layout.Sider>

        <Layout>
          <Layout.Header
            style={{
              background: '#fff'
            }}
          >
            <Header
              auth={this.props.auth}
              projects={this.props.projects}
              project={this.props.project}
              settings={this.props.settings}
              fetchProjects={this.fetchProjects}
            />
          </Layout.Header>

          <Layout.Content>
            <Layout.Content>
              <div
                style={{
                  margin: this.props.path === '/' ? 0 : 15,
                  padding: this.props.path === '/' ? 0 : 15,
                  background: this.props.path === '/' ? 'transparent' : 'white'
                }}
              >
                {this.props.children}
              </div>
            </Layout.Content>
          </Layout.Content>
        </Layout>
      </Layout>
    )
  }
}

MainLayout.propTypes = {
  auth: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  project: PropTypes.object,
  projectLastUpdated: PropTypes.string,
  path: PropTypes.string
}

export default injectIntl(connect(state => ({
  auth: state.authReducer,
  settings: state.settingsReducer,
  project: state.projectReducer.project,
  projects: state.projectReducer.projects,
  projectLastUpdated: state.projectReducer.lastUpdated
}))(MainLayout))
