import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import _ from 'lodash'
import { Table, Icon, Button, notification, Form, Input } from 'antd'
import arrayMove from 'array-move'

import userService from 'services/user'
import scheduledWorkOrdersService from 'services/scheduledWorkOrders'
import specialWorkService from 'services/specialWork'
import objectService from 'services/object'

import WorkOrderForm from 'components/WorkOrders/Form'
import PageTitle from 'components/Global/PageTitle'
import DrawerComponent from 'components/Drawer'
import PopconfirmDelete from 'components/Global/PopconfirmDelete'

export class ScheduledWorkordersPage extends React.Component {
  state = {
    workOrders: [],
    workOrder: {},
    users: [],
    objects: [],
    assignUsers: [],
    assignSpecialWork: [],
    modalCommentActive: '',
    visible: false,
    selectedRowKeys: [],
    createdByFilters: [],
    executedByFilters: [],
    loading: false,
    formLoading: false,
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50', '100']
    },
    locations: {},
    tasks: {
      id: '',
      shareholders: []
    },
    taskEditMode: false,
    fileUploadMode: false,
    objectIdSearchResult: []
  }

  toggleTaskEditMode = () => {
    this.setState({
      taskEditMode: !this.state.taskEditMode,
      fileUploadMode: false
    })
  }

  toggleFileuploadMode = () => {
    this.setState({
      fileUploadMode: !this.state.fileUploadMode,
      taskEditMode: false
    })
  }

  showDrawer = () => {
    this.setState({
      visible: true
    })
  };

  onClose = () => {
    this.setState({
      visible: false,
      taskEditMode: false,
      fileUploadMode: false,
      workOrder: {}
    })
  };

  componentDidMount () {
    this.fetchObjects()
    this.fetchWorkOrders()
  }

  componentDidUpdate (prevProps) {
    if (this.props.project !== prevProps.project) {
      this.fetchWorkOrders()
    }
  }

  markAsDoneTaskHandler = async (id) => {
    let copyShareholders = [...this.state.tasks.shareholders]
    const objTaskIndex = this.state.tasks.shareholders.findIndex(obj => obj.id === id)
    copyShareholders[objTaskIndex].complete = !copyShareholders[objTaskIndex].complete

    this.setState({
      tasks: {
        shareholders: copyShareholders
      }
    })
  }

  onSortTasksEnd = ({ oldIndex, newIndex }) => {
    this.setState(previousState => ({
      tasks: { shareholders: arrayMove(previousState.tasks.shareholders, oldIndex, newIndex) }
    }))
  };

  setSharholdersOnMount = () => {
    this.setState({
      tasks: {
        id: '',
        shareholders: []
      }
    }, () => {
      this.state.workOrder.tasks.map((task) => {
        return this.setState(previousState => ({
          tasks: {
            shareholders: [...previousState.tasks.shareholders, {
              id: task._id,
              name: task.name,
              complete: task.complete ? task.complete : false,
              comments: task.comments,
              objectId: task.objectId,
              description: task.description
            }]
          }
        }))
      })
    })
  }

  handleTaskIdChange = (field, idx) => evt => {
    const { tasks } = this.state
    const newShareholders = tasks.shareholders.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder
      return { ...shareholder, id: this.state.workOrder._id, [field]: evt.target.value }
    })

    this.setState({ tasks: { shareholders: newShareholders } })
  }

  handleTaskIdChangeAutocomplete = (field, idx, value) => {
    var currentObjectId = this.state.objects.filter(obj => {
      return obj.name === value
    })

    const { tasks } = this.state
    const newShareholders = tasks.shareholders.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder
      return { ...shareholder, id: this.state.workOrder._id, [field]: currentObjectId[0] }
    })

    this.setState({ tasks: { shareholders: newShareholders } })
  }

  handleSearchObjectId = (field, value, idx) => {
    const { tasks } = this.state
    const newShareholders = tasks.shareholders.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder
      return { ...shareholder, id: this.state.workOrder._id, [field]: { _id: null, name: value } }
    })

    this.setState({ tasks: { shareholders: newShareholders }, formLoading: true })
    const timer = setTimeout(async () => {
      try {
        const response = await objectService.listV2({
          name: [value]
        })

        this.setState({
          objectIdSearchResult: response.docs,
          formLoading: false
        })
        clearTimeout(timer)
      } catch (error) {
        console.log('Fetching error')
        this.setState({
          formLoading: false
        })
        clearTimeout(timer)
      }
    }, 1000)
  }

  handleTaskIdSelectChange = (field, idx) => value => {
    const { tasks } = this.state
    const newShareholders = tasks.shareholders.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder
      return { ...shareholder, id: this.state.workOrder._id, [field]: { name: value } }
    })

    this.setState({ tasks: { shareholders: newShareholders } })
  }

  handleAddShareholder = () => {
    const { tasks } = this.state
    this.setState({
      tasks: { shareholders: tasks.shareholders.concat([{ id: '' }]) }
    })
  };

  handleRemoveTaskholder = idx => async () => {
    try {
      // await taskService.deleteTask(this.state.workOrder._id)
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({ id: 'task removing', defaultMessage: 'Task Removing fail' })} - upload fail`
      })
    }

    const { tasks } = this.state
    this.setState({
      tasks: { shareholders: tasks.shareholders.filter((s, sidx) => idx !== sidx) }
    })
  };

  fetchObjects = async () => {
    try {
      const objects = await objectService.objectsList()
      const uniqueObjects = _.uniqBy(objects, (x) => {
        return x.name
      })

      this.setState({
        objects: uniqueObjects
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({ id: 'objects', defaultMessage: 'Objects' })} - download error`
      })
    }
  }

  fetchSpecialWork = async (value) => {
    this.setState({
      formLoading: true
    })
    const timer = setTimeout(async () => {
      try {
        const res = await specialWorkService.listV2({
          name: [value],
          limit: 100
        })

        this.setState({
          assignSpecialWork: res.docs,
          formLoading: false
        })
        clearTimeout(timer)
      } catch (error) {
        notification.error({
          message: `${this.props.intl.formatMessage({ id: 'specialwork', defaultMessage: 'Special Work' })} - download error`
        })

        this.setState({
          assignSpecialWork: [],
          formLoading: false
        })
        clearTimeout(timer)
      }
    }, 1000)
  }

  fetchUsersHandler = async (value) => {
    this.setState({
      formLoading: true
    })
    const timer = setTimeout(async () => {
      try {
        const users = await userService.listV2({
          name: [value],
          limit: 100
        })

        this.setState({
          assignUsers: users.docs,
          formLoading: false
        })
        clearTimeout(timer)
      } catch (error) {
        console.log('Fetching error')
        this.setState({
          formLoading: false
        })
        clearTimeout(timer)
      }
    }, 1000)
  }

  updateAssignedUser = (value) => {
    this.setState({
      workOrder: {
        ...this.state.workOrder,
        executedBy: value
      }
    })
  }

  sortList = list => {
    return (
      list &&
      list.sort(sortByallStatus)
    )
  };

  createWorkOrder = async () => {
    const { name, description, recurring, priority, rrule, executedBy, status, specialWork, specialWorkRequired, signatureRequired } = this.state.workOrder
    this.props.form.validateFields(async err => {
      if (!err) {
        try {
          let payload = {
            name,
            description,
            recurring,
            priority,
            rrule,
            status,
            signatureRequired,
            specialWorkRequired,
            specialWork,
            tasks: []
          }

          if (executedBy) {
            payload.executedBy = executedBy._id
          }

          if (this.state.tasks.shareholders.length) {
            this.state.tasks.shareholders.map(async (task) => {
              let payloadTasks = {}
              if (task.name) {
                payloadTasks.name = task.name
                payloadTasks.description = task.description

                if (task.objectId) {
                  var currentObjectId = this.state.objects.filter(obj => {
                    return obj.name === task.objectId.name
                  })
                  payloadTasks.objectId = currentObjectId[0]._id
                }

                payload.tasks.push(payloadTasks)
              }
            })
          }
          const savedWorkOrder = await scheduledWorkOrdersService.addWorkOrder(payload)

          this.fetchWorkOrders()
          notification.success({
            message: `${this.props.intl.formatMessage({ id: 'created successfully', defaultMessage: 'Created successfully' })}`
          })

          this.setState({
            visible: false,
            taskEditMode: false,
            fileUploadMode: false,
            workOrder: {},
            pagination: {
              current: 1
            },
            workOrders: this.sortList([
              ...this.state.workOrders,
              savedWorkOrder
            ])
          })
        } catch (error) {
          notification.error({
            message: this.props.intl.formatMessage({ id: 'saving error', defaultMessage: 'Saving Error' }),
            description: error.message
          })
        }
      }
    })
  }

  updateWorkOrder = async () => {
    const { _id, name, description, recurring, priority, rrule, executedBy, status, specialWork, specialWorkRequired, signatureRequired } = this.state.workOrder
    this.props.form.validateFields(async err => {
      if (!err) {
        try {
          const payload = {
            name,
            description,
            recurring,
            priority,
            rrule,
            status,
            signatureRequired,
            specialWorkRequired,
            specialWork,
            executedBy: executedBy ? executedBy._id : null
          }

          let copyWorkorders = [...this.state.workOrders]
          const objTaskIndex = this.state.workOrders.findIndex(obj => obj._id === this.state.workOrder._id)
          copyWorkorders[objTaskIndex].tasks = []

          if (this.state.tasks.shareholders.length) {
            this.state.tasks.shareholders.forEach((task) => {
              let payloadTasks = {}
              payloadTasks.name = task.name
              payloadTasks.description = task.description
              payloadTasks.objectId = task.objectId

              // if (task.objectId) {
              //   var currentObjectId = this.state.objects.filter(obj => {
              //     return obj.name === task.objectId
              //   })
              //   payloadTasks.objectId = currentObjectId[0]._id
              // }

              payloadTasks.complete = task.complete
              copyWorkorders[objTaskIndex].tasks.push(payloadTasks)
            })
          }
          this.setState({
            workOrders: copyWorkorders
          }, async () => {
            payload.tasks = this.state.workOrder.tasks
            const savedWorkOrder = await scheduledWorkOrdersService.updateWorkOrder(_id, payload)

            this.fetchWorkOrders()
            notification.success({
              message: `${this.props.intl.formatMessage({ id: 'updated successfully', defaultMessage: 'Updated successfully' })}`
            })

            this.setState({
              visible: false,
              taskEditMode: false,
              fileUploadMode: false,
              workOrder: {},
              workOrders: this.sortList([
                ...this.state.workOrders.filter(workOrder => workOrder._id !== savedWorkOrder._id),
                savedWorkOrder
              ])
            })
          })
        } catch (error) {
          notification.error({
            message: this.props.intl.formatMessage({ id: 'saving error', defaultMessage: 'Saving Error' }),
            description: error.message
          })
        }
      }
    })
  }

  deleteWorkOrder = async () => {
    try {
      await scheduledWorkOrdersService.deleteWorkOrder(this.state.workOrder._id)

      this.setState({
        visible: false,
        workOrder: {},
        workOrders: [
          ...this.state.workOrders.filter(workOrder => workOrder._id !== this.state.workOrder._id)
        ]
      })
    } catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({ id: 'deleting error', defaultMessage: 'Deleting Error' }),
        description: error.message
      })
    }
  }

  fetchWorkOrders = async (params) => {
    this.setState({
      loading: true
    })
    try {
      const workOrders = await scheduledWorkOrdersService.list({
        ...params
      })

      const pagination = {
        ...this.state.pagination
      }

      pagination.total = workOrders.totalDocs

      this.setState({
        workOrders: workOrders.docs.sort(sortByallStatus),
        loading: false,
        createdByFilters: workOrders.filterOptions.createdBy,
        executedByFilters: workOrders.filterOptions.executedBy,
        pagination
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({ id: 'recordings', defaultMessage: 'Recordings' })} - download error`
      })

      this.setState({
        workOrders: [],
        visible: false,
        loading: false,
        workOrder: {}
      })
    }
  }

  showModalEdit = async workOrder => {
    this.setSharholdersOnMount()
    this.setState({
      visible: true,
      new: false,
      workOrder,
      modalOption: 'edit'
    })
  }

  showModalAdd = async () => {
    this.setState({
      visible: true,
      new: true,
      workOrder: {},
      modalOption: 'add',
      tasks: {
        id: '',
        shareholders: [{ id: '' }]
      }
    })
  }

  hideModal = () => {
    this.setState({
      visible: false
    })
  }

  showModalComment = (id) => {
    this.setState({
      modalCommentActive: id
    })
  }

  hideModalComment = () => {
    this.setState({
      modalCommentActive: ''
    })
  }

  updateStateWorkOrder = (field, value) => {
    this.setState({
      workOrder: {
        ...this.state.workOrder,
        [field]: value
      }
    })
  }

  updateStateSpecialWork = (value) => {
    const specialWorks = value || []
    let newArraysIds = []

    if (specialWorks.length && this.state.assignSpecialWork.length) {
      specialWorks.forEach(item => {
        var currentObjectId = this.state.assignSpecialWork.filter(obj => {
          return obj.name === item
        })
        newArraysIds.push(currentObjectId[0]._id)
      })
      this.setState({
        workOrder: {
          ...this.state.workOrder,
          specialWork: newArraysIds
        }
      })
    }
  }

  handleReset = (field, clearFilters) => {
    clearFilters()

    this.setState({
      search: {
        ...this.state.search,
        [field]: ''
      }
    })
  }

  onSelectChange = selectedRowKeys => {
    this.setState({
      selectedRowKeys
    })
  }

  onTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    pager.offset = pagination.current

    this.setState({
      pagination: {
        ...pager,
        current: pagination.current
      }
    })

    this.fetchWorkOrders({
      limit: pagination.pageSize,
      offset: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    })
  }

  handleSearch = (selectedKeys, field, confirm) => {
    confirm()
    this.setState({
      search: {
        ...this.state.search,
        [field]: selectedKeys[0]
      }
    })
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => { this[`searchInput${dataIndex}`] = node }}
          placeholder={this.props.intl.formatMessage({ id: 'search', defaultMessage: 'Suchen' })}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Suchen
        </Button>
        <Button
          onClick={() => this.handleReset(dataIndex, clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => String(record[dataIndex]).toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this[`searchInput${dataIndex}`].select())
      }
    }
  })

  render () {
    const columns = [
      {
        title: this.props.intl.formatMessage({ id: 'number', defaultMessage: 'Number' }),
        key: 'docNumber',
        dataIndex: 'docNumber',
        ...this.getColumnSearchProps('docNumber')
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        ...this.getColumnSearchProps('name')
      }, {
        title: this.props.intl.formatMessage({ id: 'description', defaultMessage: 'Description' }),
        dataIndex: 'description',
        key: 'description',
        ...this.getColumnSearchProps('description')
      }, {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status) => {
          let statusText = ''
          switch (status) {
            case 1: {
              statusText = this.props.intl.formatMessage({ id: 'open', defaultMessage: 'Open' })
              break
            }
            case 2: {
              statusText = this.props.intl.formatMessage({ id: 'in progress', defaultMessage: 'In progress' })
              break
            }
            case 3: {
              statusText = this.props.intl.formatMessage({ id: 'on hold', defaultMessage: 'on hold' })
              break
            }
            case 4: {
              statusText = this.props.intl.formatMessage({ id: 'complete', defaultMessage: 'complete' })
              break
            }
            default:
              console.log('no status')
          }
          return (
            <span>{statusText}</span>
          )
        },
        sorter: true
      }, {
        title: this.props.intl.formatMessage({ id: 'created at', defaultMessage: 'createdAt' }),
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
        sorter: true
      },
      {
        title: this.props.intl.formatMessage({ id: 'assignee', defaultMessage: 'Assignee' }),
        key: 'executedBy',
        dataIndex: 'executedBy',
        render: executedBy => executedBy ? `${executedBy.firstName} ${executedBy.lastName}` : '',
        filters: this.state.executedByFilters.map(user => ({
          text: `${user.firstName} ${user.lastName}`,
          value: user._id
        }))
      },
      {
        title: this.props.intl.formatMessage({ id: 'created by', defaultMessage: 'Created By' }),
        key: 'createdBy',
        dataIndex: 'createdBy',
        render: createdBy => createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : '',
        filters: this.state.createdByFilters.map(user => ({
          text: `${user.firstName} ${user.lastName}`,
          value: user._id
        }))
      }, {
        title: this.props.intl.formatMessage({ id: 'starts at', defaultMessage: 'Starts at' }),
        key: 'startDate',
        dataIndex: 'startDate',
        render: (text, record) => {
          if (record.startDate) {
            return moment(record.startDate).format('DD.MM.YYYY, HH:mm')
          }
          return ''
        },
        sorter: true
      }, {
        key: 'actions',
        render: (text, record) => (
          <div style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-end'
          }}>
            <Button onClick={() => this.showModalEdit(record)} >
              <Icon
                type={(record.isVerified || record.isInvalid) ? 'info-circle' : 'edit'}
              />
            </Button>
          </div>
        )
      }]

    const {
      workOrders,
      // selectedRowKeys,
      visible,
      modalOption,
      workOrder,
      assignUsers,
      objects,
      assignSpecialWork,
      taskEditMode,
      fileUploadMode,
      modalCommentActive,
      formLoading
    } = this.state

    const headerButtons = [
      <Button
        key={526362332235}
        type="primary"
        onClick={this.showModalAdd}
      >
        <FormattedMessage
          id="create scheduled workorder"
          defaultMessage="Create scheduled workorder"
        />
      </Button>
    ]

    const footerDrawerButtons = [
      <Button onClick={this.onClose} style={{ marginRight: 8 }}>
        <FormattedMessage
          id="cancel"
          defaultMessage="Cancel"
        />
      </Button>
    ]

    if (['admin', 'superadmin'].includes(this.props.auth.auth_user.scope[0]) &&
      this.state.workOrder && !this.state.new) {
      footerDrawerButtons.unshift(
        <PopconfirmDelete onConfirmAction={this.deleteWorkOrder} />
      )
    }

    if (this.state.new) {
      footerDrawerButtons.push(
        <Button
          onClick={this.createWorkOrder} type="primary">
          {this.props.intl.formatMessage({ id: 'save workorder', defaultMessage: 'Save workorder' })}
        </Button>
      )
    } else {
      footerDrawerButtons.push(
        <Button
          onClick={this.updateWorkOrder} type="primary">
          {this.props.intl.formatMessage({ id: 'update workorder', defaultMessage: 'Update workorder' })}
        </Button>
      )
    }

    return (
      <div>
        <FormattedMessage id="head.title.workorders" defaultMessage="Workorder planning">
          {title => <Helmet><title>{title}</title></Helmet>}
        </FormattedMessage>

        <PageTitle
          title={this.props.intl.formatMessage({ id: 'workorder planning', defaultMessage: 'Workorder planning' })}
          buttons={headerButtons}
        />

        <Table
          // eslint-disable-next-line no-return-assign
          ref={ref => this._table = ref}
          rowKey={record => record._id}
          columns={columns}
          dataSource={workOrders}
          loading={this.state.loading}
          onRow={(record) => ({
            onDoubleClick: () => { this.showDrawer(record) }
          })}
          onChange={this.onTableChange}
          pagination={this.state.pagination}
        />

        <DrawerComponent
          title={this.state.new === true ? this.props.intl.formatMessage({ id: 'create a new scheduled Workorder', defaultMessage: 'Create a new scheduled Workorder' }) : this.props.intl.formatMessage({ id: 'edit workorder', defaultMessage: 'Edit workorder' })}
          onClose={this.onClose}
          visible={visible}
          footerButtons={footerDrawerButtons}
        >
          <WorkOrderForm
            scheduled={true}
            isNew={modalOption}
            workOrder={workOrder}
            users={assignUsers}
            objects={objects}
            formLoading={formLoading}
            fetchSpecialWork={this.fetchSpecialWork}
            fetchUsersHandler={this.fetchUsersHandler}
            updateAssignedUser={this.updateAssignedUser}
            objectIdSearchResult={this.state.objectIdSearchResult}
            specialWork={assignSpecialWork}
            taskEditMode={taskEditMode}
            toggleTaskEditMode={this.toggleTaskEditMode}
            fileUploadMode={fileUploadMode}
            toggleFileuploadMode={this.toggleFileuploadMode}
            updateStateWorkOrder={this.updateStateWorkOrder}
            updateStateSpecialWork={this.updateStateSpecialWork}
            option={modalOption}
            shareholders={this.state.tasks.shareholders}
            taskIdChange={this.handleTaskIdChange}
            handleTaskIdSelectChange={this.handleTaskIdSelectChange}
            handleTaskIdChangeAutocomplete={this.handleTaskIdChangeAutocomplete}
            handleSearchObjectId={this.handleSearchObjectId}
            addShareholder={this.handleAddShareholder}
            removeTaskholder={this.handleRemoveTaskholder}
            markAsDoneTask={this.markAsDoneTaskHandler}
            onSortTasksEnd={this.onSortTasksEnd}
            showModalComment={this.showModalComment}
            hideModalComment={this.hideModalComment}
            form={this.props.form}
            modalCommentActive={modalCommentActive}
          />
        </DrawerComponent>
      </div>
    )
  }
}

const sortByallStatus = (a, b) => {
  return (a.status - b.status)
}

ScheduledWorkordersPage.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.authReducer
})

export default injectIntl(Form.create({ name: 'work_orders_form' })(connect(mapStateToProps)(ScheduledWorkordersPage)))
