import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import contactService from 'services/contact'
import { Button, Modal, notification, Select, Spin, Table } from 'antd'
import defectsService from 'services/defects'
import { connect } from 'react-redux';

const { Option } = Select

const statusData = ['open', 'in progress', 'completed'];

class DefectForm extends React.Component {
  state = {
    contacts: [],
  }

  saveItem = async (item) => {
    const { intl } = this.props;
    try {
      const savedItem = await defectsService.save(item);
      if (savedItem._id) {

        return savedItem
      }
    }
    catch (error) {
      notification.error({
        message: intl.formatMessage({
          id: 'saving error',
          defaultMessage: 'Saving Error'
        }),
        description: error.error
      })
      return error

    }
  }

  fetchData = async (value, key) => {
    this.setState({
      formLoading: true
    })

    const timer = setTimeout(async () => {
      try {
        let data
        if (key === 'contacts') {
          data = await contactService.listV2({
            name: [value],
            limit: 100
          })
        }
        this.setState({
          [key]: data.docs || data,
          formLoading: false
        })

        clearTimeout(timer)
      }
      catch (error) {
        this.setState({
          [key]: [],
          formLoading: false
        })
        clearTimeout(timer)
      }
    })
  }


  handlePick = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  submitAssignment = async () => {
    const {
      selectedRowObjects,
      finishSubmitting,
      intl,
    } = this.props
    const {
      contacts,
      assignedTo,
      status
    } = this.state;

    const result = await Promise.all(selectedRowObjects.map(item => {
      if (assignedTo) {
        item.assignedTo = contacts.find(item => item._id === assignedTo)
      }
      if (status) {
        item.status = status
      }
      return this.saveItem(item)
    }))

    if (!result.some(item => !item || item.error)) {
      finishSubmitting(true)
      notification.success({
        message: `${intl.formatMessage({
          id: 'updated successfully',
          defaultMessage: 'Updated successfully'
        })}`
      })
      this.setState({
        status: null,
        assignedTo: null
      })
    } else {
      notification.success({
        message: `${intl.formatMessage({
          id: 'saving error',
          defaultMessage: 'Updating error'
        })}`
      })
    }
  }

  render() {
    const {
      assignToModalVisible,
      selectedRowObjects,
      finishSubmitting,
      intl,
      objectName,
    } = this.props

    const {
      contacts,
      formLoading,
      assignedTo,
      status
    } = this.state;

    const columns = [
      {
        title: intl.formatMessage({ id: 'number', defaultMessage: 'Number' }),
        key: 'docNumber',
        dataIndex: 'docNumber',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: objectName,
        dataIndex: 'objectId',
        key: 'objectId',
        render: (text, record) => {
          if (record.objectId && record.objectId.name) {
            return record.objectId.name
          }

          return ''
        },
      },
      {
        title: 'Zugewiesen an',
        dataIndex: 'assignedTo',
        key: 'assignedTo',
        render: (text, record) => {
          if (record.assignedTo) {
            return record.assignedTo.company
          }

          return ''
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text, record) => {
          if (record.status) {
            return record.status
          }
          return ''
        },
      },
    ];

    const updatedRowObjects = selectedRowObjects.map(item => ({
      ...item,
      assignedTo : assignedTo ? contacts.find(item => item._id === assignedTo) : item.assignedTo,
      status: status ? status : item.status
    }))

    return (
      <Modal
        title={
          <FormattedMessage
            id="bulk change button"
            defaultMessage="Bulk change"
          />
        }
        visible={assignToModalVisible}
        width={'93%'}
        style={{ maxWidth: 800 }}
        onCancel={finishSubmitting}
        destroyOnClose
        footer={[
          <Button
            key="back"
            onClick={finishSubmitting}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={this.submitAssignment}
          >
            Submit
          </Button>
        ]}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            // alignItems: 'center',
            paddingTop: 50
          }}
        >
          <Table
            columns={columns}
            size="small"
            style={{ width: '100%', }}
            dataSource={updatedRowObjects}
            pagination={false}
          />
          <div style={{
            marginTop: 30,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center'
          }}>
            <p style={{ fontWeight: 'semi-bold', fontSize: 16, marginBottom: 0 }}>
              {intl.formatMessage({
                id: 'change assignment',
                defaultMessage: 'Change assignment'
              })}
            </p>
            <Select
              showSearch
              allowClear
              placeholder={intl.formatMessage({
                id: 'choose a new contact',
                defaultMessage: 'Choose new contact'
              })}
              style={{ width: '50%' }}
              optionFilterProp="children"
              notFoundContent={formLoading ?
                <div className="objects-spinner-container"><Spin size="small"/></div> : null}
              onFocus={() => this.fetchData('', 'contacts')}
              onSearch={value => this.fetchData(value, 'contacts')}
              onChange={value => this.handlePick('assignedTo', value)}
            >
              {
                contacts.map(element => (
                  <Option key={element._id} value={element._id}>{element.company}</Option>
                ))
              }
            </Select>
          </div>

          <div style={{
            marginTop: 16,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'flex-start'
          }}>
            <p style={{ fontWeight: 'semi-bold', fontSize: 16, marginBottom: 0 }}>
              {
                intl.formatMessage({
                  id: 'change status',
                  defaultMessage: 'Change status'
                })
              }
            </p>
            <Select
              allowClear
              placeholder={intl.formatMessage({
                id: 'choose a new status',
                defaultMessage: 'Choose  new status'
              })}
              style={{ width: '50%' }}
              optionFilterProp="children"
              onChange={value => this.handlePick('status', value)}
            >
              {
                statusData.map(element => (
                  <Option key={element} value={element}>{element}</Option>
                ))
              }
            </Select>
          </div>
        </div>
      </Modal>
    )
  }
}

DefectForm.propTypes = {
  defect: PropTypes.object.isRequired,
  updateItem: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  return {
    objectName: state.settingsReducer.data.buildx.objectName,
  }
}
export default injectIntl(connect(mapStateToProps)(DefectForm))
