import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { FormattedMessage, injectIntl } from 'react-intl'

import { Form, Input, DatePicker, Select, Spin } from 'antd'

const Informations = props => (
  <React.Fragment>
    <h3>
      <FormattedMessage
        id="informations"
        defaultMessage="Informations"
      />
    </h3>

    <Form.Item {...props.formItemLayout} label={props.intl.formatMessage({
      id: 'title',
      defaultMessage: 'Title'
    })}>
      <Input
        disabled={props.readOnly}
        value={props.recording.name}
        maxLength={40}
        onChange={event => props.changeData('name', event.target.value)}
      />
    </Form.Item>

    <Form.Item {...props.formItemLayout} label={props.intl.formatMessage({
      id: 'project',
      defaultMessage: 'Project'
    })}>
      <Select
        showSearch
        allowClear
        disabled={props.readOnly}
        style={{ width: '100%' }}
        optionFilterProp="children"
        notFoundContent={props.formLoading ?
          <div className="objects-spinner-container"><Spin size="small"/></div> : null}
        onFocus={() => props.fetchProjectsOnSearch('')}
        onSearch={value => props.fetchProjectsOnSearch(value)}
        onChange={projectID => props.changeData('project',
          props.projects.find(item => item._id === projectID))}
        defaultValue={props.recording.project ? props.recording.project.projectName : ''}
      >
        {props.projects && props.projects.map(project => (
          <Select.Option key={project._id} value={project._id}>{project.projectName}</Select.Option>
        ))}
      </Select>
    </Form.Item>

    <Form.Item {...props.formItemLayout} label={props.intl.formatMessage({
      id: 'date',
      defaultMessage: 'Date'
    })}>
      <DatePicker
        disabled={props.readOnly}
        allowClear={false}
        showTime={true}
        value={moment(props.recording.date)}
        format="DD.MM.YYYY HH:mm"
        onChange={date => props.changeData('date', date.format())}
      />
    </Form.Item>

    <Form.Item {...props.formItemLayout} label={props.intl.formatMessage({
      id: 'created by',
      defaultMessage: 'Created By'
    })}>
      <span className="ant-form-text">
        {!!props.recording.createdBy && props.recording.createdBy.fullName}
      </span>
    </Form.Item>
  </React.Fragment>
)

Informations.propTypes = {
  formItemLayout: PropTypes.object.isRequired,
  recording: PropTypes.object.isRequired,
  readOnly: PropTypes.bool
  // changeData: PropTypes.func.isRequired,
}

export default injectIntl(Informations)
