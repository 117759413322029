import React from 'react'
import H1 from 'components/Global/H1'
import PropTypes from 'prop-types'

const PageTitle = (props) => {
  return (
    <div style={{
      display: 'flex',
      padding: 10,
      marginBottom: '30px'
    }}
    >
      <H1
        title={props.title}
      />

      {props.buttons}
    </div>
  )
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  buttons: PropTypes.array
}

export default PageTitle
