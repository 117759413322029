import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import { Table, notification, Icon, Button, Form, Input } from 'antd'
import protocolService from 'services/protocol'

import PageTitle from 'components/Global/PageTitle'

import './styles.scss'

export class ProtocolPage extends React.Component {
  state = {
    items: [],
    loading: false,
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50', '100']
    }
  }

  componentDidMount () {
    this.fetchProtocol()
  }

  componentDidUpdate (prevProps) {
    if (this.props.project !== prevProps.project) {
      this.fetchProtocol()
    }
  }

  fetchProtocol = async (params) => {
    this.setState({
      loading: true
    })
    try {
      const protocol = await protocolService.listV2({
        ...params
      })

      const pagination = {
        ...this.state.pagination
      }

      pagination.total = protocol.totalDocs

      this.setState({
        items: protocol.docs,
        loading: false,
        pagination
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({ id: 'protocol', defaultMessage: 'Protocol' })} - download error`
      })

      this.setState({
        visibleDrawer: false,
        loading: false
      })
    }
  }

  onTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    pager.offset = pagination.current

    this.setState({
      pagination: {
        ...pager,
        current: pagination.current
      }
    })

    this.fetchProtocol({
      limit: pagination.pageSize,
      offset: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    })
  }

  handleSearch = (selectedKeys, field, confirm) => {
    confirm()
    this.setState({
      search: {
        ...this.state.search,
        [field]: selectedKeys[0]
      }
    })
  }

  handleReset = (field, clearFilters) => {
    clearFilters()

    this.setState({
      search: {
        ...this.state.search,
        [field]: ''
      }
    })
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => { this[`searchInput${dataIndex}`] = node }}
          placeholder={this.props.intl.formatMessage({ id: 'search', defaultMessage: 'Suchen' })}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Suchen
        </Button>
        <Button
          onClick={() => this.handleReset(dataIndex, clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => String(record[dataIndex]).toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this[`searchInput${dataIndex}`].select())
      }
    }
  })

  render () {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        ...this.getColumnSearchProps('name')
      },
      {
        title: this.props.intl.formatMessage({ id: 'created at', defaultMessage: 'CreatedAt' }),
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
        sorter: true
      },
      {
        title: this.props.intl.formatMessage({ id: 'event', defaultMessage: 'Event' }),
        dataIndex: 'event',
        key: 'event',
        sorter: true
      },
      {
        title: this.props.intl.formatMessage({ id: 'description', defaultMessage: 'Description' }),
        dataIndex: 'description',
        key: 'description',
        sorter: true
      },
      {
        title: this.props.intl.formatMessage({ id: 'endpoint', defaultMessage: 'Endpoint' }),
        dataIndex: 'endpoint',
        key: 'endpoint',
        sorter: true
      },
      {
        title: this.props.intl.formatMessage({ id: 'created by', defaultMessage: 'CreatedBy' }),
        dataIndex: 'createdBy',
        key: 'createdBy',
        sorter: true,
        render: (text, record) => {
          if (record.createdBy) {
            return record.createdBy.fullName
          }

          return ''
        }
      }
    ]

    if (!this.props.project) {
      columns.push({
        title: this.props.intl.formatMessage({ id: 'project', defaultMessage: 'Project' }),
        key: 'project',
        dataIndex: 'project.projectName'
      })
    }

    return (
      <div>
        <FormattedMessage id="head.title.protocol" defaultMessage="Protocol">
          {title => <Helmet><title>{title}</title></Helmet>}
        </FormattedMessage>

        <PageTitle
          title={this.props.intl.formatMessage({ id: 'protocol', defaultMessage: 'Protocol' })}
        />

        <Table
          // eslint-disable-next-line no-return-assign
          ref={ref => this._table = ref}
          columns={columns}
          dataSource={this.state.items}
          loading={this.state.loading}
          rowKey={record => record._id}
          onChange={this.onTableChange}
          pagination={this.state.pagination}
        />
      </div>
    )
  }
}

ProtocolPage.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.authReducer,
  projects: state.projectReducer.projects,
  project: state.projectReducer.project
})

export default injectIntl(Form.create({ name: 'specialwork_page' })(connect(mapStateToProps)(ProtocolPage)))
