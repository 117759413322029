import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import moment from 'moment'
import { Form, Input, DatePicker, Radio, Icon, Select, Switch, Spin } from 'antd'
import AddButton from 'components/WorkOrders/AddButton'
import '../styles.scss'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
  className: 'formItem'
}

const { Option } = Select

class Informations extends React.Component {
  renderSelect = (array) => {
    // eslint-disable-next-line no-unused-vars
    let key = 0
    const onlyEnabled = array.filter(item => item.enabled === true)
    const selectItems = onlyEnabled.map((item) => {
      key += 1
      return (<Option key={item._id} id={item._id} value={item.name}>{item.name}</Option>)
    })

    const specialWork = this.props.workOrder.specialWork || []
    const specialWorkNames = specialWork.map(item => {
      return item.name
    })

    return (
      <Select
        mode="multiple"
        autoClearSearchValue={true}
        defaultValue={specialWorkNames}
        notFoundContent={this.props.formLoading ? <div className="workorder-spinner-container"><Spin size="small" /></div> : null}
        style={{ width: '100%' }}
        placeholder={this.props.intl.formatMessage({ id: 'choose special work', defaultMessage: 'Choose special work' })}
        onFocus={() => this.props.fetchSpecialWork('')}
        onSearch={(value) => this.props.fetchSpecialWork(value)}
        onChange={(value, e) => this.assignSpecialWorkHandler(value, e)}
      >
        {
          selectItems
        }
      </Select>
    )
  }

  assignSpecialWorkHandler = (value, e) => {
    this.props.updateStateSpecialWork(value)
  }

  onPriorityChanged = (e) => {
    let priority = 0
    switch (e.target.value) {
      case 'low':
        priority = 1
        break
      case 'medium':
        priority = 2
        break
      case 'high':
        priority = 3
        break
      default:
        priority = 0
    }

    this.props.updateStateWorkOrder('priority', priority)
  }

  setPriorityDefaultValue = () => {
    switch (this.props.workOrder.priority) {
      case 0:
        return 'none'
      case 1:
        return 'low'
      case 2:
        return 'medium'
      case 3:
        return 'high'
      default:
        return 'none'
    }
  }

  setWorkOrderStatus = () => {
    switch (this.props.workOrder.status) {
      case 0:
        return 'none'
      case 1:
        return 'open'
      case 2:
        return 'in progress'
      case 3:
        return 'on hold'
      case 4:
        return 'complete'
      default:
        return 'none'
    }
  }

  assignUserHandler = (value) => {
    if (value === undefined) {
      this.props.updateAssignedUser(null)
      return false
    }
    this.props.updateAssignedUser(value.props.data)
  }

  renderUserSelect = () => {
    return (
      <Select
        showSearch
        allowClear={true}
        value={this.props.workOrder.executedBy && this.props.workOrder.executedBy.hasOwnProperty('fullName') ? `${this.props.workOrder.executedBy.fullName} ( ${this.props.workOrder.executedBy.email} )` : ''}
        style={{ width: '100%' }}
        optionFilterProp="children"
        notFoundContent={this.props.formLoading ? <div className="workorder-spinner-container"><Spin size="small" /></div> : null}
        onSearch={this.props.fetchUsersHandler}
        onChange={(value, e) => this.assignUserHandler(e)}
        onFocus={() => this.props.fetchUsersHandler('')}
        filterOption={(input, option) => {
          const cuttedValue = option.props.value.substring(0, option.props.value.indexOf('('))
          return cuttedValue.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        }
      >
        {
          this.props.users.map((user, i) => (<Option key={i} data={user} value={`${user.fullName} ( ${user.email} )`}>{`${user.fullName} (${user.email})`}</Option>))
        }
      </Select>
    )
  }

  render () {
    const { action, showFileupload, workOrder } = this.props

    const { getFieldDecorator } = this.props.form

    return (<React.Fragment>
      <Form.Item {...formItemLayout} label="Status">
        <Select defaultValue={this.setWorkOrderStatus(workOrder.status)} style={{ width: 120 }} onChange={value =>
          this.props.updateStateWorkOrder('status', value)
        }>
          <Option value="1">{this.props.intl.formatMessage({ id: 'open', defaultMessage: 'Open' })}</Option>
          <Option value="2">{this.props.intl.formatMessage({ id: 'in progress', defaultMessage: 'In progress' })}</Option>
          <Option value="3">{this.props.intl.formatMessage({ id: 'on hold', defaultMessage: 'on hold' })}</Option>
          <Option value="4">{this.props.intl.formatMessage({ id: 'complete', defaultMessage: 'complete' })}</Option>
        </Select>
      </Form.Item>

      <Form.Item {...formItemLayout} label="Name">
        {getFieldDecorator('name', {
          initialValue: this.props.workOrder.name,
          rules: [ {
            required: true,
            message: this.props.intl.formatMessage({ id: 'name is required', defaultMessage: 'Name is required' })
          } ]
        })(
          <Input
            onChange={event =>
              this.props.updateStateWorkOrder('name', event.target.value)
            }
          />
        )}
      </Form.Item>

      <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: 'description', defaultMessage: 'Description' })}>
        <Input.TextArea
          value={this.props.workOrder.description}
          onChange={event =>
            this.props.updateStateWorkOrder('description', event.target.value)
          }
        />
      </Form.Item>

      <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: 'assignee', defaultMessage: 'Assignee' })}>
        {
          this.renderUserSelect()
        }
      </Form.Item>

      <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: 'priority', defaultMessage: 'Priority' })}>
        <Radio.Group defaultValue={this.setPriorityDefaultValue()} buttonStyle="solid">
          <Radio.Button
            active
            value="none"
            onChange={(e) => this.onPriorityChanged(e)}
          >
            {this.props.intl.formatMessage({ id: 'none', defaultMessage: 'none' })}
          </Radio.Button>
          <Radio.Button
            value="low"
            onChange={(e) => this.onPriorityChanged(e)}
          >
            <Icon type="arrow-down" />
            {this.props.intl.formatMessage({ id: 'low', defaultMessage: 'low' })}
          </Radio.Button>
          <Radio.Button
            value="medium"
            onChange={(e) => this.onPriorityChanged(e)}
          >
            <Icon type="arrow-down" />
            {this.props.intl.formatMessage({ id: 'medium', defaultMessage: 'medium' })}
          </Radio.Button>
          <Radio.Button
            value="high"
            onChange={(e) => this.onPriorityChanged(e)}
          >
            <Icon type="arrow-up" />
            {this.props.intl.formatMessage({ id: 'high', defaultMessage: 'high' })}
          </Radio.Button>
        </Radio.Group>
      </Form.Item>

      <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: 'special work required', defaultMessage: 'Special work required' })}>
        <Switch
          checked={this.props.workOrder.specialWorkRequired}
          onChange={(value) => this.props.updateStateWorkOrder('specialWorkRequired', value)}
        />
      </Form.Item>

      {this.props.workOrder.specialWorkRequired &&
        <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: 'select special work', defaultMessage: 'Select Special work' })}>
          {
            this.renderSelect(this.props.specialWork)
          }
        </Form.Item>
      }

      <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: 'due date', defaultMessage: 'Due Date' })}>
        <DatePicker
          disabledDate={disabledDate}
          allowClear={false}
          showTime={true}
          value={moment(this.props.workOrder.date)}
          format="DD.MM.YYYY HH:mm"
          onChange={date => this.props.updateStateWorkOrder('date', date.format())}
        />
      </Form.Item>

      <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: 'signature required', defaultMessage: 'Signature required' })}>
        <Switch
          checked={this.props.workOrder.signatureRequired}
          defaultChecked onChange={(value) => this.props.updateStateWorkOrder('signatureRequired', value)} />
      </Form.Item>

      <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: 'archive', defaultMessage: 'Archive' })}>
        <Switch
          checked={this.props.workOrder.archived}
          defaultChecked onChange={(value) => this.props.updateStateWorkOrder('archived', value)} />
      </Form.Item>

      <AddButton
        title={this.props.intl.formatMessage({ id: 'add tasks', defaultMessage: 'Add Tasks' })}
        action={action}
        avatar={<Icon type="solution" theme="outlined" style={{ fontSize: '33px', color: '#196b9e' }} />}
        description={this.props.intl.formatMessage({ id: 'add tasks description', defaultMessage: 'Add tasks to this workorder and assign them to a technician.' })} />

      <AddButton
        title={this.props.intl.formatMessage({ id: 'add files', defaultMessage: 'Add Files' })}
        action={showFileupload}
        avatar={<Icon type="file-add" theme="outlined" style={{ fontSize: '33px', color: '#196b9e' }} />}
        description={this.props.intl.formatMessage({ id: 'add files description', defaultMessage: 'Attach files to this workorder.' })} />

    </React.Fragment>)
  }
}

function disabledDate (current) {
  // Can not select days before today and today
  return current && current < moment().endOf('day').subtract(1, 'days')
}

export default injectIntl(connect()(Informations))
