import React from 'react'
import { injectIntl } from 'react-intl'
import { Form, Input, InputNumber, Select } from 'antd'

const EditableContext = React.createContext()

class EditableCell extends React.Component {
  changeValue = (value) => {

    if (this.props.inputType === "TextInput") {
      this.props.onEditingValueChange(value.target.value, this.props.record)
    } else {
      this.props.onEditingValueChange(value, this.props.record)
    }
  }

  renderCell = form => {
    const {
      editing,
      record,
      editingValue,
      children,
      inputType,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {
              inputType !== "TextInput" && inputType !== "Dropdown" &&
              <InputNumber type="number" value={editingValue} onChange={this.changeValue}/>
            }

            {
              inputType === "TextInput" &&
              <Input value={editingValue} onChange={this.changeValue}/>
            }
            {
              inputType === "Dropdown" &&
              <Select
                style={{ width: 100 }}
                // placeholder={props.intl.formatMessage({ id: 'select a value', defaultMessage: 'Select a value' })}
                onChange={this.changeValue}
                value={record.options[Number(editingValue)].text}
              >
                {record.options.map(option => (
                  <Select.Option key={option._id} value={option.value}>{option.text}</Select.Option>
                ))}
              </Select>
            }
          </Form.Item>
        ) : (
          children
        )}
      </td>
    )
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
  }
}

export default injectIntl(EditableCell)
