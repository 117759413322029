import store from '../store'
import config from 'config'
import axios from '../helpers/axios'

export default {
  async list (params = {}) {
    return axios.get('/defect', params)
  },

  async listV2 (params = {}) {
    const projectID = store.getState().projectReducer.project
    if (projectID) {
      params.project = projectID._id
    }
    return axios.get(config.server.host + '/1.1/defect', params)
  },

  async submit (defectID) {
    return axios.post(`/defect/${defectID}/submit`)
  },

  async save (item) {
    const {
      _id,
      updatedAt,
      createdBy,
      createdAt,
      attributes,
      deleted,
      description,
      docNumber,
      submissions,
      images,
      files,
      __v,
      ...rest
    } = item;

    const params = { ...rest };

    if (item.recording && item.recording._id) {
      params.recording = item.recording._id
    }

    if (item.objectId && item.objectId._id) {
      params.objectId = item.objectId._id
    }

    if (item.assignedTo && item.assignedTo.company) {
      params.assignedTo = item.assignedTo._id
    }

    if (item.contractSection && item.contractSection._id) {
      params.contractSection = item.contractSection._id
    }

    if (item.project && item.project._id) {
      params.project = item.project._id
    }

    if (item.protocolResponsible && item.protocolResponsible._id) {
      params.protocolResponsible = item.protocolResponsible._id
    }

    if (item._id) {
      return axios.put(`/defect/${item._id}`, params)
    } else {
      const projectID = store.getState().projectReducer.project
      if (projectID && !params.project) {
        params.project = projectID._id
      }
      return axios.post('/defect', params)
    }
  },

  async completeTask (id, data = {}) {
    const response = await axios.post(`/defect/${id}/complete`, data)
    return response
  },

  async deleteTask (id) {
    const response = await axios.delete(`/defect/${id}`)
    return response
  },

  async updateTask (id, data) {
    const response = await axios.put(`/defect/${id}`, data)
    return response
  },

  async addComment (id, data) {
    const response = await axios.post(`/defect/${id}/comment`, data)
    return response
  },

  async addImage (id, data) {
    const response = await axios.post(`/defect/${id}/image`, data)
    return response
  },

  async addFile (id, data) {
    const response = await axios.post(`/defect/${id}/file`, data)
    return response
  }
}
