import React from 'react'
import PropTypes from 'prop-types'

const H1 = (props) => {
  return (
    <h1 style={{ fontSize: '2.5rem', flexGrow: 1 }}>{props.title}</h1>
  )
}

H1.propTypes = {
  title: PropTypes.string.isRequired
}

export default H1
