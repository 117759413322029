import React from 'react'
import { injectIntl } from 'react-intl'
import { Card } from 'antd'

const { Meta } = Card;

class AddButton extends React.Component {
    render () {
        return <React.Fragment>
            <Card style={{ marginTop: 16 }} className={'workorder-add-button'} onClick={this.props.action} >
                <Meta
                    avatar={this.props.avatar}
                    title={this.props.title}
                    description={this.props.description}
                />
            </Card>
        </React.Fragment >;
    }
}

export default injectIntl(AddButton)

