import React from 'react'
import moment from 'moment'
import { Button, Form, Icon, notification, Popconfirm, Table } from 'antd'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { injectIntl, FormattedMessage } from 'react-intl'

import PageTitle from 'components/Global/PageTitle'
import ProjectForm from 'components/Project/Form'
import DrawerComponent from 'components/Drawer'

import projectService from 'services/project'
import userService from 'services/user'
import formService from 'services/form'
import reduxActions from 'actions'

export class ProjectsPage extends React.Component {
  state = {
    users: [],
    forms: [],
    projects: [],
    loading: false,
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: [ '10', '20', '30', '50', '100' ]
    },
    drawer: false,
    project: {},
    archivedFilters: []
  }

  componentDidMount () {
    this.fetchProjects()
  }

  fetchProjects = async (params) => {
    this.setState({
      loading: true
    })
    try {
      const projects = await projectService.listV2({
        ...params
      })
      this.props.dispatch(reduxActions.setProjects(projects.docs))

      const pagination = {
        ...this.state.pagination
      }

      pagination.total = projects.totalDocs

      this.setState({
        projects: projects.docs,
        loading: false,
        pagination
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({ id: 'projects', defaultMessage: 'Projects' })} - download error`
      })

      this.setState({
        projects: [],
        loading: false
      })
    }
  }

  fetchUsers = async (value) => {
    const timer = setTimeout(async () => {
      try {
        const users = await userService.listV2({
          name: [ value ],
          limit: 100
        })

        this.setState({
          users: users.docs
        })
        clearTimeout(timer)
      } catch (error) {
        notification.error({
          message: `${this.props.intl.formatMessage({ id: 'users', defaultMessage: 'Users' })} - download error`
        })

        this.setState({
          users: []
        })
        clearTimeout(timer)
      }
    }, 1000)
  }

  fetchForms = async (value) => {
    try {
      const forms = await formService.listV2({
        name: [ value ],
        limit: 100
      })

      this.setState({
        forms: forms.docs
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({ id: 'forms', defaultMessage: 'Forms' })} - download error`
      })

      this.setState({
        forms: []
      })
    }
  }

  showDrawer = project => {
    this.setState({
      drawer: true,
      project: {
        ...project
      }
    })
  }

  hideDrawer = () => {
    this.setState({
      drawer: false,
      project: {}
    })
  }

  saveProject = async () => {
    this.props.form.validateFields(async err => {
      if (!err) {
        try {
          const savedProject = await projectService.save(this.state.project)

          this.fetchProjects()
          if (this.state.project._id) {
            notification.success({
              message: `${this.props.intl.formatMessage({ id: 'updated successfully', defaultMessage: 'Updated successfully' })}`
            })
          } else {
            notification.success({
              message: `${this.props.intl.formatMessage({ id: 'created successfully', defaultMessage: 'Created successfully' })}`
            })
          }

          const updatedProjects = [
            ...this.props.projects.filter(project => project._id !== savedProject._id),
            savedProject
          ]

          this.props.dispatch(reduxActions.setProjects(updatedProjects))

          this.setState({
            drawer: false,
            project: {}
          })

          this.props.dispatch(reduxActions.setProjectLastUpdated(moment().format()))
        } catch (error) {
          notification.error({
            message: this.props.intl.formatMessage({ id: 'saving error', defaultMessage: 'Saving error' }),
            description: error.error
          })
        }
      }
    })
  }

  archiveProject = async archived => {
    try {
      const savedProject = await projectService.save({
        ...this.state.project,
        archived: archived
      })

      this.setState({
        drawer: false,
        project: {},
        projects: [
          ...this.state.projects.filter(project => project._id !== savedProject._id),
          savedProject
        ]
      })

      this.props.dispatch(reduxActions.setProjectLastUpdated(moment().format()))
    } catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({ id: 'saving error', defaultMessage: 'Saving error' }),
        description: error.error
      })
    }
  }

  updateProject = (field, value) => {
    if (field=== 'custom_forms.acceptance'){
      this.setState(
        state => ({
          project: {
            ...state.project,
            custom_forms:{
              ...this.state.custom_forms,
              acceptance: value,
            }
          }
        })
      );
      return
    }
    this.setState(
      state => ({
        project: {
          ...state.project,
          [field]: value
        }
      })
    )
  }

  onTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    pager.offset = pagination.current

    this.setState({
      pagination: {
        ...pager,
        current: pagination.current
      }
    })

    this.fetchProjects({
      limit: pagination.pageSize,
      offset: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    })
  }

  render () {
    const columns = [
      {
        title: 'Name',
        key: 'projectName',
        dataIndex: 'projectName',
        defaultSortOrder: 'ascend',
        sorter: true
        // sorter: (a, b) => {
        //   if (a.projectName < b.projectName) { return -1 }
        //   if (a.projectName > b.projectName) { return 1 }
        //   return 0
        // }
      }, {
        title: this.props.intl.formatMessage({ id: 'created at', defaultMessage: 'Created At' }),
        key: 'createdAt',
        dataIndex: 'createdAt',
        render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
        sorter: true
        // sorter: (a, b) => {
        //   return new Date(b.createdAt) - new Date(a.createdAt)
        // }
      }, {
        title: this.props.intl.formatMessage({ id: 'created by', defaultMessage: 'Created By' }),
        key: 'createdBy',
        dataIndex: 'createdBy',
        render: (text, record) => {
          if (record.createdBy) {
            return record.createdBy.fullName
          }

          return ''
        }
      }, {
        title: this.props.intl.formatMessage({ id: 'archived', defaultMessage: 'Archived' }),
        key: 'archived',
        dataIndex: 'archived',
        align: 'center',
        render: archived => (
          <React.Fragment>
            {archived && (
              <Icon type="check" style={{ color: '#005591' }} />
            )}
          </React.Fragment>
        ),
        filters: [
          {
            text: this.props.intl.formatMessage({ id: 'archived', defaultMessage: 'Archived' }),
            value: true
          },
          {
            text: this.props.intl.formatMessage({ id: 'unarchived', defaultMessage: 'Unarchived' }),
            value: false
          }
        ],
        onFilter: (value, record) => {
          if (value === 'archived') {
            return record.archived
          } else if (value === 'unarchived') {
            return !record.archived
          }

          return false
        },
        filteredValue: this.state.archivedFilters
      }, {
        key: 'actions',
        align: 'right',
        render: (text, record) => (
          <Button onClick={() => this.showDrawer(record)}>
            <Icon type={record.archived ? 'info-circle' : 'edit'} />
          </Button>
        )
      }
    ]

    const drawerButtons = [
      <Button
        key="cancel"
        onClick={this.hideDrawer}
      >
        <FormattedMessage
          id="cancel"
          defaultMessage="Cancel"
        />
      </Button>,
      <Button
        key="save"
        type="primary"
        onClick={this.saveProject}
        disabled={this.state.project && this.state.project.archived}
      >
        <FormattedMessage
          id="save"
          defaultMessage="Save"
        />
      </Button>
    ]
    if (this.state.project && this.state.project._id) {
      if (this.state.project.archived) {
        drawerButtons.unshift(
          <Popconfirm
            key="archive"
            title={this.props.intl.formatMessage({ id: 'do you want to unarchive this project?', defaultMessage: 'Do you want to unarchive this project?' })}
            onConfirm={() => this.archiveProject(false)}
            okText={this.props.intl.formatMessage({ id: 'yes', defaultMessage: 'Yes' })}
            cancelText={this.props.intl.formatMessage({ id: 'no', defaultMessage: 'No' })}
          >
            <Button
              type="danger"
              style={{
                float: 'left'
              }}
            >
              <FormattedMessage
                id="open"
                defaultMessage="Open"
              />
            </Button>
          </Popconfirm>
        )
      } else {
        drawerButtons.unshift(
          <Popconfirm
            key="archive"
            title={this.props.intl.formatMessage({ id: 'do you want to archive this project?', defaultMessage: 'Do you want to archive this project?' })}
            onConfirm={() => this.archiveProject(true)}
            okText={this.props.intl.formatMessage({ id: 'yes', defaultMessage: 'Yes' })}
            cancelText={this.props.intl.formatMessage({ id: 'no', defaultMessage: 'No' })}
          >
            <Button
              type="danger"
              style={{
                float: 'left'
              }}
            >
              <FormattedMessage
                id="archive"
                defaultMessage="Archive"
              />
            </Button>
          </Popconfirm>
        )
      }
    }

    const { projects, project, drawer, users, forms, pagination, loading } = this.state

    const headerButtons = [
      <Button
        key={85623525325222}
        type="primary"
        onClick={() => this.showDrawer({})}
      >
        <FormattedMessage
          id="create project"
          defaultMessage="Create Project"
        />
      </Button>
    ]

    return (
      <div>
        <FormattedMessage id="head.title.projects" defaultMessage="Projects">
          {title => <Helmet><title>{title}</title></Helmet>}
        </FormattedMessage>

        <PageTitle
          title={this.props.intl.formatMessage({ id: 'projects', defaultMessage: 'Projects' })}
          buttons={headerButtons}
        />

        <Table
          columns={columns}
          dataSource={projects}
          rowKey={record => record._id}
          loading={loading}
          onChange={this.onTableChange}
          onRow={(record) => ({
            onDoubleClick: () => { this.showDrawer(record) }
          })}
          pagination={pagination}
        />
        <DrawerComponent
          title={project._id ? project.projectName : this.props.intl.formatMessage({ id: 'create project', defaultMessage: 'Create project' })}
          onClose={this.hideDrawer}
          visible={!!drawer}
          footerButtons={drawerButtons}
        >
          <ProjectForm
            project={project}
            users={users}
            forms={forms}
            fetchForms={this.fetchForms}
            fetchUsers={this.fetchUsers}
            updateProject={this.updateProject}
            form={this.props.form}
          />
        </DrawerComponent>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  projects: state.projectReducer.projects
})

export default injectIntl(Form.create({ name: 'project_form' })(connect(mapStateToProps)(ProjectsPage)))
