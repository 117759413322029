import React from 'react'
import { Pie } from 'react-chartjs-2'
import { notification, Row, Col, Card, Table } from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import _ from 'lodash'

import configService from 'services/config'
import statsService from 'services/stats'

import './style.scss'

class Dashboard extends React.Component {
  state = {
    stats: {},
    settings: {}
  }

  componentDidMount () {
    this.fetchStats()
    this.fetchSettings()
  }

  fetchSettings = async () => {
    try {
      const result = await configService.get()

      this.setState({
        settings: result
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({ id: 'settings', defaultMessage: 'Settings' })} - download error`
      })
    }
  }

  fetchStats = async () => {
    try {
      const result = await statsService.get()

      this.setState({
        stats: result
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({
          id: 'stats',
          defaultMessage: 'Stats'
        })} - download error`
      })
    }
  }

  render () {
    const settings = this.state.settings || {}
    const user = this.state.stats.user || {}
    const acceptance = this.state.stats.acceptance || {}
    const acceptanceCount = acceptance.count || {}
    const recordingimage = this.state.stats.recordingimage || {}
    const recordingimageCount = recordingimage.count || {}
    const object = this.state.stats.object || {}
    const objectCount = object.count || {}
    const recording = this.state.stats.recording || {}
    const recordingData = recording.count || {}
    const requests = this.state.stats.requests || []
    const workorders = this.state.stats.workorders || []

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
      }
    ]

    if (_.isEmpty(settings)) {
      return null
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Dashboard</title>
        </Helmet>

        <Row>

          <Col span={1} className="box-col">

            {settings.buildx.enabled &&
            <Card
              title={this.props.intl.formatMessage({
                id: 'recordings',
                defaultMessage: 'Recordings'
              })}
              bordered={false}
              className="box"
            >

              <Col lg={8}>
                <div className="box">
                  <h3>
                    <FormattedMessage
                      id="verified"
                      defaultMessage="Verified"
                    />
                  </h3>

                  <span className="bigNumber" style={{ color: 'green' }}>
                    {recordingData.verified}
                  </span>
                </div>
              </Col>

              <Col lg={8}>
                <div className="box">
                  <h3>
                    <FormattedMessage
                      id="unverified"
                      defaultMessage="Unverified"
                    />
                  </h3>

                  <span className="bigNumber" style={{ color: 'orange' }}>
                    {recordingData.unverified}
                  </span>
                </div>
              </Col>

              <Col lg={8}>
                <div className="box">
                  <h3>
                    <FormattedMessage
                      id="rejected"
                      defaultMessage="Rejected"
                    />
                  </h3>

                  <span className="bigNumber" style={{ color: 'red' }}>
                    {recordingData.rejected}
                  </span>
                </div>
              </Col>

              {recordingData.verifeid + recordingData.unverified + recordingData.rejected > 0 && (
                <Pie
                  data={{
                    labels: [
                      this.props.intl.formatMessage({ id: 'verified', defaultMessage: 'Verified' }),
                      this.props.intl.formatMessage({ id: 'unverified', defaultMessage: 'Unverified' }),
                      this.props.intl.formatMessage({ id: 'rejected', defaultMessage: 'Rejected' })
                    ],
                    datasets: [
                      {
                        label: this.props.intl.formatMessage({ id: 'recordings', defaultMessage: 'Recordings' }),
                        data: [
                          recordingData.verified,
                          recordingData.unverified,
                          recordingData.rejected
                        ],
                        backgroundColor: [
                          'green',
                          'orange',
                          'red'
                        ],
                        borderColor: [
                          'green',
                          'orange',
                          'red'
                        ],
                        borderWidth: 1
                      }
                    ]
                  }}
                  options={{
                    legend: {
                      display: false
                    }
                  }}
                />
              )}

            </Card>
            }
          </Col>

          <Col span={1} className="box-col">
            <Card
              title={`${this.props.intl.formatMessage({
                id: 'number of',
                defaultMessage: 'Number of'
              })} ${this.props.intl.formatMessage({
                id: 'users',
                defaultMessage: 'Users'
              })}`}
              bordered={false}
              className="box"
            >
              <span className="bigNumber">{user.count}</span>
            </Card>
          </Col>

          {settings.buildx.enabled &&
          <Col span={1} className="box-col">
            <Card
              title={`${this.props.intl.formatMessage({
                id: 'number of',
                defaultMessage: 'Number of'
              })} ${this.props.intl.formatMessage({
                id: 'acceptances',
                defaultMessage: 'Acceptances'
              })}`}
              bordered={false}
              className="box"
            >
              <span className="bigNumber">{acceptanceCount.total}</span>
            </Card>
          </Col>
          }

          {settings.buildx.enabled &&
          <Col span={1} className="box-col">
            <Card
              title={`${this.props.intl.formatMessage({
                id: 'number of',
                defaultMessage: 'Number of'
              })} ${this.props.intl.formatMessage({
                id: 'recording images',
                defaultMessage: 'Images'
              })}`}
              bordered={false}
              className="box"
            >
              <span className="bigNumber">{recordingimageCount.total}</span>
            </Card>
          </Col>
          }

          <Col span={1} className="box-col">
            <Card
              title={`${this.props.intl.formatMessage({
                id: 'number of',
                defaultMessage: 'Number of'
              })} ${object.name}`}
              bordered={false}
              className="box"
            >
              <span className="bigNumber">{objectCount.total}</span>
            </Card>
          </Col>

          {settings.mainx.enabled &&
          <Col span={1} className="box-col">
            <Card
              title={`${this.props.intl.formatMessage({ id: 'open requests', defaultMessage: 'Open requests' })}`}
              bordered={false}
              className="box"
            >
              <Table dataSource={requests.list} columns={columns} />
            </Card>
          </Col>
          }

          {settings.mainx.enabled &&
          <Col span={1} className="box-col">
            <Card
              title={`${this.props.intl.formatMessage({ id: 'open workorders', defaultMessage: 'Open workorders' })}`}
              bordered={false}
              className="box"
            >
              <Table dataSource={workorders.list} columns={columns} />
            </Card>
          </Col>

          }
        </Row>
      </React.Fragment>
    )
  }
}

export default injectIntl(Dashboard)
