import React from 'react'
import { notification, Tabs, Button, Form, Select } from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import PageTitle from 'components/Global/PageTitle'

import config from 'config'
import configService from 'services/config'
import formService from 'services/form'
import BaseX from 'components/GlobalSettings/Tabs/BaseX'
import BuildX from 'components/GlobalSettings/Tabs/BuildX'
import Numeration from 'components/GlobalSettings/Tabs/Numeration'

const Option = Select.Option

class Settings extends React.Component {
  state = {
    settings: {},
    processing: false,
    uploading: false,
    forms: [],
    timestamp: ''
  }

  componentDidMount () {
    this.fetchSettings()
    this.fetchForms()
  }

  fetchSettings = async () => {
    try {
      const settings = await configService.get()

      this.setState({
        settings
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({
          id: 'settings',
          defaultMessage: 'Settings'
        })} - download error`
      })
    }
  }

  fetchForms = async () => {
    try {
      const forms = await formService.list()

      this.setState({
        forms
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({
          id: 'forms',
          defaultMessage: 'Forms'
        })} - download error`
      })
    }
  }

  saveSettings = async () => {
    if (this.state.processing) {
      return
    }

    this.setState({
      processing: true
    })

    this.props.form.validateFields(async err => {
      if (!err) {
        try {
          const settings = await configService.save({
            ...this.state.settings
          })

          notification.success({
            message: this.props.intl.formatMessage({ id: 'saved', defaultMessage: 'Saved' })
          })

          this.setState({
            settings
          })
        } catch (error) {
          notification.error({
            message: this.props.intl.formatMessage({
              id: 'saving error',
              defaultMessage: 'Saving Error'
            })
          })
        }
      }
    })

    this.setState({
      processing: false
    })
  }

  updateCustomForm = (type, value) => {
    const buildx = this.state.settings.buildx || {}
    const customForms = {
      ...(buildx.custom_forms || {}),
      [type]: value
    }

    this.updateBuildX('custom_forms', customForms)
  }

  updateProductMapping = (type, namesArray) => {
    const buildx = this.state.settings.buildx || {}
    const outputArray = []
    namesArray.forEach(categoryName => {
      Object.keys(buildx.product_sections).forEach(sectionName => {
        if (buildx.product_sections[sectionName].name === categoryName) {
          outputArray.push(buildx.product_sections[sectionName])
        }
      })
    })
    this.updateBuildX('product_mapping', outputArray)
  };

  updateBuildX = (field, value) => {
    const buildx = this.state.settings.buildx || {}
    buildx[field] = value
    this.setState(
      state => ({
        settings: {
          ...state.settings,
          buildx
        }
      })
    )
  };

  updateApps = (field, value) => {
    const apps = this.state.settings.apps || {}
    apps[field] = value

    this.setState(
      state => ({
        settings: {
          ...state.settings,
          apps
        }
      })
    )
  };

  updateBaseX = (field, value) => {
    const basex = this.state.settings.basex || {}
    basex[field] = value

    this.setState(
      state => ({
        settings: {
          ...state.settings,
          basex
        }
      })
    )
  }

  updateNumeration = (type, value) => {
    const numeration = this.state.settings.numeration || {}

    this.setState(
      state => ({
        settings: {
          ...state.settings,
          numeration: {
            ...numeration,
            [type]: {
              ...numeration[type],
              prefix: value
            }
          }
        }
      })
    )
  }

  beforeUpload = file => {
    const isPNG = file.type === 'image/png'
    if (!isPNG) {
      notification.error({
        message: this.props.intl.formatMessage({
          id: 'you can only upload PNG file',
          defaultMessage: 'You can only upload PNG file'
        })
      })
    }

    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      notification.error({
        message: this.props.intl.formatMessage({
          id: 'image must smaller than',
          defaultMessage: 'Image must be smaller than'
        }) + ' 2MB'
      })
    }

    return isPNG && isLt2M
  }

  logoUpload = async file => {
    this.setState({
      uploading: true
    })

    try {
      await configService.uploadLogo(file)

      this.setState({
        timestamp: new Date().getTime()
      })
    } catch (error) {
    }

    this.setState({
      uploading: false
    })

    return config.server.url + '/config/logo'
  }

  render () {
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      className: 'formItem'
    }

    const buildX = this.state.settings.buildx || {}
    const basex = this.state.settings.basex || {}
    const apps = this.state.settings.apps || {}
    const numeration = this.state.settings.numeration || {}

    const formOptions = this.state.forms.map(item => (
      <Option key={item._id} value={item._id}>{item.name}</Option>
    ))

    return (
      <React.Fragment>
        <FormattedMessage id="head.title.settings" defaultMessage="Settings">
          {title => <Helmet><title>{title}</title></Helmet>}
        </FormattedMessage>

        <PageTitle
          title={this.props.intl.formatMessage({ id: 'global settings', defaultMessage: 'Global settings' })}
        />

        <Tabs defaultActiveKey="basex">
          <Tabs.TabPane tab="BaseX" key="basex">
            <BaseX
              basex={basex}
              buildX={buildX}
              formItemLayout={formItemLayout}
              form={this.props.form}
              timestamp={this.state.timestamp}
              logoUpload={this.logoUpload}
              beforeUpload={this.beforeUpload}
              uploading={this.state.uploading}
              updateBaseX={this.updateBaseX}
              updateBuildX={this.updateBuildX}
            />
          </Tabs.TabPane>

          <Tabs.TabPane tab="BuildX" key="buildx">
            <BuildX
              buildX={buildX}
              formItemLayout={formItemLayout}
              formOptions={formOptions}
              apps={apps}
              updateCustomForm={this.updateCustomForm}
              updateProductMapping={this.updateProductMapping}
              updateApps={this.updateApps}
            />
          </Tabs.TabPane>

          <Tabs.TabPane tab={this.props.intl.formatMessage({
            id: 'numeration',
            defaultMessage: 'Numeration'
          })} key="numeration">
            <Numeration
              numeration={numeration}
              updateNumeration={this.updateNumeration}
              form={this.props.form}
            />
          </Tabs.TabPane>
        </Tabs>

        <Button
          htmlType="button"
          type="primary"
          onClick={this.saveSettings}
        >
          <FormattedMessage
            id="save"
            defaultMessage="Save"
          />
        </Button>
      </React.Fragment>
    )
  }
}

export default injectIntl(Form.create({ name: 'settings_form' })(Settings))
