import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'

import RRuleGenerator from 'react-rrule-generator'
import translations from 'translations/rruleGenerator'

class RecurringTab extends React.Component {
    getTranslation = () => (this.props.intl.locale === 'de') ? translations.de : translations.en;

    render () {
      return (<React.Fragment>
        <RRuleGenerator
          value={this.props.workOrder.rrule}
          translations={this.getTranslation()}
          onChange={(rrule) => this.props.updateStateWorkOrder('rrule', rrule)}
        />
      </React.Fragment>)
    }
}

export default injectIntl(connect()(RecurringTab))
