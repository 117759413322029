import React from 'react'
import PropTypes from 'prop-types'

import { Form, Select, Icon, Switch } from 'antd'
import { injectIntl } from 'react-intl'

const Option = Select.Option

const BuildX = props => {
  const {
    buildX,
    formItemLayout,
    formOptions,
    apps,
    updateCustomForm,
    updateProductMapping,
    updateApps
  } = props

  return (
    <React.Fragment>
      <Form.Item {...formItemLayout} label={props.intl.formatMessage({
        id: 'recording form',
        defaultMessage: 'Recording Form'
      })}>
        <Select
          value={(buildX.custom_forms || {}).recording}
          onChange={value => updateCustomForm('recording', value)}
          placeholder={props.intl.formatMessage({
            id: 'recording form',
            defaultMessage: 'Recording Form'
          })}
          style={{
            width: '100%'
          }}
        >
          {formOptions}
        </Select>

      </Form.Item>

      <Form.Item {...formItemLayout} label={props.intl.formatMessage({
        id: 'acceptance form',
        defaultMessage: 'Acceptance Form'
      })}>
        <Select
          value={(buildX.custom_forms || {}).acceptance}
          onChange={value => updateCustomForm('acceptance', value)}
          placeholder={props.intl.formatMessage({
            id: 'acceptance form',
            defaultMessage: 'Acceptance Form'
          })}
          style={{
            width: '100%'
          }}
        >
          {formOptions}
        </Select>

      </Form.Item>

      <Form.Item {...formItemLayout} label={props.intl.formatMessage({
        id: 'categoryMapping',
        defaultMessage: 'Category Mapping'
      })}>
        <Select
          mode="tags"
          style={{ width: '100%' }}
          placeholder="Please select"
          // defaultValue={['a10', 'c12']}
          value={buildX.product_mapping ? buildX.product_mapping.map(item => item.name) : []}
          onChange={value => updateProductMapping('product_mapping', value)}
        >
          {
            buildX.product_sections &&
            Object.keys(buildX.product_sections).map(key =>
              <Option key={buildX.product_sections[key].name}>{buildX.product_sections[key].name}</Option>
            )
          }
        </Select>
      </Form.Item>

      <Form.Item {...formItemLayout} label={props.intl.formatMessage({
        id: 'sapEnabled',
        defaultMessage: 'SAP Schnittstelle'
      })}>
        <Switch
          checkedChildren={<Icon type="check" />}
          unCheckedChildren={<Icon type="close" />}
          checked={(apps || {}).sap}
          onChange={value => updateApps('sap', value)}
        />
      </Form.Item>
    </React.Fragment>
  )
}

BuildX.propTypes = {
  formItemLayout: PropTypes.object.isRequired,
  buildX: PropTypes.object.isRequired,
  formOptions: PropTypes.array.isRequired,
  apps: PropTypes.object.isRequired,
  updateCustomForm: PropTypes.func.isRequired,
  updateProductMapping: PropTypes.func.isRequired,
  updateApps: PropTypes.func.isRequired
}

export default injectIntl(BuildX)
