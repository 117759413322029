import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Table, notification, Icon, Button, Form, Input } from 'antd'
import categoriesService from 'services/categories'
import projectService from 'services/project'
import { findIndexInArray } from 'helpers/utils'
import { Helmet } from 'react-helmet'
import formService from 'services/form'

import CategoryForm from 'components/Categories/Form'
import DrawerComponent from 'components/Drawer'
import PageTitle from 'components/Global/PageTitle'
import PopconfirmDelete from 'components/Global/PopconfirmDelete'

export class CategoriesPage extends React.Component {
  state = {
    categories: [],
    projects: [],
    drawer: false,
    selectedRowKeys: [],
    forms: [],
    search: {
      name: '',
      aksNumber: ''
    },
    loading: false,
    formLoading: false,
    readOnlyNewProject: false,
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50', '100']
    },
    drawerOption: '',
    category: {},
    createdByFilters: [],
    archivedFilters: ['hideArchived']
  }

  componentDidMount () {
    this.fetchCategories()
  }

  fetchForms = async (value) => {
    this.setState({
      formLoading: true
    })
    try {
      const forms = await formService.listV2({
        name: [value],
        limit: 100
      })

      this.setState({
        forms: forms.docs,
        formLoading: false
      })
    } catch (error) {
      this.setState({
        formLoading: false
      })
      notification.error({
        message: `${this.props.intl.formatMessage({
          id: 'forms',
          defaultMessage: 'Forms'
        })} - download error`
      })

      this.setState({
        forms: []
      })
    }
  }

  updateCategory = (field, value, e) => {
    if (field === 'custom_form') {
      this.setState({
        category: {
          ...this.state.category,
          custom_form: e ? e.props.data : null
        }
      })
    } else {
      this.setState({
        category: {
          ...this.state.category,
          [field]: value
        }
      })
    }
  }

  fetchCategories = async (params) => {
    this.setState({
      loading: true
    })
    try {
      const categories = await categoriesService.listV2({
        ...params
      })

      const pagination = {
        ...this.state.pagination
      }

      pagination.total = categories.totalDocs

      this.setState({
        categories: categories.docs,
        pagination,
        createdByFilters: categories.filterOptions.createdBy,
        loading: false
      })
    } catch (error) {
      this.setState({
        loading: false
      })
      notification.error({
        message: `${this.props.intl.formatMessage({
          id: 'categories',
          defaultMessage: 'Categories'
        })} - download error`
      })

      this.setState({
        drawer: false
      })
    }
  }

  fetchProjects = async (value) => {
    this.setState({
      formLoading: true
    })
    const timer = setTimeout(async () => {
      try {
        let projects = await projectService.listV2({
          projectName: [value],
          limit: 100
        })

        this.setState({
          projects: projects.docs,
          formLoading: false
        })

        clearTimeout(timer)
      } catch (error) {
        this.setState({
          projects: [],
          formLoading: false
        })
        clearTimeout(timer)
      }
    })
  }

  addCategory = async () => {
    const { category } = this.state
    let payload

    payload = {
      name: category.name,
      custom_form: category.custom_form ? category.custom_form._id : null,
      project: category.project._id || category.project || null
    }
    this.props.form.validateFields(async err => {
      if (!err) {
        try {
          const categoryResponse = await categoriesService.addCategory(payload)

          this.fetchCategories()
          notification.success({
            message: `${this.props.intl.formatMessage({ id: 'created successfully', defaultMessage: 'Created successfully' })}`
          })

          this.setState({
            drawer: false,
            category: {},
            pagination: {
              current: 1
            },
            categories: [
              ...this.state.categories, categoryResponse
            ]
          })

          this.fetchCategories()
        } catch (error) {
          notification.error({
            message: this.props.intl.formatMessage({ id: 'saving error', defaultMessage: 'Saving error' }),
            description: error.error
          })
        }
      }
    })
  }

  deleteCategory = async () => {
    try {
      await categoriesService.deleteCategory(this.state.category._id)

      notification.success({
        message: this.props.intl.formatMessage({ id: 'deleted successfully', defaultMessage: 'Deleted successfully' })
      })

      this.setState({
        drawer: false,
        category: {},
        categories: [
          ...this.state.categories.filter(category => category._id !== this.state.category._id)
        ]
      })
    } catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({
          id: 'deleting error',
          defaultMessage: 'Deleting Error'
        }),
        description: error.message
      })
    }
  }

  saveCategory = async () => {
    const { category, categories } = this.state
    this.props.form.validateFields(async err => {
      if (!err) {
        try {
          const payload = {
            name: category.name,
            custom_form: category.custom_form ? category.custom_form._id : null,
            project: category.project._id || category.project || null
          }

          await categoriesService.updateCategory(category._id, payload)

          this.fetchCategories()
          notification.success({
            message: `${this.props.intl.formatMessage({ id: 'updated successfully', defaultMessage: 'Updated successfully' })}`
          })

          const updateIndex = findIndexInArray(categories, category._id)
          let updatedArrray = [...categories]
          updatedArrray[updateIndex] = category
          this.setState({
            drawer: false,
            category: {},
            categories: [
              ...updatedArrray]
          })
        } catch (error) {
          notification.error({
            message: this.props.intl.formatMessage({ id: 'saving error', defaultMessage: 'Saving error' }),
            description: error.error
          })
        }
      }
    })
  }

  onSelectChange = selectedRowKeys => {
    this.setState({
      selectedRowKeys
    })
  }

  onTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    pager.offset = pagination.current

    this.setState({
      pagination: {
        ...pager,
        current: pagination.current
      }
    })

    this.fetchCategories({
      limit: pagination.pageSize,
      offset: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    })
  }

  showDrawer = async category => {
    this.setState({
      ...this.state.category,
      drawer: true,
      category,
      drawerOption: 'edit',
      readOnlyNewProject: false
    })
  }

  hideDrawer = () => {
    this.setState({
      drawer: false
    })
  }

  showDrawerAdd = async () => {
    this.setState({
      drawer: true,
      category: {
        project: this.props.selectedProject ? this.props.selectedProject : null
      },
      readOnlyNewProject: !!this.props.selectedProject,
      drawerOption: 'add'
    })
  }

  handleSearch = (selectedKeys, field, confirm) => {
    confirm()
    this.setState({
      search: {
        ...this.state.search,
        [field]: selectedKeys[0]
      }
    })
  }

  handleReset = (field, clearFilters) => {
    clearFilters()

    this.setState({
      search: {
        ...this.state.search,
        [field]: ''
      }
    })
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this[`searchInput${dataIndex}`] = node
          }}
          placeholder={this.props.intl.formatMessage({ id: 'search', defaultMessage: 'Suchen' })}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Suchen
        </Button>
        <Button
          onClick={() => this.handleReset(dataIndex, clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (<Icon type="search"
      style={{ color: filtered ? '#1890ff' : undefined }} />),
    onFilter: (value, record) => String(record[dataIndex])
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this[`searchInput${dataIndex}`].select())
      }
    }
  })

  render () {
    const columns = [{
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      ...this.getColumnSearchProps('name')
    }, {
      title: this.props.intl.formatMessage({ id: 'created at', defaultMessage: 'CreatedAt' }),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
      sorter: true
    }, {
      title: this.props.intl.formatMessage({ id: 'created by', defaultMessage: 'CreatedBy' }),
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: createdBy => createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : '',
      sorter: true,
      filters: this.state.createdByFilters.map(user => ({
        text: `${user.firstName} ${user.lastName}`,
        value: user._id
      }))
    }]

    if (!this.props.project) {
      columns.push({
        title: this.props.intl.formatMessage({ id: 'project', defaultMessage: 'Project' }),
        key: 'project',
        dataIndex: 'project.projectName'
      })
    }

    columns.push({
      key: 'actions',
      render: (text, record) => (
        <div style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'flex-end'
        }}>
          <Button onClick={() => this.showDrawer(category)}>
            <Icon
              type={(category.isVerified || category.isInvalid) ? 'info-circle' : 'edit'}
            />
          </Button>
        </div>
      )
    })

    const drawerButtonsAdd = [
      <Button
        key="cancel"
        onClick={this.hideDrawer}
      >
        <FormattedMessage
          id="cancel"
          defaultMessage="Cancel"
        />
      </Button>,
      <Button
        key="save"
        type="primary"
        onClick={this.addCategory}
      >
        <FormattedMessage
          id="save"
          defaultMessage="Save"
        />
      </Button>
    ]

    const drawerButtonsEdit = [
      <Button
        key="cancel"
        onClick={this.hideDrawer}
      >
        <FormattedMessage
          id="cancel"
          defaultMessage="Cancel"
        />
      </Button>,
      <Button
        key="save"
        type="primary"
        onClick={this.saveCategory}
      // disabled={this.state.recording && this.state.recording.isInvalid}
      >
        <FormattedMessage
          id="save"
          defaultMessage="Save"
        />
      </Button>
    ]
    if (this.props.auth.auth_user.scope[0] === 'superadmin' &&
      this.state.category &&
      !this.state.category.isVerified
    ) {
      drawerButtonsEdit.unshift(
        <PopconfirmDelete onConfirmAction={this.deleteCategory} />
      )
    }

    const { categories, readOnlyNewProject, selectedRowKeys, projects, drawer, drawerOption, category, forms, loading, formLoading } = this.state

    const headerButtons = [
      <Button
        key={353258989832}
        type="primary"
        onClick={this.showDrawerAdd}
      >
        <FormattedMessage
          id="create category"
          defaultMessage="Create category"
        />
      </Button>
    ]

    return (
      <div>
        <FormattedMessage id="head.title.categories" defaultMessage="Categories">
          {title => <Helmet><title>{title}</title></Helmet>}
        </FormattedMessage>

        <PageTitle
          title={this.props.intl.formatMessage({ id: 'categories', defaultMessage: 'Categories' })}
          buttons={headerButtons}
        />

        <Table
          // eslint-disable-next-line no-return-assign
          ref={ref => this._table = ref}
          rowKey={record => record._id}
          columns={columns}
          dataSource={categories}
          loading={loading}
          onChange={this.onTableChange}
          onRow={(record) => ({
            onDoubleClick: () => { this.showDrawer(record) }
          })}
          pagination={this.state.pagination}
        />
        <DrawerComponent
          title=""
          onClose={this.hideDrawer}
          visible={!!drawer}
          footerButtons={drawerOption === 'edit' ? drawerButtonsEdit : drawerButtonsAdd}
        >
          <CategoryForm
            option={drawerOption}
            category={category}
            projects={projects}
            formLoading={formLoading}
            readOnlyNewProject={readOnlyNewProject}
            readOnlyNewProject={readOnlyNewProject}
            fetchProjects={this.fetchProjects}
            fetchForms={this.fetchForms}
            updateCategory={this.updateCategory}
            form={this.props.form}
            formOptions={forms}
            edit={drawerOption === 'edit'}
          />
        </DrawerComponent>
      </div>
    )
  }
}

CategoriesPage.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.authReducer,
  selectedProject: state.projectReducer.project
})

export default injectIntl(Form.create({ name: 'categoriesPage_form' })(
  connect(mapStateToProps)(CategoriesPage)))
