import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import { Checkbox, Form, Input, Select, Spin } from 'antd'

import './styles.scss'

class ContractSectionForm extends React.Component {
  render () {
    const { getFieldDecorator } = this.props.form

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      className: 'formItem'
    }

    return (
      <Form onSubmit={() => {}} className="contractSectionForm">
        <Form.Item
          {...formItemLayout}
          label={(<span>Name<span style={{ color: '#005591' }}>*</span></span>)}
          required={false}
        >
          {getFieldDecorator('name', {
            initialValue: this.props.contractSection.name,
            rules: [ {
              required: true,
              message: this.props.intl.formatMessage({ id: 'name is required', defaultMessage: 'Name is required' })
            } ]
          })(
            <Input
              onChange={event => this.props.updateContractSection('name', event.target.value)}
            />
          )}
        </Form.Item>

        <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: 'project', defaultMessage: 'Project' })}>
          <Select
            showSearch
            allowClear
            style={{ width: '100%' }}
            optionFilterProp="children"
            notFoundContent={this.props.formLoading ? <div className="objects-spinner-container"><Spin size="small" /></div> : null}
            onFocus={() => this.props.fetchProjects('')}
            onSearch={value => this.props.fetchProjects(value)}
            onChange={projectID => this.props.updateContractSection('project', projectID)}
            defaultValue={this.props.contractSection.project ? this.props.contractSection.project.projectName : ''}
            disabled={this.props.readOnlyNewProject}
          >
            {this.props.projects.map(project => (
              <Select.Option key={project._id} value={project._id}>{project.projectName}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        {this.props.contractSection._id && (
          <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: 'disabled', defaultMessage: 'Disabled' })}>
            <Checkbox
              checked={!!this.props.contractSection.disabled}
              onChange={event => this.props.updateContractSection('disabled', event.target.checked)}
            />
          </Form.Item>
        )}
      </Form>
    )
  }
}

ContractSectionForm.propTypes = {
  contractSection: PropTypes.object.isRequired,
  updateContractSection: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired
}

export default injectIntl(ContractSectionForm)
