import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import { Checkbox, Form, Input, Select, Spin } from 'antd'

class ContactForm extends React.Component {
  render () {
    const { getFieldDecorator } = this.props.form

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      className: 'formItem'
    }

    return (
      <Form onSubmit={() => { }} className="contactForm">
        <Form.Item
          {...formItemLayout}
          label={(<span>{this.props.intl.formatMessage({ id: 'company', defaultMessage: 'Company' })}<span style={{ color: '#005591' }}>*</span></span>)}
          required={false}
        >
          {getFieldDecorator('company', {
            initialValue: this.props.contact.company,
            rules: [{
              required: true,
              message: this.props.intl.formatMessage({ id: 'company is required', defaultMessage: 'Company is required' })
            }]
          })(
            <Input
              onChange={event => this.props.updateContact('company', event.target.value)}
            />
          )}
        </Form.Item>

        <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: 'first name', defaultMessage: 'First name' })}>
          {getFieldDecorator('firstName', {
            initialValue: this.props.contact.firstName
          })(
            <Input
              onChange={event => this.props.updateContact('firstName', event.target.value)}
            />
          )}
        </Form.Item>

        <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: 'last name', defaultMessage: 'Last name' })}>
          {getFieldDecorator('lastName', {
            initialValue: this.props.contact.lastName
          })(
            <Input
              onChange={event => this.props.updateContact('lastName', event.target.value)}
            />
          )}
        </Form.Item>

        <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: 'street', defaultMessage: 'Street' })}>
          {getFieldDecorator('street', {
            initialValue: this.props.contact.street
          })(
            <Input
              onChange={event => this.props.updateContact('street', event.target.value)}
            />
          )}
        </Form.Item>

        <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: 'postcode', defaultMessage: 'Postcode' })}>
          {getFieldDecorator('postcode', {
            initialValue: this.props.contact.postcode
          })(
            <Input
              onChange={event => this.props.updateContact('postcode', event.target.value)}
            />
          )}
        </Form.Item>

        <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: 'email', defaultMessage: 'E-Mail' })}>
          {getFieldDecorator('email', {
            initialValue: this.props.contact.email
          })(
            <Input
              onChange={event => this.props.updateContact('email', event.target.value)}
            />
          )}
        </Form.Item>

        <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: 'city', defaultMessage: 'City' })}>
          {getFieldDecorator('city', {
            initialValue: this.props.contact.city
          })(
            <Input
              onChange={event => this.props.updateContact('city', event.target.value)}
            />
          )}
        </Form.Item>

        <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: 'website', defaultMessage: 'Website' })}>
          {getFieldDecorator('website', {
            initialValue: this.props.contact.website
          })(
            <Input
              onChange={event => this.props.updateContact('website', event.target.value)}
            />
          )}
        </Form.Item>

        <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: 'phone', defaultMessage: 'Phone' })}>
          {getFieldDecorator('phone', {
            initialValue: this.props.contact.phone
          })(
            <Input
              onChange={event => this.props.updateContact('phone', event.target.value)}
            />
          )}
        </Form.Item>
      </Form>
    )
  }
}

ContactForm.propTypes = {
  contact: PropTypes.object.isRequired,
  updateContact: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired
}

export default injectIntl(ContactForm)
