import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Table, Form, Icon, Button, Popconfirm, notification } from 'antd'
import EditableCell from './EditableCell'

const EditableContext = React.createContext()

class EditableTable extends React.Component {
  state = {
    editingItem: '',
    newQuantity: ''
  }

  isEditing = record => {
    return (record.product && record.product._id === this.state.editingItem) ||
      (this.state.editingItem === record.id)
  }

  onEditingValueChange = (value, record) => {
    this.setState({
      newQuantity: value
    })
  }

  removeProductFromSection = (product) => {
    const updatedArray = this.props.allRecordingProducts.filter((p) => {
      return p.product._id !== product.product._id
    })
    this.props.updateRecording('products', updatedArray)
  }

  save = (form, product, key) => {
    if (product.product_id) {
      const findProductIndex = this.props.allRecordingProducts.findIndex(p => {
        return p._id === product.product_id
      })
      const newArr = [ ...this.props.allRecordingProducts ]
      newArr[findProductIndex].extra_fields = newArr[findProductIndex].extra_fields.map(field => {
        if (field.id === product.id) {
          field.value = this.state.newQuantity
        }
        return field
      })
      this.props.updateRecording('products', newArr)
      this.setState({ editingItem: '' })
    } else {
      const findProductIndex = this.props.allRecordingProducts.findIndex(p => {
        return p._id === product._id
      })
      const newArr = [ ...this.props.allRecordingProducts ]
      newArr[findProductIndex].quantity = this.state.newQuantity

      if (Number(this.state.newQuantity) > 50 || Number(this.state.newQuantity) < 1) {
        notification.error({
          message: 'Only numbers 1 - 50'
        })
        return false
      }

      this.props.updateRecording('products', newArr)
      this.setState({ editingItem: '' })
    }
  }

  edit = (item) => {
    this.setState({
      editingItem: item.product ? item.product._id : item.id,
      newQuantity: item.product ? item.quantity : item.value
    })
  };

  cancel = (record) => {
    this.setState({
      editingItem: '',
      newQuantity: record.quantity
    })
  };

  expandedRowRender = (rowData) => {
    const components = {
      body: {
        cell: EditableCell
      }
    }
    const columns = [
      { title: 'Field Name', dataIndex: 'label', key: 'id' },
      {
        title: 'Value',
        // dataIndex: 'value',
        key: 'value',
        render: (record) => record.element === 'Dropdown' && record.options[record.value] ? record.options[record.value].text : record.value,
        onCell: record => ({
          record,
          inputType: record.element,
          dataIndex: 'value',
          title: 'value',
          editingValue: this.state.newQuantity,
          onEditingValueChange: this.onEditingValueChange,
          editing: this.isEditing(record)
        })
      },
      {
        key: 'actions',
        render: (text, record) => {
          const { editingItem } = this.state
          const editable = this.isEditing(record)
          return editable ? (
            <span>
              <EditableContext.Consumer>
                {form => (
                  <Button
                    onClick={() => this.save(form, record)}
                  >
                    <Icon
                      type="save"
                    />
                  </Button>
                )}
              </EditableContext.Consumer>
              <Button onClick={() => this.cancel(record)}>
                {this.props.intl.formatMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
              </Button>
            </span>
          ) : (
            <div style={{ width: 150 }}>
              <Button
                onClick={() => this.edit(record)}
                disabled={this.props.readOnly || editingItem !== ''}
              >
                <Icon
                  type="edit"
                />
              </Button>
            </div>
          )
        }
      }
    ]

    const data = rowData.extra_fields
    // for (let i = 0; i < 3; ++i) {
    //   data.push({
    //     key: i,
    //     date: '2014-12-24 23:12:00',
    //     name: 'This is production name',
    //     upgradeNum: 'Upgraded: 56',
    //   });
    // }
    return (<Table columns={columns}
      // eslint-disable-next-line no-return-assign
      ref={ref => this._table = ref}
      components={components}
      dataSource={data} pagination={false} />)
  }

  render () {
    const components = {
      body: {
        cell: EditableCell
      }
    }

    const columns = [
      {
        title: this.props.intl.formatMessage({ id: 'name', defaultMessage: 'Name' }),
        dataIndex: 'product',
        key: 'name',
        render: product => product.name
      },
      {
        title: this.props.intl.formatMessage({ id: 'ordernumber', defaultMessage: 'Ordernumber' }),
        dataIndex: 'product',
        key: 'ordernumber',
        render: product => product.ordernumber
      },
      {
        title: this.props.intl.formatMessage({ id: 'quantity', defaultMessage: 'Quantity' }),
        dataIndex: 'quantity',
        key: 'quantity',
        onCell: record => ({
          record,
          inputType: 'number',
          dataIndex: 'quantity',
          title: 'quantity',
          editingValue: this.state.newQuantity,
          onEditingValueChange: this.onEditingValueChange,
          editing: this.isEditing(record)
        })
      },

      {
        key: 'actions',
        render: (text, record) => {
          const { editingItem } = this.state
          const editable = this.isEditing(record)
          return editable ? (
            <span>
              <EditableContext.Consumer>
                {form => (
                  <span
                    onClick={() => this.save(form, record)}
                    style={{
                      marginRight: 8,
                      color: '#005591',
                      backgroundColor: 'transparent',
                      outline: 'none',
                      cursor: 'pointer'
                    }}
                  >
                    {this.props.intl.formatMessage({ id: 'save', defaultMessage: 'Save' })}
                  </span>
                )}
              </EditableContext.Consumer>
              <span
                onClick={() => this.cancel(record)}
                style={{
                  marginRight: 8,
                  color: '#005591',
                  backgroundColor: 'transparent',
                  outline: 'none',
                  cursor: 'pointer'
                }}
              >
                {this.props.intl.formatMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
              </span>
            </span>
          ) : (
            <div style={{ width: 150 }}>
              <Button
                onClick={() => this.edit(record)}
                disabled={this.props.readOnly || editingItem !== ''}
              >
                <Icon
                  type="edit"
                />
              </Button>
              {
                this.props.readOnly ? (
                  <Button
                    disabled={this.props.readOnly}
                  >
                    <FormattedMessage
                      id="remove"
                      defaultMessage="Remove"
                    />
                  </Button>
                ) : (
                  <Popconfirm
                    key="remove"
                    title={this.props.intl.formatMessage({
                      id: 'do you really want to remove this product?',
                      defaultMessage: 'Do you really want to remove this product?'
                    })}
                    onConfirm={() => this.removeProductFromSection(record)}
                    okText={this.props.intl.formatMessage({ id: 'yes', defaultMessage: 'Yes' })}
                    cancelText={this.props.intl.formatMessage({ id: 'no', defaultMessage: 'No' })}
                  >
                    <Button
                      disabled={this.props.readOnly}
                    >
                      <FormattedMessage
                        id="remove"
                        defaultMessage="Remove"
                      />
                    </Button>
                  </Popconfirm>
                )
              }
            </div>
          )
        }
      } ]

    return (
      <EditableContext.Provider value={this.props.form}>
        <Table
          // eslint-disable-next-line no-return-assign
          components={components}
          // eslint-disable-next-line no-return-assign
          ref={ref => this._table = ref}
          columns={columns}
          dataSource={this.props.products}
          rowKey={record => record._id}
          pagination={false}
          onChange={this.onTableChange}
          expandedRowRender={this.expandedRowRender}
        />
      </EditableContext.Provider>
    )
  }
}

const SectionTables = Form.create()(EditableTable)

export default injectIntl(SectionTables)
