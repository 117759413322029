import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { ConfigProvider } from 'antd'
import antDE from 'antd/lib/locale-provider/de_DE'
import antEN from 'antd/lib/locale-provider/en_US'
import { IntlProvider } from 'react-intl'
import messages from '../translations'
import 'moment/locale/de'

import Routes from 'routes'

import './App.css'

const translations = {
  de: {
    messages: messages['de'],
    ant: antDE
  },
  en: {
    messages: messages['en'],
    ant: antEN
  }
}

class App extends Component {
  render () {
    let lang = 'de'
    if (this.props.settings.lang === 'en') {
      lang = 'en'
    }

    const project = this.props.project.project ? this.props.project.project._id : undefined

    return (
      <IntlProvider locale={lang} messages={translations[lang]['messages']}>
        <ConfigProvider locale={translations[lang]['ant']} >
          <Routes auth={this.props.auth} project={project} />
        </ConfigProvider>
      </IntlProvider>
    )
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.authReducer,
  project: state.projectReducer,
  settings: state.settingsReducer
})

export default connect(mapStateToProps)(App)
