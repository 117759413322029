import React from 'react'
import PropTypes from 'prop-types'
import { Popconfirm } from 'antd'
import { FormattedMessage, injectIntl } from 'react-intl'
import Button from 'components/Global/Button'

const PopconfirmDelete = (props) => {
  return (
    <Popconfirm
      key="delete"
      title={props.intl.formatMessage({
        id: 'confirm deleting',
        defaultMessage: 'Confirm deleting'
      })}
      onConfirm={props.onConfirmAction}
      okText={props.intl.formatMessage({ id: 'yes', defaultMessage: 'Yes' })}
      cancelText={props.intl.formatMessage({ id: 'no', defaultMessage: 'No' })}
    >
      <Button
        type="danger"
        disabled={props.readOnly}
        value={props.value ? props.value : undefined}
        icon={props.icon}
        style={{
          float: 'left', ...props.style
        }}
      />
    </Popconfirm>)
}

PopconfirmDelete.propTypes = {
  onConfirmAction: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  value: PropTypes.string,
  icon: PropTypes.string,
  intl: PropTypes.object,
  style: PropTypes.object
}

PopconfirmDelete.defaultProps = {
  value: <FormattedMessage
    id="delete"
    defaultMessage="Delete"
  />
}

export default injectIntl(PopconfirmDelete)
