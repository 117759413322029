import React from 'react'

import { connect } from 'react-redux'

import { injectIntl } from 'react-intl'
import { Form, Tabs } from 'antd'

import InformationsTab from './Tabs/Informations'
import RecurringTab from './Tabs/Recurring'
import UpdatesTab from './Tabs/Updates'
import TaskEdit from './Tabs/TaskEdit'
import FileUpload from './Tabs/Fileupload'

import './styles.scss'

const { TabPane } = Tabs

class WorkOrderForm extends React.Component {
  render () {
    const { props } = this

    return (
      <React.Fragment>
        {!this.props.taskEditMode && !this.props.fileUploadMode &&
          <React.Fragment>
            <Form className="workOrdersForm">
              <Tabs defaultActiveKey="informations">
                <TabPane tab={this.props.intl.formatMessage({ id: 'informations', defaultMessage: 'Informations' })} key="informations">
                  <InformationsTab
                    updateAssignedUser={this.props.updateAssignedUser}
                    updateStateWorkOrder={this.props.updateStateWorkOrder}
                    updateStateSpecialWork={this.props.updateStateSpecialWork}
                    specialWork={this.props.specialWork}
                    fetchSpecialWork={this.props.fetchSpecialWork}
                    fetchUsersHandler={this.props.fetchUsersHandler}
                    action={this.props.toggleTaskEditMode}
                    showFileupload={this.props.toggleFileuploadMode}
                    users={this.props.users}
                    form={this.props.form}
                    formLoading={this.props.formLoading}
                    workOrder={this.props.workOrder} />
                </TabPane>
                {
                  this.props.scheduled
                    ? (<TabPane tab={this.props.intl.formatMessage({ id: 'recurring', defaultMessage: 'Recurring' })} key="recurring" className="workorder-tab-recurring">
                      <RecurringTab
                        updateStateWorkOrder={this.props.updateStateWorkOrder}
                        workOrder={this.props.workOrder} />
                    </TabPane>) : null
                }

                {props.option === 'edit' &&
                  <TabPane tab={this.props.intl.formatMessage({ id: 'updates', defaultMessage: 'Updates' })} key="updates">
                    <UpdatesTab
                      comments={this.props.comments}
                      workOrder={this.props.workOrder} />
                  </TabPane>
                }
              </Tabs>
            </Form>
          </React.Fragment>
        }
        {
          this.props.taskEditMode && (
            <TaskEdit
              action={this.props.toggleTaskEditMode}
              objects={this.props.objects}
              recording={this.props.recording}
              formLoading={this.props.formLoading}
              form={this.props.form}
              auth={this.props.auth}
              objectName={this.props.objectName}
              dataXEnabled={this.props.dataXEnabled}
              extraFieldsForm={this.props.extraFieldsForm}
              objectIdSearchResult={this.props.objectIdSearchResult}
              recordingIdSearchResult={this.props.recordingIdSearchResult}
              handleRecordingSelect={this.props.handleRecordingSelect}
              removeTagHandler={this.props.removeTagHandler}
              clearTagHandler={this.props.clearTagHandler}
              onSortTasksEnd={this.props.onSortTasksEnd}
              taskIdChange={this.props.taskIdChange}
              handleTaskIdSelectChange={this.props.handleTaskIdSelectChange}
              handleTaskIdChangeAutocomplete={this.props.handleTaskIdChangeAutocomplete}
              handleSearchObjectId={this.props.handleSearchObjectId}
              handleSearchRecordingId={this.props.handleSearchRecordingId}
              removeTaskholder={this.props.removeTaskholder}
              shareholders={this.props.shareholders}
              addShareholder={this.props.addShareholder}
              markAsDoneTask={this.props.markAsDoneTask}
              hideModalComment={this.props.hideModalComment}
              showModalComment={this.props.showModalComment}
              recordingDrawerVisible={this.props.recordingDrawerVisible}
              recordingDrawerShow={this.props.recordingDrawerShow}
              recordingDrawerHide={this.props.recordingDrawerHide}
              modalCommentActive={this.props.modalCommentActive}
              workorder={this.props.workOrder} />
          )
        }
        {this.props.fileUploadMode && <FileUpload action={this.props.toggleFileuploadMode} workorder={this.props.workOrder} />}
      </React.Fragment>
    )
  }
}

export default injectIntl(connect()(WorkOrderForm))
