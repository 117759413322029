import axios from '../helpers/axios'
import config from 'config'
import store from '../store'

export default {
  async list (params = {}) {
    const projectID = store.getState().projectReducer.project
    if (projectID) {
      params.project = projectID._id
    }

    return axios.get('/location', params)
  },

  async listV2 (params = {}) {
    const projectID = store.getState().projectReducer.project
    if (projectID) {
      params.project = projectID._id
    }
    return axios.get(config.server.host + '/1.1/location', params)
  },
  async save (location) {
    const params = {
      name: location.name,
      project: location.project || null,
      disabled: location.disabled
    }

    if (location._id) {
      return axios.put(`/location/${location._id}`, params)
    } else {
      const projectID = store.getState().projectReducer.project
      if (projectID) {
        params.project = projectID._id
      }

      return axios.post('/location', params)
    }
  },

  async remove (location) {
    return axios.delete(`/location/${location._id}`)
  }
}
