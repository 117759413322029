import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import _ from 'lodash'

import { Table, Icon, Button, notification, Form } from 'antd'

import LocationForm from 'components/Location/Form'
import PageTitle from 'components/Global/PageTitle'
import locationService from 'services/location'
import projectService from 'services/project'
import DrawerComponent from 'components/Drawer'
import PopconfirmDelete from 'components/Global/PopconfirmDelete/index'

export class LocationsPage extends React.Component {
  state = {
    locations: [],
    projects: [],
    drawer: false,
    location: {},
    loading: false,
    formLoading: false,
    createdByFilters: [],
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50', '100']
    },
    activeFilters: [],
    readOnlyNewProject: false
  };

  componentDidMount () {
    this.fetchLocations()
  }

  componentDidUpdate (prevProps) {
    if (this.props.project !== prevProps.project) {
      this.fetchLocations()
    }
  }

  fetchLocations = async (params) => {
    this.setState({
      loading: true
    })
    try {
      const locations = await locationService.listV2({
        ...params
      })

      const pagination = {
        ...this.state.pagination
      }

      pagination.total = locations.totalDocs

      this.setState({
        locations: locations.docs,
        createdByFilters: locations.filterOptions.createdBy,
        loading: false,
        pagination
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({ id: 'locations', defaultMessage: 'Locations' })} - download error`
      })

      this.setState({
        locations: [],
        drawer: false,
        loading: false,
        location: {}
      })
    }
  }

  fetchProjects = async (value) => {
    this.setState({
      formLoading: true
    })
    const timer = setTimeout(async () => {
      try {
        let projects = await projectService.listV2({
          projectName: [value],
          limit: 100
        })

        this.setState({
          projects: projects.docs,
          formLoading: false
        })

        clearTimeout(timer)
      } catch (error) {
        this.setState({
          projects: [],
          formLoading: false
        })
        clearTimeout(timer)
      }
    })
  }

  showDrawer = location => {
    if (_.isEmpty(location, true)) {
      this.setState({
        drawer: true,
        location: {
          ...location,
          project: this.props.selectedProject ? this.props.selectedProject : null
        },
        readOnlyNewProject: !!this.props.selectedProject
      })
    } else {
      this.setState({
        drawer: true,
        location: {
          ...location
        },
        readOnlyNewProject: false
      })
    }
  }

  hideDrawer = () => {
    this.setState({
      drawer: false,
      location: {}
    })
  }

  saveLocation = async () => {
    this.props.form.validateFields(async err => {
      if (!err) {
        try {
          const savedLocation = await locationService.save(this.state.location)

          this.fetchLocations()
          if (this.state.location._id) {
            notification.success({
              message: `${this.props.intl.formatMessage({ id: 'updated successfully', defaultMessage: 'Updated successfully' })}`
            })
          } else {
            notification.success({
              message: `${this.props.intl.formatMessage({ id: 'created successfully', defaultMessage: 'Created successfully' })}`
            })
          }

          this.setState({
            drawer: false,
            location: {},
            locations: [
              ...this.state.locations.filter(location => location._id !== savedLocation._id),
              savedLocation
            ]
          })
        } catch (error) {
          notification.error({
            message: this.props.intl.formatMessage({ id: 'saving error', defaultMessage: 'Saving error' }),
            description: error.error
          })
        }
      }
    })
  }

  removeLocation = async (location) => {
    try {
      await locationService.remove(location)

      this.setState({
        drawer: false,
        location: {},
        locations: [
          ...this.state.locations.filter(item => item._id !== location._id)
        ]
      })
    } catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({ id: 'deleting error', defaultMessage: 'Deleting error' }),
        description: error.error
      })
    }
  }

  updateLocation = (field, value) => {
    this.setState(
      state => ({
        location: {
          ...state.location,
          [field]: value
        }
      })
    )
  }

  onTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    pager.offset = pagination.current

    this.setState({
      pagination: {
        ...pager,
        current: pagination.current
      }
    })

    this.fetchLocations({
      limit: pagination.pageSize,
      offset: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    })
  }

  render () {
    const columns = [
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        defaultSortOrder: 'ascend',
        sorter: true
      },
      {
        title: this.props.intl.formatMessage({ id: 'created at', defaultMessage: 'Created At' }),
        key: 'createdAt',
        dataIndex: 'createdAt',
        sorter: true,
        render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm')
      },
      {
        title: this.props.intl.formatMessage({ id: 'created by', defaultMessage: 'Created By' }),
        key: 'createdBy',
        dataIndex: 'createdBy',
        render: createdBy => createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : '',
        filters: this.state.createdByFilters.map(user => ({
          text: `${user.firstName} ${user.lastName}`,
          value: user._id
        }))
      },
      {
        title: this.props.intl.formatMessage({ id: 'active', defaultMessage: 'Active' }),
        key: 'disabled',
        dataIndex: 'disabled',
        align: 'center',
        render: disabled => (
          <React.Fragment>
            {!disabled && (
              <Icon type="check" style={{ color: '#005591' }} />
            )}
          </React.Fragment>
        ),
        filters: [
          {
            text: this.props.intl.formatMessage({ id: 'active', defaultMessage: 'active' }),
            value: false
          },
          {
            text: this.props.intl.formatMessage({ id: 'inactive', defaultMessage: 'Inactive' }),
            value: true
          }
        ],
        filteredValue: this.state.activeFilters
      }
    ]

    if (!this.props.project) {
      columns.push({
        title: this.props.intl.formatMessage({ id: 'project', defaultMessage: 'Project' }),
        key: 'project',
        dataIndex: 'project.projectName'
      })
    }

    columns.push({
      key: 'actions',
      render: (text, record) => (
        <div style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'flex-end'
        }}>
          <React.Fragment>
            <Button onClick={() => this.showDrawer(record)}>
              <Icon type="edit" />
            </Button>
          </React.Fragment>
        </div>
      )
    })

    const drawerButtons = [
      <PopconfirmDelete onConfirmAction={() => this.removeLocation(this.state.location)} />,
      <Button key={237323536} onClick={this.hideDrawer} style={{ marginRight: 8 }}>
        <FormattedMessage
          id="cancel"
          defaultMessage="Cancel"
        />
      </Button>,
      <Button
        key={238678436}
        onClick={this.saveLocation} type="primary">
        {this.props.intl.formatMessage({ id: 'save', defaultMessage: 'Save' })}
      </Button>
    ]

    const { locations, location, projects, drawer, pagination, loading, formLoading, readOnlyNewProject } = this.state

    const headerButtons = [
      <Button
        key={237323536}
        type="primary"
        onClick={() => this.showDrawer({})}
      >
        <FormattedMessage
          id="create location"
          defaultMessage="Create Location"
        />
      </Button>
    ]

    return (
      <div>
        <FormattedMessage id="head.title.locations" defaultMessage="Locations">
          {title => <Helmet><title>{title}</title></Helmet>}
        </FormattedMessage>

        <PageTitle
          title={this.props.intl.formatMessage({ id: 'locations', defaultMessage: 'Locations' })}
          buttons={headerButtons}
        />

        <Table
          columns={columns}
          dataSource={locations}
          rowKey={record => record._id}
          loading={loading}
          onRow={(record) => ({
            onDoubleClick: () => { this.showDrawer(record) }
          })}
          onChange={this.onTableChange}
          pagination={pagination}
        />
        <DrawerComponent
          title={location._id ? location.name : this.props.intl.formatMessage({ id: 'create location', defaultMessage: 'Create location' })}
          onClose={this.hideDrawer}
          visible={!!drawer}
          footerButtons={drawerButtons}
        >
          <LocationForm
            projects={projects}
            location={location}
            readOnlyNewProject={readOnlyNewProject}
            formLoading={formLoading}
            fetchProjects={this.fetchProjects}
            updateLocation={this.updateLocation}
            form={this.props.form}
          />
        </DrawerComponent>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  projects: state.projectReducer.projects,
  selectedProject: state.projectReducer.project
})

export default injectIntl(Form.create({ name: 'location_form' })(connect(mapStateToProps)(LocationsPage)))
