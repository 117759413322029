import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Form, Input, Select, Spin } from 'antd'

import './styles.scss'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
  className: 'formItem'
}

class ManufacturerForm extends React.Component {
  render () {
    const { props } = this
    const { getFieldDecorator } = props.form
    const {
      name,
      street,
      postcode,
      city,
      country,
      website,
      email,
      phone,
      project,
    } = this.props.manufacturer

    return (
      <React.Fragment>
        <Form className="manufacturerForm">
          <Form.Item {...formItemLayout} label="Name">
            {getFieldDecorator('name', {
              initialValue: name,
              rules: [ {
                required: true,
                message: this.props.intl.formatMessage({ id: 'name is required', defaultMessage: 'Name is required' })
              } ]
            })(
              <Input
                onChange={event => props.updateManufacturer('name', event.target.value)}
              />
            )}
          </Form.Item>

          <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: 'project', defaultMessage: 'Project' })}>
            <Select
              showSearch
              allowClear
              style={{ width: '100%' }}
              optionFilterProp="children"
              notFoundContent={props.formLoading ? <div className="objects-spinner-container"><Spin size="small" /></div> : null}
              onFocus={() => props.fetchProjects('')}
              onSearch={value => props.fetchProjects(value)}
              onChange={projectID => props.updateManufacturer('project', projectID)}
              defaultValue={project ? project.projectName : ''}
              disabled={props.readOnlyNewProject}
            >
              {props.projects.map(project => (
                <Select.Option key={project._id} value={project._id}>{project.projectName}</Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item {...formItemLayout} label="Street">
            <Input
              value={street}
              onChange={event =>
                props.updateManufacturer('street', event.target.value)
              }
            />
          </Form.Item>

          <Form.Item {...formItemLayout} label="Postcode">
            <Input
              value={postcode}
              onChange={event =>
                props.updateManufacturer('postcode', event.target.value)
              }
            />
          </Form.Item>

          <Form.Item {...formItemLayout} label="City">
            <Input
              value={city}
              onChange={event =>
                props.updateManufacturer('city', event.target.value)
              }
            />
          </Form.Item>

          <Form.Item {...formItemLayout} label="Country">
            <Input
              value={country}
              onChange={event =>
                props.updateManufacturer('country', event.target.value)
              }
            />
          </Form.Item>

          <Form.Item {...formItemLayout} label="Website">
            <Input
              value={website}
              onChange={event =>
                props.updateManufacturer('website', event.target.value)
              }
            />
          </Form.Item>

          <Form.Item {...formItemLayout} label="E-Mail">
            <Input
              value={email}
              onChange={event =>
                props.updateManufacturer('email', event.target.value)
              }
            />
          </Form.Item>

          <Form.Item {...formItemLayout} label="Phone">
            <Input
              value={phone}
              onChange={event =>
                props.updateManufacturer('phone', event.target.value)
              }
            />
          </Form.Item>
        </Form>
      </React.Fragment>
    )
  }
}

export default injectIntl(connect()(ManufacturerForm))
