import moment from 'moment'

import axios from '../helpers/axios'
import store from '../store'
import config from 'config'

class AcceptanceService {
  async list (params = {}) {
    const projectID = store.getState().projectReducer.project
    if (projectID) {
      params.project = projectID._id
    }

    return axios.get('/acceptance', params)
  }

  async listV2 (params = {}) {
    const projectID = store.getState().projectReducer.project
    if (projectID) {
      params.project = projectID._id
    }
    return axios.get(config.server.host + '/1.1/acceptance', params)
  }

  async save (acceptance) {
    const params = {
      name: acceptance.name || `Abnahme vom ${moment(acceptance.createdAt).format('DD.MM.YYYY HH:ss')}`,
      project: acceptance.project || null,
      participant: acceptance.participant,
      archived: acceptance.archived,
      location: acceptance.location
    }

    if (acceptance.locationName) {
      params.location = acceptance.locationName
    }

    return axios.put(`/acceptance/${acceptance._id}`, params)
  }

  async delete (acceptance) {
    return axios.delete(`/acceptance/${acceptance._id}`)
  }
}

const acceptanceService = new AcceptanceService()

export default acceptanceService
