import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Helmet } from "react-helmet"

export class RightsPage extends React.Component {
  render () {
    return (
      <div>
        <FormattedMessage id="head.title.rights" defaultMessage="Permissions">
          {title => <Helmet><title>{title}</title></Helmet>}
        </FormattedMessage>
        <h1>
          <FormattedMessage
            id="rights"
            defaultMessage="Rights"
          />
        </h1>
      </div>
    )
  }
}

export default RightsPage
