import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import actions from 'actions'
import axios from 'axios'
import config from 'config'

import { Button, Icon, notification, Table, Form, Input, Dropdown, Menu, Tag } from 'antd'

import contractSectionService from 'services/contractSection'
import acceptanceService from 'services/acceptance'
import recordingService from 'services/recording'
import locationService from 'services/location'
import formService from 'services/form'
import objectService from 'services/object'
import projectService from 'services/project'

import AcceptanceForm from 'components/Acceptance/Form'
import RecordingForm from 'components/Recording/Form'
import DrawerComponent from 'components/Drawer'
import PageTitle from 'components/Global/PageTitle'
import PopconfirmDelete from 'components/Global/PopconfirmDelete'
import ModalDefectCreation from 'components/ModalDefectCreation'

export class AcceptancesPage extends React.Component {
  _isMounted = false
  state = {
    acceptances: [],
    recordings: [],
    locations: [],
    projects: [],
    contractSections: [],
    recording: {},
    acceptance: {},
    objectName: '',
    loading: false,
    formLoading: false,
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50', '100']
    },
    createdByFilters: [],
    recordingCustomFormDefault: {},
    recordingCustomForm: {},
    objectIdSearchResult: [],
    modalDefectCreationVisible: false
  }

  componentDidMount () {
    this._isMounted = true;

    this.fetchAcceptances();
    this.fetchSettings()
  }

  componentDidUpdate (prevProps) {
    if (this.props.project !== prevProps.project) {
      this.fetchAcceptances();
      this.fetchSettings();
      this.fetchObjects();
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { objectName } = nextProps.settings.data.buildx;
    if (objectName !== prevState.objectName) {
      return {
        objectName: nextProps.settings.data.buildx.objectName
      }
    }
    return null
  }

  fetchObjects = async () => {
    try {
      const objects = await objectService.objectsList();

      const uniqueObjects = _.uniqBy(objects, (x) => {
        return x.name
      })

      const updatedObjects = uniqueObjects || [];
      this.props.dispatch(actions.setObjects(updatedObjects))
    }
    catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({
          id: 'objects',
          defaultMessage: 'Objects'
        })} - download error`
      })
    }
  };

  fetchAcceptances = async (params) => {
    this.setState({
      loading: true
    });

    try {
      const acceptances = await acceptanceService.listV2({
        ...params
      });

      const pagination = {
        ...this.state.pagination
      };

      pagination.total = acceptances.totalDocs;

      if (this._isMounted) {
        this.setState({
          acceptances: acceptances.docs,
          createdByFilters: acceptances.filterOptions.createdBy,
          pagination,
          loading: false
        })
      }
    }
    catch (error) {
      this.setState({
        loading: false
      });
      notification.error({
        message: `${this.props.intl.formatMessage({
          id: 'acceptances',
          defaultMessage: 'Acceptances'
        })} - download error`
      })
    }
  };

  fetchProjects = async (value) => {
    this.setState({
      formLoading: true
    });
    const timer = setTimeout(async () => {
      try {
        let projects = await projectService.listV2({
          projectName: [value],
          limit: 100
        });

        this.setState({
          projects: projects.docs,
          formLoading: false
        });

        clearTimeout(timer)
      }
      catch (error) {
        this.setState({
          projects: [],
          formLoading: false
        });
        clearTimeout(timer)
      }
    })
  }

  fetchLocations = async (value) => {
    this.setState({
      formLoading: true
    });
    const timer = setTimeout(async () => {
      try {
        const locations = await locationService.listV2({
          name: [value],
          limit: 100
        });

        const uniqueLocations = _.uniqBy(locations.docs, (x) => {
          return x.name
        });

        if (this._isMounted) {
          this.setState({
            locations: uniqueLocations,
            formLoading: false
          })
        }
        clearTimeout(timer)
      }
      catch (error) {
        this.setState({
          formLoading: false
        });
        notification.error({
          message: `${this.props.intl.formatMessage({
            id: 'locations',
            defaultMessage: 'Locations'
          })} - download error`
        });
        clearTimeout(timer)
      }
    }, 1000)
  }

  fetchSettings = async () => {
    try {
      const recordingCustomFormID = (this.props.settings.data.buildx.custom_forms || {}).acceptance
      let recordingCustomFormDefault = {}
      if (recordingCustomFormID) {
        recordingCustomFormDefault = await formService.get(recordingCustomFormID)
      }
      if (this._isMounted) {
        this.setState({
          recordingCustomFormDefault
        })
      }
    }
    catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({
          id: 'settings',
          defaultMessage: 'Settings'
        })} - download error`
      })
    }
  }

  fetchContractSections = async (value) => {
    this.setState({
      formLoading: true
    });
    const timer = setTimeout(async () => {
      try {
        const contractSections = await contractSectionService.listV2({
          name: [value],
          limit: 100
        });

        if (this._isMounted) {
          this.setState({
            contractSections: contractSections.docs,
            formLoading: false
          })
        }
        clearTimeout(timer)
      }
      catch (error) {
        notification.error({
          message: `${this.props.intl.formatMessage({
            id: 'contract sections',
            defaultMessage: 'Contract sections'
          })} - download error`
        });

        this.setState({
          contractSections: [],
          formLoading: false
        });
        clearTimeout(timer)
      }
    }, 1000)
  }

  showRecordingDrawer = async recording => {
    let recordingCustomForm = {};
    if (recording.project) {
      try {
        const project = this.props.projects.filter(item => item._id === recording.project)[0]
        if (project && project.form) {
          recordingCustomForm = await formService.get(project.form)
        }
      }
      catch (error) {
      }
    }

    this.setState({
      recording,
      recordingCustomForm
    })
  }

  showAcceptanceDrawer = acceptance => {
    this.setState({
      acceptance
    })
  }

  hideDrawer = () => {
    this.setState({
      recording: {},
      acceptance: {},
      recordingCustomForm: {}
    })
  }

  assignObjectHandler = (value, e) => {
    this.changeName(e.props.children)
  };

  changeName = name => {
    this.updateRecording('objectId', {
      _id: null,
      name
    })
  };

  handleSearchObjectId = async value => {
    this.setState({
      formLoading: true
    });
    const timer = setTimeout(async () => {
      try {
        const response = await objectService.listV2({
          name: [value],
          limit: 100
        });

        this.setState({
          objectIdSearchResult: response.docs,
          formLoading: false
        });
        clearTimeout(timer)
      }
      catch (error) {
        this.setState({
          formLoading: false
        });
        clearTimeout(timer)
      }
    }, 1000)
  };

  saveRecording = async () => {
    this.props.form.validateFields(async err => {
      if (!err) {
        try {
          const savedRecording = await recordingService.save(this.state.recording);
          this.fetchAcceptances();

          notification.success({
            message: `${this.props.intl.formatMessage({
              id: 'updated successfully',
              defaultMessage: 'Updated successfully'
            })}`
          });

          this.setState({
            recording: {},
            recordings: [
              ...this.state.recordings.filter(recording => recording._id !== savedRecording._id),
              savedRecording
            ]
          })
        }
        catch (error) {
          notification.error({
            message: this.props.intl.formatMessage({
              id: 'saving error',
              defaultMessage: 'Saving Error'
            }),
            description: error.error
          })
        }
      }
    })
  };

  saveAcceptance = async () => {
    try {
      let payload = {
        ...this.state.acceptance
      };
      payload.location = this.state.acceptance.location._id;

      const savedAcceptance = await acceptanceService.save(payload);
      this.fetchAcceptances();
      notification.success({
        message: `${this.props.intl.formatMessage({
          id: 'updated successfully',
          defaultMessage: 'Updated successfully'
        })}`
      });

      delete savedAcceptance.createdBy;
      delete savedAcceptance.recordings;
      this.setState({
        acceptance: {},
        acceptances: [
          ...this.state.acceptances.map(acceptance => {
            if (acceptance._id === savedAcceptance._id) {
              return { ...acceptance, ...savedAcceptance }
            }
            return acceptance
          })
        ]
      })
    }
    catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({
          id: 'saving error',
          defaultMessage: 'Saving Error'
        }),
        description: error.error
      })
    }
  };

  deleteRecording = async () => {
    try {
      await recordingService.delete(this.state.recording);
      this.setState({
        recording: {},
        acceptances: [
          ...this.state.acceptances.map(acceptance => {
            if (acceptance._id === this.state.recording.acceptanceId) {
              const outputArray = acceptance.recordings.filter(recording => recording._id !== this.state.recording._id)
              acceptance.recordings = outputArray
            }
            return acceptance
          })
        ]
      })
    }
    catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({
          id: 'deleting error',
          defaultMessage: 'Deleting Error'
        }),
        description: error.error
      })
    }
  };

  deleteAcceptance = async () => {
    try {
      await acceptanceService.delete(this.state.acceptance);

      this.setState({
        acceptance: {},
        acceptances: [
          ...this.state.acceptances.filter(acceptance => acceptance._id !== this.state.acceptance._id)
        ]
      })
    }
    catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({
          id: 'deleting error',
          defaultMessage: 'Deleting Error'
        }),
        description: error.error
      })
    }
  };

  updateRecording = (field, value) => {
    this.setState({
      recording: {
        ...this.state.recording,
        [field]: value
      }
    }
    )
  };

  updateLocationInAcceptance = (value, e) => {
    if (e) {
      this.setState(
        state => ({
          acceptance: {
            ...state.acceptance,
            location: e.props.data
          }
        })
      )
    }
  };

  updateAcceptance = (field, value, e) => {
    this.setState(
      state => ({
        acceptance: {
          ...state.acceptance,
          [field]: value
        }
      })
    )
  };

  onTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.offset = pagination.current

    this.setState({
      pagination: {
        ...pager,
        current: pagination.current
      }
    });

    this.fetchAcceptances({
      limit: pagination.pageSize,
      offset: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    })
  };

  showRecordingPDF = recording => {
    axios({
      method: 'get',
      url: `${config.server.url}/recording/${recording._id}/pdf`,
      responseType: 'arraybuffer',
      headers: {
        authorization: localStorage.getItem('auth_token')
      }
    }).then(function (response) {
      let blob = new Blob([response.data], { type: 'application/pdf' });
      const fileName = `${recording._id}.pdf`;

      if (navigator && navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, fileName)
      } else {
        let blob = new Blob([response.data], { type: 'application/pdf' });

        let link = document.createElement('a');
        link.setAttribute('href', window.URL.createObjectURL(blob));
        link.setAttribute('download', `${recording.name}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    })
  };

  showAcceptancePDF = acceptance => {
    axios({
      method: 'get',
      url: `${config.server.url}/acceptance/${acceptance._id}/pdf`,
      responseType: 'arraybuffer',
      headers: {
        authorization: localStorage.getItem('auth_token')
      }
    }).then(function (response) {
      let blob = new Blob([response.data], { type: 'application/pdf' });
      const fileName = `${acceptance._id}.pdf`;

      if (navigator && navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, fileName)
      } else {
        let blob = new Blob([response.data], { type: 'application/pdf' });

        let link = document.createElement('a');
        link.setAttribute('href', window.URL.createObjectURL(blob));
        link.setAttribute('download', `${acceptance.name}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link)
      }
    })
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this[`searchInput${dataIndex}`] = node
          }}
          placeholder={this.props.intl.formatMessage({ id: 'search', defaultMessage: 'Suchen' })}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Suchen
        </Button>
        <Button
          onClick={() => this.handleReset(dataIndex, clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (<Icon type="search"
      style={{ color: filtered ? '#1890ff' : undefined }} />),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this[`searchInput${dataIndex}`].select())
      }
    }
  });

  handleSearch = (selectedKeys, field, confirm) => {
    confirm();
    this.setState({
      search: {
        ...this.state.search,
        [field]: selectedKeys[0]
      }
    })
  };

  handleReset = (field, clearFilters) => {
    clearFilters();

    this.setState({
      search: {
        ...this.state.search,
        [field]: ''
      }
    })
  };

  hideModalDefectCreation = () => {
    this.setState({
      modalDefectCreationVisible: false,
      recordsList: [],
      step: {
        index: 0,
        total: 0
      },
    })
  };

  showModalDefectCreation = (record) => {
    this.setState({
      modalDefectCreationVisible: true,
    })
  };

  createSingleDefect = (data) => {
    this.props.dispatch(actions.setDefectData({ income: data }));
    this.showModalDefectCreation()
  };

  createMultipleDefects = (data) => {
    const recordingsList = data.recordings.filter(item => !item.submissions || item.submissions.every(
      e => e.name !== 'defect'));
    const step = {
      index: 0,
      total: recordingsList.length
    };
    this.setState({
      recordingsList,
      step
    });
    this.props.dispatch(actions.setDefectData({
      income: recordingsList[0],
      step,
      type: 'acceptance'
    }));
    this.showModalDefectCreation()
  };

  switchRecord = () => {
    const { step, recordingsList } = this.state;
    const { dispatch } = this.props;

    if (step.index + 1 === step.total) {
      this.setState({
        modalDefectCreationVisible: false,
        step: {
          index: 0,
          total: 0
        }
      })
    } else {
      dispatch(actions.setDefectData({
        income: recordingsList[step.index + 1],
        type: 'acceptance',
        step: {
          ...step,
          index: step.index + 1,
        }
      }));
      this.setState({
        step: {
          ...step,
          index: step.index + 1,
        }
      })
    }
  };

  render () {
    const {
      originalData,
      modalDefectCreationVisible
    } = this.state;

    const expandedRowRender = (acceptance) => {
      const recordingColumns = [
        {
          title: 'Number',
          key: 'docNumber',
          dataIndex: 'docNumber',
          render: (docNumber) => {
            return docNumber || ''
          }
        },
        {
          title: this.state.objectName,
          key: 'object',
          dataIndex: 'objectId',
          render: (object) => {
            return (object && object.name) || ''
          }
        },
        {
          title: this.props.intl.formatMessage({
            id: 'reference number',
            defaultMessage: 'Reference Number'
          }),
          key: 'submittedRefNr',
          dataIndex: 'submittedRefNr',
          ...this.getColumnSearchProps('submittedRefNr'),
          render: (text, record) => {
            let data = []

            if (record.submittedWorkorder && record.submittedWorkorderId) {
              data.push(
                <span><Tag color="blue"><Icon type="check" /> Workorder ({record.submittedWorkorderId.docNumber})</Tag>&nbsp;&nbsp;</span>)
            }

            if (record.submittedTask && record.submittedTaskId) {
              data.push(
                <span><Tag color="blue"><Icon type="check" /> Task ({record.submittedTaskId.docNumber})</Tag>&nbsp;&nbsp;</span>)
            }

            if (record.submitted) {
              data.push(
                <span><Tag color="blue"><Icon type="check" /> SAP ({record.submittedRefNr})</Tag></span>)
            }

            if (record.submissions && record.submissions.some(e => e.name === 'defect')) {
              data.push(<span>
                <Tag color="blue"><Icon type="check" />
                  <FormattedMessage
                    id="defect"
                    defaultMessage="Defect" />&nbsp;
                  ({record.submissions.map(a => <span>{a.reference}</span>)})
                </Tag>
              </span>)
            }

            return data
          }
        },
        {
          key: 'actions',
          render: (record) => {
            return (<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Dropdown overlay={
                <Menu>
                  <Menu.Item
                    key="0"
                    onClick={() => this.showRecordingDrawer(record)}
                  >
                    <Icon type="edit" />&nbsp;
                    <FormattedMessage
                      id="edit"
                      defaultMessage="Edit"
                    />
                  </Menu.Item>
                  <Menu.Item
                    key="1"
                    onClick={() => this.showRecordingPDF(record)}
                  >
                    <Icon type="file-pdf" />&nbsp;
                    <FormattedMessage
                      id="generate pdf"
                      defaultMessage="Generate PDF"
                    />
                  </Menu.Item>

                  <Menu.Divider />

                  {((record.submissions && !record.submissions.some(e => e.name === 'defect')) || !record.submissions) &&
                    <Menu.Item
                      key="5"
                      onClick={() => this.createSingleDefect(record)}
                    >
                      <Icon type="plus" />&nbsp;
                    <FormattedMessage
                        id="create defect mainx"
                        defaultMessage="Create defect (MainX)"
                      />
                    </Menu.Item>
                  }

                  {record.submissions && record.submissions.some(e => e.name === 'defect') &&
                    <Menu.Item
                      disabled={true}
                      key="5"
                    >
                      <Icon type="check" />&nbsp;
                    <FormattedMessage
                        id="defect created"
                        defaultMessage="Defect created (MainX)"
                      />
                    </Menu.Item>
                  }
                </Menu>
              } trigger={['click']}>
                <a className="ant-dropdown-link" href="#">
                  <Icon type="more" style={{ fontSize: '2rem', color: '#444' }} />
                </a>
              </Dropdown>
            </div>)
          }
        }
      ]

      return (
        <Table
          rowKey={record => record._id}
          columns={recordingColumns}
          dataSource={acceptance.recordings}
          onRow={(record) => ({
            onDoubleClick: () => {
              this.showRecordingDrawer(record)
            }
          })}
          pagination={false}
        />
      )
    }

    const columns = [
      {
        title: this.props.intl.formatMessage({ id: 'number', defaultMessage: 'Number' }),
        key: 'docNumber',
        dataIndex: 'docNumber',
        ...this.getColumnSearchProps('docNumber')
      },
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        sorter: true,
        ...this.getColumnSearchProps('name')
      },
      {
        title: this.props.intl.formatMessage({ id: 'created at', defaultMessage: 'Created at' }),
        key: 'createdAt',
        dataIndex: 'createdAt',
        render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
        defaultSortOrder: 'descend',
        sorter: true
      },
      {
        title: this.props.intl.formatMessage({ id: 'created by', defaultMessage: 'Created by' }),
        key: 'createdBy',
        dataIndex: 'createdBy',
        render: createdBy => createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : '',
        filters: this.state.createdByFilters.map(user => ({
          text: `${user.firstName} ${user.lastName}`,
          value: user._id
        }))
      }, {
        key: 'actions',
        render: (text, record) => (
          <div style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-end'
          }}>
            <Dropdown overlay={
              <Menu>
                <Menu.Item
                  key="0"
                  onClick={() => this.showAcceptanceDrawer(record)}
                >
                  <Icon type="edit" />&nbsp;
                  <FormattedMessage
                    id="edit"
                    defaultMessage="Edit"
                  />
                </Menu.Item>
                <Menu.Item
                  key="1"
                  onClick={() => this.showAcceptancePDF(record)}
                >
                  <Icon type="file-pdf" />&nbsp;
                  <FormattedMessage
                    id="generate pdf"
                    defaultMessage="Generate PDF"
                  />
                </Menu.Item>

                <Menu.Divider />

                {
                  record.recordings && record.recordings.filter(item => !item.submissions || !item.submissions.some(
                    e => e.name === 'defect')).length > 0 &&
                  <Menu.Item
                    key="5"
                    disabled={record.recordings.length === 0}
                    onClick={() => this.createMultipleDefects(record)}
                  >
                    <Icon type="plus" />&nbsp;
                    <FormattedMessage
                      id="create defects mainx"
                      defaultMessage="Create defects (MainX)"
                    />
                  </Menu.Item>
                }

                {
                  record.recordings && record.recordings.length > 0 && record.recordings.filter(item => !item.submissions || !item.submissions.some(
                    e => e.name === 'defect')).length === 0 &&
                  <Menu.Item
                    disabled={true}
                    key="5"
                  >
                    <Icon type="check" />&nbsp;
                    <FormattedMessage
                      id="defect created"
                      defaultMessage="Defect created (MainX)"
                    />
                  </Menu.Item>
                }
              </Menu>
            } trigger={['click']}>
              <a className="ant-dropdown-link" href="#">
                <Icon type="more" style={{ fontSize: '2rem', color: '#444' }} />
              </a>
            </Dropdown>
          </div>
        )
      }
    ]

    const recordingDrawerButtons = [
      <Button
        key="cancel"
        onClick={this.hideDrawer}
      >
        <FormattedMessage
          id="cancel"
          defaultMessage="Cancel"
        />
      </Button>,
      <Button
        key="save"
        type="primary"
        onClick={this.saveRecording}
        disabled={this.state.recording && this.state.recording.isInvalid}
      >
        <FormattedMessage
          id="save"
          defaultMessage="Save"
        />
      </Button>
    ]

    if (['admin', 'superadmin'].includes(this.props.auth.auth_user.scope[0]) &&
      this.state.recording &&
      !this.state.recording.isVerified
    ) {
      recordingDrawerButtons.unshift(
        <PopconfirmDelete onConfirmAction={this.deleteRecording} />
      )
    }

    const acceptanceDrawerButtons = [
      <Button
        key="cancel1"
        onClick={this.hideDrawer}
      >
        <FormattedMessage
          id="cancel"
          defaultMessage="Cancel"
        />
      </Button>,
      <Button
        key="save2"
        type="primary"
        onClick={this.saveAcceptance}
      >
        <FormattedMessage
          id="save"
          defaultMessage="Save"
        />
      </Button>
    ]
    if (['admin', 'superadmin'].includes(this.props.auth.auth_user.scope[0])) {
      acceptanceDrawerButtons.unshift(
        <PopconfirmDelete key={'pop3'} onConfirmAction={this.deleteAcceptance} />
      )
    }
    return (
      <div>
        <FormattedMessage id="head.title.acceptances" defaultMessage="Acceptances">
          {title => <Helmet><title>{title}</title></Helmet>}
        </FormattedMessage>

        <PageTitle
          title={this.props.intl.formatMessage({
            id: 'acceptances',
            defaultMessage: 'Acceptances'
          })}
        />

        <Table
          columns={columns}
          dataSource={this.state.acceptances}
          loading={this.state.loading}
          rowKey={record => record._id}
          onChange={this.onTableChange}
          onRow={(record) => ({
            onDoubleClick: () => {
              this.showAcceptanceDrawer(record)
            }
          })}
          pagination={this.state.pagination}
          expandedRowRender={expandedRowRender}
        />
        <DrawerComponent
          key={'drawer1'}
          title={this.state.recording.name}
          onClose={this.hideDrawer}
          visible={!!this.state.recording._id}
          footerButtons={recordingDrawerButtons}
        >
          <RecordingForm
            recording={this.state.recording}
            searchedProjects={this.state.projects}
            formLoading={this.state.formLoading}
            fetchProjectsOnSearch={this.fetchProjects}
            updateRecording={this.updateRecording}
            assignObjectHandler={this.assignObjectHandler}
            handleSearchObjectId={this.handleSearchObjectId}
            objectIdSearchResult={this.state.objectIdSearchResult}
            fetchContractSections={this.fetchContractSections}
            objectName={this.state.objectName}
            form={this.props.form}
            contractSections={this.state.contractSections}
            extraFieldsForm={this.state.recordingCustomForm._id ? this.state.recordingCustomForm : this.state.recordingCustomFormDefault}
            acceptance
          />
        </DrawerComponent>
        <DrawerComponent
          key={'drawer2'}
          title={this.state.acceptance.name}
          onClose={this.hideDrawer}
          visible={!!this.state.acceptance._id}
          footerButtons={acceptanceDrawerButtons}
        >
          <AcceptanceForm
            acceptance={this.state.acceptance}
            projects={this.state.projects}
            formLoading={this.state.formLoading}
            fetchProjects={this.fetchProjects}
            updateAcceptance={this.updateAcceptance}
            locations={this.state.locations}
            updateLocationInAcceptance={this.updateLocationInAcceptance}
            fetchLocations={this.fetchLocations}
          />
        </DrawerComponent>
        <ModalDefectCreation
          visible={modalDefectCreationVisible}
          recordingData={originalData}
          updateStateDefect={this.updateStateDefect}
          onClose={this.hideModalDefectCreation}
          onCancel={this.hideModalDefectCreation}
          onOk={this.switchRecord}
          refreshData={this.fetchAcceptances}
        />
      </div>
    )
  }
}

AcceptancesPage.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.authReducer,
  settings: state.settingsReducer,
  objects: state.objectsReducer,
  projects: state.projectReducer.projects
})

export default injectIntl(Form.create({ name: 'acceptance_form' })(
  connect(mapStateToProps)(AcceptancesPage)))
