import types from 'actions/constants'

export const initialState = {
  data: {},
  lang: ''
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SETTINGS:
      return {
        ...state,
        data: action.settings
      }
    case types.LANG:
      return {
        ...state,
        lang: action.lang
      }
    default:
      return state
  }
}

export default reducer
