import React from 'react'
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc'
import { FormattedMessage } from 'react-intl'
import {
  Icon,
  Input,
  Menu,
  Dropdown,
  Modal,
  Button,
  Select,
  AutoComplete,
  Spin,
  Drawer
} from 'antd'
import RecordingForm from 'components/Recording/Form'

import '../Form/styles.scss'

const { Option } = Select

const DragHandle = sortableHandle(() => <span className="drag-handler">:::</span>)

const SortableItem = SortableElement((props) => {
  const {
    value,
    indexValue,
    recording,
    form,
    auth,
    objectName,
    dataXEnabled,
    extraFieldsForm,
    handleSearchObjectId,
    handleSearchRecordingId,
    objectIdSearchResult,
    removeTagHandler,
    clearTagHandler,
    recordingIdSearchResult,
    handleRecordingSelect,
    taskIdChange,
    handleTaskIdChangeAutocomplete,
    removeTaskholder,
    showModalComment,
    markAsDoneTask,
    hideModalComment,
    modalCommentActive,
    formLoading,
    recordingDrawerVisible,
    recordingDrawerShow,
    recordingDrawerHide
  } = props

  const menu = (
    <Menu>
      <Menu.Item>
        <span onClick={() => markAsDoneTask(value.id)}>
          {
            value.complete
              ? <FormattedMessage id="Mark as undone" defaultMessage="Mark as undone"/>
              : <FormattedMessage id="Mark as done" defaultMessage="Mark as done"/>
          }
        </span>
      </Menu.Item>
    </Menu>
  )

  const modalButtons = [
    <Button
      key="cancel"
      onClick={hideModalComment}
    >
      <FormattedMessage
        id="cancel"
        defaultMessage="Cancel"
      />
    </Button>,
    <Button
      key="ok"
      type="primary"
      onClick={hideModalComment}
    >
      Ok
    </Button>
  ]

  let recordingValue = []

  if (value.recording instanceof Array) {
    recordingValue = value.recording
  } else {
    recordingValue = [value.recording]
  }

  const objectIdVelue = value.objectId ? value.objectId.name : ''

  const renderRecordingsValue = () => {
    let result = []
    if (value.recording) {
      result = recordingValue.map((recording) => {
        return {
          key: recording._id || '',
          label: <div className="task-recording-tag"
                      data-tag={recording._id}
                      onClick={(e) => recordingDrawerShow(e,
                        value.recording)}>{recording.name}</div>
        }
      })
    }

    return result
  }
  return (
    < div className="taskholder" key={indexValue}>
      <div className="drag-handler-wrap">
        <DragHandle/>
      </div>
      <div className="content-wrap">
        <div className="task-name-field">
          <label>Name</label>
          <Input
            className={value.complete ? 'workorder-task-completed' : ''}
            placeholder=""
            value={value.name}
            onChange={taskIdChange('name', indexValue)}
            disabled={value.complete}
          />
        </div>
        <div>
          <label><FormattedMessage id="Objectnumber" defaultMessage="Objectnumber"/></label>
          <AutoComplete
            // showSearch
            // defaultValue={value.objectId ? value.objectId.name : ''}
            value={objectIdVelue}
            allowClear={true}
            // placeholder="Select Object"
            // optionFilterProp="children"
            notFoundContent={formLoading ?
              <div className="workorder-spinner-container"><Spin size="small"/></div> : null}
            onFocus={() => handleSearchObjectId('objectId', objectIdVelue, indexValue)}
            onSearch={(value) => handleSearchObjectId('objectId', value, indexValue)}
            onSelect={(value) => handleTaskIdChangeAutocomplete('objectId', indexValue, value)}
            disabled={value.complete}
          >
            {
              objectIdSearchResult.map((object, i) => {
                return (<Option key={object._id}
                                id={object._id}
                                value={object.name}>{object.name}</Option>)
              })
            }
          </AutoComplete>
        </div>

        <div>
          <label><FormattedMessage id="Recording" defaultMessage="Recording"/></label>
          <Select
            mode="multiple"
            labelInValue={true}
            maxTagCount={1}
            value={renderRecordingsValue()}
            allowClear={true}
            clearIcon={<Icon onClick={() => clearTagHandler(value.id)} type="close-circle"/>}
            // defaultValue={value.recording}
            notFoundContent={formLoading ?
              <div className="workorder-spinner-container"><Spin size="small"/></div> : null}
            onDeselect={(tagValue) => removeTagHandler(tagValue, value.id)}
            onFocus={(e) => handleSearchRecordingId('recording', '', indexValue, e)}
            onSearch={(value) => handleSearchRecordingId('recording', value, indexValue)}
            onSelect={(value, e) => handleRecordingSelect('recording', indexValue, value, e)}
            disabled={value.complete}
          >
            {
              recordingIdSearchResult.map((recording, i) => {
                return (<Option key={recording._id + i}
                                id={recording._id}
                                value={recording.name}>{recording.name}</Option>)
              })
            }
          </Select>
        </div>

        <div className="task-description-field">
          <label><FormattedMessage id="Description" defaultMessage="Description"/></label>
          <Input.TextArea
            className={value.complete ? 'workorder-task-completed' : ''}
            placeholder=""
            value={value.description}
            onChange={taskIdChange('description', indexValue)}
            disabled={value.complete}
          />
        </div>

        <span className="remove-workorder-task"
              onClick={removeTaskholder(indexValue)}><FormattedMessage id="Remove"
                                                                       defaultMessage="Remove"/></span>
      </div>
      <div className="task-options">
        <Dropdown overlay={menu} trigger={['click']}>
          <span>
            <Icon type="more"/>
          </span>
        </Dropdown>
        <span>
          {
            value.complete ? (<Icon className="task-workorder-icon" type="check-circle"/>) : null
          }
        </span>
        {/* {
          value.comments ? (<span onClick={() => showModalComment(value.id)}><Icon type="wechat" /></span>) : null
        } */}
        <span onClick={() => showModalComment(value.id)}><Icon type="wechat"/></span>
      </div>
      <Modal
        title=""
        visible={modalCommentActive ? modalCommentActive === value.id : false}
        width={640}
        onCancel={hideModalComment}
        destroyOnClose
        footer={modalButtons}
      >
        <h3>
          <FormattedMessage
            id="comment"
            defaultMessage="Comment"
          />
        </h3>
        <span>{value.comments ? value.comments : 'No comments'}</span>
      </Modal>
      <Drawer
        title="Recording Drawer"
        width={640}
        closable={true}
        onClose={recordingDrawerHide}
        visible={recordingDrawerVisible}
      >
        <RecordingForm
          recording={recording}
          dataXEnabled={dataXEnabled}
          extraFieldsForm={extraFieldsForm}
          objectName={objectName}
          // updateRecording={this.updateRecording}
          // assignObjectHandler={this.assignObjectHandler}
          // handleSearchObjectId={this.handleSearchObjectId}
          // objectIdSearchResult={this.state.objectIdSearchResult}
          // objectName={this.state.objectName}
          // objects={this.props.objects.objects || []}
          // dataXEnabled={this.state.dataXEnabled}
          // extraFieldsForm={this.state.recordingCustomForm._id ? this.state.recordingCustomForm : this.state.recordingCustomFormDefault}
          form={form}
          auth={auth}
          readOnly={true}
        />
      </Drawer>
    </div>
  )
})

const SortableList = SortableContainer(({
                                          items,
                                          objects,
                                          recording,
                                          formLoading,
                                          form,
                                          auth,
                                          objectName,
                                          dataXEnabled,
                                          extraFieldsForm,
                                          objectIdSearchResult,
                                          recordingIdSearchResult,
                                          removeTagHandler,
                                          clearTagHandler,
                                          taskIdChange,
                                          handleSearchRecordingId,
                                          handleTaskIdSelectChange,
                                          handleRecordingSelect,
                                          handleTaskIdChangeAutocomplete,
                                          removeTaskholder,
                                          markAsDoneTask,
                                          showModalComment,
                                          modalCommentActive,
                                          hideModalComment,
                                          recordingDrawerVisible,
                                          recordingDrawerShow,
                                          recordingDrawerHide,
                                          handleSearchObjectId
                                        }) => {
  return (
    <ul>
      {items.map((value, index) => {
        return (
          <SortableItem
            key={`item-${index}`}
            indexValue={index}
            index={index}
            value={value}
            objects={objects}
            recording={recording}
            form={form}
            auth={auth}
            objectName={objectName}
            dataXEnabled={dataXEnabled}
            extraFieldsForm={extraFieldsForm}
            formLoading={formLoading}
            objectIdSearchResult={objectIdSearchResult}
            recordingIdSearchResult={recordingIdSearchResult}
            removeTagHandler={removeTagHandler}
            clearTagHandler={clearTagHandler}
            handleRecordingSelect={handleRecordingSelect}
            taskIdChange={taskIdChange}
            handleTaskIdSelectChange={handleTaskIdSelectChange}
            handleTaskIdChangeAutocomplete={handleTaskIdChangeAutocomplete}
            handleSearchObjectId={handleSearchObjectId}
            markAsDoneTask={markAsDoneTask}
            showModalComment={showModalComment}
            hideModalComment={hideModalComment}
            modalCommentActive={modalCommentActive}
            recordingDrawerVisible={recordingDrawerVisible}
            handleSearchRecordingId={handleSearchRecordingId}
            recordingDrawerShow={recordingDrawerShow}
            recordingDrawerHide={recordingDrawerHide}
            removeTaskholder={removeTaskholder}/>
        )
      })}
    </ul>
  )
})

export default SortableList
