import axios from '../helpers/axios'

class CommentService {
  delete (id) {
    return axios.delete(`/comment/${id}`)
  }

  update (id, data) {
    return axios.put(`/comment/${id}`, data)
  }
}

const commentService = new CommentService()
export default commentService
