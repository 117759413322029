import store from '../store'
import config from 'config'
import axios from '../helpers/axios'

export default {
  async list (params = {}) {
    return axios.get('/workorder', params)
  },
  async listV2 (params = {}) {
    const projectID = store.getState().projectReducer.project
    if (projectID) {
      params.project = projectID._id
    }
    return axios.get(config.server.host + '/1.1/workorder', params)
  },
  async addWorkOrder (data) {
    const response = await axios.post('/workorder', data)
    return response
  },
  async updateWorkOrder (id, data) {
    const response = await axios.put(`/workorder/${id}`, data)
    return response
  },
  async deleteWorkOrder (id) {
    const response = await axios.delete(`/workorder/${id}`)
    return response
  }
}
