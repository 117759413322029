import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'

export class ModalComponent extends React.Component {
  render() {
    const { title, visible, children, confirmLoading, hideFooter, onClose, onOk, onCancel, okText } = this.props
    const footerProps = {};
    if (hideFooter) {
      footerProps.footer = null
    }
    return (
      <Modal
        title={title}
        placement="right"
        width={700}
        destroyOnClose={true}
        closable={true}
        maskClosable={false}
        keyboard={false}
        onClose={onClose}
        onOk={onOk}
        okText={okText}
        onCancel={onCancel}
        visible={visible}
        confirmLoading={confirmLoading}
        {...footerProps}
      >
        {children}
      </Modal>
    )
  }
}

ModalComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  hideFooter: PropTypes.bool,
}

export default ModalComponent
