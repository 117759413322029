import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'

import { Form, Input } from 'antd'
import Images from './Images'

import './styles.scss'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
  className: 'formItem'
}

class RequestForm extends React.Component {
  state = {}

  render () {
    const { props } = this

    return (
      <React.Fragment>
        <Form className="requestForm">
          <Form.Item {...formItemLayout} label="Name">
            <Input
              disabled={true}
              onChange={e => props.updateRequest('name', e.target.value)}
              value={props.request.name}
            />
          </Form.Item>
          <Form.Item {...formItemLayout} label={this.props.objectName}>
            <Input
              disabled={true}
              value={props.request.objectId && props.request.objectId.name}
            />
          </Form.Item>
          <Form.Item {...formItemLayout} label={props.intl.formatMessage({ id: 'description', defaultMessage: 'Description' })}>
            <Input.TextArea
              disabled={true}
              onChange={e => props.updateRequest('description', e.target.value)}
              value={props.request.description}
            />
          </Form.Item>
          <Images
            formItemLayout={formItemLayout}
            request={props.request}
          />
        </Form>
      </React.Fragment>
    )
  }
}

RequestForm.propTypes = {
  updateRequest: PropTypes.func.isRequired
}

export default injectIntl(connect()(RequestForm))
