import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { PageHeader } from 'antd'
import FileUploader from './FileUploader'
import imageService from 'services/image'
import defectService from 'services/defects'
import ImageZoom from 'react-medium-image-zoom'

import './styles.scss'

class ImagesUploadTab extends React.Component {
  state = {
    fileList: [],
    deleteFile: {},
  }

  handleDelete = (file) => {
    const { defect, updateImages } = this.props;
    imageService.delete(file._id)
    .then(response => {
      updateImages(defect.images.filter(item => item._id !== file._id))
    })
    .catch(error => {
      console.log(error)
    })
  }

  onChange = (info) => {
    this.setState({
      image: { ...info.file, fileName: info.file.name }
    })
  };

  uploadFile = async ([file]) => {
    const { defect, updateImages } = this.props;
    const formData = new FormData();
    formData.append('image', file);

    defectService.addImage(defect._id, formData)
    .then(response => {
      updateImages([response,...defect.images])
      this.setState({
        image: undefined
      })
    })
    .catch(error => {
      console.log(error)
    })
  };

  waitUntilImageLoaded = resolve => {
    setTimeout(() => {
      this.state.image
        ? resolve() // from onChange method
        : this.waitUntilImageLoaded(resolve);
    }, 10);
  };

  render() {
    const { defect, updateImages } = this.props;

    return <React.Fragment>
      <PageHeader
        onBack={this.props.action}
        title={this.props.intl.formatMessage({
          id: 'images upload',
          defaultMessage: 'images upload'
        })}
      />
      <div className="dragger-cover">
        <FileUploader
          handleUpload={this.uploadFile}
          handleDelete={this.handleDelete}
          handleUpdate={updateImages}
          files={defect.images}
        />
      </div>
    </React.Fragment>
  }
}

export default injectIntl(connect()(ImagesUploadTab))

