import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import jwtDecode from 'jwt-decode'

const ProtectedRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    render={() => {
      // ensure it's actually JWT format
      try {
        jwtDecode(localStorage.getItem('auth_token'))
      } catch (error) {
        console.log('👾 invalid token format', error)
        localStorage.setItem('auth_token', '')
        return <Redirect to="/login" />
      }

      // Check if token expired
      let expired = false

      const token = localStorage.getItem('auth_token')
      if (token) {
        const currentTime = new Date().getTime() / 1000
        if (Math.round(currentTime) > jwtDecode(token).exp) {
          /* expired */
          expired = true
        }
      }

      if (!token || expired) {
        localStorage.setItem('auth_token', '')
        return <Redirect to="/login" />
      }

      if (!rest.user.id) {
        return <Layout />
      }

      const userRole = rest.user.scope[0]
      if (!userRole) {
        return <Redirect to="/no-permissions" />
      }

      if (rest.roles && rest.roles.length > 0 && !rest.roles.includes(userRole)) {
        return <Redirect to="/no-permissions" />
      }

      return (
        <Layout path={rest.path}>
          <Component {...rest} />
        </Layout>
      )
    }}
  />
)

ProtectedRoute.propTypes = {
  user: PropTypes.object.isRequired,
  roles: PropTypes.array,
  path: PropTypes.string.isRequired
}

export default ProtectedRoute
