import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Tabs, AutoComplete } from 'antd'
import DrawerComponent from 'components/Drawer'
import DefectForm from 'components/Defect/Form'
import RecordingForm from 'components/Recording/Form'


import FileUploadTab from '../../../../components/FileUploadTab';
import ImagesUploadTab from '../../../../components/ImagesUploadTab';
import Updates from './Updates';

const { TabPane } = Tabs

class DefectsDrawerTabs extends React.Component {

  render() {
    const {
      action,
      workorder,
      updateFiles,
      defect,
      projects,
      contractSections,
      readOnlyNewProject,
      formLoading,
      fetchProjects,
      fetchContractSections,
      updateItem,
      form,
      handleRefClick,
      defectRecording,
      defectDrawerVisible,
      recordingCustomForm,
      drawerButtons,
      hideDrawer,
      updateImages
    } = this.props
    return (
      <Tabs defaultActiveKey="form">
        <TabPane
          tab={this.props.intl.formatMessage({
            id: 'informations',
            defaultMessage: 'Informations'
          })}
          key="form"
          className="recordings-popup-wrap"
        >
          <DefectForm
            defect={defect}
            projects={projects}
            contractSections={contractSections}
            readOnlyNewProject={readOnlyNewProject}
            formLoading={formLoading}
            fetchProjects={fetchProjects}
            fetchContractSections={fetchContractSections}
            updateItem={updateItem}
            form={form}
            handleRefClick={handleRefClick}
          />
          <DrawerComponent
            title={this.props.intl.formatMessage({
              id: 'recording details',
              defaultMessage: 'Recording details'
            }) + ` (${defectRecording.name})`}
            onClose={() => hideDrawer('defectDrawerVisible')}
            visible={defectDrawerVisible}
            footerButtons={drawerButtons}
            coverStyle={{ zIndex: '1001' }}
            closable
          >
            <RecordingForm
              recording={defectRecording}
              extraFieldsForm={recordingCustomForm}
              form={this.props.form}
              readOnly={true}
              acceptance={defectRecording.acceptanceId}
              defectsPage
            />
          </DrawerComponent>
        </TabPane>
        {
          defect._id &&
          [
            <TabPane
              tab={this.props.intl.formatMessage({ id: 'files', defaultMessage: 'Files' })}
              key="files"
            >
              <FileUploadTab
                action={action}
                workorder={workorder}
                updateFiles={updateFiles}
                defect={defect}
              />
            </TabPane>,
            <TabPane
              tab={this.props.intl.formatMessage({ id: 'images', defaultMessage: 'images' })}
              key="images"
            >
              <ImagesUploadTab
                action={action}
                workorder={workorder}
                updateImages={updateImages}
                defect={defect}
              />
            </TabPane>,
            <TabPane
              tab={this.props.intl.formatMessage({
                id: 'updates',
                defaultMessage: 'updates'
              })}
              key="updates"
            >
              <Updates
                updateItem={updateItem}
                data={defect}
              />
            </TabPane>
          ]
        }
      </Tabs>
    )
  }
}

DefectsDrawerTabs.propTypes = {
  recording: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  contractSections: PropTypes.array,
  submittedAt: PropTypes.instanceOf(Date)
}

const mapStateToProps = state => {
  return {
    auth: state.authReducer,
    projects: state.projectReducer.projects
  }
}

export default injectIntl(connect(mapStateToProps)(DefectsDrawerTabs))
