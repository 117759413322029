import React from 'react'
import { notification } from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'

import FormsList from './List'
import FormBuilder from './Builder'
import formService from 'services/form'

export class FormsPage extends React.Component {
  state = {
    forms: [],
    loading: false,
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: [ '10', '20', '30', '50', '100' ]
    },
    form: {}
  }

  componentDidMount () {
    this.fetchForms()
  }

  componentDidUpdate (prevProps) {
    if (this.props.project !== prevProps.project) {
      this.fetchForms()
    }
  }

  fetchForms = async (params) => {
    this.setState({
      loading: true
    })
    try {
      const forms = await formService.listV2({
        ...params
      })

      const pagination = {
        ...this.state.pagination
      }

      pagination.total = forms.totalDocs

      this.setState({
        forms: forms.docs,
        loading: false,
        pagination
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({ id: 'forms', defaultMessage: 'Forms' })} - download error`
      })

      this.setState({
        forms: [],
        loading: false
      })
    }
  }

  setForm = form => {
    this.setState({
      form
    })
  }

  updateForm = (field, value) => {
    this.setState({
      form: {
        ...this.state.form,
        [field]: value
      }
    })
  }

  saveForm = async () => {
    try {
      const savedForm = await formService.save(this.state.form)

      this.fetchForms()
      if (this.state.form._id) {
        notification.success({
          message: `${this.props.intl.formatMessage({ id: 'updated successfully', defaultMessage: 'Updated successfully' })}`
        })
      } else {
        notification.success({
          message: `${this.props.intl.formatMessage({ id: 'created successfully', defaultMessage: 'Created successfully' })}`
        })
      }

      this.setState({
        form: {},
        forms: [
          ...this.state.forms.filter(form => form._id !== savedForm._id),
          savedForm
        ]
      })
    } catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({ id: 'saving error', defaultMessage: 'Saving Error' }),
        description: error.error
      })
    }
  }

  deleteForm = async (form) => {
    try {
      await formService.delete(form)

      this.setState({
        form: {},
        forms: [
          ...this.state.forms.filter(item => item._id !== form._id)
        ]
      })
    } catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({ id: 'deleting drror', defaultMessage: 'Deleting Error' }),
        description: error.message
      })
    }
  }

  onTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    pager.offset = pagination.current

    this.setState({
      pagination: {
        ...pager,
        current: pagination.current
      }
    })

    this.fetchForms({
      limit: pagination.pageSize,
      offset: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    })
  }
  render () {
    const { form, forms, project, pagination, loading } = this.state

    return (
      <React.Fragment>
        <FormattedMessage id="head.title.forms" defaultMessage="Forms">
          {title => <Helmet><title>{title}</title></Helmet>}
        </FormattedMessage>
        {form.name === undefined ? (
          <FormsList
            forms={forms}
            projects={this.props.projects}
            project={project}
            loading={loading}
            onTableChange={this.onTableChange}
            pagination={pagination}
            setForm={this.setForm}
            deleteForm={this.deleteForm}
          />
        ) : (
          <FormBuilder
            formData={form}
            setForm={this.setForm}
            updateForm={this.updateForm}
            saveForm={this.saveForm}
            authUser={this.props.user}
          />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  projects: state.projectReducer.projects
})

export default injectIntl(connect(mapStateToProps)(FormsPage))
