import axios from '../helpers/axios'

export default {
  reset (password, token) {
    return axios.post(`/password?token=${token}`, {
      password
    })
  },

  async sendToken (email) {
    return axios.post('/resetpassword', {
      email,
      passwordUrl: `${window.location.origin}/password`
    })
  }
}
