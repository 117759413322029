import axios from '../helpers/axios'
// import store from '../store'
import config from 'config'
const axiosCustom = require('axios')

export default {
  async list () {
    return axios.get('/project')
  },

  async listV2 (params = {}) {
    // const projectID = store.getState().projectReducer.project
    // if (projectID) {
    //   params.project = projectID._id
    // }
    return axios.get(config.server.host + '/1.1/project', params)
  },

  async searchProjects (limit, offset, name) {
    const token = localStorage.getItem('auth_token')
    const response = await axiosCustom.get(`${config.server.host}/1.1/project?limit=${limit}&offset=${offset}&projectName[]=${name}`, { headers: {
      'authorization': token
    }
    })
    return response.data
  },

  async save (project) {
    const params = {
      projectName: project.projectName,
      projectNumber: project.projectNumber,
      projectManager: project.projectManager,
      form: project.form,
      status: project.status,
      archived: project.archived,
      custom_forms: project.custom_forms
    }

    if (typeof params.projectManager === 'object') {
      params.projectManager = params.projectManager._id
    }

    if (typeof params.form === 'object') {
      params.form = params.form._id
    }

    if (project._id) {
      return axios.put(`/project/${project._id}`, params)
    } else {
      return axios.post('/project', params)
    }
  }
}
