import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import { Table, notification, Icon, Button, Form, Input } from 'antd'
import specialWorkService from 'services/specialWork'
import { findIndexInArray } from 'helpers/utils'

import SpecialWorkForm from 'components/SpecialWork/Form'
import PageTitle from 'components/Global/PageTitle'
import DrawerComponent from 'components/Drawer'
import PopconfirmDelete from 'components/Global/PopconfirmDelete'

import './styles.scss'

export class SpecialWorkPage extends React.Component {
  state = {
    items: [],
    item: {},
    new: false,
    visibleDrawer: false,
    loading: false,
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50', '100']
    }
  }

  componentDidMount () {
    this.fetchSpecialWork()
  }

  fetchSpecialWork = async (params) => {
    this.setState({
      loading: true
    })
    try {
      const specialWork = await specialWorkService.listV2({
        ...params
      })

      const pagination = {
        ...this.state.pagination
      }

      pagination.total = specialWork.totalDocs

      this.setState({
        items: specialWork.docs,
        loading: false,
        pagination
      })
    }
    catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({
          id: 'specialwork',
          defaultMessage: 'Special Work'
        })} - download error`
      })

      this.setState({
        visibleDrawer: false,
        loading: false
      })
    }
  }

  showDrawerAdd = async (item) => {
    this.setState({
      visibleDrawer: true,
      new: true,
      item
    })
  }

  showDrawerEdit = async item => {
    this.setState({
      visibleDrawer: true,
      new: false,
      item
    })
  }

  hideDrawer = () => {
    this.setState({
      visibleDrawer: false,
      item: {}
    })
  }

  addSpecialWork = async () => {
    const { item, items } = this.state
    this.props.form.validateFields(async err => {
      if (!err) {
        try {
          const payload = {
            name: item.name,
            title: item.title,
            description: item.description,
            enabled: item.enabled
          }

          const specialWorkResponse = await specialWorkService.add(payload)

          this.fetchSpecialWork()
          notification.success({
            message: `${this.props.intl.formatMessage({
              id: 'created successfully',
              defaultMessage: 'Created successfully'
            })}`
          })

          let updatedArrray = [...items]
          updatedArrray.unshift(specialWorkResponse)

          this.setState({
            visibleDrawer: false,
            item: {},
            items: [...updatedArrray]
          })
        }
        catch (error) {
          notification.error({
            message: this.props.intl.formatMessage({
              id: 'saving error',
              defaultMessage: 'Saving error'
            }),
            description: error.error
          })
        }
      }
    })
  }

  saveSpecialWork = async () => {
    const { item, items } = this.state
    try {
      const payload = {
        name: item.name,
        title: item.title,
        description: item.description,
        enabled: item.enabled
      }

      await specialWorkService.update(item._id, payload)

      this.fetchSpecialWork()
      notification.success({
        message: `${this.props.intl.formatMessage({
          id: 'updated successfully',
          defaultMessage: 'Updated successfully'
        })}`
      })

      const updateIndex = findIndexInArray(items, item._id)
      let updatedArrray = [...items]
      updatedArrray[updateIndex] = item
      this.setState({
        visibleDrawer: false,
        item: {},
        items: [...updatedArrray]
      })
    }
    catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({
          id: 'saving error',
          defaultMessage: 'Saving error'
        }),
        description: error.message
      })
    }
  }

  deleteSpecialWork = async () => {
    try {
      await specialWorkService.delete(this.state.item._id)

      notification.success({
        message: this.props.intl.formatMessage({
          id: 'deleted successfully',
          defaultMessage: 'Deleted successfully'
        })
      })

      this.setState({
        visibleDrawer: false,
        item: {},
        items: [
          ...this.state.items.filter(item => item._id !== this.state.item._id)
        ]
      })
    }
    catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({
          id: 'deleting error',
          defaultMessage: 'Deleting Error'
        }),
        description: error.message
      })
    }
  }

  updateStateSpecialWork = (field, value) => {
    this.setState({
      item: {
        ...this.state.item,
        [field]: value
      }
    })
  }

  onTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    pager.offset = pagination.current

    this.setState({
      pagination: {
        ...pager,
        current: pagination.current
      }
    })

    this.fetchSpecialWork({
      limit: pagination.pageSize,
      offset: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    })
  }

  handleSearch = (selectedKeys, field, confirm) => {
    confirm()
    this.setState({
      search: {
        ...this.state.search,
        [field]: selectedKeys[0]
      }
    })
  }

  handleReset = (field, clearFilters) => {
    clearFilters()

    this.setState({
      search: {
        ...this.state.search,
        [field]: ''
      }
    })
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this[`searchInput${dataIndex}`] = node
          }}
          placeholder={this.props.intl.formatMessage({ id: 'search', defaultMessage: 'Suchen' })}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Suchen
        </Button>
        <Button
          onClick={() => this.handleReset(dataIndex, clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type="search"
      style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => String(record[dataIndex])
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this[`searchInput${dataIndex}`].select())
      }
    }
  })

  render () {
    const { intl } = this.props
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        ...this.getColumnSearchProps('name')
      }, {
        title: intl.formatMessage({ id: 'active', defaultMessage: 'Active' }),
        dataIndex: 'enabled',
        key: 'enabled',
        render: active => active &&
          <Icon type="check" style={{ color: '#005591', display: 'flex', alignSelf: 'center' }} />,
        filters: [
          {
            text: intl.formatMessage({ id: 'active', defaultMessage: 'Active' }),
            value: true
          }, {
            text: intl.formatMessage({ id: 'inactive', defaultMessage: 'Inactive' }),
            value: false
          }
        ]
      }, {
        title: intl.formatMessage({ id: 'created at', defaultMessage: 'CreatedAt' }),
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
        sorter: true
      }, {
        title: intl.formatMessage({ id: 'created by', defaultMessage: 'CreatedBy' }),
        dataIndex: 'createdBy',
        key: 'createdBy',
        sorter: true,
        render: (text, record) => {
          if (record.createdBy) {
            return record.createdBy.fullName
          }

          return ''
        }
      }, {
        key: 'actions',
        render: (text, record) => (
          <div style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-end'
          }}>
            <Button onClick={() => this.showDrawerEdit(record)}>
              <Icon type={'edit'} />
            </Button>
          </div>
        )
      }]

    const headerButtons = [
      <Button
        key={59934324}
        type="primary"
        onClick={this.showDrawerAdd}
      >
        <FormattedMessage
          id="create special work"
          defaultMessage="Create special work"
        />
      </Button>
    ]

    const footerDrawerButtons = [
      <Button onClick={this.hideDrawer} style={{ marginRight: 8 }}>
        <FormattedMessage
          id="cancel"
          defaultMessage="Cancel"
        />
      </Button>
    ]

    if (['admin', 'superadmin'].includes(this.props.auth.auth_user.scope[0]) &&
      this.state.item && !this.state.new) {
      footerDrawerButtons.unshift(
        <PopconfirmDelete onConfirmAction={this.deleteSpecialWork} />
      )
    }

    if (this.state.new) {
      footerDrawerButtons.push(
        <Button
          onClick={this.addSpecialWork} type="primary">
          {intl.formatMessage({ id: 'save special work', defaultMessage: 'Save special work' })}
        </Button>
      )
    } else {
      footerDrawerButtons.push(
        <Button
          onClick={this.saveSpecialWork} type="primary">
          {intl.formatMessage({ id: 'update special work', defaultMessage: 'Update special work' })}
        </Button>
      )
    }

    return (
      <div>
        <FormattedMessage id="head.title.specialwork" defaultMessage="Special Work">
          {title => <Helmet><title>{title}</title></Helmet>}
        </FormattedMessage>

        <PageTitle
          title={intl.formatMessage({ id: 'specialwork', defaultMessage: 'Special Work' })}
          buttons={headerButtons}
        />

        <Table
          // eslint-disable-next-line no-return-assign
          ref={ref => this._table = ref}
          columns={columns}
          dataSource={this.state.items}
          loading={this.state.loading}
          onRow={(record) => ({
            onDoubleClick: () => {
              this.showDrawerEdit(record)
            }
          })}
          expandedRowRender={record => {
            return <div>
              <p style={{ fontWeight: 'bold' }}>{record.title}</p>
              <p>{record.description}</p>
            </div>
            // return (<Editor editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(record.description)))} readOnly={true} />)
          }}
          rowKey={record => record._id}
          onChange={this.onTableChange}
          pagination={this.state.pagination}
        />
        <DrawerComponent
          title={this.state.new === true ? intl.formatMessage({
            id: 'create special work',
            defaultMessage: 'Create special work'
          }) : this.props.intl.formatMessage({
            id: 'edit special work',
            defaultMessage: 'Edit special work'
          })}
          onClose={this.hideDrawer}
          visible={this.state.visibleDrawer}
          footerButtons={footerDrawerButtons}
        >
          <SpecialWorkForm
            item={this.state.item}
            editorState={this.state.editorState}
            onEditorStateChange={this.onEditorStateChange}
            updateStateSpecialWork={this.updateStateSpecialWork}
            form={this.props.form}
          />
        </DrawerComponent>
      </div>
    )
  }
}

SpecialWorkPage.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.authReducer
})

export default injectIntl(Form.create({ name: 'specialwork_page' })(
  connect(mapStateToProps)(SpecialWorkPage)))
