import React from 'react'
import PropTypes from 'prop-types'
import config from 'config'
import ImageZoom from 'react-medium-image-zoom'
import { Collapse } from 'antd'

import './Form/styles.scss'

const { Panel } = Collapse

const RecordingPreview = (props) => {
  const { recording } = props
  return (
    <React.Fragment>
      <Collapse defaultActiveKey={[ '1' ]}>
        <Panel header={`${recording.name}`} key="1">
          <div className="image-block-cover">
            {recording.images && recording.images.map((value, index) => (
              <div className={"image-block"} key={index}>
                <ImageZoom
                  image={{
                    src: config.server.url + value.imageThumbUrl,
                    alt: '',
                    style: { width: '100px' }
                  }}
                  zoomImage={{
                    src: config.server.url + value.imageThumbUrl,
                    alt: ''
                  }}
                  defaultStyles={{
                    zoomContainer: {
                      zIndex: 9999
                    }
                  }}
                />
                <p>{value.comment}</p>
              </div>
            ))}
          </div>
        </Panel>

      </Collapse>
    </React.Fragment>
  )
}

RecordingPreview.propTypes = {
  recording: PropTypes.object.isRequired
}

export default RecordingPreview
