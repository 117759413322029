import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import _ from 'lodash'
import { Table, Icon, Button, notification, Form, Input, Tooltip } from 'antd'
import arrayMove from 'array-move'

import userService from 'services/user'
import workOrdersService from 'services/workOrders'
import specialWorkService from 'services/specialWork'
import objectService from 'services/object'
import recordingService from 'services/recording'
import formService from 'services/form'

import WorkOrderForm from 'components/WorkOrders/Form'
import PageTitle from 'components/Global/PageTitle'
import DrawerComponent from 'components/Drawer'
import PopconfirmDelete from 'components/Global/PopconfirmDelete'

export class WorkordersPage extends React.Component {
  state = {
    workOrders: [],
    users: [],
    assignUsers: [],
    specialWorks: [],
    objectName: '',
    assignSpecialWork: [],
    modalCommentActive: '',
    workOrder: {},
    visible: false,
    recordingDrawerVisible: false,
    selectedRowKeys: [],
    archivedFilters: [],
    createdByFilters: [],
    executedByFilters: [],
    projectsFilters: [],
    loading: false,
    formLoading: false,
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50', '100']
    },
    locations: {},
    objects: [],
    tasks: {
      id: '',
      shareholders: []
    },
    recordingCustomFormDefault: {},
    recordingCustomForm: {},
    taskEditMode: false,
    fileUploadMode: false,
    objectIdSearchResult: [],
    recordingIdSearchResult: [],
    allRecordings: [],
    recording: {}
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { objectName, recordingsName } = nextProps.settings.data.buildx
    if (objectName !== prevState.objectName ||
      recordingsName !== prevState.recordingsName ||
      nextProps.settings.data.datax.enabled !== prevState.dataXEnabled ||
      nextProps.settings.data.apps.sap !== prevState.sapEnabled) {
      return {
        objectName: nextProps.settings.data.buildx.objectName,
        recordingsName: nextProps.settings.data.buildx.recordingsName,
        dataXEnabled: nextProps.settings.data.datax.enabled,
        sapEnabled: nextProps.settings.data.apps.sap
      }
    }
    return null
  }

  toggleTaskEditMode = () => {
    this.setState({
      taskEditMode: !this.state.taskEditMode,
      fileUploadMode: false
    })
  }

  toggleFileuploadMode = () => {
    this.setState({
      fileUploadMode: !this.state.fileUploadMode,
      taskEditMode: false
    })
  }

  showDrawer = () => {
    this.setState({
      visible: true
    })
  };

  onClose = () => {
    this.setState({
      visible: false,
      taskEditMode: false,
      fileUploadMode: false,
      workOrder: {}
    })
  };

  showRecordingDrawer = async (e, recording) => {
    e.stopPropagation()
    const clickedTag = this.state.recordingIdSearchResult.filter((tag) => {
      return tag._id === e.target.getAttribute('data-tag')
    })

    let recordingCustomForm = {}
    if (recording.project) {
      try {
        const project = this.props.projects.filter(item => item._id === recording.project)[0]
        if (project && project.form) {
          recordingCustomForm = await formService.get(project.form)
        }
      } catch (error) {
      }
    }

    this.setState({
      recordingDrawerVisible: true,
      recording: clickedTag[0] || recording,
      recordingCustomForm
    })
  };

  removeTagHandler = (removingValue, shareHolderValueID) => {
    let copyShareholders = [...this.state.tasks.shareholders]
    const objTaskIndex = this.state.tasks.shareholders.findIndex(obj => obj.id === shareHolderValueID)
    if (copyShareholders[objTaskIndex].recording.length) {
      copyShareholders[objTaskIndex].recording = copyShareholders[objTaskIndex].recording.filter((recording) => {
        return recording._id !== removingValue.key
      })
    } else {
      copyShareholders[objTaskIndex].recording = []
    }

    this.setState({
      tasks: {
        shareholders: copyShareholders
      }
    })
  }

  clearTagHandler = (shareHolderValueID) => {
    let copyShareholders = [...this.state.tasks.shareholders]
    const objTaskIndex = this.state.tasks.shareholders.findIndex(obj => obj.id === shareHolderValueID)
    copyShareholders[objTaskIndex].recording = []

    this.setState({
      tasks: {
        shareholders: copyShareholders
      }
    })
  }

  onRecordingDrawerClose = () => {
    this.setState({
      recordingDrawerVisible: false
    })
  };

  componentDidMount () {
    this.fetchObjects()
    this.fetchWorkOrders()
    this.fetchSettings()
  }

  componentDidUpdate (prevProps) {
    if (this.props.project !== prevProps.project) {
      this.fetchWorkOrders()
    }
  }

  markAsDoneTaskHandler = async (id) => {
    let copyShareholders = [...this.state.tasks.shareholders]
    const objTaskIndex = this.state.tasks.shareholders.findIndex(obj => obj.id === id)
    copyShareholders[objTaskIndex].complete = !copyShareholders[objTaskIndex].complete

    this.setState({
      tasks: {
        shareholders: copyShareholders
      }
    })
  }

  onSortTasksEnd = ({ oldIndex, newIndex }) => {
    this.setState(previousState => ({
      tasks: { shareholders: arrayMove(previousState.tasks.shareholders, oldIndex, newIndex) }
    }))
  };

  setSharholdersOnMount = () => {
    this.setState({
      tasks: {
        id: '',
        shareholders: []
      }
    }, () => {
      this.state.workOrder.tasks.map((task) => {
        return this.setState(previousState => ({
          tasks: {
            shareholders: [...previousState.tasks.shareholders, {
              id: task._id,
              name: task.name,
              complete: task.complete ? task.complete : false,
              comments: task.comments,
              objectId: task.objectId,
              recording: task.recording,
              description: task.description
            }]
          }
        }))
      })
    })
  }

  handleTaskIdChange = (field, idx) => evt => {
    const { tasks } = this.state
    const newShareholders = tasks.shareholders.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder
      return { ...shareholder, id: this.state.workOrder._id, [field]: evt.target.value }
    })

    this.setState({ tasks: { shareholders: newShareholders } })
  }

  handleTaskIdChangeAutocomplete = (field, idx, value) => {
    var currentObjectId = this.state.objectIdSearchResult.filter(obj => {
      return obj.name === value
    })

    const { tasks } = this.state
    const newShareholders = tasks.shareholders.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder
      return { ...shareholder, id: this.state.workOrder._id, [field]: currentObjectId[0] }
    })

    this.setState({ tasks: { shareholders: newShareholders } })
  }

  handleSearchObjectId = (field, value, idx) => {
    const { tasks } = this.state
    const newShareholders = tasks.shareholders.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder
      return { ...shareholder, id: this.state.workOrder._id, [field]: { _id: null, name: value } }
    })

    this.setState({ tasks: { shareholders: newShareholders }, formLoading: true })
    const timer = setTimeout(async () => {
      try {
        const response = await objectService.listV2({
          name: [value],
          limit: 100
        })

        this.setState({
          objectIdSearchResult: response.docs,
          formLoading: false
        })
        clearTimeout(timer)
      } catch (error) {
        console.log('Fetching error')
        this.setState({
          formLoading: false
        })
        clearTimeout(timer)
      }
    }, 1000)
  }

  handleSearchRecordingId = (field, value, idx, e) => {
    this.setState({
      formLoading: true
    })
    const timer = setTimeout(async () => {
      try {
        const response = await recordingService.listV2({
          name: [value],
          limit: 100
        })

        const uniqueRecordings = _.uniqBy(response.docs, (x) => {
          return x.name
        })

        const concatRecordings = [...this.state.allRecordings, ...uniqueRecordings]

        this.setState({
          recordingIdSearchResult: uniqueRecordings,
          allRecordings: concatRecordings,
          formLoading: false
        })
        clearTimeout(timer)
      } catch (error) {
        console.log('Fetching error')
        this.setState({
          formLoading: false
        })
        clearTimeout(timer)
      }
    }, 1000)
  }

  handleRecordingSelect = (field, idx, value, e) => {
    var currentRecording = this.state.recordingIdSearchResult.filter(obj => {
      return obj._id === e.props.id
    })
    const { tasks } = this.state
    const newShareholders = tasks.shareholders.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder
      if (shareholder.recording) {
        return { ...shareholder, id: this.state.workOrder._id, [field]: [...currentRecording] }
      }
      return { ...shareholder, id: this.state.workOrder._id, [field]: currentRecording }
    })

    this.setState({ tasks: { shareholders: newShareholders } })
  }

  handleTaskIdSelectChange = (field, idx) => value => {
    const { tasks } = this.state
    const newShareholders = tasks.shareholders.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder
      return { ...shareholder, id: this.state.workOrder._id, [field]: { name: value } }
    })

    this.setState({ tasks: { shareholders: newShareholders } })
  }

  handleAddShareholder = () => {
    const { tasks } = this.state
    this.setState({
      tasks: { shareholders: tasks.shareholders.concat([{ id: '' }]) }
    })
  };

  handleRemoveTaskholder = idx => async () => {
    try {
      // await taskService.deleteTask(this.state.workOrder._id)
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({ id: 'task removing', defaultMessage: 'Task Removing fail' })} - upload fail`
      })
    }

    const { tasks } = this.state
    this.setState({
      tasks: { shareholders: tasks.shareholders.filter((s, sidx) => idx !== sidx) }
    })
  };

  fetchSettings = async () => {
    try {
      const recordingCustomFormID = (this.props.settings.data.buildx.custom_forms || {}).recording
      let recordingCustomFormDefault = {}
      if (recordingCustomFormID) {
        recordingCustomFormDefault = await formService.get(recordingCustomFormID)
      }
      this.setState({
        recordingCustomFormDefault
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({
          id: 'settings',
          defaultMessage: 'Settings'
        })} - download error`
      })
    }
  }

  fetchObjects = async () => {
    try {
      const objects = await objectService.objectsList()
      const uniqueObjects = _.uniqBy(objects, (x) => {
        return x.name
      })

      this.setState({
        objects: uniqueObjects
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({ id: 'objects', defaultMessage: 'Objects' })} - download error`
      })
    }
  }

  fetchSpecialWork = async (value) => {
    this.setState({
      formLoading: true
    })
    const timer = setTimeout(async () => {
      try {
        const res = await specialWorkService.listV2({
          name: [value],
          limit: 100
        })

        this.setState({
          assignSpecialWork: res.docs,
          formLoading: false
        })
        clearTimeout(timer)
      } catch (error) {
        notification.error({
          message: `${this.props.intl.formatMessage({ id: 'specialwork', defaultMessage: 'Special Work' })} - download error`
        })

        this.setState({
          assignSpecialWork: [],
          formLoading: false
        })
        clearTimeout(timer)
      }
    }, 1000)
  }

  fetchSpecialWorkOnMount = async () => {
    this.setState({
      formLoading: true
    })
    try {
      const res = await specialWorkService.listV2({
        limit: 100
      })

      this.setState({
        specialWorks: res.docs,
        formLoading: false
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({ id: 'specialwork', defaultMessage: 'Special Work' })} - download error`
      })

      this.setState({
        specialWorks: [],
        formLoading: false
      })
    }
  }

  fetchUsersHandler = async (value) => {
    this.setState({
      formLoading: true
    })
    const timer = setTimeout(async () => {
      try {
        const users = await userService.listV2({
          name: [value],
          limit: 100
        })

        this.setState({
          assignUsers: users.docs,
          formLoading: false
        })
        clearTimeout(timer)
      } catch (error) {
        console.log('Fetching error')
        this.setState({
          formLoading: false
        })
        clearTimeout(timer)
      }
    }, 1000)
  }

  sortList = list => {
    return (
      list &&
      list.sort(sortByallStatus)
    )
  };

  createWorkOrder = async () => {
    const { name, description, priority, rrule, executedBy, status, specialWork, specialWorkRequired, signatureRequired, archived } = this.state.workOrder
    this.props.form.validateFields(async err => {
      if (!err) {
        try {
          let payload = {
            name,
            description,
            priority,
            rrule,
            status,
            signatureRequired,
            specialWorkRequired,
            specialWork,
            archived: archived || false,
            tasks: []
          }
          if (executedBy) {
            payload.executedBy = executedBy._id
          }

          if (this.state.tasks.shareholders.length) {
            this.state.tasks.shareholders.map(async (task) => {
              let payloadTasks = {}
              if (task.name) {
                payloadTasks.name = task.name
                payloadTasks.description = task.description
                if (task.objectId) {
                  var currentObjectId = this.state.objects.filter(obj => {
                    return obj.name === task.objectId.name
                  })

                  payloadTasks.objectId = currentObjectId[0]._id
                }
                if (task.recording) {
                  if (task.recording instanceof Array) {
                    payloadTasks.recording = task.recording.map((recording) => recording._id)
                  } else {
                    payloadTasks.recording = [task.recording._id]
                  }
                }

                payload.tasks.push(payloadTasks)
              }
            })
          }
          const savedWorkOrder = await workOrdersService.addWorkOrder(payload)

          this.fetchWorkOrders()
          notification.success({
            message: `${this.props.intl.formatMessage({ id: 'created successfully', defaultMessage: 'Created successfully' })}`
          })

          this.setState({
            visible: false,
            taskEditMode: false,
            fileUploadMode: false,
            workOrder: {},
            pagination: {
              current: 1
            },
            workOrders: this.sortList([
              ...this.state.workOrders,
              savedWorkOrder
            ])
          })
        } catch (error) {
          notification.error({
            message: this.props.intl.formatMessage({ id: 'saving error', defaultMessage: 'Saving Error' }),
            description: error.message
          })
        }
      }
    })
  }

  updateWorkOrder = async () => {
    const { _id, name, description, priority, rrule, executedBy, status, specialWork, specialWorkRequired, signatureRequired, archived } = this.state.workOrder
    this.props.form.validateFields(async err => {
      if (!err) {
        try {
          const payload = {
            name,
            description,
            priority,
            rrule,
            status,
            signatureRequired,
            specialWorkRequired,
            specialWork,
            executedBy: executedBy ? executedBy._id : null,
            archived: archived || false
          }

          let copyWorkorders = [...this.state.workOrders]
          const objTaskIndex = this.state.workOrders.findIndex(obj => obj._id === this.state.workOrder._id)
          copyWorkorders[objTaskIndex].tasks = []

          if (this.state.tasks.shareholders.length) {
            this.state.tasks.shareholders.forEach((task) => {
              let payloadTasks = {}
              payloadTasks.name = task.name
              payloadTasks.description = task.description
              payloadTasks.objectId = task.objectId
              if (task.recording) {
                if (task.recording instanceof Array) {
                  payloadTasks.recording = task.recording.map((recording) => recording._id)
                } else {
                  payloadTasks.recording = [task.recording._id]
                }
              }

              if (task.objectId) {
                var currentObjectId = this.state.objects.filter(obj => {
                  return obj.name === task.objectId.name
                })

                payloadTasks.objectId = currentObjectId[0]._id
              }

              payloadTasks.complete = task.complete
              copyWorkorders[objTaskIndex].tasks.push(payloadTasks)
            })
          }
          this.setState({
            workOrders: copyWorkorders
          }, async () => {
            payload.tasks = this.state.workOrder.tasks
            if (this.state.workOrder.tasks.length ===0 && this.state.tasks.length !==0) {
              payload.tasks = this.state.tasks.shareholders
            }
            const savedWorkOrder = await workOrdersService.updateWorkOrder(_id, payload)
            this.setState({
              visible: false,
              taskEditMode: false,
              fileUploadMode: false,
              workOrder: {},
              workOrders: this.sortList([
                ...this.state.workOrders.filter(workOrder => workOrder._id !== savedWorkOrder._id),
                savedWorkOrder
              ])
            })
          })

          this.fetchWorkOrders()
          notification.success({
            message: `${this.props.intl.formatMessage({ id: 'updated successfully', defaultMessage: 'Updated successfully' })}`
          })
        } catch (error) {
          notification.error({
            message: this.props.intl.formatMessage({ id: 'saving error', defaultMessage: 'Saving Error' }),
            description: error.message
          })
        }
      }
    })
  }

  deleteWorkOrder = async () => {
    try {
      await workOrdersService.deleteWorkOrder(this.state.workOrder._id)

      this.setState({
        visible: false,
        workOrder: {},
        workOrders: [
          ...this.state.workOrders.filter(workOrder => workOrder._id !== this.state.workOrder._id)
        ]
      })
    } catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({ id: 'deleting error', defaultMessage: 'Deleting Error' }),
        description: error.message
      })
    }
  }

  fetchWorkOrders = async (params) => {
    this.setState({
      loading: true
    })
    try {
      const workOrders = await workOrdersService.listV2({
        ...params
      })

      const pagination = {
        ...this.state.pagination
      }

      pagination.total = workOrders.totalDocs

      this.setState({
        workOrders: workOrders.docs.sort(sortByallStatus),
        loading: false,
        createdByFilters: workOrders.filterOptions.createdBy,
        executedByFilters: workOrders.filterOptions.executedBy,
        projectsFilters: workOrders.filterOptions.createdBy,
        pagination
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({ id: 'recordings', defaultMessage: 'Recordings' })} - download error`
      })

      this.setState({
        workOrders: [],
        visible: false,
        loading: false,
        workOrder: {}
      })
    }
  }

  showModalEdit = async workOrder => {
    this.setSharholdersOnMount()
    this.setState({
      visible: true,
      new: false,
      workOrder,
      modalOption: 'edit'
    })
  }

  showModalAdd = async () => {
    this.setState({
      visible: true,
      new: true,
      workOrder: {},
      modalOption: 'add',
      tasks: {
        id: '',
        shareholders: [{ id: '' }]
      }
    })
  }

  showModalComment = (id) => {
    this.setState({
      modalCommentActive: id
    })
  }

  hideModalComment = () => {
    this.setState({
      modalCommentActive: ''
    })
  }

  updateStateWorkOrder = (field, value) => {
    this.setState({
      workOrder: {
        ...this.state.workOrder,
        [field]: value
      }
    })
  }

  updateAssignedUser = (value) => {
    this.setState({
      workOrder: {
        ...this.state.workOrder,
        executedBy: value
      }
    })
  }

  updateStateSpecialWork = (value) => {
    const specialWorks = value || []
    let newArraysIds = []
    if (specialWorks.length && this.state.assignSpecialWork.length) {
      specialWorks.forEach(item => {
        const currentObjectId = this.state.assignSpecialWork.find(obj => {
          return obj.name === item
        })
        if (currentObjectId) {
          newArraysIds.push(currentObjectId)
        }
      })
      this.setState({
        workOrder: {
          ...this.state.workOrder,
          specialWork: newArraysIds
        }
      })
    } else {
      this.setState({
        workOrder: {
          ...this.state.workOrder,
          specialWork: []
        }
      })
    }
  }

  handleReset = (field, clearFilters) => {
    clearFilters()

    this.setState({
      search: {
        ...this.state.search,
        [field]: ''
      }
    })
  }

  onTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    pager.offset = pagination.current

    this.setState({
      pagination: {
        ...pager,
        current: pagination.current
      }
    })

    this.fetchWorkOrders({
      limit: pagination.pageSize,
      offset: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    })
  }

  handleSearch = (selectedKeys, field, confirm) => {
    confirm()
    this.setState({
      search: {
        ...this.state.search,
        [field]: selectedKeys[0]
      }
    })
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => { this[`searchInput${dataIndex}`] = node }}
          placeholder={this.props.intl.formatMessage({ id: 'search', defaultMessage: 'Suchen' })}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Suchen
        </Button>
        <Button
          onClick={() => this.handleReset(dataIndex, clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => String(record[dataIndex]).toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this[`searchInput${dataIndex}`].select())
      }
    }
  })

  render () {
    const columns = [
      {
        title: this.props.intl.formatMessage({ id: 'number', defaultMessage: 'Number' }),
        key: 'docNumber',
        dataIndex: 'docNumber',
        ...this.getColumnSearchProps('docNumber'),
        render: (text, record) => {
          if (!record.executedBy) {
            return <span>{text} <Tooltip placement="top" title={this.props.intl.formatMessage({ id: 'incomplete', defaultMessage: 'Incomplete' })}><Icon type="warning" theme="filled" style={{ color: '#fa541c', alignSelf: 'center' }} /></Tooltip></span >
          } else {
            return <span>{text}</span>
          }
        }
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        ...this.getColumnSearchProps('name')
      }, {
        title: this.props.intl.formatMessage({ id: 'description', defaultMessage: 'Description' }),
        dataIndex: 'description',
        key: 'description',
        ...this.getColumnSearchProps('description')
      }, {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status) => {
          let statusText = ''
          switch (status) {
            case 1: {
              statusText = this.props.intl.formatMessage({ id: 'open', defaultMessage: 'Open' })
              break
            }
            case 2: {
              statusText = this.props.intl.formatMessage({ id: 'in progress', defaultMessage: 'In progress' })
              break
            }
            case 3: {
              statusText = this.props.intl.formatMessage({ id: 'on hold', defaultMessage: 'on hold' })
              break
            }
            case 4: {
              statusText = this.props.intl.formatMessage({ id: 'complete', defaultMessage: 'complete' })
              break
            }
            default:
              console.log('no status')
          }
          return (
            <span>{statusText}</span>
          )
        },
        sorter: true
      }, {
        title: this.props.intl.formatMessage({ id: 'created at', defaultMessage: 'createdAt' }),
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
        sorter: true
      },
      {
        title: this.props.intl.formatMessage({ id: 'assignee', defaultMessage: 'Assignee' }),
        key: 'executedBy',
        dataIndex: 'executedBy',
        render: executedBy => executedBy ? `${executedBy.firstName} ${executedBy.lastName}` : '',
        filters: this.state.executedByFilters.map(user => ({
          text: `${user.firstName} ${user.lastName}`,
          value: user._id
        }))
      },
      {
        title: this.props.intl.formatMessage({ id: 'created by', defaultMessage: 'Created By' }),
        key: 'createdBy',
        dataIndex: 'createdBy',
        render: createdBy => createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : '',
        filters: this.state.createdByFilters.map(user => ({
          text: `${user.firstName} ${user.lastName}`,
          value: user._id
        }))
      }, {
        title: this.props.intl.formatMessage({ id: 'archived', defaultMessage: 'Archived' }),
        key: 'archived',
        dataIndex: 'archived',
        align: 'center',
        render: active => active && <Icon type="check" style={{ color: '#005591' }} />,
        filters: [
          {
            text: this.props.intl.formatMessage({
              id: 'show all',
              defaultMessage: 'show all'
            }),
            value: 'showAll'
          },
          {
            text: this.props.intl.formatMessage({
              id: 'show archived',
              defaultMessage: 'show archived'
            }),
            value: 'showArchived'
          }
        ],
        filterMultiple: false,
        filteredValue: this.state.archivedFilters
      }
    ]

    if (!this.props.project) {
      columns.push({
        title: this.props.intl.formatMessage({ id: 'project', defaultMessage: 'Project' }),
        key: 'project',
        dataIndex: 'project.projectName'
      })
    }

    columns.push({
      key: 'actions',
      render: (text, record) => (
        <div style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'flex-end'
        }}>
          <Button onClick={() => this.showModalEdit(record)} >
            <Icon type={'edit'} />
          </Button>
        </div>
      )
    })

    const {
      workOrders,
      // selectedRowKeys,
      visible,
      modalOption,
      workOrder,
      assignUsers,
      objects,
      recording,
      assignSpecialWork,
      taskEditMode,
      fileUploadMode,
      modalCommentActive,
      formLoading,
      recordingDrawerVisible
    } = this.state

    const headerButtons = [
      <Button
        key={3589729832}
        type="primary"
        onClick={this.showModalAdd}
      >
        <FormattedMessage
          id="create workorder"
          defaultMessage="Create work order"
        />
      </Button>
    ]
    const footerDrawerButtons = [
      <Button onClick={this.onClose} style={{ marginRight: 8 }}>
        <FormattedMessage
          id="cancel"
          defaultMessage="Cancel"
        />
      </Button>
    ]

    if (['admin', 'superadmin'].includes(this.props.auth.auth_user.scope[0]) &&
      this.state.workOrder && !this.state.new) {
      footerDrawerButtons.unshift(
        <PopconfirmDelete onConfirmAction={this.deleteWorkOrder} />
      )
    }

    if (this.state.new) {
      footerDrawerButtons.push(
        <Button
          onClick={this.createWorkOrder} type="primary">
          {this.props.intl.formatMessage({ id: 'save workorder', defaultMessage: 'Save workorder' })}
        </Button>
      )
    } else {
      footerDrawerButtons.push(
        <Button
          onClick={this.updateWorkOrder} type="primary">
          {this.props.intl.formatMessage({ id: 'update workorder', defaultMessage: 'Update workorder' })}
        </Button>
      )
    }

    return (
      <div>
        <FormattedMessage id="head.title.workorders" defaultMessage="Workorders">
          {title => <Helmet><title>{title}</title></Helmet>}
        </FormattedMessage>

        <PageTitle
          title={this.props.intl.formatMessage({ id: 'orders', defaultMessage: 'Workorders' })}
          buttons={headerButtons}
        />

        <Table
          // eslint-disable-next-line no-return-assign
          ref={ref => this._table = ref}
          rowKey={record => record._id}
          columns={columns}
          dataSource={workOrders}
          loading={this.state.loading}
          onChange={this.onTableChange}
          onRow={(record) => ({
            onDoubleClick: () => { this.showModalEdit(record) }
          })}
          pagination={this.state.pagination}
        />

        <DrawerComponent
          title={this.state.new === true ? this.props.intl.formatMessage({ id: 'create a new workorder', defaultMessage: 'Create a new workorder' }) : this.props.intl.formatMessage({ id: 'edit workorder', defaultMessage: 'Edit workorder' })}
          onClose={this.onClose}
          visible={visible}
          footerButtons={footerDrawerButtons}
        >
          <WorkOrderForm
            scheduled={false}
            isNew={modalOption}
            workOrder={workOrder}
            users={assignUsers}
            objects={objects}
            recording={recording}
            form={this.props.form}
            auth={this.props.auth}
            objectName={this.state.objectName}
            formLoading={formLoading}
            extraFieldsForm={this.state.recordingCustomForm._id ? this.state.recordingCustomForm : this.state.recordingCustomFormDefault}
            dataXEnabled={this.state.dataXEnabled}
            fetchSpecialWork={this.fetchSpecialWork}
            fetchUsersHandler={this.fetchUsersHandler}
            objectIdSearchResult={this.state.objectIdSearchResult}
            recordingIdSearchResult={this.state.recordingIdSearchResult}
            handleRecordingSelect={this.handleRecordingSelect}
            removeTagHandler={this.removeTagHandler}
            clearTagHandler={this.clearTagHandler}
            specialWork={assignSpecialWork}
            taskEditMode={taskEditMode}
            toggleTaskEditMode={this.toggleTaskEditMode}
            fileUploadMode={fileUploadMode}
            toggleFileuploadMode={this.toggleFileuploadMode}
            updateAssignedUser={this.updateAssignedUser}
            updateStateWorkOrder={this.updateStateWorkOrder}
            updateStateSpecialWork={this.updateStateSpecialWork}
            recordingDrawerVisible={recordingDrawerVisible}
            recordingDrawerShow={this.showRecordingDrawer}
            recordingDrawerHide={this.onRecordingDrawerClose}
            option={modalOption}
            shareholders={this.state.tasks.shareholders}
            taskIdChange={this.handleTaskIdChange}
            handleTaskIdSelectChange={this.handleTaskIdSelectChange}
            handleTaskIdChangeAutocomplete={this.handleTaskIdChangeAutocomplete}
            handleSearchObjectId={this.handleSearchObjectId}
            handleSearchRecordingId={this.handleSearchRecordingId}
            addShareholder={this.handleAddShareholder}
            removeTaskholder={this.handleRemoveTaskholder}
            markAsDoneTask={this.markAsDoneTaskHandler}
            onSortTasksEnd={this.onSortTasksEnd}
            showModalComment={this.showModalComment}
            hideModalComment={this.hideModalComment}
            modalCommentActive={modalCommentActive}
          />
        </DrawerComponent>
      </div>
    )
  }
}

const sortByallStatus = (a, b) => {
  return (a.status - b.status)
}

WorkordersPage.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.authReducer,
  settings: state.settingsReducer,
  projects: state.projectReducer.projects,
  project: state.projectReducer.project
})

export default injectIntl(Form.create({ name: 'work_orders_form' })(connect(mapStateToProps)(WorkordersPage)))
