import axios from '../helpers/axios'
import config from 'config'
import store from '../store'

export default {
  async list (params = {}) {
    const projectID = store.getState().projectReducer.project
    if (projectID) {
      params.project = projectID._id
    }

    return axios.get('/contract-section', params)
  },

  async listV2 (params = {}) {
    const projectID = store.getState().projectReducer.project
    if (projectID) {
      params.project = projectID._id
    }
    return axios.get(config.server.host + '/1.1/contract-section', params)
  },

  async save (contractSection) {
    const params = {
      name: contractSection.name,
      disabled: contractSection.disabled,
      project: contractSection.project || null
    }

    if (contractSection._id) {
      return axios.put(`/contract-section/${contractSection._id}`, params)
    } else {
      const projectID = store.getState().projectReducer.project
      if (projectID) {
        params.project = projectID._id
      }

      return axios.post('/contract-section', params)
    }
  },

  async remove (contractSection) {
    return axios.delete(`/contract-section/${contractSection._id}`)
  }
}
