// import store from '../store'
import config from 'config'
import axios from '../helpers/axios'

export default {
  async get () {
    const response = await axios.get('/protocol')
    return response
  },
  async listV2 (params = {}) {
    // const projectID = store.getState().projectReducer.project
    // if (projectID) {
    //   params.project = projectID._id
    // }
    return axios.get(config.server.host + '/1.1/protocol', params)
  },
  async add (data) {
    const response = await axios.post('/protocol', data)
    return response
  },
  async update (id, data) {
    const response = await axios.put(`/protocol/${id}`, data)
    return response
  },
  async delete (id) {
    const response = await axios.delete(`/protocol/${id}`)
    return response
  }
}
