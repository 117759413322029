import React from 'react'
import { injectIntl } from 'react-intl'
import { List, Select, Form, Input, Button } from 'antd'

// import config from '../../../../config'

const Option = Select.Option

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
  className: 'formItem'
}

class AddProduct extends React.Component {
  state = {
    searchValue: '',
    selectedCategory: ''
  }

  updateSearch = (value) => {
    this.setState({
      searchValue: value
    })
  }

  updateCategory = (value) => {
    this.setState({
      selectedCategory: value
    })
  }

  renderSelect = (array) => {
    let key = 0
    const selectItems = array.map((category) => {
      key += 1
      return (<Option key={`${category.name}-${key}`} id={category._id} value={category.name}>{category.name}</Option>)
    })
    return (
      <Select
        defaultValue={this.props.intl.formatMessage({ id: 'choose category', defaultMessage: 'Choose a category' })}
        style={{ width: 250 }}
        onChange={(value) => this.updateCategory(value)}
      >
        {
          selectItems
        }
      </Select>
    )
  }

  render () {
    let filteredProducts = this.props.products.filter((product) => {
      return product.category && product.category.name === this.state.selectedCategory
    }).filter((product) => {
      return product.name.indexOf(this.state.searchValue) !== -1
    })

    return (
      <div className="recordings-add-product-wrap">
        <Button
          onClick={this.props.addProductSwitcher}
          type="primary"
          className="recordings-products-tab-back"
        >
          {this.props.intl.formatMessage({ id: 'back', defaultMessage: 'Back' })}
        </Button>
        <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: 'category', defaultMessage: 'Category' })}>
          {
            this.renderSelect(this.props.categories)
          }
        </Form.Item>
        <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: 'search', defaultMessage: 'Search' })}>
          <Input
            value={this.state.searchValue}
            onChange={event => this.updateSearch(event.target.value)}
          />
        </Form.Item>
        <List
          className="recordings-products-list"
          header={<div>{this.props.intl.formatMessage({ id: 'products', defaultMessage: 'Products' })}</div>}
          bordered
          dataSource={filteredProducts}
          renderItem={item => (
            <List.Item className="recordings-products-list-item" onClick={() => this.props.addProductToSection(item)}>
              {item.name}
            </List.Item>
          )}
        />
      </div>
    )
  }
}

AddProduct.propTypes = {}

export default injectIntl(AddProduct)
