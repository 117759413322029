import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import { Table, notification, Icon, Button, Form, Input, Badge } from 'antd'
import requestService from 'services/requests'
import workOrdersService from 'services/workOrders'

import RequestForm from 'components/Request/Form'
import DrawerComponent from 'components/Drawer'
import PageTitle from 'components/Global/PageTitle'

import './styles.scss'

const statusColors = {
  Green: '#40c353',
  Yellow: '#ddab56',
  Red: '#c53e3d',
  Blue: '#015590'
}

export class RequestsPage extends React.Component {
  state = {
    requests: [],
    drawer: false,
    objectName: '',
    search: {
      name: '',
      aksNumber: ''
    },
    loading: false,
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50', '100']
    },
    createdByFilters: [],
    request: {}
  }

  componentDidMount () {
    this.fetchRequests()
  }

  componentDidUpdate (prevProps) {
    if (this.props.project !== prevProps.project) {
      this.fetchRequests()
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { objectName } = nextProps.settings.data.buildx
    if (objectName !== prevState.objectName) {
      return {
        objectName: nextProps.settings.data.buildx.objectName
      }
    }
    return null
  }

  updateStateRequest = (field, value) => {
    this.setState({
      request: {
        ...this.state.request,
        [field]: value
      }
    })
  }

  createWorkOrder = async (savedRequest) => {
    const { name, description } = this.state.request
    this.props.form.validateFields(async err => {
      if (!err) {
        try {
          const payload = {
            name,
            description: description || ''
          }
          await workOrdersService.addWorkOrder(payload)

          notification.success({
            message: this.props.intl.formatMessage({ id: 'workorder created', defaultMessage: 'Workorder successfully created' })
          })

          this.setState({
            drawer: false,
            request: {},
            requests: this.sortList([
              ...this.state.requests.filter(request => request._id !== savedRequest._id),
              savedRequest
            ])
          })
        } catch (error) {
          notification.error({
            message: this.props.intl.formatMessage({ id: 'saving error', defaultMessage: 'Saving Error' }),
            description: error.message
          })
        }
      }
    })
  }

  approveRequst = async () => {
    const { _id } = this.state.request
    this.props.form.validateFields(async err => {
      if (!err) {
        try {
          const payload = {
            status: 2
          }
          const savedRequest = await requestService.updateRequest(_id, payload)

          this.createWorkOrder(savedRequest)
          this.fetchRequests()

          this.setState({
            drawer: false,
            request: {},
            requests: this.sortList([
              ...this.state.requests.filter(request => request._id !== savedRequest._id),
              savedRequest
            ])
          })
        } catch (error) {
          notification.error({
            message: this.props.intl.formatMessage({ id: 'saving error', defaultMessage: 'Saving Error' }),
            description: error.message
          })
        }
      }
    })
  }

  rejectRequst = async (status) => {
    const { _id } = this.state.request
    this.props.form.validateFields(async err => {
      if (!err) {
        try {
          const payload = {
            status
          }

          const savedRequest = await requestService.updateRequest(_id, payload)

          this.fetchRequests()
          notification.success({
            message: `${this.props.intl.formatMessage({ id: 'updated successfully', defaultMessage: 'Updated successfully' })}`
          })

          this.setState({
            drawer: false,
            request: {},
            requests: this.sortList([
              ...this.state.requests.filter(request => request._id !== savedRequest._id),
              savedRequest
            ])
          })
        } catch (error) {
          notification.error({
            message: this.props.intl.formatMessage({ id: 'saving error', defaultMessage: 'Saving Error' }),
            description: error.message
          })
        }
      }
    })
  }

  sortList = list => {
    return (
      list &&
      list.sort(sortByCreatedAt)
    )
  };

  fetchRequests = async (params) => {
    this.setState({
      loading: true
    })
    try {
      const requests = await requestService.listV2({
        ...params
      })

      const pagination = {
        ...this.state.pagination
      }

      pagination.total = requests.totalDocs

      this.setState({
        requests: this.sortList(requests.docs),
        createdByFilters: requests.filterOptions.createdBy,
        loading: false,
        pagination
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({ id: 'requests', defaultMessage: 'Requests' })} - download error`
      })

      this.setState({
        drawer: false,
        loading: false
      })
    }
  }

  showDrawer = async request => {
    this.setState({
      drawer: true,
      request
    })
  }

  hideDrawer = () => {
    this.setState({
      drawer: false
    })
  }

  onTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    pager.offset = pagination.current

    this.setState({
      pagination: {
        ...pager,
        current: pagination.current
      }
    })

    this.fetchRequests({
      limit: pagination.pageSize,
      offset: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    })
  }

  handleSearch = (selectedKeys, field, confirm) => {
    confirm()
    this.setState({
      search: {
        ...this.state.search,
        [field]: selectedKeys[0]
      }
    })
  }

  handleReset = (field, clearFilters) => {
    clearFilters()

    this.setState({
      search: {
        ...this.state.search,
        [field]: ''
      }
    })
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => { this[`searchInput${dataIndex}`] = node }}
          placeholder={this.props.intl.formatMessage({ id: 'search', defaultMessage: 'Suchen' })}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Suchen
        </Button>
        <Button
          onClick={() => this.handleReset(dataIndex, clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => String(record[dataIndex]).toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this[`searchInput${dataIndex}`].select())
      }
    }
  })

  render () {
    const columns = [
      {
        title: this.props.intl.formatMessage({ id: 'number', defaultMessage: 'Number' }),
        key: 'docNumber',
        dataIndex: 'docNumber',
        sorter: true,
        ...this.getColumnSearchProps('docNumber')
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        ...this.getColumnSearchProps('name')
      },
      {
        title: this.state.objectName,
        key: 'objectId',
        dataIndex: 'objectId',
        sorter: true,
        render: (text, record) => {
          if (record.objectId && record.objectId.name) {
            return record.objectId.name
          }

          return ''
        },
        ...this.getColumnSearchProps('aksNumber')
      },
      {
        title: this.props.intl.formatMessage({ id: 'status', defaultMessage: 'Status' }),
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        render: (status) => {
          let backgroundColor = ''
          let statusText = ''
          switch (status) {
            case 1: {
              statusText = this.props.intl.formatMessage({ id: 'open', defaultMessage: 'Open' })
              backgroundColor = statusColors.Blue
              break
            }
            case 2: {
              statusText = this.props.intl.formatMessage({ id: 'approved', defaultMessage: 'Approved' })
              backgroundColor = statusColors.Green
              break
            }
            case 3: {
              statusText = this.props.intl.formatMessage({ id: 'rejected', defaultMessage: 'Rejected' })
              backgroundColor = statusColors.Red
              break
            }
            default:
              statusText = this.props.intl.formatMessage({ id: 'open', defaultMessage: 'Open' })
              backgroundColor = statusColors.Blue
          }

          return (
            <Badge count={statusText} style={{ backgroundColor }} />
          )
        },
        filteredValue: [1],
        filters: [
          {
            text: this.props.intl.formatMessage({ id: 'open', defaultMessage: 'Open' }),
            // value: 'open'
            value: 1
          }, {
            text: this.props.intl.formatMessage({ id: 'approved', defaultMessage: 'Approved' }),
            // value: 'approved'
            value: 2
          }, {
            text: this.props.intl.formatMessage({ id: 'rejected', defaultMessage: 'Rejected' }),
            // value: 'rejected'
            value: 3
          }
        ]
      },
      {
        title: this.props.intl.formatMessage({ id: 'created at', defaultMessage: 'CreatedAt' }),
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: true,
        render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm')
      }, {
        title: this.props.intl.formatMessage({ id: 'created by', defaultMessage: 'CreatedBy' }),
        dataIndex: 'createdBy',
        key: 'createdBy',
        render: createdBy => createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : '',
        filters: this.state.createdByFilters.map(user => ({
          text: `${user.firstName} ${user.lastName}`,
          value: user._id
        }))
      }
    ]

    if (!this.props.project) {
      columns.push({
        title: this.props.intl.formatMessage({ id: 'project', defaultMessage: 'Project' }),
        key: 'project',
        dataIndex: 'project.projectName'
      })
    }

    columns.push({
      key: 'actions',
      render: (text, record) => (
        <div style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'flex-end'
        }}>
          <Button onClick={() => this.showDrawer(record)} >
            <Icon type={'edit'} />
          </Button>
        </div>
      )
    })

    const drawerButtons = [
      <Button
        key="cancel"
        onClick={this.hideDrawer}
        style={{
          float: 'left'
        }}
      >
        <FormattedMessage
          id="cancel"
          defaultMessage="Cancel"
        />
      </Button>,
      <Button
        className="reject-button"
        key="reject"
        type="danger"
        onClick={() => this.rejectRequst(3)}
        disabled={this.state.request.status > 1}
      >
        <FormattedMessage
          id="reject"
          defaultMessage="Reject"
        />
      </Button>,
      <Button
        className="approve-button"
        key="approve"
        type="primary"
        onClick={this.approveRequst}
        disabled={this.state.request.status > 1}
      >
        <FormattedMessage
          id="approve"
          defaultMessage="Approve"
        />
      </Button>
    ]

    return (
      <div>
        <FormattedMessage id="head.title.requests" defaultMessage="Requests">
          {title => <Helmet><title>{title}</title></Helmet>}
        </FormattedMessage>

        <PageTitle
          title={this.props.intl.formatMessage({ id: 'requests', defaultMessage: 'Requests' })}
        />

        <Table
          // eslint-disable-next-line no-return-assign
          ref={ref => this._table = ref}
          rowKey={record => record._id}
          columns={columns}
          dataSource={this.state.requests}
          loading={this.state.loading}
          onChange={this.onTableChange}
          onRow={(record) => ({
            onDoubleClick: () => { this.showDrawer(record) }
          })}
          pagination={this.state.pagination}
        />
        <DrawerComponent
          title={this.state.request.name}
          onClose={this.hideDrawer}
          visible={!!this.state.drawer}
          footerButtons={drawerButtons}
        >
          <RequestForm
            request={this.state.request}
            objectName={this.state.objectName}
            updateRequest={this.updateStateRequest}
          />
        </DrawerComponent>
      </div>
    )
  }
}

const sortByCreatedAt = (a, b) => {
  return new Date(b.createdAt) - new Date(a.createdAt)
}

RequestsPage.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.authReducer,
  settings: state.settingsReducer,
  projects: state.projectReducer.projects,
  project: state.projectReducer.project
})

export default injectIntl(Form.create({ name: 'requests_form' })(connect(mapStateToProps)(RequestsPage)))
