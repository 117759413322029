import React from 'react'
import { Button, Menu, Dropdown, Icon, Select, Avatar, Spin } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { NavLink, useLocation } from 'react-router-dom'

import './styles.scss'

import actions from 'actions'

const Header = props => {
  const roles = props.auth.auth_user.scope || []
  const location = useLocation()

  const hiddenProjectSelection = ['/projects', '/settings', '/forms', '/modules', '/groups', '/users', '/contacts']

  const nonStandarUser = (roles[0] === 'admin' || roles[0] === 'superadmin')

  const menu = (
    <Menu>
      {nonStandarUser && (
        <Menu.Item>
          <NavLink to="/settings" className="nav-text">
            <Icon type="setting" style={{ marginRight: 8 }} />
            <span className="nav-text">
              <FormattedMessage
                id="settings"
                defaultMessage="Settings"
              />
            </span>
          </NavLink>
        </Menu.Item>
      )}

      <Menu.Divider />

      <Menu.Item onClick={() => props.dispatch(actions.authLogout())}>
        <Icon type="poweroff" />
        <span className="nav-text">
          <FormattedMessage
            id="logout"
            defaultMessage="Logout"
          />
        </span>
      </Menu.Item>
    </Menu>
  )

  const changeProject = (projectID, e) => {
    if (e && e.props && e.props.data) {
      props.dispatch(actions.setProject(e.props.data))
    } else {
      props.dispatch(actions.setProject(undefined))
    }
  }

  const projects = props.projects || []

  return (
    <React.Fragment>
      {projects.length > 0 && !hiddenProjectSelection.includes(location.pathname) && (
        <Select
          showSearch
          allowClear
          style={{ width: 200 }}
          placeholder={props.intl.formatMessage({ id: 'select a project', defaultMessage: 'Select a project' })}
          optionFilterProp="children"
          // filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          notFoundContent={<div className="header-spinner-container"><Spin size="small" /></div>}
          onSearch={(value) => props.fetchProjects(value)}
          // onFocus={() => props.fetchProjects('')}
          onChange={changeProject}
          value={props.project ? props.project.projectName : props.intl.formatMessage({ id: 'select a project', defaultMessage: 'Select a project' })}
        >
          {props.projects.map(project => (
            <Select.Option key={project._id} data={project} value={project._id}>{project.projectName}</Select.Option>
          ))}
        </Select>
      )}

      <Dropdown
        overlay={menu}
        placement="bottomRight"
        trigger={['click']}
      >
        <Button style={{ border: 0, float: 'right', marginTop: 16 }}>
          <Avatar icon="user" style={{ marginLeft: 0, marginRight: 10, marginTop: -1 }} /> <span className="nav-text" style={{ marginTop: 5 }}>{props.auth.auth_user.fullName}</span>
        </Button>
      </Dropdown>

      <Dropdown
        overlay={<Menu>
          <Menu.Item onClick={() => props.dispatch(actions.setLang('en'))}>
            <span className="nav-text" style={{ fontWeight: props.settings.lang === 'en' ? 'bold' : 'normal' }}>EN</span>
          </Menu.Item>

          <Menu.Item onClick={() => props.dispatch(actions.setLang('de'))}>
            <span className="nav-text" style={{ fontWeight: props.settings.lang === 'en' ? 'normal' : 'bold' }}>DE</span>
          </Menu.Item>
        </Menu>}
        placement="bottomCenter"
        trigger={['click']}
      >
        <Button
          shape="circle"
          icon="global"
          style={{ float: 'right', border: 0, marginTop: 15, marginLeft: 10 }}
        />
      </Dropdown>
    </React.Fragment>
  )
}

Header.propTypes = {
  dispatch: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  projects: PropTypes.array.isRequired,
  project: PropTypes.object,
  settings: PropTypes.object.isRequired
}

export default injectIntl(connect()(Header))
