import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Table, notification, Icon, Button, Form, Input } from 'antd'
import productService from 'services/products'
import projectService from 'services/project'
import categoriesService from 'services/categories'
import manufacturersService from 'services/manufacturers'
import { findIndexInArray } from 'helpers/utils'

import ProductForm from 'components/Products/Form'
import PageTitle from 'components/Global/PageTitle'
import DrawerComponent from 'components/Drawer'
import PopconfirmDelete from 'components/Global/PopconfirmDelete'

export class ProductsPage extends React.Component {
  state = {
    products: [],
    categories: [],
    manufacturers: [],
    projects: [],
    searchProjects: [],
    selectedRowKeys: [],
    visible: false,
    search: {
      name: ''
    },
    drawerOption: '',
    product: {},
    loading: false,
    formLoading: false,
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50', '100']
    },
    manufacturerSearchResult: [],
    manufacturerFilters: [],
    categoriesSearchResult: [],
    categoryFilters: [],
    createdByFilters: [],
    priceFocused: false,
    readOnlyNewProject: false
  }

  componentDidMount () {
    this.fetchProducts()
  }

  componentDidUpdate (prevProps) {
    if (this.props.project !== prevProps.project) {
      this.fetchProducts()
    }
  }

  fetchProjects = async () => {
    try {
      const projects = await projectService.list()

      this.setState({
        projects
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({ id: 'projects', defaultMessage: 'Projects' })} - download error`
      })

      this.setState({
        projects: []
      })
    }
  }

  formatDecimals = (value) => {
    const convertToString = `${value}`
    if (convertToString.includes('.')) {
      if (convertToString.substr(convertToString.length - 1) === '0' && convertToString.toString().split('.')[1].length !== 2) {
        return 1
      } else {
        if ((convertToString % 1) !== 0) {
          return convertToString.toString().split('.')[1].length
        }
        return 0
      }
    }
  }

  fetchProjectsOnSearch = async (value) => {
    this.setState({
      formLoading: true
    })
    const timer = setTimeout(async () => {
      try {
        let projects = await projectService.listV2({
          projectName: [value],
          limit: 100
        })

        this.setState({
          projects: projects.docs,
          formLoading: false
        })

        clearTimeout(timer)
      } catch (error) {
        this.setState({
          projects: [],
          formLoading: false
        })
        clearTimeout(timer)
      }
    })
  }

  onFocusPriceHandler = (value) => {
    this.setState({
      priceFocused: true
    })
  }

  formatAfterDotZeros = (field, value, product) => {
    let parsedValue = value
    const countNumbersAfterDot = this.formatDecimals(!product ? this.state.product[field] : product[field])

    if (countNumbersAfterDot === 1) {
      parsedValue += '0'
    }

    if (parsedValue.substr(parsedValue.length - 1) === '.') {
      parsedValue += '00'
    }
    return parsedValue
  }

  onBlurNumberHandler = (field, value) => {
    this.setState({
      priceFocused: false
    }, () => {
      const finalValue = this.formatAfterDotZeros(field, value)

      this.setState({
        product: {
          ...this.state.product,
          [field]: finalValue
        }
      })
    })
  }

  updateStateProduct = (field, value, numbers) => {
    if (numbers) {
      const onlyDigets = value.replace(/[^0-9.]/g, '')
      const removeMultiplyDots = onlyDigets.replace(/\.+/g, '.')
      let formattedNumber = removeMultiplyDots.replace(/(.*\.[0-9][0-9]?).*/g, '$1')
      this.setState({
        product: {
          ...this.state.product,
          [field]: formattedNumber
        }
      })
    } else {
      this.setState({
        product: {
          ...this.state.product,
          [field]: value
        }
      })
    }
  }

  handleSearchAutocomplete = async (categoryType, value) => {
    const timer = setTimeout(async () => {
      if (categoryType === 'manufacturer') {
        this.setState({
          formLoading: true
        })
        try {
          const response = await manufacturersService.listV2({
            name: [value],
            limit: 100
          })

          this.setState({
            manufacturerSearchResult: response.docs,
            formLoading: false
          })
          clearTimeout(timer)
        } catch (error) {
          this.setState({
            formLoading: false
          })
          console.log('Fetching error')
          clearTimeout(timer)
        }
      } else {
        this.setState({
          formLoading: true
        })
        try {
          const response = await categoriesService.listV2({
            name: [value],
            limit: 100
          })

          this.setState({
            categoriesSearchResult: response.docs,
            formLoading: false
          })
          clearTimeout(timer)
        } catch (error) {
          console.log('Fetching error')
          this.setState({
            formLoading: false
          })
          clearTimeout(timer)
        }
      }
    }, 1000)
  }

  updateStateProductObject = (field, property, value, e) => {
    this.setState({
      product: {
        ...this.state.product,
        [field]: {
          [property]: value,
          id: e.props.id
        }
      }
    })
  }

  fetchProducts = async (params) => {
    this.setState({
      loading: true
    })
    try {
      const products = await productService.listV2({
        ...params
      })

      const pagination = {
        ...this.state.pagination
      }

      pagination.total = products.totalDocs

      this.setState({
        products: products.docs,
        pagination,
        categoryFilters: products.filterOptions.category,
        manufacturerFilters: products.filterOptions.manufacturer,
        createdByFilters: products.filterOptions.createdBy,
        loading: false
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({ id: 'products', defaultMessage: 'Products' })} - download error`
      })

      this.setState({
        visible: false,
        loading: false
      })
    }
  }

  addProduct = async () => {
    const { product } = this.state

    let payload = {
      name: product.name,
      ordernumber: product.ordernumber,
      description: product.description,
      ean: product.ean,
      width: product.width,
      height: product.height,
      depth: product.depth,
      supplierNumber: product.supplierNumber,
      length: product.length,
      price: product.price ? product.price : -1,
      active: product.active ? product.active : false
    }

    if (product.project) {
      payload.project = product.project._id || product.project || null
    }

    if (product.manufacturer) {
      payload.manufacturer = product.manufacturer.id
    }
    if (product.category) {
      payload.category = product.category.id
    }

    this.props.form.validateFields(async err => {
      if (!err) {
        try {
          const productResponse = await productService.addProduct(payload)

          this.fetchProducts()
          notification.success({
            message: `${this.props.intl.formatMessage({ id: 'created successfully', defaultMessage: 'Created successfully' })}`
          })

          this.setState({
            visible: false,
            product: {},
            products: [
              ...this.state.products, productResponse
            ]
          })
        } catch (error) {
          notification.error({
            message: this.props.intl.formatMessage({ id: 'saving error', defaultMessage: 'Saving error' }),
            description: error.error
          })
        }
      }
    })
  }

  saveProduct = async () => {
    const { product, products } = this.state
    let payload = {
      name: product.name,
      project: product.project || null,
      ordernumber: product.ordernumber,
      description: product.description,
      ean: product.ean && product.ean,
      width: product.width,
      height: product.height,
      depth: product.depth,
      supplierNumber: product.supplierNumber,
      length: product.length,
      price: product.price ? product.price : -1,
      active: product.active ? product.active : false
    }

    if (product.manufacturer) {
      payload.manufacturer = product.manufacturer.id
    } else {
      payload.manufacturer = null
    }
    if (product.category) {
      payload.category = product.category.id
    } else {
      payload.category = null
    }

    try {
      await productService.updateProduct(product._id, payload)

      this.fetchProducts()
      notification.success({
        message: `${this.props.intl.formatMessage({ id: 'updated successfully', defaultMessage: 'Updated successfully' })}`
      })

      const updateIndex = findIndexInArray(products, product._id)
      let updatedArrray = [...products]
      updatedArrray[updateIndex] = product

      this.setState({
        visible: false,
        product: {},
        products: [
          ...updatedArrray]
      })
    } catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({ id: 'saving error', defaultMessage: 'Saving error' }),
        description: error.error
      })
    }
  }

  deleteProduct = async () => {
    try {
      await productService.deleteProduct(this.state.product._id)

      notification.success({
        message: this.props.intl.formatMessage({ id: 'deleted successfully', defaultMessage: 'Deleted successfully' })
      })

      this.setState({
        visible: false,
        product: {},
        products: [
          ...this.state.products.filter(product => product._id !== this.state.product._id)
        ]
      })
    } catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({ id: 'deleting error', defaultMessage: 'Deleting Error' }),
        description: error.message
      })
    }
  }

  renderCategoryFilter = () => {
    let filters = []
    this.state.categoryFilters.map((category) => {
      const filterObject = {
        text: category.name,
        value: category._id
      }
      return filters.push(filterObject)
    })
    return filters
  }

  renderManufacturerFilter = () => {
    let filters = []
    this.state.manufacturerFilters.map((manufacturer) => {
      const filterObject = {
        text: manufacturer.name,
        value: manufacturer._id
      }
      return filters.push(filterObject)
    })
    return filters
  }

  onTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    pager.offset = pagination.current

    this.setState({
      pagination: {
        ...pager,
        current: pagination.current
      }
    })

    this.fetchProducts({
      limit: pagination.pageSize,
      offset: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    })
  }

  showDrawer = async product => {
    if (product.ordernumber) {
      this.setState({
        visible: true,
        drawerOption: 'edit',
        product,
        readOnlyNewProject: false
      })
    } else {
      this.setState({
        visible: true,
        product: {
          project: this.props.selectedProject ? this.props.selectedProject : null
        },
        readOnlyNewProject: !!this.props.selectedProject
      })
    }
  }

  hideDrawer = () => {
    this.props.form.resetFields()
    this.setState({
      visible: false
    })
  }

  onSelectChange = selectedRowKeys => {
    this.setState({
      selectedRowKeys
    })
  }

  handleSearch = (selectedKeys, field, confirm) => {
    confirm()
    this.setState({
      search: {
        ...this.state.search,
        [field]: selectedKeys[0]
      }
    })
  }

  handleReset = (field, clearFilters) => {
    clearFilters()

    this.setState({
      search: {
        ...this.state.search,
        [field]: ''
      }
    })
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => { this[`searchInput${dataIndex}`] = node }}
          placeholder={this.props.intl.formatMessage({ id: 'search', defaultMessage: 'Suchen' })}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Suchen
        </Button>
        <Button
          onClick={() => this.handleReset(dataIndex, clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => String(record[dataIndex]).toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this[`searchInput${dataIndex}`].select())
      }
    }
  })

  render () {
    const columns = [{
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      ...this.getColumnSearchProps('name')
    }, {
      title: this.props.intl.formatMessage({ id: 'ordernumber', defaultMessage: 'Ordernumber' }),
      dataIndex: 'ordernumber',
      key: 'ordernumber'
    }, {
      title: this.props.intl.formatMessage({ id: 'manufacturer', defaultMessage: 'Manufacturer' }),
      dataIndex: 'manufacturer',
      key: 'manufacturer',
      filterMultiple: false,
      filters: this.renderManufacturerFilter(),
      render: (manufacturer = '') => {
        if (manufacturer) {
          return manufacturer.name
        }
        return ''
      }
    }, {
      title: this.props.intl.formatMessage({ id: 'category', defaultMessage: 'Category' }),
      dataIndex: 'category',
      key: 'category',
      render: (category = '') => {
        if (category) {
          return category.name
        }
        return ''
      },
      filters: this.renderCategoryFilter(),
      sorter: true
    }, {
      title: this.props.intl.formatMessage({ id: 'active', defaultMessage: 'Active' }),
      dataIndex: 'active',
      key: 'active',
      render: active => active && <Icon type="check" style={{ color: '#005591' }} />
    }, {
      title: this.props.intl.formatMessage({ id: 'price', defaultMessage: 'Price' }),
      dataIndex: 'price',
      key: 'price',
      render: price => {
        let parsedValue = price.toString()
        const countNumbersAfterDot = this.formatDecimals(price)

        if (countNumbersAfterDot === 1) {
          parsedValue += '0'
        }

        if (parsedValue.substr(parsedValue.length - 1) === '.') {
          parsedValue += '00'
        }
        return (<div style={{ width: 135 }}>{`CHF ${parsedValue}`}</div>)
      },
      sorter: true
    }, {
      title: this.props.intl.formatMessage({ id: 'created at', defaultMessage: 'CreatedAt' }),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
      sorter: true
    }, {
      title: this.props.intl.formatMessage({ id: 'created by', defaultMessage: 'CreatedBy' }),
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: createdBy => createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : '',
      sorter: true,
      filters: this.state.createdByFilters.map(user => ({
        text: `${user.firstName} ${user.lastName}`,
        value: user._id
      }))
    }]

    if (!this.props.project) {
      columns.push({
        title: this.props.intl.formatMessage({ id: 'project', defaultMessage: 'Project' }),
        key: 'project',
        dataIndex: 'project.projectName'
      })
    }

    columns.push({
      key: 'actions',
      render: (text, record) => (
        <div style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'flex-end'
        }}>
          <Button onClick={() => this.showDrawer(record)} >
            <Icon type={'edit'} />
          </Button>
        </div>
      )
    })

    const drawerButtonsAdd = [
      <Button
        key="cancel"
        onClick={this.hideDrawer}
      >
        <FormattedMessage
          id="cancel"
          defaultMessage="Cancel"
        />
      </Button>,
      <Button
        key="save2"
        type="primary"
        onClick={this.addProduct}
      >
        <FormattedMessage
          id="save"
          defaultMessage="Save"
        />
      </Button>
    ]

    const drawerButtonsEdit = [
      <Button
        key="cancel"
        onClick={this.hideDrawer}
      >
        <FormattedMessage
          id="cancel"
          defaultMessage="Cancel"
        />
      </Button>,
      <Button
        key="save3"
        type="primary"
        onClick={this.saveProduct}
      >
        <FormattedMessage
          id="save"
          defaultMessage="Save"
        />
      </Button>
    ]

    if (['admin', 'superadmin'].includes(this.props.auth.auth_user.scope[0]) &&
      this.state.product &&
      !this.state.product.isVerified
    ) {
      drawerButtonsEdit.unshift(
        <PopconfirmDelete onConfirmAction={this.deleteProduct} />
      )
    }

    const {
      products,
      projects,
      readOnlyNewProject,
      drawerOption,
      visible,
      product,
      categoriesSearchResult,
      manufacturerSearchResult,
      loading,
      formLoading
    } = this.state

    const headerButtons = [
      <Button
        key={354655589832}
        type="primary"
        onClick={this.showDrawer}
      >
        <FormattedMessage
          id="create product"
          defaultMessage="Create product"
        />
      </Button>
    ]

    return (
      <div>
        <FormattedMessage id="head.title.products" defaultMessage="Products">
          {title => <Helmet><title>{title}</title></Helmet>}
        </FormattedMessage>

        <PageTitle
          title={this.props.intl.formatMessage({ id: 'products', defaultMessage: 'Products' })}
          buttons={headerButtons}
        />

        <Table
          // eslint-disable-next-line no-return-assign
          ref={ref => this._table = ref}
          rowKey={record => record._id}
          columns={columns}
          dataSource={products}
          loading={loading}
          onChange={this.onTableChange}
          onRow={(record) => ({
            onDoubleClick: () => { this.showDrawer(record) }
          })}
          pagination={this.state.pagination}
        />

        <DrawerComponent
          title={drawerOption === 'edit' ? this.props.intl.formatMessage({ id: 'edit product', defaultMessage: 'Edit product' }) : this.props.intl.formatMessage({ id: 'add product', defaultMessage: 'Add product' })}
          visible={visible}
          onClose={this.hideDrawer}
          onCancel={this.hideDrawer}
          footerButtons={drawerOption === 'edit' ? drawerButtonsEdit : drawerButtonsAdd}
        >
          <ProductForm
            option={drawerOption}
            product={product}
            projects={projects}
            formLoading={formLoading}
            readOnlyNewProject={readOnlyNewProject}
            fetchProjectsOnSearch={this.fetchProjectsOnSearch}
            categories={categoriesSearchResult}
            manufacturers={manufacturerSearchResult}
            updateProduct={this.updateStateProduct}
            onFocusPrice={this.onFocusPriceHandler}
            priceFocused={this.state.priceFocused}
            onBlurNumber={this.onBlurNumberHandler}
            handleSearchAutocomplete={this.handleSearchAutocomplete}
            updateStateProductObject={this.updateStateProductObject}
            form={this.props.form}
          />
        </DrawerComponent>
      </div >
    )
  }
}

ProductsPage.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.authReducer,
  selectedProject: state.projectReducer.project
})

export default injectIntl(Form.create({ name: 'requests_form' })(connect(mapStateToProps)(ProductsPage)))
