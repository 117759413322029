import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect, Link } from 'react-router-dom'
import { Input, Button, Card, Form, Icon, notification, Menu, Dropdown } from 'antd';
import { connect } from 'react-redux'
import jwtDecode from 'jwt-decode'
import { injectIntl, FormattedMessage } from 'react-intl'

import actions from 'actions'
import authService from 'services/auth'

import './style.scss'

class Login extends Component {
  state = {
    email: '',
    password: '',
    processing: false
  }

  login = async () => {
    if (this.state.processing) {
      return
    }

    this.setState({
      processing: true
    })

    try {
      if (!this.state.email || !this.state.password) {
        const error = {
          message: this.props.intl.formatMessage({ id: 'email and password are required', defaultMessage: 'E-mail and Password are required' })
        }

        throw error
      }

      const tokenResponse = await authService.login({
        email: this.state.email,
        password: this.state.password
      })

      this.props.dispatch(actions.setProject(undefined))
      this.props.dispatch(actions.authToken(tokenResponse.token))
      this.props.dispatch(actions.authUser(jwtDecode(tokenResponse.token).user))

      return
    } catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({ id: 'login error', defaultMessage: 'Login Error' }),
        description: this.props.intl.formatMessage({ id: 'incorrect email or password', defaultMessage: 'Incorrect email or password' })
      })
    }

    this.setState({
      processing: false
    })
  }

  render () {
    if (localStorage.getItem('auth_token')) {
      return <Redirect to='/' />
    }

    return (
      <div className="centerBoxWrapper">
        <p className="lang-selector">
          <Dropdown
            overlay={<Menu>
              <Menu.Item onClick={() => this.props.dispatch(actions.setLang('en'))}>
                <span className="nav-text" style={{ fontWeight: this.props.settings.lang === 'en' ? 'bold' : 'normal' }}>EN</span>
              </Menu.Item>

              <Menu.Item onClick={() => this.props.dispatch(actions.setLang('de'))}>
                <span className="nav-text" style={{ fontWeight: this.props.settings.lang === 'en' ? 'normal' : 'bold' }}>DE</span>
              </Menu.Item>
            </Menu>}
            placement="bottomCenter"
            trigger={['click']}
          >
            <Button
              shape="circle"
              icon="global"
            />
          </Dropdown>
        </p>

        <Card className="card">
          <img src="/images/logo.png" alt="logo" />

          <p className="card-title">{this.props.intl.formatMessage({ id: 'log in to your ddSuite instance', defaultMessage: 'Log in to your ddSuite instance' })}</p>

          <Form.Item>
            <p className="card-label">{this.props.intl.formatMessage({ id: 'email', defaultMessage: 'Email' })}</p>

            <Input
              onChange={e => this.setState({ email: e.target.value })}
              onPressEnter={this.login}
              placeholder={this.props.intl.formatMessage({ id: 'email', defaultMessage: 'Email' })}
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            />
          </Form.Item>

          <Form.Item>
            <p className="card-label">{this.props.intl.formatMessage({ id: 'password', defaultMessage: 'Password' })}</p>
            <Input.Password
              onChange={e => this.setState({ password: e.target.value })}
              onPressEnter={this.login}
              placeholder={this.props.intl.formatMessage({ id: 'password', defaultMessage: 'Password' })}
              type="password"
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            />
          </Form.Item>

          <Form.Item style={{ textAlign: 'right' }}>
            <Button
              htmlType="button"
              onClick={this.login}
              type="primary"
              loading={this.state.processing}
            >
              <FormattedMessage
                id="login"
                defaultMessage="Login"
              />
            </Button>
          </Form.Item>

          <Link to="/forgot-password">
            <FormattedMessage
              id="forgot password"
              defaultMessage="Forgot password"
            />?
          </Link>
        </Card>
      </div>
    )
  }
}

Login.propTypes = {
  dispatch: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  settings: state.settingsReducer
})
export default injectIntl(connect(mapStateToProps)(Login))
