import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import { Table, notification, Icon, Button, Form, Input } from 'antd'
import manufacturersService from 'services/manufacturers'
import projectService from 'services/project'
import { findIndexInArray } from 'helpers/utils'

import ManufacturerForm from 'components/Manufacturers/Form'
import DrawerComponent from 'components/Drawer'
import PageTitle from 'components/Global/PageTitle'
import PopconfirmDelete from 'components/Global/PopconfirmDelete'

export class ManufacturersPage extends React.Component {
  state = {
    manufacturers: [],
    projects: [],
    drawer: false,
    selectedRowKeys: [],
    search: {
      name: '',
      aksNumber: ''
    },
    loading: false,
    formLoading: false,
    readOnlyNewProject: false,
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50', '100']
    },
    drawerOption: '',
    manufacturer: {},
    createdByFilters: [],
    archivedFilters: ['hideArchived']
  }

  componentDidMount () {
    this.fetchManufacturers()
  }

  updateManufacturer = (field, value) => {
    this.setState({
      manufacturer: {
        ...this.state.manufacturer,
        [field]: value
      }
    })
  }

  fetchManufacturers = async (params) => {
    this.setState({
      loading: true
    })
    try {
      const manufacturers = await manufacturersService.listV2({
        ...params
      })

      const pagination = {
        ...this.state.pagination
      }

      pagination.total = manufacturers.totalDocs

      this.setState({
        manufacturers: manufacturers.docs,
        pagination,
        createdByFilters: manufacturers.filterOptions.createdBy,
        loading: false
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({ id: 'manufacturer', defaultMessage: 'Manufacturer' })} - download error`
      })

      this.setState({
        drawer: false,
        loading: false
      })
    }
  }

  fetchProjects = async (value) => {
    this.setState({
      formLoading: true
    })
    const timer = setTimeout(async () => {
      try {
        let projects = await projectService.listV2({
          projectName: [value],
          limit: 100
        })

        this.setState({
          projects: projects.docs,
          formLoading: false
        })

        clearTimeout(timer)
      } catch (error) {
        this.setState({
          projects: [],
          formLoading: false
        })
        clearTimeout(timer)
      }
    })
  }

  addManufacturer = async () => {
    let payload = this.state.manufacturer

    if (this.state.manufacturer.project) {
      payload.project = this.state.manufacturer.project._id || this.state.manufacturer.project || null
    }

    this.props.form.validateFields(async err => {
      if (!err) {
        try {
          const manufacturerResponse = await manufacturersService.addManufacturer(payload)

          this.fetchManufacturers()
          notification.success({
            message: `${this.props.intl.formatMessage({ id: 'created successfully', defaultMessage: 'Created successfully' })}`
          })

          this.setState({
            drawer: false,
            manufacturer: {},
            manufacturers: [
              ...this.state.manufacturers, manufacturerResponse
            ]
          })
        } catch (error) {
          notification.error({
            message: this.props.intl.formatMessage({ id: 'saving error', defaultMessage: 'Saving error' }),
            description: error.error
          })
        }
      }
    })
  }

  deleteManufacturer = async () => {
    try {
      await manufacturersService.deleteManufacturer(this.state.manufacturer._id)

      notification.success({
        message: this.props.intl.formatMessage({ id: 'deleted successfully', defaultMessage: 'Deleted successfully' })
      })

      this.setState({
        drawer: false,
        manufacturer: {},
        manufacturers: [
          ...this.state.manufacturers.filter(manufacturer => manufacturer._id !== this.state.manufacturer._id)
        ]
      })
    } catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({ id: 'deleting error', defaultMessage: 'Deleting Error' }),
        description: error.message
      })
    }
  }

  saveManufacturer = async () => {
    const { manufacturer, manufacturers } = this.state
    this.props.form.validateFields(async err => {
      if (!err) {
        try {
          const payload = {
            name: manufacturer.name,
            project: manufacturer.project || null,
            street: manufacturer.street,
            postcode: manufacturer.postcode,
            city: manufacturer.city,
            country: manufacturer.country,
            website: manufacturer.website,
            email: manufacturer.email,
            phone: manufacturer.phone
          }
          await manufacturersService.updateManufacturer(manufacturer._id, payload)

          this.fetchManufacturers()
          notification.success({
            message: `${this.props.intl.formatMessage({ id: 'updated successfully', defaultMessage: 'Updated successfully' })}`
          })

          const updateIndex = findIndexInArray(manufacturers, manufacturer._id)
          let updatedArrray = [...manufacturers]
          updatedArrray[updateIndex] = manufacturer

          this.setState({
            drawer: false,
            manufacturer: {},
            manufacturers: [
              ...updatedArrray]
          })
        } catch (error) {
          notification.error({
            message: this.props.intl.formatMessage({ id: 'saving error', defaultMessage: 'Saving error' }),
            description: error.error
          })
        }
      }
    })
  }

  onTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    pager.offset = pagination.current

    this.setState({
      pagination: {
        ...pager,
        current: pagination.current
      }
    })

    this.fetchManufacturers({
      limit: pagination.pageSize,
      offset: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    })
  }

  showDrawer = async manufacturer => {
    this.setState({
      ...this.state.manufacturer,
      drawer: true,
      manufacturer,
      drawerOption: 'edit',
      readOnlyNewProject: false
    })
  }

  hideDrawer = () => {
    this.setState({
      drawer: false
    })
  }

  showDrawerAdd = async () => {
    this.setState({
      drawer: true,
      manufacturer: {
        project: this.props.selectedProject ? this.props.selectedProject : null
      },
      drawerOption: 'add',
      readOnlyNewProject: !!this.props.selectedProject
    })
  }

  handleSearch = (selectedKeys, field, confirm) => {
    confirm()
    this.setState({
      search: {
        ...this.state.search,
        [field]: selectedKeys[0]
      }
    })
  }

  handleReset = (field, clearFilters) => {
    clearFilters()

    this.setState({
      search: {
        ...this.state.search,
        [field]: ''
      }
    })
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => { this[`searchInput${dataIndex}`] = node }}
          placeholder={this.props.intl.formatMessage({ id: 'search', defaultMessage: 'Suchen' })}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Suchen
        </Button>
        <Button
          onClick={() => this.handleReset(dataIndex, clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => String(record[dataIndex]).toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this[`searchInput${dataIndex}`].select())
      }
    }
  })

  render () {
    const columns = [{
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      ...this.getColumnSearchProps('name')
    }, {
      title: this.props.intl.formatMessage({ id: 'created at', defaultMessage: 'CreatedAt' }),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
      sorter: true
    }, {
      title: this.props.intl.formatMessage({ id: 'created by', defaultMessage: 'CreatedBy' }),
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: createdBy => createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : '',
      sorter: true,
      filters: this.state.createdByFilters.map(user => ({
        text: `${user.firstName} ${user.lastName}`,
        value: user._id
      }))
    }]

    if (!this.props.project) {
      columns.push({
        title: this.props.intl.formatMessage({ id: 'project', defaultMessage: 'Project' }),
        key: 'project',
        dataIndex: 'project.projectName'
      })
    }

    columns.push({
      key: 'actions',
      render: (text, record) => (
        <div style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'flex-end'
        }}>
          <Button onClick={() => this.showDrawer(manufacturer)} >
            <Icon
              type={(manufacturer.isVerified || manufacturer.isInvalid) ? 'info-circle' : 'edit'}
            />
          </Button>
        </div>
      )
    })

    const drawerButtonsAdd = [
      <Button
        key="cancel"
        onClick={this.hideDrawer}
      >
        <FormattedMessage
          id="cancel"
          defaultMessage="Cancel"
        />
      </Button>,
      <Button
        key="save"
        type="primary"
        onClick={this.addManufacturer}
      >
        <FormattedMessage
          id="save"
          defaultMessage="Save"
        />
      </Button>
    ]

    const drawerButtonsEdit = [
      <Button
        key="cancel"
        onClick={this.hideDrawer}
      >
        <FormattedMessage
          id="cancel"
          defaultMessage="Cancel"
        />
      </Button>,
      <Button
        key="save"
        type="primary"
        onClick={this.saveManufacturer}
      // disabled={this.state.recording && this.state.recording.isInvalid}
      >
        <FormattedMessage
          id="save"
          defaultMessage="Save"
        />
      </Button>
    ]

    if (['admin', 'superadmin'].includes(this.props.auth.auth_user.scope[0]) &&
      this.state.manufacturer &&
      !this.state.manufacturer.isVerified
    ) {
      drawerButtonsEdit.unshift(
        <PopconfirmDelete onConfirmAction={this.deleteManufacturer} />
      )
    }

    const { manufacturers, projects, readOnlyNewProject, drawer, drawerOption, manufacturer, loading, formLoading } = this.state

    const headerButtons = [
      <Button
        key={354389832}
        type="primary"
        onClick={this.showDrawerAdd}
      >
        <FormattedMessage
          id="create manufacturer"
          defaultMessage="Create manufacturer"
        />
      </Button>
    ]

    return (
      <div>
        <FormattedMessage id="head.title.manufacturers" defaultMessage="Manufacturers">
          {title => <Helmet><title>{title}</title></Helmet>}
        </FormattedMessage>

        <PageTitle
          title={this.props.intl.formatMessage({ id: 'manufacturers', defaultMessage: 'Manufacturers' })}
          buttons={headerButtons}
        />

        <Table
          // eslint-disable-next-line no-return-assign
          ref={ref => this._table = ref}
          rowKey={record => record._id}
          columns={columns}
          dataSource={manufacturers}
          loading={loading}
          onChange={this.onTableChange}
          onRow={(record) => ({
            onDoubleClick: () => { this.showDrawer(record) }
          })}
          pagination={this.state.pagination}
        />
        <DrawerComponent
          title={drawerOption === 'edit' ? this.props.intl.formatMessage({ id: 'edit manufacturer', defaultMessage: 'Edit manufacturer' }) : this.props.intl.formatMessage({ id: 'add manufacturer', defaultMessage: 'Add manufacturer' })}
          onClose={this.hideDrawer}
          visible={!!drawer}
          footerButtons={drawerOption === 'edit' ? drawerButtonsEdit : drawerButtonsAdd}
        >
          <ManufacturerForm
            option={drawerOption}
            projects={projects}
            formLoading={formLoading}
            readOnlyNewProject={readOnlyNewProject}
            manufacturer={manufacturer}
            fetchProjects={this.fetchProjects}
            updateManufacturer={this.updateManufacturer}
            form={this.props.form}
          />
        </DrawerComponent>
      </div>
    )
  }
}

ManufacturersPage.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.authReducer,
  selectedProject: state.projectReducer.project
})

export default injectIntl(Form.create({ name: 'manufacturersPage_form' })(connect(mapStateToProps)(ManufacturersPage)))
