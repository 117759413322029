import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'

import { Form, Input, Checkbox, Select } from 'antd'
import { PasswordInput } from 'antd-password-input-strength'
import _ from 'lodash'

import './styles.scss'

class UserForm extends React.Component {
  render () {
    const { getFieldDecorator } = this.props.form

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      className: 'formItem'
    }

    return (
      <Form onSubmit={() => { }} className="userForm">
        <Form.Item
          {...formItemLayout}
          label={(<span>E-Mail<span style={{ color: '#005591' }}>*</span></span>)}
          required={false}
        >
          {getFieldDecorator('email', {
            initialValue: _.isEmpty(this.props.user) ? '' : this.props.user.email,
            rules: [{
              required: true,
              message: `E-Mail ${this.props.intl.formatMessage({ id: 'is required', defaultMessage: 'is required' })}`
            }, {
              type: 'email',
              message: this.props.intl.formatMessage({ id: 'email is not valid', defaultMessage: 'E-mail is not valid' })
            }],
          })(
            <Input
              onChange={event => this.props.updateUser('email', event.target.value)}
              disabled={!!this.props.user._id}
            />
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={(<span><FormattedMessage id="first name" defaultMessage="First name" /><span style={{ color: '#005591' }}>*</span></span>)}
          required={false}
        >
          {getFieldDecorator('firstName', {
            initialValue: _.isEmpty(this.props.user) ? '' : this.props.user.firstName,
            rules: [{
              required: true,
              message: `${this.props.intl.formatMessage({ id: 'first name', defaultMessage: 'First name' })} ${this.props.intl.formatMessage({ id: 'is required', defaultMessage: 'is required' })}`
            }],
          })(
            <Input
              onChange={event => this.props.updateUser('firstName', event.target.value)}
            />
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={(<span><FormattedMessage id="last name" defaultMessage="Last name" /><span style={{ color: '#005591' }}>*</span></span>)}
          required={false}
        >
          {getFieldDecorator('lastName', {
            initialValue: _.isEmpty(this.props.user) ? '' : this.props.user.lastName,
            rules: [{
              required: true,
              message: `${this.props.intl.formatMessage({ id: 'last name', defaultMessage: 'Last name' })} ${this.props.intl.formatMessage({ id: 'is required', defaultMessage: 'is required' })}`
            }],
          })(
            <Input
              onChange={event => this.props.updateUser('lastName', event.target.value)}
            />
          )}
        </Form.Item>

        <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: 'function', defaultMessage: 'Function' })}>
          <Input
            value={this.props.user.function}
            onChange={event => this.props.updateUser('function', event.target.value)}
          />
        </Form.Item>

        <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: 'employee number', defaultMessage: 'Employee Number' })}>
          <Input
            value={this.props.user.employeeNumber}
            onChange={event => this.props.updateUser('employeeNumber', event.target.value)}
          />
        </Form.Item>

        <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: 'password', defaultMessage: 'Password' })}>
          <PasswordInput
            value={this.props.user.password}
            onChange={event => this.props.updateUser('password', event.target.value)}
          />
        </Form.Item>

        <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: 'retype password', defaultMessage: 'Retype password' })}>
          <Input.Password
            value={this.props.user.passwordRetype}
            onChange={event => this.props.updateUser('passwordRetype', event.target.value)}
          />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={(<span><FormattedMessage id="group" defaultMessage="Group" /><span style={{ color: '#005591' }}>*</span></span>)}
          required={false}
        >
          {getFieldDecorator('scope', {
            initialValue: this.props.user.scope,
            rules: [{
              required: true,
              message: `${this.props.intl.formatMessage({ id: 'group', defaultMessage: 'Group' })} ${this.props.intl.formatMessage({ id: 'is required', defaultMessage: 'is required' })}`
            }],
          })(
            <Select
              style={{ width: '100%' }}
              onChange={value => this.props.updateUser('scope', value)}
            >
              {Object.keys(this.props.roles).map(role => (
                <Select.Option key={role} value={role}>{this.props.roles[role]}</Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item {...formItemLayout} label={this.props.intl.formatMessage({ id: 'activated', defaultMessage: 'Activated' })}>
          <Checkbox
            checked={this.props.user.activated}
            onChange={event => this.props.updateUser('activated', event.target.checked)}
          />
        </Form.Item>
      </Form>
    )
  }
}

UserForm.propTypes = {
  user: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  roles: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired
}

export default injectIntl(UserForm)
