import React from 'react'
import { notification, Table } from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Helmet } from "react-helmet"

import PageTitle from 'components/Global/PageTitle'
import groupService from 'services/group'

export class GroupsPage extends React.Component {
  state = {
    groups: []
  }

  componentDidMount () {
    this.fetchGroups()
  }

  fetchGroups = async () => {
    try {
      const groups = await groupService.list()

      this.setState({
        groups
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({ id: 'groups', defaultMessage: 'Groups' })} - download error`
      })
    }
  }

  render () {
    const recordingColumns = [
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name'
      }
    ]

    return (
      <div>
        <FormattedMessage id="head.title.groups" defaultMessage="Groups">
          {title => <Helmet><title>{title}</title></Helmet>}
        </FormattedMessage>

        <PageTitle
          title={this.props.intl.formatMessage({ id: 'groups', defaultMessage: 'Groups' })}
        />

        <Table
          columns={recordingColumns}
          dataSource={this.state.groups}
          rowKey={record => record._id}
        />
      </div>
    )
  }
}

export default injectIntl(GroupsPage)
