import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import _ from 'lodash'

import { Table, Icon, Button, notification, Form } from 'antd'

import ContactForm from 'components/Contacts/Form'
import PageTitle from 'components/Global/PageTitle'
import PopconfirmDelete from 'components/Global/PopconfirmDelete'
import contactService from 'services/contact'
import projectService from 'services/project'
import DrawerComponent from 'components/Drawer'

export class ContactPage extends React.Component {
  state = {
    items: [],
    item: {},
    drawer: false,
    loading: false,
    formLoading: false,
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50', '100']
    },
    createdByFilters: [],
    activeFilters: [],
    readOnlyNewProject: false
  };

  componentDidMount () {
    this.fetchContacts()
  }

  fetchContacts = async (params) => {
    this.setState({
      loading: true
    })
    try {
      const contacts = await contactService.listV2({
        ...params
      })

      const pagination = {
        ...this.state.pagination
      }

      pagination.total = contacts.totalDocs

      this.setState({
        items: contacts.docs,
        createdByFilters: contacts.filterOptions.createdBy,
        loading: false,
        pagination
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({ id: 'contacts', defaultMessage: 'Contract Sections' })} - download error`
      })

      this.setState({
        items: [],
        drawer: false,
        loading: false
      })
    }
  }

  showDrawer = contact => {
    if (_.isEmpty(contact, true)) {
      this.setState({
        drawer: true,
        item: {
          ...contact
        }
      })
    } else {
      this.setState({
        drawer: true,
        item: {
          ...contact
        }
      })
    }
  }

  hideDrawer = () => {
    this.setState({
      drawer: false,
      item: {}
    })
  }

  saveContact = async () => {
    this.props.form.validateFields(async err => {
      if (!err) {
        try {
          const savedContact = await contactService.save(this.state.item)
          this.fetchContacts()
          if (this.state.item._id) {
            notification.success({
              message: `${this.props.intl.formatMessage({ id: 'updated successfully', defaultMessage: 'Updated successfully' })}`
            })
          } else {
            notification.success({
              message: `${this.props.intl.formatMessage({ id: 'created successfully', defaultMessage: 'Created successfully' })}`
            })
          }

          this.setState({
            drawer: false,
            item: {},
            items: [
              ...this.state.items.filter(item => item._id !== savedContact._id),
              savedContact
            ]
          })
        } catch (error) {
          notification.error({
            message: this.props.intl.formatMessage({ id: 'saving error', defaultMessage: 'Saving Error' }),
            description: error.error
          })
        }
      }
    })
  }

  removeContact = async contact => {
    try {
      await contactService.remove(contact)

      this.setState({
        drawer: false,
        item: {},
        items: [
          ...this.state.items.filter(item => item._id !== contact._id)
        ]
      })
    } catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({ id: 'deleting error', defaultMessage: 'Deleting Error' }),
        description: error.error
      })
    }
  }

  updateContact = (field, value) => {
    this.setState(
      state => ({
        item: {
          ...state.item,
          [field]: value
        }
      })
    )
  }

  onTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    pager.offset = pagination.current

    this.setState({
      pagination: {
        ...pager,
        current: pagination.current
      }
    })

    this.fetchContractSections({
      limit: pagination.pageSize,
      offset: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    })
  }

  render () {
    const columns = [
      {
        title: this.props.intl.formatMessage({ id: 'company', defaultMessage: 'Company' }),
        key: 'company',
        dataIndex: 'company',
        defaultSortOrder: 'ascend',
        sorter: true
      },
      {
        title: this.props.intl.formatMessage({ id: 'street', defaultMessage: 'Street' }),
        key: 'street',
        dataIndex: 'street'
      },
      {
        title: this.props.intl.formatMessage({ id: 'postcode', defaultMessage: 'Postcode' }),
        key: 'postcode',
        dataIndex: 'postcode'
      },
      {
        title: this.props.intl.formatMessage({ id: 'city', defaultMessage: 'City' }),
        key: 'city',
        dataIndex: 'city'
      },
      {
        title: this.props.intl.formatMessage({ id: 'created at', defaultMessage: 'Created At' }),
        key: 'createdAt',
        dataIndex: 'createdAt',
        sorter: true,
        render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm')
      },
      {
        title: this.props.intl.formatMessage({ id: 'created by', defaultMessage: 'Created By' }),
        key: 'createdBy',
        dataIndex: 'createdBy',
        render: createdBy => createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : '',
        filters: this.state.createdByFilters.map(user => ({
          text: `${user.firstName} ${user.lastName}`,
          value: user._id
        }))
      }, {
        key: 'actions',
        render: (text, record) => (
          <div style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-end'
          }}>
            <React.Fragment>
              <Button onClick={() => this.showDrawer(record)}>
                <Icon type="edit" />
              </Button>
            </React.Fragment>
          </div>
        )
      }
    ]

    const drawerButtons = [
      <PopconfirmDelete onConfirmAction={() => this.removeContact(this.state.item)} />
      ,
      <Button key={65493152} onClick={this.hideDrawer} style={{ marginRight: 8 }}>
        <FormattedMessage
          id="cancel"
          defaultMessage="Cancel"
        />
      </Button>,
      <Button
        key={237323536}
        onClick={this.saveContact} type="primary">
        {this.props.intl.formatMessage({ id: 'save', defaultMessage: 'Save' })}
      </Button>
    ]

    const { items, item, drawer, pagination, projects, loading, formLoading, readOnlyNewProject } = this.state

    const headerButtons = [
      <Button
        key={238397323536}
        type="primary"
        onClick={() => this.showDrawer({})}
      >
        <FormattedMessage
          id="create contact"
          defaultMessage="Create contact"
        />
      </Button>
    ]

    return (
      <div>
        <FormattedMessage id="head.title.contacts" defaultMessage="Contacts">
          {title => <Helmet><title>{title}</title></Helmet>}
        </FormattedMessage>


        <PageTitle
          title={this.props.intl.formatMessage({ id: 'contacts', defaultMessage: 'Contacts' })}
          buttons={headerButtons}
        />

        <Table
          rowKey={record => record._id}
          columns={columns}
          dataSource={items}
          onRow={(record) => ({
            onDoubleClick: () => { this.showDrawer(record) }
          })}
          loading={loading}
          onChange={this.onTableChange}
          pagination={pagination}
        />

        <DrawerComponent
          title={item._id ? item.company : this.props.intl.formatMessage({ id: 'create contact', defaultMessage: 'Create contact' })}
          onClose={this.hideDrawer}
          visible={!!drawer}
          footerButtons={drawerButtons}
        >
          <ContactForm
            contact={item}
            formLoading={formLoading}
            updateContact={this.updateContact}
            form={this.props.form}
          />
        </DrawerComponent>
      </div>
    )
  }
}

const mapStateToProps = state => ({
})

export default injectIntl(Form.create({ name: 'contact_form' })(connect(mapStateToProps)(ContactPage)))
